/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';
import debounce from 'lodash/debounce';

import { currentDiagramStore } from 'stores';
import {
  addBottomOverlayAdaptively,
  addFirstOverlayFromRight,
  removeOverlay,
  removeOverlayById
} from 'App/Pages/Diagram/overlay-util';
import { publicationStore } from 'App/Pages/Diagram/stores';
import createPermission from 'utils/createPermission';

import formLinkStore from './FormLinkStore';
import { getSupportedElements, isFormLinkingSupportedByElement, isPublicationElement } from './utils';

const DIAGRAM_CHANGE_DEBOUNCE_TIME = 100;
const OVERLAY_TYPE = 'form-link-menu';
const overlayIcons = {};

function FormLinkExtension(overlays, eventBus, elementRegistry) {
  const onDiagramChanges = debounce(() => {
    formLinkStore.setLastSelectedElementChanged();
    hydrateLinkedElements();
    computeFeatureSupportAndOverlay();
  }, DIAGRAM_CHANGE_DEBOUNCE_TIME);

  const onDiagramChangesFromModdlePropertiesUpdate = (event) => {
    const { context } = event;

    if (context.element.id === formLinkStore.selectedElementId) {
      onDiagramChanges();
    }
  };

  const onSelectionChanged = (event) => {
    const newElement = event.newSelection[0];

    if (!newElement) {
      formLinkStore.setSelectedElement(null);
      return;
    }

    storeSelectedElementOnSelection(event.newSelection);
    computeFeatureSupportAndOverlay();
  };

  const cleanExistingOverlaysPerElement = (element) => {
    overlayIcons[element.id]?.forEach((overlay) => removeOverlayById(overlays, overlay));
    overlayIcons[element.id] = [];
  };

  const addOverlayToElement = (overlays, element) => {
    let overlay;

    const permission = createPermission(currentDiagramStore.state?.project?.permissionAccess);
    if (permission.is(['WRITE', 'ADMIN', 'COMMENT'])) {
      // Comment overlay present at first position from right
      addBottomOverlayAdaptively(overlays, OVERLAY_TYPE, element);
    } else {
      addFirstOverlayFromRight(overlays, OVERLAY_TYPE, element);
    }

    if (overlay) {
      cleanExistingOverlaysPerElement(element);
      overlayIcons[element.id] = [...(overlayIcons[element.id] || []), overlay];
    }
  };

  const computeFeatureSupportAndOverlay = () => {
    let isPublicAccessSupported = false;

    const compute = (element) => {
      try {
        if (isFormLinkingSupportedByElement(element)) {
          // If the element is a publication element, we mark the feature as supported to update the store later
          if (isPublicationElement(element)) {
            isPublicAccessSupported = true;
          }

          addOverlayToElement(overlays, element);
        } else {
          removeOverlay(overlays, OVERLAY_TYPE, element);
        }
      } catch (err) {
        console.warn(err);
      }
    };

    getSupportedElements(elementRegistry).forEach((startEvent) => compute(startEvent));

    publicationStore.setIsPublicAccessSupported(isPublicAccessSupported);
  };

  /**
   * Hydrates the formLinkStore with all elements that have a linked form.
   */
  const hydrateLinkedElements = () => {
    const elementsWithLinkedForm = getElementsWithLinkedForm();
    const { userTasks, startEvents } = elementsWithLinkedForm;

    formLinkStore.setLinkedElements([...userTasks, ...startEvents]);
    publicationStore.setIsStartEventAttachedToForm(startEvents.length > 0);
  };

  const getElementsWithLinkedForm = () => {
    const forms = {};

    // Find business object of all processes. If the root element is a collaboration,
    // the process is not a direct element of the registry, but accessible via the processRef
    // field of the collaboration participant
    const processes = elementRegistry
      .filter(
        ({ businessObject }) =>
          businessObject?.$instanceOf('bpmn:Process') || businessObject?.processRef?.$instanceOf('bpmn:Process')
      )
      .map((element) => element.businessObject?.processRef || element.businessObject);

    if (!processes?.length) {
      return { userTasks: [], startEvents: [] };
    }

    processes?.forEach((process) => {
      process?.extensionElements?.values?.forEach((value) => {
        if (value.$type === 'zeebe:UserTaskForm') {
          forms[value.id] = value.body;
        }
      });
    });

    const getElementsOfType = (type) => {
      const valueHasValidFormKey = (value) => {
        const regex = /^camunda-forms:bpmn:(usertaskform)/i;
        return value.$type === 'zeebe:FormDefinition' && regex.test(value.formKey);
      };
      const elements = elementRegistry.filter((el) => is(el, type));
      const output = [];

      elements?.forEach((task) => {
        task?.businessObject?.extensionElements?.values?.forEach((value) => {
          if (value.formId) {
            // Newer diagrams use formId instead of formKey
            if (forms[value.formId]?.trim() !== '') {
              output.push({ elementId: task.id, elementType: type, formId: value.formId });
            }
          } else if (valueHasValidFormKey(value)) {
            // Older diagrams use formKey, which is a reference to the embedded form
            let key;
            if (value && value.formKey) {
              const parts = value.formKey.split(':');
              if (parts.length > 2) {
                key = parts[2];

                const hasValidFormConfiguration = forms[key] && forms[key].trim() !== '';
                if (hasValidFormConfiguration) {
                  output.push({ elementId: task.id, elementType: type, formKey: key, formBody: forms[key] });
                }
              } else {
                throw new Error(`Invalid formKey: ${value.formKey}`);
              }
            } else {
              throw new Error('No formKey found');
            }
          }
        });
      });

      return output;
    };

    return {
      userTasks: getElementsOfType('bpmn:UserTask'),
      startEvents: getElementsOfType('bpmn:StartEvent')
    };
  };

  const storeSelectedElementOnSelection = (newSelection) => {
    if (newSelection?.length === 1) {
      const element = newSelection[0];
      storeElement(element);
    }
  };

  const storeElement = (element) => {
    if (element && isFormLinkingSupportedByElement(element)) {
      formLinkStore.setSelectedElement(element);
    } else {
      formLinkStore.setSelectedElement(null);
    }
  };

  const onLabelUpdate = (e) => {
    const elementWhoseLabelWasUpdated = e?.context?.element;
    if (formLinkStore.selectedElementId === elementWhoseLabelWasUpdated?.id) {
      storeElement(elementWhoseLabelWasUpdated);
    }
  };

  eventBus.on(
    ['shape.added', 'shape.removed', 'commandStack.propertiesPanel.zeebe.changeTemplate.postExecute'],
    onDiagramChanges
  );
  eventBus.on('commandStack.element.updateModdleProperties.postExecute', onDiagramChangesFromModdlePropertiesUpdate);
  eventBus.on('selection.changed', onSelectionChanged);
  eventBus.on('commandStack.element.updateLabel.executed', onLabelUpdate);
}

FormLinkExtension.$inject = ['overlays', 'eventBus', 'elementRegistry'];

export default {
  __init__: ['formLinkExtension'],
  formLinkExtension: ['type', FormLinkExtension]
};
