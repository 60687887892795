/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { EventSourcePolyfill } from 'event-source-polyfill';

import config from 'utils/config';

import Service from './Service';

class ClusterService extends Service {
  /**
   * Fetches all available clusters.
   *
   * @returns {Promise}
   */
  getClusters(organizationId) {
    return this.get(`/cloud/console/organizations/${organizationId}/clusters`);
  }

  getEventStream(token) {
    return new EventSourcePolyfill(`https://console.${config.camundaCloudBaseDomain}/external/events`, {
      headers: {
        authorization: `Bearer ${token}`
      }
    });
  }

  /**
   * Creates a temporary cluster
   *
   * @returns {Promise}
   */
  createTemporaryCluster(organizationId) {
    return this.post(`/cloud/console/organizations/${organizationId}/temporaryCluster`);
  }

  /**
   * Creates a temporary cluster in the current organization if the user is in a non-expired
   * trial org and does not have any clusters yet.
   *
   * @returns {Promise}
   */
  async createClusterIfNecessary({ organizationId, isUsingTrial, isTrialExpired }) {
    if (isUsingTrial && !isTrialExpired) {
      // is on a non-expired trial cluster, check if they have a cluster
      const clusterInfo = await this.getClusters(organizationId);

      if (!clusterInfo?.length) {
        // no cluster exists, create one
        try {
          await this.createTemporaryCluster(organizationId);
        } catch (e) {
          // fail silently
        }
      }
    }
  }

  resumeCluster(organizationId, clusterId) {
    return this.patch(`/cloud/console/organizations/${organizationId}/clusters/${clusterId}/wake`);
  }
}

export default new ClusterService();
