/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Typography, Input, Loader } from 'primitives';

export const Heading = styled(Typography)`
  text-align: center;
`;

export const Textarea = styled(Input)`
  margin-top: var(--spacing-small);
`;

export const HelperText = styled.p`
  margin: 0;
  font-size: 13px;
`;

export const LoadingAnimation = styled(Loader)`
  margin-right: 10px;
`;

export const ExternalUserWarning = styled.div`
  margin-top: 10px;
`;
