/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Avatar } from 'primitives';

export const Wrapper = styled.div`
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
`;

export const UserAvatar = styled(Avatar)`
  margin-right: -5px;
`;

export const UserCount = styled(UserAvatar)`
  --color: white;
  border-color: var(--grey-base-40);
  color: var(--grey-base-40);
  border-width: 1px;
  box-shadow: 0 0 0 2px white;
`;
