/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { trackingService } from 'services';

export function trackClusterCreation() {
  return trackingService.track('modeler:zeebePlay:clusterCreated');
}
export function trackDeployment({ redeployment, success, message, failure_type, details }) {
  return trackingService.track('modeler:zeebePlay:deployment', {
    redeployment,
    success,
    message,
    failure_type,
    details
  });
}
export function trackResolveIncident(process_id) {
  return trackingService.track('zeebePlay:single-operation', {
    operationType: 'RESOLVE_INCIDENT',
    process_id: process_id
  });
}
export function trackIncident(incident, process_id) {
  const incidentTrackingState = JSON.parse(localStorage.getItem('play-incidentTracking') || '{}');

  if (incidentTrackingState[incident.key]) return;

  trackingService.track('zeebePlay:incident:trigger', {
    error: incident.errorMessage,
    process_id
  });

  incidentTrackingState[incident.key] = true;

  localStorage.setItem('play-incidentTracking', JSON.stringify(incidentTrackingState));
}
export function trackBpmnElementCompletion(elementInstance, bpmnId, bpmnViewer) {
  if (!bpmnViewer) return;

  const { bpmnElementType } = elementInstance.element;

  // start events are handled when instances are started to extract "From:" property
  if (bpmnElementType === 'START_EVENT') return;

  const elementInstanceTrackingState = JSON.parse(localStorage.getItem('tracking:' + bpmnId) || '{}');

  // check if the element is a connector
  const element = bpmnViewer.get('elementRegistry').get(elementInstance.element.elementId);
  const businessObject = element?.businessObject ?? element;

  let connector = null;
  if (businessObject?.hasOwnProperty('modelerTemplate')) {
    connector = businessObject?.modelerTemplate;
  } else if (businessObject?.$attrs?.hasOwnProperty('zeebe:modelerTemplate')) {
    connector = businessObject?.$attrs['zeebe:modelerTemplate'];
  }

  const connectorId = convertConnectorId(connector);
  const hasConnector = Boolean(connectorId);

  const connectorOrElementType = connectorId || bpmnElementType;

  // track process completion
  if (bpmnElementType === 'PROCESS' && !elementInstanceTrackingState[bpmnElementType + ':' + elementInstance.key]) {
    trackingService.track('zeebePlay:bpmnelement:completed', {
      element_type: 'PROCESS',
      process_id: bpmnId
    });
    elementInstanceTrackingState[bpmnElementType + ':' + elementInstance.key] = true;
    elementInstanceTrackingState[bpmnElementType] = true;
  }

  // track element completion
  if (!elementInstanceTrackingState[connectorOrElementType]) {
    trackingService.track('zeebePlay:bpmnelement:completed', {
      [hasConnector ? 'connector_type' : 'element_type']: connectorOrElementType,
      process_id: bpmnId
    });
    elementInstanceTrackingState[connectorOrElementType] = true;
  }

  localStorage.setItem('tracking:' + bpmnId, JSON.stringify(elementInstanceTrackingState));
}

export function trackInstanceStart(process_id) {
  return trackingService.track('zeebePlay:bpmnelement:completed', {
    element_type: 'START_EVENT',
    From: 'webModeler',
    process_id
  });
}

export function trackAPIError(path, status_code) {
  return trackingService.track('zeebePlay:apiError', {
    path,
    status_code
  });
}

function convertConnectorId(id) {
  if (id && !id.startsWith('io.camunda.connectors.')) {
    return 'proprietary_connector';
  }
  return id;
}
