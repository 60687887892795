/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Launch } from '@carbon/icons-react';

import * as Styled from './WithExternalLinkIcon.styled';

export default function WithExternalLinkIcon({ label, iconColor }) {
  return (
    <Styled.Wrapper $iconColor={iconColor}>
      <Styled.Label>{label}</Styled.Label>
      <Launch size="16" className="externalLinkIcon" />
    </Styled.Wrapper>
  );
}
