/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { transportCarrier } from 'transports';

export default class RealtimeChannel {
  constructor(channelName, events) {
    this.channelName = channelName;
    this.events = events;
    this.socket = transportCarrier.socket;
  }

  subscribe = () => {
    if (!this.socket) {
      return;
    }

    this.channel = this.socket.subscribe(this.channelName);
    this.listenToEvents();
  };

  listenToEvents = () => {
    Object.keys(this.events).forEach((eventName) => {
      this.channel?.bind(eventName, this.events[eventName]);
    });
  };

  unsubscribe = () => {
    if (!this.socket) {
      return;
    }

    this.socket.unsubscribe(this.channelName);
  };
}
