/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState, useRef } from 'react';

import { Input } from 'primitives';
import { Cross, Check } from 'icons';

import * as Styled from './TargetSelector.styled';

export default function NewItem({ onCancel, onSubmit, initialName }) {
  const [name, setName] = useState(initialName);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.setSelectionRange(0, initialName.length);
  }, []);

  useEffect(() => {
    const handleKeyDown = (evt) => {
      if (evt.key === 'Escape') {
        evt.stopPropagation();
        onCancel();
      }

      if (evt.key === 'Enter') {
        onSubmit(name);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [name]);

  return (
    <Styled.NewItem>
      <Input
        ref={inputRef}
        placeholder="Enter a name"
        autoFocus
        value={name}
        onChange={(evt) => setName(evt.target.value)}
        data-test="target-name"
      />
      <Styled.ItemButton onClick={onCancel} title="Cancel" data-test="cancel">
        <Cross width="18" height="18" />
      </Styled.ItemButton>
      <Styled.ItemButton onClick={() => onSubmit(name)} title="Submit" data-test="submit">
        <Check width="18" height="18" />
      </Styled.ItemButton>
    </Styled.NewItem>
  );
}
