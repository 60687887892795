/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { currentDiagramStore, notificationStore } from 'stores';

const CONFLICT_MESSAGE =
  'There was a conflict in diagram saving, your last change may have been lost due to a force refresh.';

export default class ConflictPreventionExtension {
  constructor(eventBus) {
    this.eventBus = eventBus;

    eventBus.on(['import.done'], this.addDragListener);
  }

  handleDragInit = () => {
    currentDiagramStore.isDraggingActive = true;
  };

  handleDragEnded = () => {
    currentDiagramStore.isDraggingActive = false;

    if (currentDiagramStore.hasReceivedUpdateWhileDragging || currentDiagramStore.hasFetchedDiagramWhileDragging) {
      currentDiagramStore.hasReceivedUpdateWhileDragging = false;
      currentDiagramStore.hasFetchedDiagramWhileDragging = false;
      currentDiagramStore.reloadDiagram();
      notificationStore.showNotification({
        message: CONFLICT_MESSAGE,
        variant: 'error'
      });
    }
  };

  addDragListener = () => {
    this.eventBus.on('drag.init', this.handleDragInit);
    this.eventBus.on('drag.ended', this.handleDragEnded);
  };
}

ConflictPreventionExtension.$inject = ['eventBus', 'config.conflictPreventionExtension'];
