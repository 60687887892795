/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { createRoot } from 'react-dom/client';
import { Link } from '@carbon/react';
import { autorun } from 'mobx';
import { is } from 'bpmn-js/lib/util/ModelUtil';

import { currentDiagramStore } from 'stores';

import MarketplaceIcon from './MarketplaceIcon';
import {
  browseMarketplaceButtonEventManager,
  BROWSE_MARKETPLACE_ONCLICK_EVENT,
  BROWSE_MARKETPLACE_ONDESTROY_EVENT
} from './';

const DISALLOWED_TYPES = [
  'bpmn:TextAnnotation',
  'bpmn:EndEvent',
  'bpmn:DataObjectReference',
  'bpmn:DataStoreReference',
  'bpmn:Group',
  'bpmn:Participant',
  'bpmn:Association'
];

class BrowseMarketplaceProvider {
  #popupMenu;
  #eventBus;
  #marketplaceCtaContainer;
  #mutationObserver;
  #storeListenerDisposer;

  constructor(popupMenu, eventBus) {
    this.#popupMenu = popupMenu;
    this.#eventBus = eventBus;

    this.#popupMenu.registerProvider('bpmn-create', this);
    this.#popupMenu.registerProvider('bpmn-replace', this);
    this.#popupMenu.registerProvider('bpmn-append', this);
    this.#popupMenu.registerProvider('element-template-chooser', this);

    this.#registerMutationObserver();
    this.#registerStoreListener();
    this.#init();
  }

  getPopupMenuEntries() {
    return {};
  }

  getPopupMenuHeaderEntries(target) {
    if (!this.#isAllowedType(target)) {
      return {};
    }

    return {
      browseConnectors: {
        action: () => browseMarketplaceButtonEventManager.emit(BROWSE_MARKETPLACE_ONCLICK_EVENT),
        label: 'Browse',
        title: 'Browse Marketplace for more Connectors',
        className: 'btn-browse-marketplace',
        imageUrl: MarketplaceIcon
      }
    };
  }

  #isAllowedType(target) {
    return DISALLOWED_TYPES.every((disallowedType) => !is(target, disallowedType));
  }

  #registerStoreListener() {
    this.#storeListenerDisposer = autorun(() => {
      if (currentDiagramStore.state?.templates?.length) {
        // When the templates are loaded, we need to force the popup menu to re-render.
        // We do it by closing and opening the popup menu again (until we find a better solution).
        // NOTE: There is a feature request to solve this problem: https://github.com/bpmn-io/diagram-js/issues/804
        try {
          if (this.#popupMenu.isOpen()) {
            const { target, position, options, className: providerId } = this.#popupMenu._current;

            this.#popupMenu.close();

            if (providerId === 'element-template-chooser') {
              // The element template chooser implicitely opens the popup, and on top of that registers event listeners to apply the template.
              // We take advantage of this implementation by firing the event that the element template chooser listens to.
              this.#eventBus.fire('elementTemplates.select', { element: target });
            } else {
              // For the other providers, we need to open the popup again.
              this.#popupMenu.open(target, providerId, position, options);
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    });
  }

  #registerMutationObserver() {
    const callback = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const noResultsElement = document.querySelector('.djs-popup-no-results');
          if (noResultsElement) {
            this.#addMarketplaceLinkToNoResults(noResultsElement);
          }
        }
      }
    };

    const targetNode = document.querySelector('.diagram-container');

    const config = { childList: true, subtree: true };
    this.#mutationObserver = new MutationObserver(callback);
    this.#mutationObserver.observe(targetNode, config);
  }

  #init() {
    browseMarketplaceButtonEventManager.subscribe(BROWSE_MARKETPLACE_ONDESTROY_EVENT, this.#reset);
  }

  #reset = () => {
    this.#unregisterMutationObserver();
    this.#unregisterStoreListener();
    browseMarketplaceButtonEventManager.unsubscribe(BROWSE_MARKETPLACE_ONDESTROY_EVENT, this.#reset);
  };

  #unregisterMutationObserver() {
    if (this.#mutationObserver) {
      this.#mutationObserver.disconnect();
    }
  }

  #unregisterStoreListener() {
    if (this.#storeListenerDisposer) {
      this.#storeListenerDisposer();
    }
  }

  #addMarketplaceLinkToNoResults(targetNode) {
    if (targetNode?.textContent === 'No matching entries found.') {
      const marketplaceCta = (
        <div>
          <span>No matching entries found.</span>
          <div style={{ marginTop: '5px' }}>
            <Link
              href="#"
              title="Browse Marketplace for more Connectors."
              onClick={() => browseMarketplaceButtonEventManager.emit(BROWSE_MARKETPLACE_ONCLICK_EVENT)}
            >
              Browse Marketplace for more Connectors.
            </Link>
          </div>
        </div>
      );

      if (this.#marketplaceCtaContainer) {
        this.#marketplaceCtaContainer.unmount();
      }

      this.#marketplaceCtaContainer = createRoot(targetNode);
      this.#marketplaceCtaContainer.render(marketplaceCta);
    }
  }
}

BrowseMarketplaceProvider.$inject = ['popupMenu', 'eventBus'];

export default {
  __init__: ['browseMarketplace'],
  browseMarketplace: ['type', BrowseMarketplaceProvider]
};
