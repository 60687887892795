/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Button, ProgressBar } from '@carbon/react';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { truncate } from 'lodash';
import moment from 'moment';

import { Dropdown, TargetSelector, FormPreview } from 'components';
import { ElementMenuButton } from 'primitives';
import { Details, Link } from 'icons';
import { currentDiagramStore, commentsStore, notificationStore } from 'stores';
import { trackingService, fileService } from 'services';
import buildSlug from 'utils/buildSlug';
import * as LinkOverlayStyled from 'App/Pages/Diagram/LinkOverlay.styled';

import formLinkStore from './FormLinkStore';
import * as Styled from './FormLinkOverlay.styled';

function findElement(selector, parent = document) {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const element = parent.querySelector(selector);

      if (element) {
        clearInterval(interval);
      }

      resolve(element);
    }, 10);
  });
}

function triggerChange(element, value) {
  const event = new CustomEvent('input', {});

  element.value = value;
  element.dispatchEvent(event);
}

function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const Overlay = observer(({ element, readOnly }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [linkedForms, setLinkedForms] = useState([]);
  const [formState, setFormState] = useState({
    isValidLink: false,
    isEmbedded: false,
    parsingFailed: false,
    content: null
  });
  const { project } = currentDiagramStore.state;
  const { isLoading, lastSelectedElementChanged } = formLinkStore;

  const getLinkedFormsAndStore = async () => {
    try {
      const forms = await formLinkStore.getExistingLinks(element);
      setLinkedForms(forms);
    } catch (err) {
      notificationStore.showError('Yikes! Could not fetch linked forms. Please try again later.');
      setFormState((prevState) => ({ ...prevState, parsingFailed: true }));
    }
  };

  const getProjectDetails = async () => {
    setProjectDetails(await currentDiagramStore.updateProject(project.id));
  };

  const parseEmbededdFormAndSetState = ({ isValidLink, isEmbedded }) => {
    const trimmedFormBody = formLinkStore.getEmbeddedForm(element)?.trim();

    try {
      const parsedContent = JSON.parse(trimmedFormBody);
      setFormState((prevState) => ({ ...prevState, isValidLink, isEmbedded, content: parsedContent }));
    } catch (e) {
      console.error(e);
      setFormState((prevState) => ({ ...prevState, parsingFailed: true }));
      notificationStore.showError('Failed to parse form schema.');
    }
  };

  useEffect(() => {
    let isValidLink = false;
    let isEmbedded = false;

    if (element) {
      isValidLink = formLinkStore.isElementLinked(element);
      isEmbedded = formLinkStore.hasEmbeddedForm(element);
    }

    if (anchorEl) {
      if (isValidLink) {
        if (isEmbedded) {
          parseEmbededdFormAndSetState({ isValidLink, isEmbedded });
        } else {
          getLinkedFormsAndStore(element);
        }
      } else {
        getProjectDetails();
      }
    } else {
      setLinkedForms([]);
      setFormState({ isValidLink, isEmbedded, content: null });
    }
  }, [anchorEl, element, lastSelectedElementChanged]);

  /**
   * Sets the form in the properties panel.
   * @param {*} formId
   */
  const setFormInPropertiesPanel = async (formId) => {
    selectElement();

    const group = await findElement('[data-group-id="group-form"]');
    const groupHeader = group?.querySelector('.bio-properties-panel-group-header');

    if (!groupHeader) {
      throw new Error('Missing form group header');
    }

    if (groupHeader && !groupHeader.matches('.open')) {
      groupHeader.click();
    }

    const formType = await findElement('#bio-properties-panel-formType');

    if (!formType) {
      throw new Error('Missing form type input');
    }

    if (formId === '') {
      triggerChange(formType, '');
      formType.focus();
    } else {
      // change form type
      if (formType.value !== 'camunda-form-linked') {
        triggerChange(formType, 'camunda-form-linked');
      }

      await wait(10);

      // change form value
      const formImpl = await findElement('#bio-properties-panel-formId');

      if (!formImpl) {
        throw new Error('Missing form implementation input');
      }

      triggerChange(formImpl, formId);
      formImpl.select();
    }
  };

  const trackResourceLinking = (action) => {
    trackingService.trackResourceLinking({ action, resource: 'form' });
  };

  const linkForm = async (form) => {
    const { content } = await fileService.fetchById(form.id);
    try {
      const parsedContent = JSON.parse(content);

      setAnchorEl(null);
      commentsStore.makePropertiesVisible();
      await setFormInPropertiesPanel(parsedContent.id);
      notificationStore.showSuccess('Form has been successfully linked.');
      trackResourceLinking('link');
    } catch (e) {
      console.error(e);
      notificationStore.showError('Failed to link form.');
    }
  };

  const unlinkForm = async () => {
    commentsStore.makePropertiesVisible();
    await setFormInPropertiesPanel('');
    setAnchorEl(null);
    notificationStore.showSuccess('Form has been unlinked.');
    trackResourceLinking('unlink');
  };

  const openForm = async () => {
    if (linkedForms?.length === 1) {
      history.push(`/forms/${buildSlug(linkedForms[0].fileId, linkedForms[0].fileName)}`);
    }
  };

  const handleClick = (e) => {
    // popupMenu is not available in the NavigatedViewer, which is used for read only users; that's why we need to check for it
    // and suppress the error in case it's not available.
    currentDiagramStore.modeler.get('popupMenu', false)?.close();

    if (formContent) {
      selectElement();
    }

    const completeLabelEditingOnMenuOpen = () => {
      if (!anchorEl) {
        currentDiagramStore.modeler.get('directEditing', false)?.complete();
      }
    };
    completeLabelEditingOnMenuOpen();

    if (!anchorEl) {
      trackResourceLinking('openMenu');
    }

    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const selectElement = () => {
    const { modeler } = currentDiagramStore;
    const task = modeler.get('elementRegistry').get(element.id);
    modeler.get('selection').select(task);
  };

  const canShowActions = !readOnly || (readOnly && !formState.isEmbedded);
  const hasOnlyOneForm = formState.content || (linkedForms?.length === 1 && linkedForms[0].content);
  const hasMultipleForms = linkedForms?.length > 1;
  const hasLinkedFormButNoFiles = formState.isValidLink && !formState.isEmbedded && linkedForms?.length === 0;
  const formName = linkedForms?.[0]?.name;
  const formContent = formState.content || linkedForms?.[0]?.content;
  const formLastUpdate = linkedForms?.[0]?.lastUpdate;
  const formPreviewTitle = hasLinkedFormButNoFiles || hasMultipleForms ? 'Linked form' : formName || 'Form Preview';

  return (
    <LinkOverlayStyled.Container>
      <ElementMenuButton
        onClick={handleClick}
        title={`${formState.isValidLink ? 'Click to see the form configuration' : 'Click to add a form'}`}
      >
        {formState.isEmbedded ? (
          <Details data-test="form-embed-icon" width="20" height="20" />
        ) : (
          <Link data-test="form-link-icon" width="20" height="20" />
        )}
      </ElementMenuButton>

      {formState.isValidLink ? (
        <Dropdown
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          width={360}
          align="left"
          noPadding
        >
          <Dropdown.Title noPadding>
            {formPreviewTitle}{' '}
            {formLastUpdate && (
              <Styled.FormLastUpdated>
                Last updated on{' '}
                <span title={moment(formLastUpdate).calendar()}>{moment(formLastUpdate).format("MMM Do 'YY")}</span>
              </Styled.FormLastUpdated>
            )}
          </Dropdown.Title>

          {isLoading ? (
            <Styled.FormBody>
              <ProgressBar label="Loading" helperText="Fetching linked forms" />
            </Styled.FormBody>
          ) : (
            <>
              {formState.parsingFailed ? (
                <Styled.FormBody>Form schema invalid</Styled.FormBody>
              ) : (
                <>
                  {hasLinkedFormButNoFiles && (
                    <>
                      <LinkOverlayStyled.InfoResourceId>
                        {formLinkStore.getLinkedFormId(element)}
                      </LinkOverlayStyled.InfoResourceId>
                      <LinkOverlayStyled.InfoMessage>
                        A form with this ID could not be found in the current project.
                      </LinkOverlayStyled.InfoMessage>
                    </>
                  )}

                  {hasMultipleForms && (
                    <>
                      <LinkOverlayStyled.LinkContainer>
                        <ul>
                          {linkedForms?.map((form) => (
                            <li key={form.id}>
                              <LinkOverlayStyled.Link
                                data-test={`linked-diagram-${form.name}`}
                                onClick={() => {
                                  history.push(`/forms/${buildSlug(form.id, form.name)}`);
                                }}
                              >
                                {form.name}
                              </LinkOverlayStyled.Link>
                            </li>
                          ))}
                        </ul>
                      </LinkOverlayStyled.LinkContainer>

                      <LinkOverlayStyled.InfoMessage>
                        There are {linkedForms.length} forms with the same id:{' '}
                        <span title={linkedForms[0].formId}>{truncate(linkedForms[0].formId, { length: 20 })}</span>.
                        Please consider updating the forms to have unique identifiers.
                      </LinkOverlayStyled.InfoMessage>
                    </>
                  )}
                  {hasOnlyOneForm && formContent && <FormPreview formDefinition={formContent} scale={0.8} readOnly />}
                </>
              )}
            </>
          )}

          {canShowActions && (
            <LinkOverlayStyled.Footer>
              <LinkOverlayStyled.FooterActions>
                {!formState.isEmbedded && (
                  <>
                    {!readOnly && (
                      <Button kind="danger--ghost" size="sm" onClick={unlinkForm}>
                        Unlink
                      </Button>
                    )}

                    {hasOnlyOneForm && (
                      <Button kind="ghost" size="sm" onClick={openForm}>
                        Open in form editor
                      </Button>
                    )}
                  </>
                )}

                {formState.isEmbedded && !readOnly && (
                  <Button kind="danger--ghost" size="sm" onClick={unlinkForm}>
                    Remove form
                  </Button>
                )}
              </LinkOverlayStyled.FooterActions>
            </LinkOverlayStyled.Footer>
          )}
        </Dropdown>
      ) : (
        <>
          {projectDetails && (
            <TargetSelector
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              startingPoint={project}
              onSubmit={linkForm}
              action="form-link"
              showFiles
              description="Add form"
            />
          )}
        </>
      )}
    </LinkOverlayStyled.Container>
  );
});

const FormLinkOverlay = ({ ...props }) => {
  const selector = `.djs-overlays[data-container-id="${props.element?.id}"] .form-link-menu`;
  const container = document.querySelector(selector);

  if (!container) {
    console.warn('No container found for FormLinkOverlay');
    return null;
  }

  return ReactDOM.createPortal(<Overlay {...props} />, container);
};

export default FormLinkOverlay;
