/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import BPMNModdle from 'bpmn-moddle';
import { is } from 'bpmn-js/lib/util/ModelUtil';

import { getCalledElementProcessId } from 'utils/web-modeler-diagram-parser';

const isCallActivity = (element) => {
  return is(element, 'bpmn:CallActivity');
};

const getLinkedCallActivities = async (xml) => {
  try {
    if (xml) {
      const bpmnModdle = new BPMNModdle();
      const { elementsById } = await bpmnModdle.fromXML(xml);
      const callActivities = Object.values(elementsById).filter(isCallActivity);
      return callActivities.filter((element) => {
        const extensionElements = element?.get('extensionElements');
        return Boolean(getCalledElementProcessId(extensionElements));
      });
    }
  } catch (e) {
    console.info(e);
  }
  return [];
};

const getCallActivityLinksFromElements = (elements) => {
  let callActivities = [];
  elements?.forEach((element) => {
    if (element.flowElements) {
      callActivities = callActivities.concat(getCallActivityLinksFromElements(element.flowElements));
    } else {
      if (is(element, 'bpmn:CallActivity')) {
        const extensionElements = element?.get('extensionElements');
        const processId = getCalledElementProcessId(extensionElements);
        if (processId) {
          callActivities.push(processId);
        }
      }
    }
  });
  return callActivities;
};

export const getCallActivityLinksFromXml = async (xml) => {
  const linkedCallActivities = await getLinkedCallActivities(xml);
  return getCallActivityLinksFromElements(linkedCallActivities);
};

export const getCallActivityLinks = (definitions) => {
  if (!definitions) {
    throw new Error('You need to pass modeler definitions to get the call activity links of the process');
  }

  const { rootElements } = definitions;
  const callActivityLinks = getCallActivityLinksFromElements(rootElements);

  if (callActivityLinks.length === 0) {
    return;
  }
  return callActivityLinks;
};
