/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Service from './Service';

class MilestoneService extends Service {
  /**
   * Fetches a single milestone (including its content and additional
   * property) based on the given `milestoneId`.
   *
   * @param {String} milestoneId
   * @returns {Promise}
   */
  fetchById(milestoneId) {
    return this.get(`/internal-api/milestones/${milestoneId}`);
  }

  /**
   * Fetches all milestones for a file based on the given `fileId`.
   *
   * @param {String} fileId
   * @returns {Promise}
   */
  fetchByFileId(fileId) {
    return this.get(`/internal-api/files/${fileId}/milestones`);
  }

  /**
   * Creates a new milestone.
   *
   * @param {Object} payload
   * @param {String} payload.fileId
   * @param {String} payload.name The milestone's name.
   * @returns {Promise}
   */
  create(payload) {
    return this.post('/internal-api/milestones', payload);
  }

  /**
   * Conditionally creates a new "Autosaved" milestone. The backend
   * determines based on the file content whether this new milestone
   * should be created or not.
   *
   * @param {String} fileId
   * @param {Object} payload
   * @param {String} payload.name The milestone name.
   * @returns
   */
  createAutosaved(fileId, payload) {
    return this.post(`/internal-api/files/${fileId}/milestones/autosave`, payload);
  }

  /**
   * Creates a new diagram off a specific milestone. The milestone's content
   * will be used for the creation.
   *
   * @param {String} milestoneId The milestone's ID that's used as the source.
   * @param {Object} payload
   * @param {String} payload.targetProjectId The target project's ID that will contain the newly created diagram.
   * @param {String} payload.targetFolderId The target folder's ID that will contain the newly created diagram.
   * @param {String} [payload.processId] The process ID (only for DMN and BPMN diagrams).
   * @returns {Promise}
   */
  createDiagramFromMilestone(milestoneId, payload) {
    return this.post(`/internal-api/milestones/${milestoneId}/duplicate`, payload);
  }

  /**
   * Renames a specific milestone based on the given `milestoneId`.
   *
   * @param {String} milestoneId
   * @param {Object} payload
   * @param {String} payload.name The new milestone name.
   * @returns {Promise}
   */
  rename(milestoneId, payload) {
    return this.patch(`/internal-api/milestones/${milestoneId}`, payload);
  }

  /**
   * Publishes a specific milestone to the organization.
   *
   * @param {String} milestoneId
   * @returns {Promise}
   */
  publishToOrganization(milestoneId) {
    return this.patch(`/internal-api/milestones/${milestoneId}`, { organizationPublic: true });
  }

  /**
   * Removes a specific milestone based on the given `milestoneId`.
   *
   * @param {String} milestoneId
   * @returns {Promise}
   */
  destroy(milestoneId) {
    return this.delete(`/internal-api/milestones/${milestoneId}`);
  }

  /**
   * Restores a specific milestone's content into it's associated diagram.
   *
   * @param {String} milestoneId
   * @param {Object} payload
   * @param {String} payload.restoredName The new diagram name after restoration.
   * @param {String} [payload.relationId] The diagram's relation ID (only for DMN and BPMN diagrams).
   * @param {String} [payload.processId] The diagram's process ID (only for DMN and BPMN diagrams).
   * @returns {Promise}
   */
  restore(milestoneId, payload) {
    return this.post(`/internal-api/milestones/${milestoneId}/restore`, payload);
  }

  /**
   * Restores a related diagram's content into the target file.
   *
   * @param {String} targetFileId The target file, which will receive the changes.
   * @param {Object} payload
   * @param {String} payload.fileId The source diagram, which will provide the changes.
   * @param {String} [payload.processId] The diagram's process ID (only for DMN and BPMN diagrams).
   * @returns {Promise}
   */
  restoreRelatedDiagram(targetFileId, payload) {
    return this.post(`/internal-api/files/${targetFileId}/milestones/related`, payload);
  }
}

export default new MilestoneService();
