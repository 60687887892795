/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useMemo } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Typography.styled';

export default function Typography({ variant, component, children, ...props }) {
  const tag = useMemo(() => {
    if (component) {
      return component;
    } else if (variant === 'body') {
      return 'p';
    } else if (variant === 'caption') {
      return 'span';
    } else {
      return variant;
    }
  });

  return (
    <Styled.Element $variant={variant} as={tag} {...props}>
      {children}
    </Styled.Element>
  );
}

Typography.propTypes = {
  variant: PropTypes.oneOf(['body', 'caption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  component: PropTypes.string,
  children: PropTypes.node
};

Typography.defaultProps = {
  variant: 'body'
};
