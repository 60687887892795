/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState, useRef } from 'react';
import { observer } from 'mobx-react';

import { ElementMenuButton, Tooltip } from 'primitives';
import { Dropdown } from 'components';
import { Link } from 'icons';
import pluralize from 'utils/pluralize';
import buildSlug from 'utils/buildSlug';

import callingProcessesStore from './CallingProcessesStore';
import * as Styled from './CallingProcesses.styled';

const CallingProcesses = ({ type, includeBottomBorder = true, alignDropdown = 'left' }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef();
  const linkRef = useRef();

  const { showCallingProcesses, callingProcesses } = callingProcessesStore;

  return (
    showCallingProcesses && (
      <>
        <Styled.Wrapper
          onClick={() => setAnchorEl(anchorEl ? null : alignDropdown === 'left' ? buttonRef.current : linkRef.current)}
          includeBottomBorder={includeBottomBorder}
        >
          <ElementMenuButton ref={buttonRef}>
            <Link width="20" height="20" />
          </ElementMenuButton>
          <Styled.Label>
            This {type} is called by{' '}
            <Styled.Hyperlink>
              <u ref={linkRef}>
                {callingProcesses.length} {pluralize('process', callingProcesses.length)}
              </u>
            </Styled.Hyperlink>
          </Styled.Label>
        </Styled.Wrapper>
        <Dropdown align={alignDropdown} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          {callingProcesses.map((process) => (
            <Dropdown.ListItem key={process.id} to={`/diagrams/${buildSlug(process.id, process.name)}`}>
              <Tooltip title={process.name} showOnlyOnOverflow>
                <span className="overflow-ellipsis">{process.name}</span>
              </Tooltip>
            </Dropdown.ListItem>
          ))}
        </Dropdown>
      </>
    )
  );
};

export default observer(CallingProcesses);
