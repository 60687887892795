/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Dropdown } from 'components';

export const ActionItem = styled(Dropdown.ListItem)`
  padding: var(--spacing-tiny) var(--spacing-small);

  & > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: var(--spacing-tiny);
    }
  }
`;
