/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Checkbox as PrimitiveCheckbox } from 'primitives';

export const Checkbox = styled(PrimitiveCheckbox)`
  margin: 0 0 2px 1px;

  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  ${({ $isOpaque }) => {
    if ($isOpaque) {
      return `
        opacity: 0;
      `;
    }
  }}

  &:hover ${Checkbox} {
    opacity: 1;
  }
`;

export const Arrow = styled.svg`
  margin-left: 5px;
  transition: transform 0.2s ease;

  opacity: ${({ $isCurrentSorted }) => ($isCurrentSorted ? 1 : 0)};

  ${({ $isASC }) => {
    if ($isASC) {
      return 'transform: rotate(180deg);';
    }
  }}
`;

export const Column = styled.span`
  padding: 0 10px;
  display: flex;
  color: rgba(0, 0, 0, 0.4);

  ${({ $disableResponsiveness }) => {
    if (!$disableResponsiveness) {
      return `
        @media (max-width: 1200px) {
          &:nth-last-of-type(2) {
            display: none;
          }
        }
      
        @media (max-width: 1000px) {
          &:nth-last-of-type(3) {
            display: none;
          }
        }
      `;
    }
  }}

  ${({ $isPrimary, $columnWidth = 180 }) => {
    if ($isPrimary) {
      return 'flex: 1;';
    }

    return `
      flex: 0 0 ${$columnWidth}px;

      ${
        $columnWidth == 180 &&
        `
        @media (max-width: 1400px) {
          flex-basis: ${$columnWidth * 0.8}px;
        }
      `
      }
    `;
  }}
`;

export const HeadButton = styled.button`
  font: inherit;
  color: inherit;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;

  &::-moz-focus-inner {
    border: 0;
  }

  &:hover,
  &:focus {
    color: rgb(51, 51, 51);
  }
`;
