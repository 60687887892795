/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';
import { ProgressBar } from '@carbon/react';

import * as Styled from './ProjectsDropdown.styled';

const ProjectsDropdown = ({
  selectedProject,
  isCreatingProject,
  isPublishing,
  hasProjects,
  filteredProjects,
  onCreateNewProject,
  onProjectSelected
}) => {
  if (isCreatingProject || isPublishing) {
    const label = isCreatingProject ? 'Creating project' : 'Publishing to project';
    return <ProgressBar label={label} />;
  }

  return (
    <Styled.ProjectsDropdown
      id="select-project"
      label="Select a project"
      selectedItem={selectedProject?.name || ''}
      titleText=""
      items={[
        ...(hasProjects
          ? filteredProjects
          : [{ id: 'no-projects', name: 'No projects have been created yet', disabled: true }]),
        { id: 'create-project', name: 'Create new project' }
      ]}
      itemToElement={(item) => {
        return item.id === 'create-project' ? (
          <Styled.CreateNewProjectItem>{item.name}</Styled.CreateNewProjectItem>
        ) : (
          item.name
        );
      }}
      onChange={({ selectedItem }) => {
        if (selectedItem.id === 'create-project') {
          onCreateNewProject();
        } else {
          onProjectSelected(selectedItem);
        }
      }}
    />
  );
};

ProjectsDropdown.propTypes = {
  selectedProject: PropTypes.object,
  isCreatingProject: PropTypes.bool,
  isPublishing: PropTypes.bool,
  hasProjects: PropTypes.bool,
  filteredProjects: PropTypes.array,
  onCreateNewProject: PropTypes.func,
  onProjectSelected: PropTypes.func
};

export default ProjectsDropdown;
