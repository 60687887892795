/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observable, runInAction, makeObservable } from 'mobx';

import handleChunkLoadingError from 'utils/handleChunkLoadingError';

const LAYOVER_HIDE_TIMEOUT = 250;

const DEFAULT_STATE = {
  currentLayoverComponent: null,
  currentLayoverProps: {},
  dialogProps: {},
  isLayoverOpen: false
};

const layoverMap = {
  notificationSettings: async () => await import('App/Layovers/NotificationSettings').catch(handleChunkLoadingError)
};

const getLayoverComponent = (layoverName) => {
  if (layoverMap[layoverName]) {
    return layoverMap[layoverName]();
  }
};
class LayoverStore {
  state = Object.assign({}, DEFAULT_STATE);

  constructor() {
    makeObservable(this, {
      state: observable
    });
  }

  showLayover =
    (layoverName, props = {}, dialogProps = {}) =>
    async () => {
      const layoverComponent = await getLayoverComponent(layoverName);

      runInAction(() => {
        this.state.currentLayoverComponent = layoverComponent.default;
        this.state.currentLayoverProps = props;
        this.state.dialogProps = dialogProps;
        this.state.isLayoverOpen = true;
      });
    };

  hideLayover = () => {
    runInAction(() => {
      this.state.isLayoverOpen = false;
    });

    setTimeout(() => {
      runInAction(() => {
        this.state.currentLayoverComponent = null;
        this.state.currentLayoverProps = {};
      });
    }, LAYOVER_HIDE_TIMEOUT);
  };
}

export default new LayoverStore();
