/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { Tooltip } from 'primitives';
import { Dropdown } from 'components';
import { breadcrumbStore } from 'stores';
import * as Styled from 'components/Breadcrumb/Breadcrumb.styled';

class DropdownCrumb extends Component {
  render() {
    const { title, children, handleSubmit, forPage } = this.props;
    const { isEditing } = breadcrumbStore.state;

    // `forPage` refers to the page on which this component is used, e.g. "diagram" or "project".
    // If the `isEditing` variable has the same value as `forPage`, it means that we are renaming
    // the entity and need to display the input.
    return forPage !== isEditing ? (
      <Fragment>
        <Tooltip title={title} showOnlyOnOverflow>
          <Styled.Button
            variant="text"
            dropdown
            onClick={breadcrumbStore.toggleDropdownVisibility}
            data-test={this.props['data-test']}
          >
            {title}
          </Styled.Button>
        </Tooltip>
        <Dropdown
          anchorEl={breadcrumbStore.state.dropdownAnchorEl}
          open={breadcrumbStore.isDropdownVisible}
          onClose={breadcrumbStore.toggleDropdownVisibility}
        >
          {children}
        </Dropdown>
      </Fragment>
    ) : (
      <Styled.Editable
        initialValue={title}
        onSubmit={handleSubmit}
        onCancel={() => breadcrumbStore.finishEditing()}
        placeholder="Enter name here"
        isEditing
      >
        {title}
      </Styled.Editable>
    );
  }
}

export default observer(DropdownCrumb);
