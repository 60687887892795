/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import { Folders } from '@carbon/icons-react';

export const Container = styled.main`
  display: flex;
  flex: 1;
  overflow-x: hidden;
`;

export const EmptyStateIcon = styled(Folders)`
  color: var(--cm-color-orange-empty-state);
  width: 100px;
  height: 100px;
`;

export const SuperUserModeWarning = styled.div`
  padding: 30px 65px 10px 65px;

  .inline-notification-warning {
    max-width: initial;
  }
`;

export const CollaboratorsWrapper = styled.div`
  > * {
    margin: 0;
  }
`;
