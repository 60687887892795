/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import { CodeSnippet, InlineNotification } from '@carbon/react';
import { observer } from 'mobx-react';

import { clustersStore, notificationStore } from 'stores';
import { trackingService } from 'services';
import { detailsPanelTabsStore, inboundConnectorStore } from 'App/Pages/Diagram/stores';
import capitalize from 'utils/capitalize';
import {
  isWebhookConnector,
  isSubscriptionConnector,
  isPollingConnector,
  isAnyInboundConnector
} from 'utils/connectors/inbound-connectors';

import connectorService from './ConnectorService';
import * as Styled from './InboundConnector.styled';

let pollingInterval;

const InboundConnector = () => {
  const { isInboundConnectorTabSelected } = detailsPanelTabsStore;
  const {
    parentProcessId,
    inboundConnectorId,
    selectedElementId,
    isSelectedElementInboundConnector,
    recentlyDeployed,
    needsRedeploymentElementIds,
    selectedInboundFunctionality,
    selectedInboundShareableEntity,
    lastValidInboundConnectorType
  } = inboundConnectorStore;

  const isBrowserTabVisible = useBrowserTabVisibilityListener();
  const { activeInboundConnectors, initialLoadingInProgress } = usePollInboundConnectorStatus({
    parentProcessId,
    inboundConnectorId,
    selectedElementId,
    isBrowserTabVisible,
    isInboundConnectorTabSelected,
    isSelectedElementInboundConnector,
    needsRedeploymentElementIds,
    lastValidInboundConnectorType,
    interval: 3000
  });

  const needsRedeployment = needsRedeploymentElementIds.has(selectedElementId);

  return (
    <Styled.InboundConnector>
      {!needsRedeployment && (initialLoadingInProgress || (recentlyDeployed && activeInboundConnectors?.length < 1)) ? (
        <Styled.Loading description={`Updating ${selectedInboundFunctionality} status`} />
      ) : (
        <>
          {!needsRedeployment && activeInboundConnectors?.length > 0 ? (
            <>
              <Styled.ActiveInboundConnectorTitle>{`${capitalize(
                selectedInboundFunctionality
              )} is active in the following clusters:`}</Styled.ActiveInboundConnectorTitle>
              {activeInboundConnectors?.map((inboundConnectorDetails) => (
                <Styled.ActiveInboundConnector
                  key={`${inboundConnectorDetails.clusterId}-${inboundConnectorDetails.data?.path}`}
                >
                  {inboundConnectorDetails.clusterName}
                  <CodeSnippet
                    wrapText
                    type="multi"
                    onClick={() => {
                      notificationStore.showSuccess(
                        `The ${selectedInboundShareableEntity} has been copied to the clipboard`
                      );
                      trackingService.trackInboundConnectorURLCopy(
                        inboundConnectorDetails.bpmnProcessId,
                        inboundConnectorDetails.data?.path
                      );
                    }}
                  >
                    {generateInfoFromActiveClusterDetails(inboundConnectorDetails)}
                  </CodeSnippet>
                </Styled.ActiveInboundConnector>
              ))}
            </>
          ) : (
            <InlineNotification kind="info" lowContrast hideCloseButton>
              <strong>{`${capitalize(selectedInboundFunctionality)} is not active in any cluster`}</strong>
              {`Deploy the diagram to activate the ${selectedInboundFunctionality}`}
            </InlineNotification>
          )}
        </>
      )}
    </Styled.InboundConnector>
  );
};

const useBrowserTabVisibilityListener = () => {
  const [isBrowserTabVisible, setIsBrowserTabVisible] = useState(true);
  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.hidden) {
        setIsBrowserTabVisible(false);
      } else {
        setIsBrowserTabVisible(true);
      }
    };
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, []);

  return isBrowserTabVisible;
};

/**
 * Poll if the browser tab is visible AND inbound connector tab is active
 */
const usePollInboundConnectorStatus = ({
  parentProcessId,
  inboundConnectorId,
  selectedElementId,
  needsRedeploymentElementIds,
  isBrowserTabVisible,
  isInboundConnectorTabSelected,
  isSelectedElementInboundConnector,
  lastValidInboundConnectorType,
  interval = 5000
}) => {
  const [initialLoadingInProgress, setInitialLoadingInProgress] = useState(true);
  const [activeInboundConnectors, setActiveInboundConnectors] = useState(null);

  useEffect(() => {
    (async () => {
      const needsRedeployment = needsRedeploymentElementIds.has(selectedElementId);
      const canDoPolling =
        !needsRedeployment && isBrowserTabVisible && isInboundConnectorTabSelected && isSelectedElementInboundConnector;

      if (needsRedeployment) {
        setActiveInboundConnectors(null);
      }

      if (canDoPolling) {
        const queryInboundConnectorStatus = async () => {
          const currentActiveInboundConnectors = [];

          await Promise.all(
            clustersStore.clusters?.map(async (cluster) => {
              const baseUrl = cluster.urls.connectors;
              const activeInboundConnectorsInCluster = await connectorService.getActiveInboundConnectors(
                baseUrl,
                parentProcessId,
                selectedElementId
              );

              const inboundConnector = activeInboundConnectorsInCluster?.filter?.(
                ({ data, type }) =>
                  isAnyInboundConnector(type) &&
                  (isWebhookConnector(type) ? data?.path === inboundConnectorId : true) &&
                  (lastValidInboundConnectorType === null || type === lastValidInboundConnectorType)
              )[0];

              if (inboundConnector) {
                currentActiveInboundConnectors.push({
                  ...inboundConnector,
                  clusterId: cluster.uuid,
                  clusterName: cluster.name,
                  clusterUrl: baseUrl
                });
              }
            })
          );

          const sortedActiveInboundConnectors = currentActiveInboundConnectors.sort((a, b) =>
            a.clusterName.localeCompare(b.clusterName)
          );

          setActiveInboundConnectors(sortedActiveInboundConnectors);
        };

        // Make initial request
        await queryInboundConnectorStatus();
        setInitialLoadingInProgress(false);

        if (pollingInterval) {
          // Clear previous interval
          clearInterval(pollingInterval);
        }

        // Keep polling
        pollingInterval = setInterval(queryInboundConnectorStatus, interval);
      } else {
        clearInterval(pollingInterval);
      }
    })();

    return () => {
      clearInterval(pollingInterval);
    };
  }, [
    isBrowserTabVisible,
    isInboundConnectorTabSelected,
    inboundConnectorId,
    needsRedeploymentElementIds,
    parentProcessId
  ]);

  return { activeInboundConnectors, initialLoadingInProgress };
};

/**
 *
 * @param details response returned by the Connectors API
 * @returns {string|*} the data that should be displayed in the Inbound Connector tab
 */
const generateInfoFromActiveClusterDetails = (details) => {
  if (isWebhookConnector(details?.type)) {
    return `${details.clusterUrl}/inbound/${details?.data?.path}`;
  } else if (isSubscriptionConnector(details?.type) || isPollingConnector(details?.type)) {
    return details.type;
  }
};

export default observer(InboundConnector);
