/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useExperimentWithTracking } from 'App/Statsig';
import config from 'utils/config';

export default function useRemoveClusterAutoCreationExperiment({ isTrackingEnabled = true } = {}) {
  const { config: experimentConfig } = useExperimentWithTracking({
    key: 'cluster_autocreate_from_template',
    isTrackingEnabled
  });
  const isRemoveClusterAutoCreation = config?.statsig?.enabled && Boolean(experimentConfig?.value?.enable_feature);

  return isRemoveClusterAutoCreation;
}
