/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';

import { milestoneStore, diagramControlStore, diffingStore, notificationStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { projectService, fileService, trackingService, folderService } from 'services';
import { CodeEditor } from 'components';
import { Spinner } from 'primitives';

import Sidebar from './Sidebar';
import Header from './Header';
import * as Styled from './FormMilestones.styled';

const FormMilestones = () => {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    trackingService.trackPageView('milestone');

    init();

    return () => {
      milestoneStore.reset();
      diffingStore.reset();
      diagramControlStore.reset();
    };
  }, [params.id]);

  const init = async () => {
    setLoading(true);

    try {
      const file = await fileService.fetchById(params.id);
      const requests = [projectService.fetchById({ projectId: file.projectId })];
      if (file.folderId) {
        requests.push(folderService.fetchById(file.folderId));
      }
      const [project, folder] = await Promise.all(requests);
      if (!hasAccess(project, actions.VIEW_VERSIONS)) {
        return history.push(`/forms/${params.id}`);
      }
      const milestoneIdsFromUrl = params.slug?.split('...');

      milestoneStore.init({ ...file, folder }, project, [], milestoneIdsFromUrl);
      await milestoneStore.fetchAll();

      milestoneStore.trackMilestoneView('milestone-history');

      setLoading(false);
    } catch (ex) {
      notificationStore.showError('Yikes! Could not load the milestone data. Please try again later.');
    }
  };

  return (
    <Styled.Container>
      {loading ? (
        <Spinner fullHeight />
      ) : (
        <Fragment>
          <Header />
          <CodeEditor
            titles={milestoneStore.titles}
            value={milestoneStore.primaryJSON}
            modified={milestoneStore.secondaryJSON}
          />
          <Sidebar />
        </Fragment>
      )}
    </Styled.Container>
  );
};

export default observer(FormMilestones);
