/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { autorun, toJS } from 'mobx';

import { currentDiagramStore } from 'stores';

import DetectConnectorsModule from './detectConnectors';
import GenerateWebhookIdModule from './generateWebhookId';

export const hasBuiltInTemplates = false;

let storeListenerDisposer;

export default (additionalTemplates) => {
  return {
    __depends__: [DetectConnectorsModule, GenerateWebhookIdModule],
    __init__: [
      [
        'elementTemplatesLoader',
        function (elementTemplatesLoader) {
          let lasElementTemplates;

          const setTemplates = (templates) => {
            const newTemplates = ensureCategoryIsPresent(templates);

            // Avoid unnecessary updates if the templates are the same
            if (JSON.stringify(templates) === JSON.stringify(lasElementTemplates)) {
              return;
            }

            elementTemplatesLoader.setTemplates(newTemplates);
            lasElementTemplates = newTemplates;
          };

          setTemplates(additionalTemplates);

          if (storeListenerDisposer) {
            storeListenerDisposer();
          }

          storeListenerDisposer = autorun(() => {
            if (currentDiagramStore.state?.templates?.length) {
              setTemplates(toJS(currentDiagramStore.state.templates));
            }
          });
        }
      ]
    ]
  };
};

function ensureCategoryIsPresent(templates) {
  return templates.map((template) => {
    if (!template.category) {
      template.category = {
        id: 'connectors',
        name: 'Connectors'
      };
    }
    return template;
  });
}
