/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useEffect } from 'react';

import PlayContext from '../PlayContext';

export default function SequenceFlowHighlighter({ element }) {
  const context = useContext(PlayContext);

  const sequenceFlows = context.elementInstances?.takenSequenceFlows ?? [];
  const completedElementInstances = context.elementInstances?.completedElementInstances ?? [];

  const modeler = context.modeler;

  useEffect(() => {
    const elementRegistry = modeler.get('elementRegistry');
    const graphicsFactory = modeler.get('graphicsFactory');

    if (sequenceFlows.some((step) => step.element.elementId === element.id)) {
      element.di.set('stroke', '#52b415');
      element.di.set('fill', '#52b415');

      graphicsFactory.update('connection', element, elementRegistry.getGraphics(element));
    } else if (
      element?.di?.bpmnElement?.sourceRef?.$type === 'bpmn:EventBasedGateway' &&
      completedElementInstances.some(
        (elementInstance) => elementInstance.element.elementId === element.di.bpmnElement.targetRef.id
      )
    ) {
      element.di.set('stroke', '#52b415');
      element.di.set('fill', '#52b415');

      graphicsFactory.update('connection', element, elementRegistry.getGraphics(element));
    } else {
      element.di.set('stroke', '#22242a');
      element.di.set('fill', '#22242a');

      graphicsFactory.update('connection', element, elementRegistry.getGraphics(element));
    }

    return () => {
      element.di.set('stroke', '#22242a');
      element.di.set('fill', '#22242a');

      graphicsFactory.update('connection', element, elementRegistry.getGraphics(element));
    };
  }, [sequenceFlows, modeler]);

  return null;
}
