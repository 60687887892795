/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext } from 'react';
import { createPortal } from 'react-dom';

import { currentDiagramStore } from 'stores';

import PlayContext from './PlayContext';
import StartInstanceButton from './definition/StartInstanceButton';
import JobCompleteButton from './instance/JobCompleteButton';
import TriggerTimerButton from './TriggerTimerButton';
import TriggerMessageSubscriptionButton from './TriggerMessageSubscriptionButton';
import IncidentButton from './instance/IncidentButton';
import SequenceFlowHighlighter from './instance/SequenceFlowHighlighter';
import ProblemDiagramOverlay from './definition/ProblemDiagramOverlay';
import ConnectorDiagramOverlay from './definition/ConnectorDiagramOverlay';
import InvokeConnectorButton from './instance/InvokeConnectorButton';
import ChildInstanceButton from './instance/ChildInstanceButton';
import {
  CHILD_INSTANCE_BUTTON,
  CONNECTOR,
  INCIDENT_BUTTON,
  INVOKE_CONNECTOR_BUTTON,
  JOB_COMPLETE_BUTTON,
  PROBLEM,
  SEQUENCE_FLOW_HIGHLIGHTER,
  START_INSTANCE_BUTTON,
  TRIGGER_MESSAGE_SUBSCRIPTION_BUTTON,
  TRIGGER_TIMER_BUTTON,
  FINISHED_OVERLAY
} from './utils/overlayPositions';
import { CompletedOverlay } from './CompletedOverlay';

export default function DiagramOverlay({ element }) {
  const context = useContext(PlayContext);
  const completedElements = context.elementInstances?.completedElementInstances?.map(
    ({ element }) => element.elementId
  );

  const playModeExtension = currentDiagramStore.modeler.get('playModeExtension');

  const problem =
    !context.instanceId &&
    context.filesStatus?.find((file) => file.status !== 'success' && file.element.id === element.id);
  if (problem) {
    const node = playModeExtension.addOverlay(element, 'PROBLEM', PROBLEM);
    return createPortal(<ProblemDiagramOverlay element={element} problem={problem} />, node);
  }

  const connector =
    !context.instanceId &&
    context.connectorInfo?.connectorElementsWithSecrets?.find((connector) => connector.element.id === element.id);
  if (connector) {
    const node = playModeExtension.addOverlay(element, 'CONNECTOR', CONNECTOR);
    return createPortal(<ConnectorDiagramOverlay element={element} connector={connector} />, node);
  }

  const incident = context.incidents.find(
    (incident) => incident.elementInstance?.element?.elementId === element.id && incident.state === 'CREATED'
  );
  if (incident) {
    const node = playModeExtension.addOverlay(element, 'INCIDENT_BUTTON', INCIDENT_BUTTON);
    return createPortal(<IncidentButton element={element} incident={incident} />, node);
  }

  if (
    element.type == 'bpmn:StartEvent' &&
    ['bpmn:Process', 'bpmn:Participant'].includes(element.parent.type) &&
    (!element.businessObject.eventDefinitions || element.businessObject.eventDefinitions.length === 0)
  ) {
    const node = playModeExtension.addOverlay(element, 'START_INSTANCE_BUTTON', START_INSTANCE_BUTTON);

    return createPortal(<StartInstanceButton element={element} isRestartMode={context.instanceId} />, node);
  }

  if (
    element.type == 'bpmn:EndEvent' &&
    context.instanceDetails?.state === 'COMPLETED' &&
    element.parent.type === 'bpmn:Process' &&
    completedElements.includes(element.id)
  ) {
    const node = playModeExtension.addOverlay(element, 'FINISHED_OVERLAY', FINISHED_OVERLAY);
    return createPortal(<CompletedOverlay element={element} />, node);
  }

  const timer = context.timers.find((timer) => timer.element.elementId === element.id && timer.state === 'CREATED');
  if (timer) {
    const node = playModeExtension.addOverlay(element, 'TRIGGER_TIMER_BUTTON', TRIGGER_TIMER_BUTTON);
    return createPortal(<TriggerTimerButton element={element} timer={timer} />, node);
  }
  const definitionTimer = context.definitionTimers.find(
    (timer) => timer.element.elementId === element.id && timer.state === 'CREATED'
  );
  if (definitionTimer) {
    const node = playModeExtension.addOverlay(element, 'TRIGGER_TIMER_BUTTON', TRIGGER_TIMER_BUTTON);
    return createPortal(
      <TriggerTimerButton
        element={element}
        timer={definitionTimer}
        isRestartMode={context.instanceId}
        shouldWaitForInstanceCreation
      />,
      node
    );
  }
  const messageSubscription = context.messageSubscriptions.find((subscription) => {
    if (subscription.element.elementId !== element.id) return false;
    if (context.instanceId) {
      return (
        subscription.state === 'CREATED' ||
        (subscription.state === 'CORRELATED' && subscription.elementInstance?.state === 'ACTIVATED')
      );
    }
    return true;
  });
  if (messageSubscription) {
    const node = playModeExtension.addOverlay(
      element,
      'TRIGGER_MESSAGE_SUBSCRIPTION_BUTTON',
      TRIGGER_MESSAGE_SUBSCRIPTION_BUTTON
    );
    return createPortal(
      <TriggerMessageSubscriptionButton element={element} messageSubscription={messageSubscription} />,
      node
    );
  }

  const definitionMessageSubscription = context.definitionMessageSubscriptions.find(
    (subscription) => subscription.element.elementId === element.id
  );

  if (definitionMessageSubscription) {
    const node = playModeExtension.addOverlay(
      element,
      'TRIGGER_MESSAGE_SUBSCRIPTION_BUTTON',
      TRIGGER_MESSAGE_SUBSCRIPTION_BUTTON
    );
    return createPortal(
      <TriggerMessageSubscriptionButton
        element={element}
        messageSubscription={definitionMessageSubscription}
        isRestartMode={context.instanceId}
      />,
      node
    );
  }

  if (element.type === 'bpmn:SequenceFlow') {
    const node = playModeExtension.addOverlay(element, 'SEQUENCE_FLOW_HIGHLIGHTER', SEQUENCE_FLOW_HIGHLIGHTER);
    return createPortal(<SequenceFlowHighlighter element={element} />, node);
  }

  const connectorJob =
    context.instanceId && context.connectorInfo?.connectorElements?.find((connector) => connector.id === element.id);
  if (connectorJob) {
    const node = playModeExtension.addOverlay(element, 'INVOKE_CONNECTOR_BUTTON', INVOKE_CONNECTOR_BUTTON);
    return createPortal(<InvokeConnectorButton element={element} />, node);
  }

  const childInstance =
    context.instanceId &&
    context.childInstances?.find(
      (childInstance) => childInstance.parentElementInstance.element.elementId === element.id
    );

  if (childInstance) {
    const node = playModeExtension.addOverlay(element, 'CHILD_INSTANCE_BUTTON', CHILD_INSTANCE_BUTTON);
    return createPortal(<ChildInstanceButton element={element} childInstance={childInstance} />, node);
  }
  const node = playModeExtension.addOverlay(element, 'JOB_COMPLETE_BUTTON', JOB_COMPLETE_BUTTON);

  return createPortal(<JobCompleteButton element={element} />, node);
}
