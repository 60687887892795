/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Tag } from '@carbon/react';
import styled from 'styled-components';

import { Button } from 'primitives';

export const Container = styled.div``;

export const Title = styled.div`
  background: var(--silver-base-97);
  border-top: 1px solid var(--silver-darken-80);
  border-bottom: 1px solid var(--silver-darken-80);
  cursor: pointer;
`;

export const StatusIcon = styled.span`
  svg {
    vertical-align: middle;
  }
  margin-right: var(--spacing-small);
`;

export const StatusText = styled.span`
  vertical-align: middle;
`;

export const EmptyState = styled.div`
  padding: 32px;
`;

export const EmptyHeader = styled.div`
  color: var(--text-text-primary, #161616);
  /* Fixed heading styles/heading-03 */
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */

  margin-bottom: 8px;
`;

export const EmptyDescription = styled.div`
  color: var(--text-text-secondary, #525252);
  /* Utility styles/helper-text-02 */
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
`;

export const Collapse = styled.span`
  float: right;
  cursor: pointer;
  ${({ isCollapsed }) => isCollapsed && ` svg { transform: rotate(180deg); } `}
  padding: 7px 10px;
  height: 36px;

  border-radius: 4px;
  :hover {
    background: var(--silver-darken-87);
  }
`;

export const Tab = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 6px 18px 4px 18px;
  font-weight: ${(props) => (props.active && !props.isErrorPanelCollapsed ? '500' : '')};
  border-bottom: ${(props) => (props.active && !props.isErrorPanelCollapsed ? '2px solid var(--cds-blue-60)' : '')};
  margin-left: 12px;
  height: 38px;
`;

export const Badge = styled.span`
  background-color: var(--grey-base-40);
  border-radius: 10px;
  font-weight: bold;
  font-size: 10px;
  color: white;
  display: inline-block;
  margin-left: 6px;
  padding: 1px 6px;

  @media (max-width: 2000px) {
    font-size: 10px;
  }
`;

export const MenuRight = styled.div`
  float: right;
`;

export const Content = styled.div`
  height: 200px;
  width: 100%;
  overflow-y: auto;
  background: white;
`;

export const IconContainer = styled.div`
  display: inline-block;
  margin-right: 16px;
  margin-top: -10px;
  transform: translateY(4px);
`;

export const InstanceButton = styled(Button)`
  color: #0f62fe;
  font-size: 14px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
  word-wrap: break-word;
  height: 20px;
`;

export const ErrorCounterTag = styled(Tag)`
  min-inline-size: 24px;
`;
