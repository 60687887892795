/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import debounce from 'utils/debounce';

import Service from './Service';

class ShareService extends Service {
  /**
   * Fetches a specific share.
   *
   * @param {String} shareId
   * @returns {Promise}
   */
  fetchById(shareId) {
    return this.get(`/internal-api/shares/${shareId}`);
  }

  /**
   * Fetches a specific share, but authenticates with the provided password first.
   * Used for password-protected shares.
   *
   * @param {String} shareId
   * @param {Object} payload
   * @param {String} payload.password
   * @returns {Promise}
   */
  authenticate(shareId, payload) {
    return this.post(`/internal-api/shares/${shareId}/authenticate`, payload);
  }

  /**
   * Creates a new share for the given `fileId`.
   *
   * @param {Object} payload
   * @param {String} payload.fileId
   * @returns {Promise}
   */
  create = debounce(
    (payload) => {
      return this.post(`/internal-api/shares`, payload);
    },
    Service.debounceDelay,
    Service.debounceOptions
  );

  /**
   * Creates a password for an existing share.
   *
   * @param {String} shareId
   * @param {Object} payload
   * @param {String} payload.password
   */
  createPassword(shareId, payload) {
    return this.post(`/internal-api/shares/${shareId}/password`, payload);
  }

  /**
   * Removes an existing share.
   *
   * @param {String} shareId
   * @returns {Promise}
   */
  destroy = debounce(
    (shareId) => this.delete(`/internal-api/shares/${shareId}`),
    Service.debounceDelay,
    Service.debounceOptions
  );

  /**
   * Removes the password from a share.
   *
   * @param {String} shareId
   * @returns {Promise}
   */
  destroyPassword(shareId) {
    return this.post(`/internal-api/shares/${shareId}/password`, {
      password: ''
    });
  }

  /**
   * Sends an email to the given set of emails, containing the link to
   * the share.
   *
   * @param {String} shareId
   * @param {Object} payload
   * @param {String[]} payload.emails An array of email recipients.
   * @param {String} payload.message The message, showed in the email body.
   * @returns {Promise}
   */
  sendShareEmail(shareId, payload) {
    return this.post(`/internal-api/shares/${shareId}/email`, payload);
  }
}

export default new ShareService();
