/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import config from 'utils/config';

// eslint-disable-next-line no-undef
__webpack_public_path__ = `${config.modelerBasePath}/`;
