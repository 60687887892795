/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Details, Diagram, Folder, Project, Table } from 'icons';

export default function ItemIcon({ type }) {
  switch (type) {
    case 'BPMN':
      return <Diagram width="16" height="16" />;
    case 'DMN':
      return <Table width="16" height="16" />;
    case 'DECISION':
      return '';
    case 'FORM':
      return <Details width="16" height="16" />;
    case 'FOLDER':
      return <Folder width="16" height="16" />;
    default:
      return <Project width="16" height="16" />;
  }
}
