/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { businessRuleTaskLinkStore } from 'App/Pages/Diagram/BusinessRuleTaskLinking';
import { DMN } from 'utils/constants';
import { breadcrumbStore, notificationStore } from 'stores';
import { fileService } from 'services';
import buildSlug from 'utils/buildSlug';
import { detectDecisions } from 'App/Pages/Diagram/model-parser-util';
import { createEntity, generateIdFromElementName, trackSubResourceCreation } from 'components/TargetSelector/utils';

export default async function createDmnDiagram(target, history) {
  const elementName = getBusinessObject(businessRuleTaskLinkStore.selectedBusinessRuleTask)?.name || '';
  const attributes = elementName
    ? {
        name: elementName,
        decisionName: elementName,
        decisionId: generateIdFromElementName(elementName, DMN)
      }
    : {};
  const entity = createEntity({
    type: DMN,
    attributes,
    target
  });
  entity.decisions = await detectDecisions(entity?.content);

  try {
    const diagram = await fileService.create(entity);

    trackSubResourceCreation({ newFileId: diagram.id, newFileType: DMN, newFileTarget: target });
    breadcrumbStore.toggleEditingFor('diagram');
    history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
  } catch (ex) {
    notificationStore.showError('Could not create new DMN diagram. Please try again.');
  }
}
