/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Typography } from 'primitives';
import Form, { TextField } from 'components/Form';
import { shareService } from 'services';
import { notificationStore } from 'stores';

import * as Styled from './PasswordProtectedShare.styled';

class PasswordProtectedShare extends Component {
  static propTypes = {
    shareId: PropTypes.string,
    onSuccess: PropTypes.func
  };

  shareId = this.props.shareId;

  handlePasswordSubmit = (formData) => {
    shareService
      .authenticate(this.shareId, { password: formData.password })
      .then((share) => this.props.onSuccess(share.file))
      .catch(() => notificationStore.showError('Sorry, you did not enter the correct password.'));
  };

  render() {
    return (
      <Styled.Wrapper>
        <Typography variant="h1" data-test="share-title" $gutterBottom>
          Password protected
        </Typography>
        <Typography $gutterBottom>To view this link, please input password.</Typography>
        <Form buttonText="View link" data-test="password-protected-form" onSubmit={this.handlePasswordSubmit}>
          <TextField
            name="password"
            type="password"
            errorMessage="Please enter the password."
            placeholder="Enter password"
            rules="required"
            data-test="password-input"
          />
        </Form>
      </Styled.Wrapper>
    );
  }
}

export default observer(PasswordProtectedShare);
