/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Launch as LaunchIcon } from 'icons';
import { Errors as ErrorsStyled } from 'components';

export default function DocumentationLink(props) {
  const { href } = props;

  const stopPropagation = (event) => event.stopPropagation();

  return (
    <ErrorsStyled.DocumentationLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={stopPropagation}
      title="Go to documentation"
    >
      <LaunchIcon width="12" height="12" viewBox="0 0 12 12" />
    </ErrorsStyled.DocumentationLink>
  );
}
