/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

const TOP_BAR_HEIGHT = 104;
const BORDER_OFFSET = 1;
const EDITOR_HEIGHT = TOP_BAR_HEIGHT + BORDER_OFFSET;

export const Container = styled.div`
  position: absolute;
  top: ${TOP_BAR_HEIGHT}px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--z-xml-editor);

  display: flex;
  flex: 1;
  min-height: 0;
  height: calc(100% - ${EDITOR_HEIGHT}px);

  background: white;
`;
