/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Service } from 'services';

import { trackAPIError } from './tracking';

class ZeebePlayService extends Service {
  async postWithErrorHandling(path, body) {
    return this.post(path, body)
      .then((res) => res)
      .catch((res) => {
        trackAPIError(path, res.status);
        return res;
      });
  }

  getConnectorSecrets(endpoint) {
    return this.postWithErrorHandling('/zeebe-play/connector-secrets', { endpoint });
  }
  setConnectorSecret(endpoint, name, value) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling('/zeebe-play/set-connector-secret', { endpoint, name, value });
  }
  checkReadiness(endpoint) {
    return this.postWithErrorHandling('/zeebe-play/check-readiness', { endpoint });
  }
  deploy(endpoint, xml, isDmn = false) {
    return this.postWithErrorHandling('/zeebe-play/deploy', { endpoint, xml, isDmn });
  }
  startInstance(endpoint, id, variables) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling('/zeebe-play/start-instance', { endpoint, id, variables });
  }
  async fetchJobs(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/jobs', {
      endpoint,
      payload: {
        variables: { key: id, zoneId: getTimeZone() }
      }
    });

    return result?.processInstance?.jobs;
  }
  async fetchElementInstances(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/element-instances', {
      endpoint,
      payload: {
        variables: { key: id, zoneId: getTimeZone() }
      }
    });

    return result?.processInstance;
  }
  async fetchChildInstances(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/child-instances', {
      endpoint,
      payload: {
        variables: { key: id, zoneId: getTimeZone() }
      }
    });

    return result?.processInstance?.childProcessInstances;
  }
  async fetchInstances(endpoint, id, perPage = 10000, page = 0) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/instances', {
      endpoint,
      payload: {
        variables: { key: id, perPage, page, zoneId: getTimeZone() }
      }
    });

    if (result?.process?.processInstances?.nodes) {
      // sort the list of process instances in reverse chronological order
      result.process.processInstances.nodes.sort(
        (a, b) => new Date(b.startTime).valueOf() - new Date(a.startTime).valueOf()
      );
    }

    return result?.process;
  }

  async fetchTimers(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/timers', {
      endpoint,
      payload: {
        variables: { key: id, zoneId: getTimeZone() }
      }
    });

    return result?.processInstance?.timers;
  }

  async fetchProcessTimers(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/process-timers', {
      endpoint,
      payload: {
        variables: { key: id, zoneId: getTimeZone() }
      }
    });

    return result?.process?.timers;
  }

  async fetchMessageSubscriptions(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/message-subscriptions', {
      endpoint,
      payload: {
        variables: { key: id, zoneId: getTimeZone() }
      }
    });

    return result?.processInstance?.messageSubscriptions;
  }

  async fetchProcessMessageSubscriptions(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/process-message-subscriptions', {
      endpoint,
      payload: {
        variables: { key: id, zoneId: getTimeZone() }
      }
    });

    return result?.process?.messageSubscriptions;
  }

  async fetchUserTasks(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/user-tasks', {
      endpoint,
      payload: {
        variables: { key: id }
      }
    });

    return result?.processInstance?.userTasks?.nodes;
  }

  async invokeConnector(endpoint, jobType, jobKey) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling(`/zeebe-play/connectors/${jobType}/execute/${jobKey}`, { endpoint });
  }

  async completeJob(endpoint, jobKey, variables) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling(`/zeebe-play/jobs/${jobKey}/complete`, { endpoint, variables });
  }

  async failJob(endpoint, jobKey, retries, errorMessage) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling(`/zeebe-play/jobs/${jobKey}/fail`, { endpoint, retries, errorMessage });
  }

  async throwError(endpoint, jobKey, errorCode, errorMessage) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling(`/zeebe-play/jobs/${jobKey}/throw-error`, { endpoint, errorCode, errorMessage });
  }

  async timeTravel(endpoint, dateTime) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling('/zeebe-play/timers', { endpoint, dateTime });
  }

  async publishMessage(endpoint, messageName, correlationKey, variables, timeToLive, messageId) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling('/zeebe-play/messages', {
      endpoint,
      messageName,
      correlationKey,
      variables,
      timeToLive,
      messageId
    });
  }

  async fetchVariablesByUserTask(endpoint, userTask) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/variables-by-user-task', {
      endpoint,
      payload: {
        variables: { key: userTask }
      }
    });

    return result?.userTask?.elementInstance?.variables;
  }

  async fetchInstanceDetails(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/instance-details', {
      endpoint,
      payload: {
        variables: { key: id, zoneId: getTimeZone() }
      }
    });

    return result?.processInstance;
  }

  async fetchIncidents(endpoint, id) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/incidents', {
      endpoint,
      payload: {
        variables: { key: id, zoneId: getTimeZone() }
      }
    });

    return result?.processInstance?.incidents;
  }

  async fetchVariables(endpoint, instanceId) {
    const result = await this.postWithErrorHandling('/zeebe-play/query/variables', {
      endpoint,
      payload: {
        variables: { key: instanceId, zoneId: getTimeZone() }
      }
    });

    return result?.processInstance?.variables;
  }

  async setVariables(endpoint, instanceId, scope, variablesString) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling('/zeebe-play/process-instances/' + instanceId + '/variables', {
      endpoint,
      scopeKey: scope,
      variables: variablesString
    });
  }

  async updateRetries(endpoint, jobKey, retries) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling('/zeebe-play/jobs/' + jobKey + '/update-retries', {
      endpoint,
      retries
    });
  }

  async resolveIncident(endpoint, incidentKey) {
    fetch(`https://spawn.play.camunda.io/api/cluster/${extractClusterId(endpoint)}/extend`, { method: 'PUT' });
    return this.postWithErrorHandling('/zeebe-play/incidents/' + incidentKey + '/resolve', {
      endpoint
    });
  }
}

export default new ZeebePlayService();

function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function extractClusterId(endpoint) {
  return endpoint.substring(0, 36);
}
