/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { diffingStore } from 'stores';
import { Button, Dialog } from 'primitives';
import { changeText } from 'utils/diffing/IconOverlay/ListOfChanges';

import * as Styled from './DiffingDetailsModal.styled';

export default function DiffingDetailsModalContent() {
  return (
    <>
      <Dialog.Header>
        <Dialog.Title>Details</Dialog.Title>
        <Dialog.Subtitle>Overview of all the changes made to this activity</Dialog.Subtitle>
      </Dialog.Header>
      <Dialog.Content>
        <Styled.List>
          {diffingStore.diffingDetailsContent.map((item) => (
            <li key={item.property}>{changeText(item.property, item.before, item.after, item.removed)}</li>
          ))}
        </Styled.List>
      </Dialog.Content>
      <Dialog.Footer>
        <Button variant="primary" onClick={diffingStore.hideDiffingDetails}>
          Close
        </Button>
      </Dialog.Footer>
    </>
  );
}
