/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { userStore } from 'stores';

export default ({ orgName }) => {
  return {
    ariaLabel: 'Navigation',
    orgName: orgName,
    elements: [],
    tags: userStore.isSuperAdminModeActive
      ? [
          {
            key: 'superUserMode',
            label: 'Super-user mode',
            color: 'high-contrast'
          }
        ]
      : undefined
  };
};
