/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import {
  DataTable,
  DataTableSkeleton,
  Table,
  TableBatchAction,
  TableBatchActions,
  TableBody,
  TableHead,
  TableRow,
  TableSelectAll,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch
} from '@carbon/react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { DropTarget } from 'components';
import { containsSearchWord } from 'components/EntityTable/utils';
import { EmptyState } from 'primitives';

import * as Styled from './EntityTable.styled';

export default function EntityTable({
  action,
  batchActions = false,
  columns = [],
  emptyState = EmptyState,
  isLoading,
  onDrop = false,
  rows = [],
  title
}) {
  const getIdentityFn = () => (val) => val;

  const columnsConfig = columns.reduce((config, column) => {
    config[column.key] = {
      noPadding: column.noPadding,
      renderer: column.renderer
    };
    return config;
  }, {});

  const sortingPositionByRow = rows.reduce((config, row) => {
    config[row.id] = row.__sortingPosition;
    return config;
  }, {});

  function getCellClassNames({ noPadding }) {
    return `${noPadding ? 'no-padding' : ''}`;
  }

  function filterRows({ cellsById, getCellId, headers, inputValue, rowIds }) {
    const searchWord = inputValue.trim().toLowerCase();

    return rowIds.filter((rowId) =>
      headers.some((header) => {
        const cell = cellsById[getCellId(rowId, header.key)];
        if (header.renderer) {
          return header.renderer.containsSearchWord?.(cell?.value, searchWord) ?? false;
        }
        return containsSearchWord(cell?.value, searchWord);
      })
    );
  }

  function sortRows(a, b, { compare, key, rowIds: [rowIdA, rowIdB], sortDirection, sortStates }) {
    const sortingPositionA = sortingPositionByRow[rowIdA];
    const sortingPositionB = sortingPositionByRow[rowIdB];

    if (sortingPositionA !== sortingPositionB) {
      return sortingPositionA - sortingPositionB;
    }

    let compared = 0;

    const renderer = columnsConfig[key].renderer;
    if (renderer) {
      const comparableValueA = renderer.getComparableValue?.(a) ?? 0;
      const comparableValueB = renderer.getComparableValue?.(b) ?? 0;
      compared = compare(comparableValueA, comparableValueB);
    } else {
      compared = compare(a, b);
    }

    if (sortDirection === sortStates.DESC) {
      compared *= -1;
    }

    return compared;
  }

  const getSkeleton = useCallback((headers) => {
    const visibleHeaders = headers.filter((header) => header.header?.length > 0);
    return (
      <DataTableSkeleton
        data-test="entity-table-skeleton"
        columnCount={visibleHeaders.length}
        headers={visibleHeaders}
        showHeader={false}
      />
    );
  }, []);

  return (
    <DataTable rows={rows} size="md" headers={columns} filterRows={filterRows} sortRow={sortRows}>
      {({
        rows: filteredRows,
        headers,
        getBatchActionProps,
        getHeaderProps,
        getRowProps,
        getSelectionProps,
        getTableProps,
        getToolbarProps,
        onInputChange,
        getTableContainerProps,
        selectAll,
        selectedRows
      }) => {
        const batchActionProps = getBatchActionProps();
        const selection = selectedRows.map((selectedRow) => rows.find((row) => row.id === selectedRow.id));
        return (
          <DropTarget isDisabled={!onDrop} onDrop={onDrop} displayStatus="block">
            <Styled.TableContainer title={title} {...getTableContainerProps()}>
              {isLoading ? (
                getSkeleton(headers)
              ) : rows.length === 0 ? (
                emptyState
              ) : (
                <>
                  <TableToolbar {...getToolbarProps()} aria-label="entity table toolbar">
                    <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                      <TableToolbarSearch
                        onChange={onInputChange}
                        persistent
                        tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                      />
                      {action?.({ tabIndex: batchActionProps.shouldShowBatchActions ? -1 : 0 })}
                    </TableToolbarContent>
                    {batchActions && (
                      <TableBatchActions {...batchActionProps}>
                        {batchActions.map((batchAction) => (
                          <TableBatchAction
                            key={batchAction?.title}
                            renderIcon={() => null}
                            tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                            disabled={!batchAction?.isAllowed?.(selection)}
                            onClick={(evt) => batchAction?.action?.(selection, () => selectAll(false), evt)}
                          >
                            {batchAction?.title}
                          </TableBatchAction>
                        ))}
                      </TableBatchActions>
                    )}
                  </TableToolbar>
                  <Table {...getTableProps()} data-test="entity-table" aria-label="entity table">
                    <TableHead>
                      <TableRow>
                        {batchActions && (
                          <TableSelectAll {...getSelectionProps()} ariaLabel="entity table select all" />
                        )}
                        {headers.map((header) => (
                          <Styled.TableHeader
                            key={header.key}
                            $customWidth={header.width}
                            {...getHeaderProps({
                              header,
                              isSortable: header.sortable
                            })}
                          >
                            {header.header}
                          </Styled.TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRows.map((row) => (
                        <TableRow key={row.id} {...getRowProps({ row })}>
                          {batchActions && (
                            <TableSelectRow
                              {...getSelectionProps({
                                row
                              })}
                              ariaLabel={`entity table select ${row.id}`}
                            />
                          )}
                          {row.cells.map((cell) => {
                            const columnConfig = columnsConfig[cell.info.header];
                            const rendererFn = columnConfig?.renderer ?? getIdentityFn();
                            const dataTest = rendererFn.getDataTest?.(cell.value) ?? undefined;
                            const noPadding = columnConfig?.noPadding ?? false;
                            return (
                              <Styled.TableCell
                                key={cell.id}
                                className={getCellClassNames({ noPadding })}
                                {...(dataTest ? { 'data-test': dataTest } : undefined)}
                              >
                                {rendererFn(cell.value)}
                              </Styled.TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Styled.TableContainer>
          </DropTarget>
        );
      }}
    </DataTable>
  );
}

EntityTable.propTypes = {
  action: PropTypes.func,
  batchActions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  columns: PropTypes.array.isRequired,
  emptyState: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isLoading: PropTypes.bool,
  onDrop: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  rows: PropTypes.array.isRequired,
  title: PropTypes.node.isRequired
};
