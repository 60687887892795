/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Tooltip } from 'primitives';
import * as Styled from 'components/Breadcrumb/Breadcrumb.styled';

export default (props) => (
  <Tooltip title={props.title} showOnlyOnOverflow {...props.tooltipProps}>
    <Styled.LinkCrumb to={props.to} data-test={props['data-test']}>
      {props.title}
    </Styled.LinkCrumb>
  </Tooltip>
);
