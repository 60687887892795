/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import detectConnectorsStore from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/detectConnectors/DetectConnectorsStore';
import detectUserTasksStore from 'App/Pages/Diagram/BpmnJSExtensions/detectUserTasksExtension/DetectUserTasksStore';
import { getProcessIdFromContent } from 'utils/web-modeler-diagram-parser';
import { FILE_TYPE_MAPPING, DEFAULT, BPMN, CONNECTOR_TEMPLATE } from 'utils/constants';
import { getFlowNodes, getFormFields, getFormFieldTypeCounts } from 'utils/helpers';
import config from 'utils/config';
import { PUBLISHED_ON } from 'utils/milestones/published-on';

import MixpanelIntegration from './MixpanelIntegration';

export class TrackingService extends MixpanelIntegration {
  #getConnectorsFromXml(xml) {
    return detectConnectorsStore.detectConnectors(xml);
  }

  #getConnectorsFromModeler() {
    const connectors = detectConnectorsStore.connectors;
    if (connectors) {
      return Array.from(connectors);
    }
    return [];
  }

  #getContainsUserTasksFromXml(xml) {
    return detectUserTasksStore.detectContainsUserTasks(xml);
  }

  #getContainsUserTasksFromModeler() {
    return detectUserTasksStore.hasUserTasks;
  }

  /**
   * Enriches the given payload with the information about the template used.
   *
   * @param {String} content The XML content of the template diagram.
   * @param {Object} payload The mixpanel payload to be enriched.
   * @returns {Object}
   */
  async #injectTemplateUsed(content, payload) {
    if (content.length > 0) {
      const processId = await getProcessIdFromContent(content);
      payload.templateUsed = processId;
    }

    return payload;
  }

  async trackCreateFile(fileId, from, type, parentType, content = '', processTemplateUsed, parentFileId) {
    const payload = {
      fileId,
      createdFrom: from,
      fileType: FILE_TYPE_MAPPING[type],
      parentType: FILE_TYPE_MAPPING[parentType]
    };
    if (type === 'BPMN') {
      payload.connectors = await this.#getConnectorsFromXml(content);
      payload.containsUserTasks = await this.#getContainsUserTasksFromXml(content);

      if (processTemplateUsed) {
        payload.templateUsed = processTemplateUsed;
      }
    }
    if (parentFileId) {
      payload.parentFileId = parentFileId;
    }

    this.track('modeler:create:file', payload);
  }

  trackViewFile = (origin, user, file, modeler, extraProps) => {
    const props = {
      ...extraProps,
      origin: origin,
      user: user
    };

    // the diagram and user are not disclosed in share and embeds
    if (!['share', 'embed'].includes(origin)) {
      props.fileId = file.id;
      props.fileType = FILE_TYPE_MAPPING[file.type];

      switch (props.fileType) {
        case 'bpmn':
        case 'dmn':
          props.flowNodes = getFlowNodes(file, modeler)?.length;
          break;
        case 'form':
          props.formFields = getFormFields(file)?.length;
          break;
      }
    }

    this.#trackInternalViewFile(props);
  };

  #trackInternalViewFile(payload) {
    this.track('modeler:view:file', payload);
  }

  trackEditFile({ fileId, type, mode, source }) {
    if (type === 'FORM') {
      this.increment('modeler:formChanges');
    } else if (type === 'CONNECTOR_TEMPLATE') {
      this.increment('modeler:connectorChanges');
    } else {
      this.increment(`modeler:diagramChanges${type === 'DMN' ? ':dmn' : ''}`);
    }

    const props = { fileId, fileType: FILE_TYPE_MAPPING[type] };
    if (type === 'BPMN') {
      props.connectors = this.#getConnectorsFromModeler();
      props.containsUserTasks = this.#getContainsUserTasksFromModeler();
    }

    if (mode) {
      props.mode = mode;
    }

    if (source) {
      props.source = source;
    }

    this.track('modeler:edit:file', props);
  }

  trackShareFile(fileId, type) {
    this.track('modeler:share:file', {
      fileId,
      fileType: FILE_TYPE_MAPPING[type]
    });
  }

  trackCloseFile(fileId, type, content) {
    const fileType = FILE_TYPE_MAPPING[type];

    const props = {
      fileId,
      fileType
    };

    if (type === 'FORM') {
      const formFields = getFormFields({ type, content });
      props.formFieldTypes = getFormFieldTypeCounts(formFields);
    }

    this.track('modeler:close:file', props);
  }

  async trackViewTemplate(content) {
    this.track('modeler:template:details', await this.#injectTemplateUsed(content, {}, true));
  }

  trackProjectInvite(rights, numberOfInvites) {
    this.track('modeler:invite:project', {
      rights,
      numberOfInvites
    });
  }

  trackSingleProjectInvitation({ email, projectAccess, isExternal }) {
    this.track('modeler:invite:project:v2', {
      email,
      projectAccess,
      isExternal
    });
  }

  trackCreateProject(from) {
    this.track('modeler:create:project', {
      createdFrom: from === 'move' ? 'move-entity' : from === 'copy' ? 'copy-from-milestone' : from
    });
  }

  trackCreateFolder(from, type) {
    this.track('modeler:create:folder', {
      createdFrom: from === 'move' ? 'move-entity' : from === 'copy' ? 'copy-from-milestone' : from,
      parentType: type.toLowerCase()
    });
  }

  trackRename(type = DEFAULT) {
    this.track(`modeler:rename:${FILE_TYPE_MAPPING[type]}`);
  }

  trackDeleteEntities(type, numberOfFiles = 0, numberOfFolders = 0) {
    this.track('modeler:delete:entity', {
      parentType: FILE_TYPE_MAPPING[type],
      numberOfFiles,
      numberOfFolders
    });
  }

  trackFileExport(fileId, type, format) {
    this.track('modeler:export:file', {
      fileId,
      fileType: FILE_TYPE_MAPPING[type],
      exportType: format
    });
  }

  async trackCreateMilestone(from, file, organizationPublic) {
    const props = {
      createdFrom: from,
      fileType: FILE_TYPE_MAPPING[file.type],
      fileId: file.id
    };
    if (file.type === BPMN) {
      props.connectors = await this.#getConnectorsFromXml(file.content);
      props.containsUserTasks = await this.#getContainsUserTasksFromXml(file.content);
    }
    if (file.type === CONNECTOR_TEMPLATE) {
      props.publicationTarget = organizationPublic ? PUBLISHED_ON.ORGANIZATION : PUBLISHED_ON.PROJECT;
    }
    this.track('modeler:create:milestone', props);
  }

  trackPublicationMilestoneToOrganization({ from, fileId, fileType }) {
    const props = {
      createdFrom: from,
      fileId,
      fileType,
      publicationTarget: PUBLISHED_ON.ORGANIZATION
    };

    this.track('modeler:update:milestone', props);
  }

  trackPageView(name) {
    this.track('modeler:view:page', {
      view: name,
      url: location.href
    });
  }

  trackAddComment({ file, elementType, mentions, previousCommenters, previousComments }) {
    this.increment('modeler:addedComments');
    this.track('modeler:add:comment', {
      fileId: file.id,
      fileType: FILE_TYPE_MAPPING[file.type],
      elementType,
      mentions,
      previousCommenters,
      previousComments
    });
  }

  trackLicenseCheck(license, expired) {
    this.track('modeler:licenseCheck', { license, expired });
  }

  incrementExpiredTrialCounter() {
    this.increment('modeler:show:expiredTrialBanner');
  }

  trackDeployment({ diagram, errorMessage, deployedForms, clusterVersion }) {
    const props = {
      fileId: diagram.id,
      fileType: FILE_TYPE_MAPPING[diagram.type]
    };

    if (deployedForms) {
      props.deployedForms = deployedForms;
    }

    if (clusterVersion) {
      props.clusterVersion = clusterVersion;
    }

    if (diagram.type === 'BPMN') {
      props.connectors = this.#getConnectorsFromModeler();
      props.containsUserTasks = this.#getContainsUserTasksFromModeler();
    }

    this.track('modeler:deploy:confirm', props);

    if (errorMessage) {
      this.track('modeler:deploy:error', { ...props, errorMessage });
    }
  }

  trackExecution({ diagram, errorMessage, deployedForms, clusterVersion }) {
    const props = {
      fileId: diagram.id,
      fileType: FILE_TYPE_MAPPING[diagram.type]
    };

    if (deployedForms) {
      props.deployedForms = deployedForms;
    }

    if (clusterVersion) {
      props.clusterVersion = clusterVersion;
    }

    if (diagram.type === 'BPMN') {
      props.connectors = this.#getConnectorsFromModeler();
      props.containsUserTasks = this.#getContainsUserTasksFromModeler();
    }

    this.track('modeler:start:confirm', props);

    if (errorMessage) {
      this.track('modeler:start:error', { ...(props ? props : {}), errorMessage });
    }
  }

  trackForceRelogin() {
    this.track('modeler:login:force', {
      url: location.href
    });
  }

  trackTokenSimulation(eventType) {
    this.track('modeler:tokenSimulation:click', {
      eventType
    });
  }

  trackTargetEngineVersion(file, value) {
    this.track('modeler:executionPlatform:version', {
      value,
      fileId: file.id,
      fileType: file.type
    });
  }

  trackDiagramErrors(file, type, count) {
    this.track('modeler:diagramErrors:count', {
      type,
      count,
      fileId: file.id,
      fileType: file.type
    });
  }

  trackAssetRefresh() {
    this.track('modeler:refresh');
  }

  /**
   * action: openMenu|link|unlink
   * resource: form|decision|BPMN
   */
  trackResourceLinking({ action, resource }) {
    this.track('modeler:resource:link', {
      action,
      resource
    });
  }

  trackPopover(type) {
    this.track('modeler:popover', { type });
  }

  trackTopBarAction(item, section, props) {
    if (config.features.trackTopbarEnabled) {
      this.track('modeler:topbarClicked', { ...props, section, item });
    }
  }

  trackFormEditorLayoutChanged(layout, triggeredBy, fileId) {
    this.track('modeler:formEditor:layoutChanged', { layout, triggeredBy, fileId });
  }

  trackFormEditorPreviewChanged(fileId) {
    this.track('modeler:formEditor:previewChanged', { fileId });
  }

  trackFormEditorInputDataChanged(fileId) {
    this.track('modeler:formEditor:inputDataChanged', { fileId });
  }

  trackFormAssistantOpen(origin) {
    this.track('modeler:formAssistant:open', { origin });
  }

  trackFormAssistantClose(page) {
    this.track('modeler:formAssistant:close', { page });
  }

  trackFormAssistantGenerationStart(prompt) {
    this.track('modeler:formAssistant:generationStart', { prompt });
  }

  trackFormAssistantGenerationError(errorMessage) {
    this.track('modeler:formAssistant:generationError', { errorMessage });
  }

  trackFormAssistantGenerationSuccess() {
    this.track('modeler:formAssistant:generationSuccess');
  }

  trackFormAssistantImportError(errorMessage) {
    this.track('modeler:formAssistant:importError', { errorMessage });
  }

  trackFormAssistantImportSuccess() {
    this.track('modeler:formAssistant:importSuccess');
  }

  trackDiagramModeChange(fileId, type, mode) {
    this.track('modeler:diagram:changeMode', { fileId, fileType: FILE_TYPE_MAPPING[type], mode });
  }

  trackActionsMenuOpening(fileId, type, mode) {
    this.track('modeler:file:activityMenuOpen', { fileId, fileType: FILE_TYPE_MAPPING[type], mode });
  }

  trackActionsMenuItemClick(item, fileId, type, mode) {
    this.track('modeler:file:activityMenuItemClick', { item, fileId, fileType: FILE_TYPE_MAPPING[type], mode });
  }

  trackDetailsPanelTabClick(target) {
    this.track('modeler:detailsPanelTab:open', { target });
  }

  trackInboundConnectorTabClick() {
    this.trackDetailsPanelTabClick('inbound');
  }

  trackInboundConnectorURLCopy(processId, id) {
    this.track('modeler:webhookURL:copy', { processId, webhookId: id });
  }

  trackPublicLinkCopy(processId) {
    this.track('modeler:publicLink:copy', { processId });
  }

  trackCheckoutPage(displayedSalesPlan) {
    this.track('checkout:open', { from: 'Modeler', displayedSalesPlan });
  }

  trackPublicationTabClick() {
    this.trackDetailsPanelTabClick('publication');
  }

  trackPublicationGroupExpand() {
    this.track('modeler:publicationGroup:expand');
  }

  trackPublicationToggleClick(processId, flag) {
    this.track(`modeler:publication:${flag ? 'enable' : 'disable'}`, { processId });
  }

  trackDiagramAction({ eventName, fileId, type, mode, eventData }) {
    const props = { fileId, fileType: FILE_TYPE_MAPPING[type], mode, ...eventData };
    this.track(`modeler:${eventName}`, props);
  }

  trackSaveAsConnectorTemplate({ connectorId, fileId, type }) {
    const props = { fileId, fileType: FILE_TYPE_MAPPING[type], connectorId };
    this.track('modeler:connector:saveAs', props);
  }

  trackDuplicateImportedConnectorTemplate({ connectorId, fileId, type }) {
    const props = { fileId, fileType: FILE_TYPE_MAPPING[type], connectorId };
    this.track('modeler:connector:imported-duplicate', props);
  }

  trackResourcesImportOpening({ urls }) {
    this.track('modeler:resourceImport:open', { urls });
  }

  trackImportedResourcePublish({ urls, projectId }) {
    this.track('modeler:resourceImport:publish', {
      urls,
      projectId
    });
  }

  trackXMLEditorOpen(fileId, type) {
    this.track('modeler:xmlEditor:open', { fileId, fileType: FILE_TYPE_MAPPING[type] });
  }

  trackExperiment({ experimentKey, group }) {
    this.track(`modeler:experiment:${experimentKey}`, {
      group
    });
  }
}

export default new TrackingService();
