/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

function getHelperText(endPoint, isReady, hasDiagramError, hasProblems) {
  if (!endPoint) {
    return 'Setting up a temporary cluster...';
  }
  if (!isReady) {
    return 'Auto-deploying supporting files...';
  }

  if (hasDiagramError) {
    return 'The diagram failed to deploy.\nPlease check the Output tab in the Implement mode for more details.';
  }
  if (hasProblems) {
    return 'Some parts of your process will not work. Please check the problems tab for actionable solutions.';
  }

  return 'Play environment is ready';
}

export { getHelperText };
