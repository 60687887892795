/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Dropdown } from '@carbon/react';
import styled from 'styled-components';

export const ProjectsDropdown = styled(Dropdown)`
  margin-bottom: var(--spacing-small);
`;

export const CreateNewProjectItem = styled.div`
  color: var(--cds-blue-60);
`;
