/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState } from 'react';

import notificationStore from 'stores/NotificationStore';

import ActionButton from './ActionButton';
import PublishMessageModal from './PublishMessageModal';
import PlayContext from './PlayContext';
import Envelope from './icons/svgr/Envelope.svg';
import Retry from './icons/svgr/Retry.svg';
import { NEW_PROCESS_INSTANCE, REQUEST_FAILURE } from './utils/constants';

export default function TriggerMessageSubscriptionButton({ messageSubscription, isRestartMode }) {
  const context = useContext(PlayContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const Icon = isRestartMode ? Retry : Envelope;

  return (
    <>
      <ActionButton
        actions={[
          {
            label: isRestartMode ? 'Restart process' : 'Publish Message',
            renderIcon: () => <Icon color="white" />,
            onClick: async () => {
              const isSuccess = await context.publishMessage(
                messageSubscription.messageName,
                messageSubscription.messageCorrelationKey
              );

              if (!isSuccess) {
                notificationStore.showError(REQUEST_FAILURE);
              } else {
                if (isRestartMode) {
                  notificationStore.showSuccess(NEW_PROCESS_INSTANCE);
                }
              }
            }
          },
          {
            label: 'Publish Message with Variables',
            onClick: () => {
              setIsModalOpen(true);
            }
          }
        ]}
      />

      <PublishMessageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialMessageName={messageSubscription.messageName}
        initialCorrelationKey={messageSubscription.messageCorrelationKey}
        shouldShowSuccessNotification={isRestartMode}
      />
    </>
  );
}
