/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeObservable, observable, runInAction, action } from 'mobx';

import { userService } from 'services';
import config from 'utils/config';

class BaseAuthService {
  isReady = false;
  hasReset = false;
  modelerUser = undefined;
  jwtUser = undefined;

  authClient = undefined;
  isInit = false;
  isAuthenticated = false;
  token = undefined;

  _tokenMinValidity = 30; // seconds

  constructor() {
    makeObservable(this, {
      isReady: observable,
      hasReset: observable,
      token: observable,
      setToken: action
    });
  }

  /**
   * Triggers the token refresh and returns the current token.
   * @returns {Promise}
   */
  getToken() {
    return this.refreshToken();
  }

  setToken(value) {
    this.token = value;
  }

  async fetchJWTUser() {
    throw new Error('Not implemented');
  }

  async refreshToken() {
    throw new Error('Not implemented');
  }

  async getTokenAndFetchAuthProviderUser() {
    throw new Error('Not implemented');
  }

  async fetchModelerUser() {
    try {
      const user = await userService.fetchMyself();
      if (user) {
        this.modelerUser = { ...user };
      } else {
        this.logout();
      }
    } catch (error) {
      this.logout();
    }
  }

  async prepareSession() {
    await this.refreshToken();

    await this.fetchJWTUser();
    await this.createModelerUser();

    runInAction(() => {
      this.isReady = true;
    });
  }

  getReturnToFromUrl() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let { returnUrl: returnTo } = Object.fromEntries(urlSearchParams.entries());

    if (returnTo) {
      returnTo = returnTo.replace(config.modelerBasePath, '');
    }

    if (returnTo === '/login' || !returnTo) {
      returnTo = '/';
    }

    return returnTo;
  }

  async createModelerUser() {
    await this.upsertModelerUser();
    await this.fetchModelerUser();
  }

  upsertModelerUser() {
    return userService.upsertUser(this.mapJwtDataToUser(this.jwtUser));
  }

  mapJwtDataToUser(jwtData) {
    const { sub: iamId, name, email } = jwtData;

    const user = {
      iamId,
      name,
      email
    };

    // NOTE: This property is available only on Auth0
    const originalUserId = jwtData['https://camunda.com/originalUserId'];
    if (originalUserId) {
      user.originalUserId = originalUserId;
    }

    return user;
  }

  reset() {
    this._token = undefined;
    this.modelerUser = undefined;
    this.jwtUser = undefined;

    runInAction(() => {
      this.isReady = false;
      this.hasReset = true;
    });
  }
}

export default BaseAuthService;
