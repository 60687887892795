/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import BpmnModdle from 'bpmn-moddle';
import ModelerModdleExtension from 'modeler-moddle/resources/modeler.json';

export default async function parseXMLtoModdleDefinition(xml) {
  const moddle = new BpmnModdle({
    modeler: ModelerModdleExtension
  });
  const { rootElement } = await moddle.fromXML(xml);

  return rootElement;
}
