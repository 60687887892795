/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Helmet } from 'react-helmet-async';

import { TopBar, Breadcrumb } from 'components';
import { milestoneStore } from 'stores';
import { getPageTitle } from 'utils/helpers';
import buildSlug from 'utils/buildSlug';

export default function Header() {
  const { diagram, project } = milestoneStore.state;

  return (
    <>
      <Helmet>
        <title>{getPageTitle(`${diagram.name} - Versions`)}</title>
      </Helmet>
      <TopBar.Breadcrumbs>
        <Breadcrumb data-test="breadcrumb-home" title="Home" variant="link" to="/" />
        <Breadcrumb data-test="breadcrumb-project" title={project.name} variant="link" to={`/projects/${project.id}`} />
        {Boolean(diagram.folder) && (
          <>
            {diagram.folder.parentId && <Breadcrumb title="..." variant="text" data-test="parent-dots" />}
            <Breadcrumb
              title={diagram.folder.name}
              variant="link"
              to={`/folders/${buildSlug(diagram.folder.id, diagram.folder.name)}`}
              data-test="parent-folder"
            />
          </>
        )}
        <Breadcrumb
          data-test="breadcrumb-diagram"
          title={diagram.name}
          variant="link"
          to={`/connector-templates/${diagram.id}`}
        />
        <Breadcrumb data-test="breadcrumb-milestone" title="Versions" variant="text" isBold />
      </TopBar.Breadcrumbs>
    </>
  );
}
