/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';

import * as Styled from './EmptyState.styled';

export default function EmptyState({ title, description, icon, action, link, ...props }) {
  return (
    <Styled.Wrapper data-test="empty-state" {...props}>
      {icon && <Styled.Icon>{icon}</Styled.Icon>}
      <Styled.Content>
        <Styled.Title>{title}</Styled.Title>
        {description && <Styled.Description>{description}</Styled.Description>}
        {action && <Styled.Action>{action}</Styled.Action>}
        {link && <Styled.Link>{link}</Styled.Link>}
      </Styled.Content>
    </Styled.Wrapper>
  );
}

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  action: PropTypes.node,
  link: PropTypes.node
};
