/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment } from 'react';
import { observer } from 'mobx-react';

import { Locked, Unlocked } from 'icons';

import * as Styled from './PasswordToggle.styled';

export default observer(({ diagram, togglePasswordControl, toggleRemovePasswordControl }) =>
  diagram.passwordProtected ? (
    <Fragment>
      <Locked height="16" />
      <Styled.Status data-test="share-password-status">Password Protected</Styled.Status>
      <Styled.Action data-test="share-password-toggle" onClick={toggleRemovePasswordControl(true)}>
        Remove
      </Styled.Action>
    </Fragment>
  ) : (
    <Fragment>
      <Unlocked height="16" />
      <Styled.Status data-test="share-password-status">Password protect</Styled.Status>
      <Styled.Action data-test="share-password-toggle" onClick={togglePasswordControl(true)}>
        Add
      </Styled.Action>
    </Fragment>
  )
);
