/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { createRoot } from 'react-dom/client';

import { commentsStore } from 'stores';

import CommentsOverlay from './CommentsOverlay';

export default function prepareOverlays(bpmnjs) {
  const overlays = bpmnjs.get('overlays');
  const OVERLAY_TYPE = 'comments-menu';

  /**
   * Renders all specification overlays for BPMN elements that have
   * specification.
   */
  const render = () => {
    const { elementIds } = commentsStore;
    const registry = bpmnjs.get('elementRegistry');

    elementIds.forEach((elementId) => {
      const element = registry.get(elementId);

      if (!element || element.type === 'bpmn:Process' || element.type === 'bpmn:Collaboration') {
        return;
      }

      add(element);
    });
  };

  /**
   * Appends a specification overlay to a certain BPMN element.
   *
   * @param {Object} element The BPMN element to append the overlay to.
   */
  const add = (element) => {
    const html = document.createElement('div');

    createRoot(html).render(<CommentsOverlay element={element} bpmnjs={bpmnjs} />);

    if (element.type === 'bpmn:Process' || element.type === 'bpmn:Collaboration') {
      html.style.position = 'absolute';
      html.style.top = '7px';
      html.style.left = '12px';

      document.querySelector('.djs-container').appendChild(html);
    } else {
      // Remove existing overlay to avoid duplicates
      overlays.remove({ element: element.id, type: OVERLAY_TYPE });

      overlays.add(element, OVERLAY_TYPE, {
        position: { bottom: -5, right: 12 },
        show: { minZoom: 0.7 },
        html
      });
    }
  };

  /**
   * Removes a specification overlay from a certain BPMN element.
   *
   * @param {Object} element The BPMN element that should get its overlays removed.
   */
  const remove = (element) => {
    const matches = overlays.get({
      element: element,
      type: OVERLAY_TYPE
    });

    matches.forEach((match) => overlays.remove(match.id));
  };

  return { render, add, remove };
}
