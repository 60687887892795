/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { TopBanner } from 'primitives';

export const Container = styled(TopBanner)`
  background-color: #fff1f1;
  border: 1px solid #da1e28;
  height: 3rem;
  padding: 0;
  width: 100%;
  gap: 1rem;
`;
