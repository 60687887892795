/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

class ZeebeClientStore {
  checkConnectionResponse = null;
  checkConnectionErrors = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCheckConnectionResponse(response) {
    this.checkConnectionResponse = response;
  }

  setCheckConnectionErrors(errors) {
    this.checkConnectionErrors = errors;
  }
}

export default new ZeebeClientStore();
