/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext } from 'react';
import { View } from '@carbon/react/icons';

import buildSlug from 'utils/buildSlug';

import ActionButton from '../ActionButton';
import PlayContext from '../PlayContext';

export default function ChildInstanceButton({ childInstance }) {
  const context = useContext(PlayContext);

  return (
    <ActionButton
      actions={[
        {
          secondary: childInstance.state === 'COMPLETED',
          label: 'View called process',
          renderIcon: () => <View color="white" />,
          onClick: () => {
            const process = childInstance.process.bpmnProcessId;
            const { fileId, filename, playId } = context.filesStatus.find((file) => file.id === process);

            const url = window.location.origin + '/diagrams/' + buildSlug(fileId, filename);

            localStorage.setItem(
              'play-directAccess',
              JSON.stringify({
                endPoint: context.endPoint,
                filesStatus: context.filesStatus,
                definition: playId,
                instance: childInstance.key
              })
            );

            if (childInstance.state !== 'COMPLETED') {
              const calledProcesses = JSON.parse(localStorage.getItem('play-calledProcesses') || '[]');
              calledProcesses.push(childInstance.key);
              localStorage.setItem('play-calledProcesses', JSON.stringify(calledProcesses));
            }

            window.open(url, '_blank').focus();
          }
        }
      ]}
    />
  );
}
