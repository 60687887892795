/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Table, TableBody, TableHead, TableHeader, TableRow, TableCell } from '@carbon/react';
import { useContext, useEffect, useState } from 'react';

import StateIcon from './StateIcon';
import PlayContext from './PlayContext';
import InstanceHistory from './instance/InstanceHistory';
import * as Styled from './BottomBar.styled';
import zeebePlayService from './ZeebePlayService';
import ErrorPanel from './definition/ErrorPanel';
import { formatStartTime } from './utils/formatStartTime';

export default function BottomBar({ goToInstance, filesStatus, connectorInfo }) {
  const problems = filesStatus
    .filter((file) => file.status !== 'success')
    .concat(connectorInfo.connectorElementsWithMissingSecrets);

  const context = useContext(PlayContext);
  const [instances, setInstances] = useState();
  const [activeTab, setActiveTab] = useState(problems.length > 0 ? 'problems' : 'instances');

  useEffect(() => {
    if (!context.instanceId && context.endPoint && context.playId) {
      (async () => {
        const response = await zeebePlayService.fetchInstances(context.endPoint, context.playId);

        if (response === undefined) {
          context.setHasError(true);
        }

        setInstances(response?.processInstances?.nodes);
      })();
    }
  }, [context.instanceId, context.endPoint, context.playId]);

  const flowNodeInstances = context.elementInstances?.elementInstances?.filter(
    (instance) => instance.element.bpmnElementType !== 'SEQUENCE_FLOW'
  );

  if (context.instanceId && flowNodeInstances) return <InstanceHistory flowNodeInstances={flowNodeInstances} />;

  return (
    <Styled.Container>
      <Styled.Title>
        <Styled.Tab active={activeTab === 'instances'} onClick={() => setActiveTab('instances')}>
          <Styled.StatusText>Process Instances</Styled.StatusText>
        </Styled.Tab>
        <Styled.Tab active={activeTab === 'problems'} onClick={() => setActiveTab('problems')}>
          <Styled.StatusText>
            Problems{' '}
            {problems.length > 0 && (
              <Styled.ErrorCounterTag type="red" title={problems.length.toString()} size="sm">
                {problems.length.toString()}
              </Styled.ErrorCounterTag>
            )}
          </Styled.StatusText>
        </Styled.Tab>
      </Styled.Title>
      <Styled.Content>
        {activeTab === 'instances' ? (
          instances?.length > 0 ? (
            <Table size="sm">
              <TableHead>
                <TableRow>
                  <TableHeader width="300">Instance Key</TableHeader>
                  <TableHeader>Started at</TableHeader>
                  <TableHeader>Operations</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {instances?.map(({ key, startTime, state }) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>
                        <Styled.IconContainer>
                          <StateIcon state={state} size={20} />
                        </Styled.IconContainer>
                        <Styled.InstanceButton
                          variant="text"
                          onClick={() => {
                            goToInstance(key);
                          }}
                        >
                          {key}
                        </Styled.InstanceButton>
                      </TableCell>
                      <TableCell>{formatStartTime(startTime)}</TableCell>
                      <TableCell>-</TableCell>
                    </TableRow>
                  );
                }) ?? (
                  <TableRow>
                    <TableCell>No Content</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            <Styled.EmptyState>
              <Styled.EmptyHeader>Start by activating a process instance</Styled.EmptyHeader>
              <Styled.EmptyDescription>
                To activate an instance, click the Play button on the canvas
              </Styled.EmptyDescription>
            </Styled.EmptyState>
          )
        ) : (
          <ErrorPanel filesStatus={filesStatus} connectorInfo={connectorInfo} />
        )}
      </Styled.Content>
    </Styled.Container>
  );
}
