/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

class SelectedElementExtensionStore {
  selectedElement = null;
  needsRefresh = false;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.selectedElement = null;
  }

  setSelectedElement(element) {
    this.selectedElement = element;
  }

  setNeedsRefresh(flag) {
    this.needsRefresh = flag;
  }
}
export default new SelectedElementExtensionStore();
