/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { isBPMN, isDMN, isForm, isProject, sanitizeName } from 'utils/helpers';
import FileMapper from 'utils/file-mapper';
import generateId from 'utils/generate-id';
import { DEFAULT, DMN, FOLDER, FORM } from 'utils/constants';
import { currentDiagramStore } from 'stores';
import { trackingService } from 'services';

/**
 * Determines whether a new folder or project can be created, based on
 * the passed action, starting point type, and license.
 *
 * @param {String} action The action type, such as "link" or "move".
 * @returns {Boolean}
 */
export const canCreateProjectOrFolder = (action) => {
  return !isLinkAction(action);
};

/**
 * Determines whether a single item (like a diagram or folder) inside the item list
 * is disabled. This could be the case if a user tried to move a folder into itself.
 *
 * @param {Object} state The current reducer's state.
 * @param {Object} item The item to check.
 * @returns {Boolean}
 */
export const isItemDisabled = (state, item) => {
  if (isBPMN(item)) {
    return state.invalidTargetIds.includes(item.id);
  }

  return state.startingPoint.id !== item.id && state.invalidTargetIds.includes(item.id) && !item.type;
};

/**
 * Determines whether the "Move" button is disabled.
 *
 * Case #1: when the action is "link", the button must be disabled if no selection has been made
 * or if the selection does not contain a BPMN diagram.
 *
 * Case #2: when the action is "import", the button must be disabled if no selection has been made
 * or if the selection does not contain a form.
 *
 * Case #3: when the organization root is displayed and no selection has been made.
 *
 * Case #4: when the selected (or currently opened) target has the same ID as
 * the source target (where the user started from).
 *
 * @param {Object} state The current reducer's state.
 * @param {Object} selected The currently selected item.
 * @returns {Boolean}
 */
export const isMoveButtonDisabled = (state, selected) => {
  if (state.action === 'link') {
    return !selected || (selected && !isBPMN(selected));
  } else if (state.action === 'business-rule-task-link') {
    if (selected && isDMN(selected) && selected?.decisions?.length === 1) {
      return false;
    }
    if (selected && selected.type === 'DECISION') {
      return false;
    }
    return true;
  } else if (state.action === 'form-link') {
    return !selected || (selected && !isForm(selected));
  }

  return state.invalidTargetIds.includes(selected?.id || state.current.id) || (!selected && state.level === 'root');
};

export const isLinkAction = (action) => {
  return action === 'link' || action === 'business-rule-task-link' || action === 'form-link';
};

export const createEntity = ({ type, attributes = {}, target }) => {
  const entity = new FileMapper(type).generate(attributes);

  const nameWithoutExtension = entity.name?.replace(/\.[^/.]+$/, '');
  const projectId = isProject(target) ? target.id : target.projectId;
  const folderId = isProject(target) ? null : target.id;

  return {
    ...entity,
    name: nameWithoutExtension,
    projectId,
    folderId
  };
};

export const generateIdFromElementName = (elementName, elementType) => {
  let idPrefixFromElementType;
  switch (elementType) {
    case DMN:
      idPrefixFromElementType = 'decision';
      break;
    case FORM:
      idPrefixFromElementType = 'form';
      break;
  }

  const idPrefixFromElementName = sanitizeName(elementName, idPrefixFromElementType);

  return generateId({
    prefix: `${isValidId(idPrefixFromElementName) ? idPrefixFromElementName : idPrefixFromElementType}-`
  });
};

const isValidId = (id) => {
  // Check if the ID starts with a letter, underscore, or non-digit character
  return !(id && !/^[a-zA-Z_][\w.-]*$/.test(id));
};

export const trackSubResourceCreation = ({ newFileId, newFileType, newFileTarget }) => {
  trackingService.trackCreateFile(
    newFileId,
    'linkDialog',
    newFileType,
    isProject(newFileTarget) ? DEFAULT : FOLDER,
    '',
    undefined,
    currentDiagramStore.state?.diagram?.id
  );
};
