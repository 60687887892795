/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Comment } from 'icons';

export const Content = styled.div`
  overflow-x: auto;
  max-height: 400px;
  scrollbar-color: rgba(255, 255, 255, 0.8) transparent;
  scroll-width: thin;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
  }

  @media (max-height: 450px) {
    max-height: 80vh;
  }

  > a {
    color: white;
    text-decoration: none;
    border-bottom: 1px dotted white;

    &:hover {
      border-bottom-style: solid;
    }
  }
`;

export const CommentIcon = styled(Comment)`
  width: 20px;
  height: 20px;
  padding: 2px;
`;
