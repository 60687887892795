/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import tokenSimulationStore from './TokenSimulationStore';

function ToggleOverride(eventBus, toggleMode) {
  eventBus.on('tokenSimulation.toggleMode', function () {
    tokenSimulationStore.toggleTokenSimulation();
  });

  const onTokenSimulationChange = (flag) => {
    if (typeof flag === 'boolean') {
      toggleMode.toggleMode(flag);
    } else {
      toggleMode.toggleMode();
    }
  };
  tokenSimulationStore.setOnTokenSimulationChange(onTokenSimulationChange);

  const cleanup = () => {
    eventBus.fire('tokenSimulation.resetSimulation');
  };
  tokenSimulationStore.setCleanup(cleanup);
}

ToggleOverride.$inject = ['eventBus', 'toggleMode'];

export default {
  __init__: ['toggleOverride'],
  toggleOverride: ['type', ToggleOverride]
};
