/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Sidebar } from 'primitives';
import { milestoneStore } from 'stores';
import { MilestonesDiffingToggle, MilestonesSection } from 'components';

import RelatedDiagramsSection from './RelatedDiagramsSection';
import * as Styled from './Sidebar.styled';

export default function MilestoneSidebar() {
  const { hasRelatedFiles } = milestoneStore;

  return (
    <Sidebar $isVisible>
      <Sidebar.Inner>
        {hasRelatedFiles && <Sidebar.Title>Milestones and related diagrams</Sidebar.Title>}
        <Styled.SidebarSection $hasVersions={hasRelatedFiles}>
          <Sidebar.Subtitle>Milestones</Sidebar.Subtitle>
          <MilestonesSection />
        </Styled.SidebarSection>

        {hasRelatedFiles && (
          <Sidebar.Section>
            <Sidebar.Subtitle>Related diagrams</Sidebar.Subtitle>
            <RelatedDiagramsSection />
          </Sidebar.Section>
        )}
      </Sidebar.Inner>

      <MilestonesDiffingToggle />
    </Sidebar>
  );
}
