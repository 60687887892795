/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { isDMN } from 'utils/helpers';

import * as Styled from './KeyboardShortcuts.styled';

const IS_MAC = window.navigator.platform === 'MacIntel';

const COMMON_SHORTCUTS = [
  { title: 'Undo', default: 'Ctrl + Z', mac: '⌘ + Z' },
  { title: 'Redo', default: 'Ctrl + ⇧ + Z', mac: '⌘ + ⇧ + Z' },
  { title: 'Select All', default: 'Ctrl + A', mac: '⌘ + A' },
  { title: 'Hand Tool', default: 'H' },
  { title: 'Direct Editing', default: 'E' },
  { title: 'Lasso Tool', default: 'L' },
  { title: 'Create Milestone', default: '⇧ + M' }
];

const BPMN_DESIGN_MODE_SHORTCUTS = [{ title: 'Token Simulation', default: 'T' }];

const BPMN_SHORTCUTS = [
  ...COMMON_SHORTCUTS,
  {
    title: 'Scrolling (Vertical)',
    default: 'Ctrl + Scrolling',
    mac: '⌥ + Scrolling'
  },
  {
    title: 'Scrolling (Horizontal)',
    default: 'Ctrl + ⇧ + Scrolling',
    mac: '⌥ + ⇧ + Scrolling'
  },
  { title: 'Attention Grabber', default: 'A' },
  { title: 'Space Tool', default: 'S' },
  { title: 'Search BPMN Symbol', default: 'Ctrl + F', mac: '⌘ + F' }
];

const DMN_SHORTCUTS = [...COMMON_SHORTCUTS];

export default function KeyboardShortcuts({ diagram, isDesignMode }) {
  let SHORTCUTS;
  if (isDMN(diagram)) {
    SHORTCUTS = DMN_SHORTCUTS;
  } else if (isDesignMode) {
    SHORTCUTS = [...BPMN_SHORTCUTS, ...BPMN_DESIGN_MODE_SHORTCUTS];
  } else {
    SHORTCUTS = BPMN_SHORTCUTS;
  }

  return (
    <Styled.ShortcutTable>
      <tbody data-test="shortcut-table">
        {SHORTCUTS.map((shortcut) => (
          <tr key={shortcut.title}>
            <td>{shortcut.title}</td>
            <td>{IS_MAC ? shortcut.mac || shortcut.default : shortcut.default}</td>
          </tr>
        ))}
      </tbody>
    </Styled.ShortcutTable>
  );
}
