/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Collaborator = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-small) 0;
`;

export const Base = styled.div`
  display: flex;
  overflow: hidden;
`;

export const AvatarContainer = styled.div`
  flex: 0 1 0;
  padding-right: var(--spacing-small);
`;
export const CollaboratorDetails = styled.div`
  flex: 1 0 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export const CollaboratorName = styled.div`
  color: var(--grey-darken-33);
  font-weight: bold;
`;

export const CollaboratorSubtitle = styled.div`
  font-size: 0.875em;
  line-height: 1.618;
  color: var(--grey-lighten-50);
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
