/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import minimapModule from 'diagram-js-minimap';
import ExecutionPlatformModule from '@camunda/execution-platform';

import { currentDiagramStore, diagramControlStore, realtimeCollaborationStore, attentionGrabberStore } from 'stores';
import { deploymentErrorsStore } from 'App/Pages/Diagram/stores';
import { Modeler, DiagramControls } from 'components';
import { callingProcessesExtension } from 'App/Pages/Diagram/CallingProcesses';
import { businessRuleTaskLinkStore } from 'App/Pages/Diagram/BusinessRuleTaskLinking';
import { XMLEditor, XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';
import { ResizablePanel } from 'primitives';

import clipboardExtension from './BpmnJSExtensions/clipboardExtension';
import attentionGrabberExtension from './BpmnJSExtensions/attentionGrabberExtension';
import DetailsPanel from './DetailsPanel/DetailsPanel';
import DiagramErrorPanel from './ErrorPanel';
import * as Styled from './Diagram.styled';

export const DmnViewer = ({ permission }) => {
  const [isSidebarAvailable, setIsSidebarAvailable] = useState(true);

  const { diagram } = currentDiagramStore.state;

  const diagramId = diagram?.id;

  // initialize the attentionGrabberStore with the current diagram
  useEffect(() => {
    if (diagramId) {
      attentionGrabberStore.currentDiagramId = diagramId;
      attentionGrabberStore.isInProjectContext = true;

      return () => {
        attentionGrabberStore.reset();
      };
    }
  }, [diagramId]);

  const getModelerPlugins = () => {
    const additionalPlugins = {
      drd: [minimapModule, callingProcessesExtension],
      decisionTable: [clipboardExtension],
      literalExpression: []
    };

    if (permission.is(['WRITE', 'ADMIN', 'COMMENT'])) {
      additionalPlugins.drd.push(attentionGrabberExtension);
    }

    if (permission.is(['WRITE', 'ADMIN'])) {
      additionalPlugins.drd.push(ExecutionPlatformModule);
      additionalPlugins.decisionTable.push(ExecutionPlatformModule);
      additionalPlugins.literalExpression.push(ExecutionPlatformModule);
    }

    return additionalPlugins;
  };

  const handleChange = () => {
    if (!XMLEditorStore.isEditorOpen) {
      // prevents double save, when the XML editor is open
      currentDiagramStore.debouncedSaveContent();
    }
  };

  const handleModelerLoaded = (modeler) => {
    currentDiagramStore.setModeler(modeler);
    diagramControlStore.setModeler(modeler);
    attentionGrabberStore.setModeler(modeler);
    realtimeCollaborationStore.init({ permission });
    businessRuleTaskLinkStore.handleNavigationFromBusinessRuleTaskLink(modeler);
  };

  const handleModelerInit = () => {
    currentDiagramStore.trackDiagramView('direct');
  };

  const handleViewChange = (view) => {
    if (view === 'drd') {
      setIsSidebarAvailable(true);
    } else {
      setIsSidebarAvailable(false);
    }
  };

  const isViewOnly = permission.is(['COMMENT', 'READ']);
  const shouldRenderXMLEditor = permission.is(['WRITE', 'ADMIN']) && XMLEditorStore.isEditorOpen;

  return (
    <>
      <Styled.Wrapper>
        <Modeler
          diagram={diagram}
          initialContent={diagram.content}
          onChanged={handleChange}
          onModelerLoaded={handleModelerLoaded}
          onModelerInit={handleModelerInit}
          onViewChange={handleViewChange}
          bottomRight={
            <DiagramControls
              hasKeyboardShortcuts={permission.is(['WRITE', 'ADMIN'])}
              hasAttentionGrabber={permission.is(['WRITE', 'COMMENT', 'ADMIN'])}
            />
          }
          additionalModules={getModelerPlugins()}
          isViewOnly={isViewOnly}
        />
        {!isViewOnly && isSidebarAvailable && <DetailsPanel permission={permission} />}
      </Styled.Wrapper>

      {!isViewOnly && deploymentErrorsStore.deploymentErrors && (
        <ResizablePanel
          panelKey="error-panel"
          open={!currentDiagramStore.isErrorPanelCollapsed}
          background="white"
          position="bottom"
          minSize={235}
          maxSize={560}
          sizeClosed={35}
        >
          <DiagramErrorPanel deployErrors={deploymentErrorsStore.deploymentErrors} />
        </ResizablePanel>
      )}

      {shouldRenderXMLEditor && <XMLEditor />}
    </>
  );
};

export default observer(DmnViewer);
