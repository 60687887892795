/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function sanitizeTechnicalTerms(str = '') {
  if (typeof str !== 'string') {
    return str;
  }

  if (str.startsWith('bpmn:')) {
    return str.substring(5);
  } else if (str.startsWith('camunda:')) {
    return str.substring(8);
  } else if (str.startsWith('$')) {
    return str.substring(1);
  } else if (str === 'name') {
    return 'Label';
  } else if (str.startsWith('zeebe:')) {
    return str.substring(6);
  } else {
    return str;
  }
}
