/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import pluralize from 'utils/pluralize';

export const stringifyEntityCounts = ({ fileCount, folderCount, subfolderCount = 0 }) => {
  let text = '';

  if (folderCount > 0) {
    text += folderCount + ' ' + pluralize('folder', folderCount);

    if ((subfolderCount > 0 && fileCount === 0) || (subfolderCount === 0 && fileCount > 0)) {
      text += ' and ';
    } else if (subfolderCount > 0 && fileCount > 0) {
      text += ', ';
    }
  }

  if (subfolderCount > 0) {
    text += subfolderCount + ' ' + pluralize('subfolder', subfolderCount);

    if (fileCount > 0) {
      text += ', and ';
    }
  }

  if (fileCount > 0) {
    text += fileCount + ' ' + pluralize('file', fileCount);
  }

  return text;
};
