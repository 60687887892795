/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState, Fragment } from 'react';

import { Dropdown } from 'components';
import { IconButton } from 'primitives';
import { Sort } from 'icons';
import EntityContext from 'components/EntityList/EntityContext';

export default function SortDropdown({ onSort }) {
  const context = useContext(EntityContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = (evt) => {
    evt.stopPropagation();

    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton
        title="Sort"
        aria-label="Sort this list"
        aria-haspopup="true"
        onClick={handleOpen}
        data-test="entity-sort-dropdown"
        $isActive={Boolean(anchorEl)}
      >
        <Sort />
      </IconButton>

      <Dropdown anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <Dropdown.Title>Sort by</Dropdown.Title>

        {context.columns.map((column, index) => {
          if (!column.name || !column.sortable) return null;

          return (
            <Dropdown.ListItem
              key={column.name}
              $isHover={context.isSortedBy(index)}
              onClick={() => onSort(index)}
              data-test={context.isSortedBy(index) ? 'entity-sort-highlighted' : null}
            >
              {column.name}
            </Dropdown.ListItem>
          );
        })}
      </Dropdown>
    </Fragment>
  );
}
