/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import { TabList as _TabList, TabPanel as _TabPanel } from '@carbon/react';

import { Sidebar } from 'primitives';

export const TabsContainer = styled.div`
  margin-top: 1px;
  height: calc(100% - 1px);
`;

export const TabList = styled(_TabList)`
  background: var(--silver-base-97);
`;

export const SpaceFiller = styled.div`
  width: 100vw;
  border-bottom: 2px solid var(--cds-border-subtle);
  margin-left: 0.0625rem;
`;

export const TabPanel = styled(_TabPanel)`
  height: ${({ $showCallingProcesses }) => ($showCallingProcesses ? 'calc(100% - 92px)' : ' calc(100% - 40px)')};
  padding: unset;
`;

export const TabPanelDiv = styled.div`
  height: ${({ $showCallingProcesses }) => ($showCallingProcesses ? 'calc(100% - 92px)' : ' calc(100% - 40px)')};
  padding: unset;
`;

export const PropertiesSection = styled(Sidebar.Section)`
  height: 100%;
  ${({ $showSidebarTitle }) => $showSidebarTitle && 'height: calc(100% - 78px)'};
  ${({ $showCallingProcesses }) => $showCallingProcesses && 'height: calc(100% - 52px)'};
  background-color: #f8f8f8;
  overflow: auto;
`;

export const CommentsSection = styled(Sidebar.Section)`
  height: 100%;
  ${({ $showCallingProcesses }) => $showCallingProcesses && 'height: calc(100% - 52px)'};
`;

export const CommentsTitle = styled(Sidebar.Title)`
  border: none;
`;

export const CommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100% - 34px);
`;
