/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Exposes a memoized URLSearchParams instance to handle parameters from the query string
 * @returns URLSearchParams
 */
export default function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
