/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Link = styled.a`
  color: var(--cds-blue-60);
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: var(--cds-blue-70);
  }
`;
