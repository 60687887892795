/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { currentDiagramStore } from 'stores';
import { addFirstOverlayFromRight, addSecondOverlayFromRight, removeOverlay } from 'App/Pages/Diagram/overlay-util';
import { getCalledDecisionId, isBusinessRuleTask } from 'App/Pages/Diagram/model-parser-util';
import createPermission from 'utils/createPermission';

import businessRuleTaskLinkStore from './BusinessRuleTaskLinkStore';

const OVERLAY_TYPE = 'business-rule-task-menu';

export function BusinessRuleTaskLinkExtension(eventBus, overlays, elementRegistry) {
  eventBus.on('import.done', () => {
    businessRuleTaskLinkStore.setLinkedBusinessRuleTasks(getLinkedBusinessRuleTasks(elementRegistry));
  });

  eventBus.on(['selection.changed'], (event) => {
    const element = event.newSelection[0];
    if (isBusinessRuleTask(element)) {
      businessRuleTaskLinkStore.setSelectedBusinessRuleTask(element);
    } else {
      businessRuleTaskLinkStore.setSelectedBusinessRuleTask(null);
    }
  });

  eventBus.on('element.changed', () => {
    /**
     * For changes in elements, re-compute which BusinessRuleTask elements are linked
     * to decide whether to show an overlay or not.
     */
    /**
     * Set to null first to force a re-render of the overlays. Otherwise the overlay does not update
     * if the list itself does not change and the change is only in the Decision ID of the Called decision (deep change)
     * which happens when the Decision ID is changed in the properties panel
     */
    businessRuleTaskLinkStore.setLinkedBusinessRuleTasks(null);

    businessRuleTaskLinkStore.setLinkedBusinessRuleTasks(getLinkedBusinessRuleTasks(elementRegistry));
  });

  eventBus.on(['shape.added', 'connection.added'], (event) => {
    if (isBusinessRuleTask(event.element)) {
      const permission = createPermission(currentDiagramStore.state?.project?.permissionAccess);
      if (permission.is(['WRITE', 'ADMIN', 'COMMENT'])) {
        // Comment overlay present at first position from right
        addSecondOverlayFromRight(overlays, OVERLAY_TYPE, event.element);
      } else {
        addFirstOverlayFromRight(overlays, OVERLAY_TYPE, event.element);
      }
    }
  });

  eventBus.on(['shape.remove'], (event) => {
    if (isBusinessRuleTask(event.element)) {
      removeOverlay(overlays, OVERLAY_TYPE, event.element);
    }
  });

  eventBus.on('commandStack.element.updateLabel.executed', (event) => {
    const elementWhoseLabelWasUpdated = event?.context?.element;
    if (businessRuleTaskLinkStore?.selectedBusinessRuleTask?.id === elementWhoseLabelWasUpdated?.id) {
      businessRuleTaskLinkStore.setSelectedBusinessRuleTask(elementWhoseLabelWasUpdated);
    }
  });

  const getLinkedBusinessRuleTasks = (elementRegistry) => {
    const businessRuleTasks = elementRegistry.filter(isBusinessRuleTask);

    return businessRuleTasks.filter((element) => {
      const extensionElements = element.businessObject?.get('extensionElements');
      return Boolean(getCalledDecisionId(extensionElements));
    });
  };
}

BusinessRuleTaskLinkExtension.$inject = ['eventBus', 'overlays', 'elementRegistry'];

export default {
  __init__: ['businessRuleTaskLinkExtension'],
  businessRuleTaskLinkExtension: ['type', BusinessRuleTaskLinkExtension]
};
