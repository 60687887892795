/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

/**
 * Stringifies a passed XML document tree.
 *
 * @param {XMLDocument} dom The XML to be stringified.
 * @returns {String}
 */
export default function stringifyXML(dom) {
  const serializer = new XMLSerializer();
  return serializer.serializeToString(dom);
}
