/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

const REQUEST_FAILURE = 'Unable to process request. Please try again';
const NEW_PROCESS_INSTANCE = 'New process instance created';

export { REQUEST_FAILURE, NEW_PROCESS_INSTANCE };
