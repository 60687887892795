/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import { Form as CarbonForm, TextArea } from '@carbon/react';

import { Dialog, Button } from 'primitives';

import { isValidJSONObject } from './utils/isValidJSONObject';

const VARIABLE_VALIDATION_ERROR = 'Value has to be in the following format: {"variableName": "variableValue"}';

export default function VariablesInputDialog({ title, isOpen, onSubmit, onClose, submitLabel }) {
  const [variableModalContent, setVariableModalContent] = useState(null);
  const [variableModalError, setVariableModalError] = useState(null);

  useEffect(() => {
    return () => {
      setVariableModalContent(null);
      setVariableModalError(null);
    };
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Header>
        <Dialog.Title>{title}</Dialog.Title>
      </Dialog.Header>

      <Dialog.Content>
        <CarbonForm>
          <TextArea
            labelText="Variables (JSON)"
            placeholder={`{"variableName": "variableValue"}`}
            onChange={(evt) => {
              if (evt.target.value === '') {
                setVariableModalError(null);
                setVariableModalContent(null);
                return;
              }

              if (!isValidJSONObject(evt.target.value)) {
                setVariableModalError(VARIABLE_VALIDATION_ERROR);
              } else {
                setVariableModalError(null);
              }

              setVariableModalContent(evt.target.value);
            }}
            value={variableModalContent ?? ''}
            invalid={variableModalError !== null}
            invalidText={variableModalError}
          />
        </CarbonForm>
      </Dialog.Content>
      <Dialog.Footer>
        <Button
          onClick={() => {
            setVariableModalContent(null);
            setVariableModalError(null);
          }}
          variant="text"
          style={{ marginRight: 'auto' }}
        >
          Reset
        </Button>
        <Button
          onClick={() => {
            setVariableModalError(null);
            onClose();
          }}
          variant="secondary"
        >
          Close
        </Button>
        <Button
          onClick={() => {
            if (variableModalContent === null) {
              setVariableModalError(VARIABLE_VALIDATION_ERROR);
              return;
            }

            onSubmit(variableModalContent);
          }}
          variant="primary"
          disabled={variableModalError !== null}
        >
          {submitLabel}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
