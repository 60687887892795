/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment } from 'react';
import { observable, action, computed, runInAction, makeObservable } from 'mobx';
import debounce from 'lodash/debounce';
import moment from 'moment';
import Modeler from 'camunda-bpmn-js/lib/camunda-cloud/Modeler';
import { CamundaCloudModeler as DmnModeler } from 'camunda-dmn-js';
import ModelerModdleExtension from 'modeler-moddle/resources/modeler.json';
import DmnModelerModdleExtension from 'modeler-moddle/resources/dmn-modeler.json';
import camundaModdleDescriptor from 'camunda-dmn-moddle/resources/camunda.json';

import { getSearchParams, replaceSearchParams } from 'utils/url-params';
import { sort } from 'utils/sort';
import { fileService, projectService, trackingService, folderService } from 'services';
import {
  breadcrumbStore,
  confirmActionStore,
  milestoneStore,
  notificationStore,
  organizationStore,
  userStore
} from 'stores';
import history from 'utils/history';
import throttle from 'utils/throttle-async';
import { validateFiles } from 'utils/file-io';
import {
  getCallActivityLinks,
  getExecutableProcessId,
  getRelationId,
  parseXML,
  stringifyXML,
  getDefinitions
} from 'utils/web-modeler-diagram-parser';
import createPermission from 'utils/createPermission';
import {
  DEFAULT_ZEEBE_VERSION,
  EXECUTION_PLATFORM,
  ORIGINAL_RELEASE_DATE,
  BPMN,
  DEFAULT,
  FOLDER,
  NO_PROCESS_ID,
  FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION,
  FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION
} from 'utils/constants';
import { isExpression } from 'App/Pages/Diagram/properties-panel-util';
import hasAccess, { actions } from 'utils/user-access';
import { patchDmnFileDefinitions } from 'utils/validate-diagram';
import { getLinkedDecisions, detectDecisions } from 'App/Pages/Diagram/model-parser-util';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { checkAndSyncProcessName } from 'App/Pages/Diagram/modeler-util';

const EMPTY_PROJECT = {
  name: 'Loading...',
  diagrams: [],
  collaborators: [],
  invitations: []
};

const DEFAULT_STATE = {
  isFetching: true,
  isLoadingModeler: true,
  isShowingTemplate: true,
  isLegacyDiagram: false,
  diagram: null,
  templates: [],
  project: EMPTY_PROJECT,
  lastSaved: null,
  currentDate: null,
  currentVisibleMenu: {
    elementId: null,
    menuType: null
  },
  modelerViewboxScale: 1
};

const SAVING_MESSAGE_DURATION_IN_MILLIS = 300;
const SAVE_CONTENT_DEBOUNCE = 100;
const CHANGE_PARAMS_DEBOUNCE = 600;
const BOTTOM_PADDING = 45;

class CurrentDiagramStore {
  state = Object.assign({}, DEFAULT_STATE);
  executionPlatformVersion = DEFAULT_ZEEBE_VERSION;

  modeler = null;
  isDraggingActive = false;
  hasReceivedUpdateWhileDragging = false;
  hasFetchedDiagramWhileDragging = false;
  initialViewbox = null;
  isErrorPanelCollapsed = (() => {
    if (!localStorage.getItem('errorpanel_collapsed')) {
      localStorage.setItem('errorpanel_collapsed', 'true');
      return true;
    } else {
      return localStorage.getItem('errorpanel_collapsed') === 'true' ? true : false;
    }
  })();

  constructor() {
    makeObservable(this, {
      state: observable,
      executionPlatformVersion: observable,
      isErrorPanelCollapsed: observable,
      iconScale: computed,
      reset: action('reset store'),
      setDiagram: action('set diagram'),
      setProject: action('set project'),
      setIsFetching: action('set is fetching'),
      setIsShowingTemplate: action('set is showing template'),
      setModeler: action('set modeler '),
      updateLastSaved: action,
      refreshCurrentDate: action,
      discardTemplateGuide: action,
      initExecutionPlaformVersion: action,
      setExecutionPlatformVersion: action,
      setIsErrorPanelCollapsed: action,
      autosaveMessage: computed,
      sortDiagrams: action('change diagrams sort of project'),
      hasLinksToParent: computed,
      isBPMN: computed,
      admins: computed,
      calledBy: computed,
      isProcessTemplate: computed
    });
  }

  get iconScale() {
    return this.state.modelerViewboxScale < 0.8 ? calculateIconScale(this.state.modelerViewboxScale) : 1;
  }

  reset = () => {
    this.state = Object.assign({}, DEFAULT_STATE);
    this.executionPlatformVersion = DEFAULT_ZEEBE_VERSION;
    this.modeler = null;
    this.elementRegistry = null;
    this.canvas = null;
    this.selection = null;
    this.initialViewbox = null;
  };

  setDiagram = async (diagram) => {
    // See issue #2682
    if (diagram.type === 'DMN') {
      diagram.content = patchDmnFileDefinitions(diagram.content);
      const decisions = await detectDecisions(diagram.content);
      diagram.decisionIds = decisions.map((decision) => decision.id);
    }

    runInAction(() => {
      this.state.diagram = diagram;
      this.state.isLegacyDiagram = diagram.created < ORIGINAL_RELEASE_DATE;
    });
  };

  setTemplates = (templates) => {
    this.state.templates = templates;
  };

  setProject = (project) => {
    this.state.project = project;
  };

  setTooltips = (tooltips) => {
    this.state.tooltips = tooltips;
  };

  unsetTooltips = () => {
    this.state.tooltips = undefined;
  };

  setIsFetching = (value) => {
    this.state.isFetching = value;
  };

  setIsShowingTemplate = (value) => {
    this.state.isShowingTemplate = value;
  };

  setModeler = (modeler) => {
    this.modeler = modeler;
    this.elementRegistry = modeler.get('elementRegistry');
    this.canvas = modeler.get('canvas');
    this.selection = modeler.get('selection');

    // initializes the value of the executionPlatformVersion store field
    this.initExecutionPlaformVersion();

    // Diagram uploaded from the project page may not have executionPlatform set
    // This method sets it when the diagram is opened for the first time
    this.setExecutionPlatformIfNotPresent();

    this.state.isLoadingModeler = false;
  };

  setExecutionPlatformIfNotPresent = async () => {
    const permission = createPermission(this.state.project && this.state.project.permissionAccess);
    if (permission.is(['WRITE', 'ADMIN'])) {
      let executionPlatformHelper;
      if (this.state.diagram.type === BPMN) {
        executionPlatformHelper = this.modeler.get('executionPlatform');
      } else {
        executionPlatformHelper = this.modeler.getActiveViewer().get('executionPlatform');
      }

      const executionPlatform = executionPlatformHelper.getExecutionPlatform();
      if (
        !executionPlatform ||
        (executionPlatform && (executionPlatform?.name !== EXECUTION_PLATFORM || !executionPlatform?.version))
      ) {
        executionPlatformHelper.setExecutionPlatform({
          name: EXECUTION_PLATFORM,
          version: DEFAULT_ZEEBE_VERSION
        });
        await this.saveContent();
      }
    }
  };

  initExecutionPlaformVersion = () => {
    const permission = createPermission(this.state.project && this.state.project.permissionAccess);
    if (permission.is(['WRITE', 'ADMIN'])) {
      if (this.modeler) {
        const executionPlatformHelper = this.modeler.get('executionPlatform');
        const executionPlatform = executionPlatformHelper.getExecutionPlatform();
        if (executionPlatform?.version) {
          this.executionPlatformVersion = executionPlatform?.version;
        }
      }
    }
  };

  // Sets the executionPlatformVersion in the diagram and in the store field
  setExecutionPlatformVersion = async (version) => {
    if (this.modeler) {
      this.executionPlatformVersion = version; // store field

      // diagram
      let executionPlatformHelper;
      executionPlatformHelper = this.modeler.get('executionPlatform');
      const executionPlatform = executionPlatformHelper.getExecutionPlatform();
      if (executionPlatform?.version !== version) {
        executionPlatformHelper.setExecutionPlatform({
          name: EXECUTION_PLATFORM,
          version: version
        });
        await this.saveContent();
        trackingService.trackTargetEngineVersion(this.state.diagram, version);
      }
    }
  };

  setIsErrorPanelCollapsed = (val) => {
    this.isErrorPanelCollapsed = val;
    localStorage.setItem('errorpanel_collapsed', val ? 'true' : 'false');
  };

  renameDiagram = async (newName) => {
    const diagram = this.state.diagram;

    if (newName === diagram.name) {
      return;
    }

    fileService
      .update(diagram.id, {
        name: newName,
        revision: diagram.revision,
        originAppInstanceId: userStore.originAppInstanceId
      })
      .then((file) => {
        runInAction(() => {
          this.state.diagram.name = newName;
          this.state.diagram.revision = file.revision;
          this.state.diagram.calledBy = file.calledBy;

          checkAndSyncProcessName(this.modeler, newName);
        });
      })
      .catch(() => notificationStore.showError('Could not rename your diagram.'));
  };

  /**
   * From a given list of tooltips, stores only those who can be applied to existing elements in the given diagram content.
   *
   * @param {String} content : The XML content of the diagram;
   * @param {Object} tooltips The list of tooltips
   */
  async #setValidTooltips(content, tooltips) {
    if (!tooltips) {
      this.unsetTooltips();
      return;
    }

    const definitions = await getDefinitions(content);

    // Get the ids of all the elements in the diagram
    const elementsIds = definitions.diagrams
      .flatMap((diagram) => diagram.plane?.planeElement?.flat())
      .map((element) => element?.bpmnElement.id);

    // Filter out the tooltips that are not applicable to the current diagram
    tooltips = tooltips.filter((tooltip) => elementsIds.includes(tooltip.id));

    if (tooltips.length) {
      this.setTooltips(tooltips);
    } else {
      this.discardTemplateGuide(false, false);
      this.unsetTooltips();
    }
  }

  fetchDiagramById = async (fileId) => {
    this.setIsFetching(true);

    try {
      const file = await fileService.fetchById(fileId);
      const requests = [
        projectService.fetchById({ projectId: file.projectId, includeFolders: true, includeFiles: true })
      ];

      if (file.folderId) {
        requests.push(folderService.fetchById(file.folderId));
      }

      const [project, folder] = await Promise.all(requests);

      if (file?.type === 'BPMN' && hasAccess(project, actions.MODIFY_DIAGRAM)) {
        await this.loadElementTemplates(file.projectId);
      }

      organizationStore.compareAndSwitchOrganization(file.organizationId);
      runInAction(() => {
        this.setDiagram({ ...file, folder });
        this.setProject(project);
        if (file?.templateTooltips) {
          this.#setValidTooltips(file.content, JSON.parse(file.templateTooltips));
        }
      });
    } catch (ex) {
      notificationStore.showError('Yikes! Could not fetch the diagram. Please try again later.');
    } finally {
      this.setIsFetching(false);
    }
  };

  async loadElementTemplates(projectId) {
    const templates = await projectService.fetchTemplates(projectId);
    if (templates) {
      runInAction(() => {
        this.setTemplates(templates);
      });
    }
  }

  trackDiagramView = (origin, extraProps) => {
    const user = userStore?.userId || this.admins[0]?.id;

    trackingService.trackViewFile(origin, user, this.state.diagram, this.modeler, extraProps);
  };

  updateProject = async (projectId) => {
    const project = await projectService.fetchById({ projectId, includeFolders: true, includeFiles: true });
    this.setProject(project);
    return project;
  };

  /**
   * We need to throttle the saveContent method, so multiple actions like undo/redo or moving elements with the keyboard
   * can get queued up and don't provoke a conflict with the modeling client (customer using the app) itself.
   */
  saveContent = throttle(async () => {
    if (!this.modeler) {
      console.info('Tried to save XML, but navigated away from modeler already, content was not available anymore.');
      return;
    }

    const { xml } = await this.modeler.saveXML({ format: true });
    const comparisonContent = stringifyXML(parseXML(xml));

    if (this.lastReloadedContent && comparisonContent === this.lastReloadedContent) {
      this.notifyConflict();
      return;
    }

    const currentModelDefinitions = this.modeler._definitions;

    const payload = {
      content: xml,
      revision: this.state.diagram.revision,
      relationId: getRelationId(currentModelDefinitions),
      originAppInstanceId: userStore.originAppInstanceId
    };
    if (this.isBPMN) {
      payload.processId = getExecutableProcessId(currentModelDefinitions) || NO_PROCESS_ID;
      payload.callActivityLinks =
        getCallActivityLinks(currentModelDefinitions)?.filter((pid) => !isExpression(pid)) || [];
      payload.businessRuleTaskLinks =
        getLinkedDecisions(this.modeler)?.filter((decisionId) => !isExpression(decisionId)) || [];
    } else {
      payload.decisions = await detectDecisions(xml);
    }

    try {
      const file = await fileService.update(this.state.diagram.id, payload);
      trackingService.trackEditFile({
        fileId: this.state.diagram.id,
        type: this.state.diagram.type,
        mode: this.isBPMN ? dedicatedModesStore.selectedModeLabel : undefined
      });

      if (file?.templateTooltips) {
        this.#setValidTooltips(file.content, JSON.parse(file.templateTooltips));
      }

      this.updateLastSaved();

      if (file) {
        runInAction(() => {
          if (this.isBPMN) {
            this.state.diagram.processId = payload.processId;
            this.state.diagram.calledBy = file.calledBy;
          } else {
            this.state.diagram.decisionIds = payload.decisions.map((decision) => decision.id);
          }
          this.state.diagram.revision = file.revision;
        });
        this.updateProject(file.projectId);
      }
    } catch (error) {
      if (error.status === 409) {
        if (this.isDraggingActive) {
          this.hasReceivedUpdateWhileDragging = true;
        } else {
          await this.reloadDiagram();
          this.notifyConflict();
        }
      } else {
        await this.reloadDiagram();
        notificationStore.showError(FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION);
      }
    }

    this.isUpdating = false;
  });

  notifyConflict = () => {
    notificationStore.showNotification({
      message: FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION,
      variant: 'error'
    });
  };

  debouncedSaveContent = debounce(this.saveContent, SAVE_CONTENT_DEBOUNCE, {
    trailing: true
  });

  debouncedReplaceParams = debounce(replaceSearchParams, CHANGE_PARAMS_DEBOUNCE, { trailing: true });

  updateLastSaved = () => {
    this.state.lastSaved = new Date();
    this.refreshCurrentDate();
    setTimeout(this.refreshCurrentDate, SAVING_MESSAGE_DURATION_IN_MILLIS);
  };

  refreshCurrentDate = () => {
    this.state.currentDate = new Date();
  };

  get autosaveMessage() {
    const lastSavedMilliSecondsAgo = this.state.currentDate - this.state.lastSaved;

    if (this.state.lastSaved == null) {
      return null;
    } else if (lastSavedMilliSecondsAgo < SAVING_MESSAGE_DURATION_IN_MILLIS) {
      return {
        status: 'progress',
        message: 'Saving...'
      };
    } else {
      return {
        status: 'done',
        message: `Autosaved at ${moment().format('HH:mm:ss')}`
      };
    }
  }

  async reloadDiagram() {
    fileService
      .fetchById(this.state.diagram.id)
      .then((file) => {
        if (file && file.content) {
          this.lastReloadedContent = stringifyXML(parseXML(file.content));

          runInAction(() => {
            this.state.diagram.revision = file.revision;
          });

          if (this.isDraggingActive) {
            this.fetchedDiagramWhileDragging = true;
          } else {
            this.importContent(file.content);
          }
        }
      })
      .catch(() => notificationStore.showError('Could not fetch your diagram.'));
  }

  importContent = (content) => {
    if (this.isDMN) {
      content = patchDmnFileDefinitions(content);
    }
    return this.modeler.importXML(content);
  };

  /**
   * Checks if the given content can be imported into the modeler by performing a dry run import.
   *
   * @param {String} content The content to be imported
   * @returns {Boolean} True if the content can be imported, false otherwise
   */
  dryRunImport = async (content) => {
    if (this.isDMN) {
      content = patchDmnFileDefinitions(content);
    }

    let modeler;

    try {
      if (this.isBPMN) {
        modeler = new Modeler({
          moddleExtensions: {
            modeler: ModelerModdleExtension
          }
        });
      } else if (this.isDMN) {
        modeler = new DmnModeler({
          moddleExtensions: {
            camunda: camundaModdleDescriptor,
            modeler: DmnModelerModdleExtension
          }
        });
      } else {
        throw new Error('Invalid diagram type');
      }

      const { error, warnings } = await modeler.importXML(content);

      if (error || warnings.length) {
        return { error, warnings };
      }
    } catch (error) {
      return { error, warnings: [] };
    }

    return { error: false, warnings: [] };
  };

  setInitialViewbox = () => {
    const searchParams = getSearchParams();

    if (searchParams.v) {
      const viewboxParams = searchParams.v.split(',');

      this.initialViewbox = {
        x: viewboxParams[0],
        y: viewboxParams[1],
        scale: viewboxParams[2]
      };
    }
  };

  handleViewboxChange = (viewbox) => {
    const { x, y, scale } = viewbox;

    const viewboxString = `?v=${x},${y},${scale}`;

    this.initialViewbox = viewbox;

    // append viewbox information to URL
    this.debouncedReplaceParams(history, viewboxString);
  };

  getElementBoundingBox = (elementId) => {
    const element = this.elementRegistry.get(elementId);
    return this.canvas.getAbsoluteBBox(element);
  };

  isElementVisible = (elementId) => {
    const element = this.elementRegistry.get(elementId);
    return element && !element.hidden;
  };

  /**
   * Decides whether a menu should be positioned above an element menu button
   */
  isPositionedAbove = (elementId) => {
    const height = this.canvas.getContainer().clientHeight;
    const boundingBox = this.getElementBoundingBox(elementId);
    const bottomSpace = height - (boundingBox.y + boundingBox.height) - BOTTOM_PADDING;

    return bottomSpace < height / 2;
  };

  sortDiagrams = (value) => {
    this.state.project.files = sort(this.state.project.files, value);
  };

  deleteCurrentDiagram = async () => {
    const WARNINGS = {
      LINKED_DIAGRAM: 'Links to or from the diagrams will be removed.',
      COMMENTS: 'All comments will be deleted.',
      SHARED_LINK: 'Shared links will be inaccessible.'
    };

    breadcrumbStore.toggleDropdownVisibility();

    const { diagram, project } = this.state;

    let warnings = [];
    try {
      warnings = (await fileService.destroyDryRun([diagram.id])).warnings;
    } catch (error) {
      if (error.status == 404) {
        return notificationStore.showError("You don't have permission to delete this diagram.");
      } else {
        return notificationStore.showError("Your diagram couldn't be deleted. Please try again later.");
      }
    }

    const confirmed = await confirmActionStore.confirm({
      title: 'Deleting diagram',
      text: (
        <Fragment>
          Are you sure you want to delete this diagram from the project?
          {warnings.length > 0 && (
            <ul>
              {warnings.map((warning) => (
                <li key={warning}>{WARNINGS[warning]}</li>
              ))}
            </ul>
          )}
        </Fragment>
      ),
      confirmLabel: 'Delete diagram',
      isDangerous: true
    });

    if (confirmed) {
      fileService
        .destroy([diagram.id])
        .then(() => {
          notificationStore.showSuccess('Your diagram has been deleted.');
          trackingService.trackDeleteEntities(DEFAULT, 1);

          if (diagram.folder) {
            history.push(`/folders/${diagram.folder.id}`);
          } else {
            history.push(`/projects/${project.id}`);
          }
        })
        .catch(() => notificationStore.showError("Yikes! Couldn't remove your diagram. Please try again later."));
    }
  };

  uploadFiles = async (files) => {
    breadcrumbStore.toggleDropdownVisibility();

    if (files.length > 1) {
      return notificationStore.showError('You can only upload one single file to replace this diagram.');
    }

    const { diagram } = this.state;
    const { valid, invalid } = await validateFiles(files, [this.state.diagram.type]);

    if (invalid.length > 0) {
      notificationStore.showError(
        `"${invalid[0].name}" is not a valid Zeebe ${this.state.diagram.type} diagram and can't be uploaded. Please choose another file.`
      );
    }

    if (valid.length > 0) {
      notificationStore.showSuccess(`"${valid[0].name}" is being uploaded to replace this diagram.`);

      // Create an "Autosaved" milestone if the diagram has changed since
      // the creation of the last milestone
      const res = await milestoneStore.createAutosaved(diagram, 'upload', {
        name: 'Autosaved during upload'
      });
      if (res) {
        notificationStore.showSuccess('The previous diagram content has been saved as a milestone.');
      }

      trackingService.trackCreateFile(
        diagram.id,
        'upload',
        diagram.type,
        diagram.folder ? FOLDER : DEFAULT,
        files[0].content
      );

      await this.importContent(files[0].content);
      await this.setExecutionPlatformIfNotPresent();
      await this.saveContent();

      milestoneStore
        .create({
          file: diagram,
          origin: 'upload',
          name: `${files[0].name.replace(/(\.bpmn|\.xml)/, '')} (upload)`
        })
        .catch(() =>
          notificationStore.showError('Yikes! There was a problem saving the previous diagram content as a milestone.')
        );
    }
  };

  discardTemplateGuide(shouldUnsetTooltips = true, shouldShowError = true) {
    const diagram = this.state.diagram;

    fileService
      .update(diagram.id, {
        processTemplateId: null,
        revision: diagram.revision,
        originAppInstanceId: userStore.originAppInstanceId
      })
      .then(() => {
        if (shouldUnsetTooltips) {
          this.unsetTooltips();
        }
        this.setIsShowingTemplate(false);
      })
      .catch(async (error) => {
        if (!shouldShowError) {
          return;
        }

        if (error.status === 409) {
          if (this.isDraggingActive) {
            this.hasReceivedUpdateWhileDragging = true;
          } else {
            await this.reloadDiagram();
            this.notifyConflict();
          }
        } else {
          notificationStore.showError(FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION);
        }
      });
  }

  get hasLinksToParent() {
    return this.state.diagram.parents.length > 0;
  }

  get isBPMN() {
    return this.state.diagram?.type === 'BPMN';
  }

  get isDMN() {
    return this.state.diagram?.type === 'DMN';
  }

  get admins() {
    return this.state.project?.collaborators?.filter(({ permissionAccess }) => permissionAccess === 'ADMIN') || [];
  }

  get calledBy() {
    return this.state.diagram?.calledBy;
  }

  get isProcessTemplate() {
    return this.state.diagram?.templateTooltips;
  }
}

export default new CurrentDiagramStore();

const calculateIconScale = (viewboxScale) => {
  if (viewboxScale >= 0.6) {
    return 1.25;
  }
  if (viewboxScale >= 0.4) {
    return 2;
  }
  if (viewboxScale >= 0.2) {
    return 3;
  }
};
