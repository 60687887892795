/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import callActivityLinkStore from './CallActivityLinkStore';
import CallActivityLinkOverlay from './CallActivityLinkOverlay';

const CallActivityLinking = ({ isLoadingModeler, permission }) => {
  const { selectedCallActivity, isElementLinked } = callActivityLinkStore;

  let showOverlay = !isLoadingModeler && selectedCallActivity;
  if (permission.is(['COMMENT', 'READ'])) {
    if (!isElementLinked(selectedCallActivity)) {
      showOverlay = false;
    }
  }

  return (
    <>
      {showOverlay && (
        <CallActivityLinkOverlay element={selectedCallActivity} readOnly={permission.is(['COMMENT', 'READ'])} />
      )}
    </>
  );
};

export default observer(CallActivityLinking);
