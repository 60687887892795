/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const EditorWrapper = styled.main`
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100%;

  .readOnlyLine {
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

export const EditorContainer = styled.div`
  flex: 1;
  min-height: 0;
`;

export const Header = styled.header`
  display: flex;
  height: 28px;
  background-color: white;
  border-bottom: 1px solid var(--silver-darken-87);
`;

export const HeaderTitle = styled.h6`
  flex: 1;
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 28px;

  &:first-child {
    padding-left: 50px;
  }

  &:last-child {
    padding-left: 28px;
  }
`;

export const BottomRight = styled.div`
  position: absolute;
  bottom: var(--spacing-medium);
  right: var(--spacing-medium);
`;
