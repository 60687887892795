/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import BPMNModdle from 'bpmn-moddle';
import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil';
import DMNModdle from 'dmn-moddle';

export const isBusinessRuleTask = (element) => {
  return is(element, 'bpmn:BusinessRuleTask');
};

const isDecision = (element) => {
  return is(element, 'dmn:Decision');
};

/**
 * Given the element registry, returns the list of business rule task elements
 * that has the property "Called decision -> Decision ID" set
 */
const getLinkedBusinessRuleTasks = (elementRegistry) => {
  const businessRuleTasks = elementRegistry.filter(isBusinessRuleTask);
  return businessRuleTasks.filter((element) => {
    const extensionElements = getBusinessObject(element)?.get('extensionElements');
    return Boolean(getCalledDecisionId(extensionElements));
  });
};

const getLinkedBusinessRuleTasksFromXml = async (xml) => {
  try {
    if (xml) {
      const bpmnModdle = new BPMNModdle();
      const { elementsById } = await bpmnModdle.fromXML(xml);
      return getLinkedBusinessRuleTasks(Object.values(elementsById));
    }
  } catch (e) {
    console.info(e);
  }
  return [];
};

const getBusinessRuleTasksLinksFromElements = (elements) => {
  let businessRuleTaskLinks = [];
  elements?.forEach((element) => {
    if (element.flowElements) {
      businessRuleTaskLinks = businessRuleTaskLinks.concat(getBusinessRuleTasksLinksFromElements(element.flowElements));
    } else {
      if (isBusinessRuleTask(element)) {
        const extensionElements = element?.get('extensionElements');
        const decisionId = getCalledDecisionId(extensionElements);
        if (decisionId) {
          businessRuleTaskLinks.push(decisionId);
        }
      }
    }
  });
  return businessRuleTaskLinks;
};

export const getBusinessRuleTasksLinksFromXml = async (xml) => {
  const linkedBusinessRuleTasks = await getLinkedBusinessRuleTasksFromXml(xml);
  return getBusinessRuleTasksLinksFromElements(linkedBusinessRuleTasks);
};

export const getBusinessRuleTasksLinksFromDefinitions = (definitions) => {
  const { rootElements } = definitions;
  const businessRuleTaskLinks = getBusinessRuleTasksLinksFromElements(rootElements);
  return businessRuleTaskLinks;
};

/**
 * Given a modeler instance, returns a list of IDs of decisions linked to
 * business rule task elements in the diagram loaded in the modeler
 */
export const getLinkedDecisions = (modeler) => {
  const linkedBusinessRuleTasks = getLinkedBusinessRuleTasks(modeler.get('elementRegistry'));
  return linkedBusinessRuleTasks.map((el) => {
    const extensionElements = getBusinessObject(el)?.get('extensionElements');
    return getCalledDecisionId(extensionElements);
  });
};

const getCalledDecision = (extensionElements) => {
  return extensionElements?.get('values').filter((elem) => {
    return elem.$type === 'zeebe:CalledDecision' || elem.$type === 'zeebe:calledDecision';
  })[0];
};

export const getCalledDecisionId = (extensionElements) => {
  const calledElement = getCalledDecision(extensionElements);
  if (calledElement?.decisionId) {
    return calledElement?.decisionId?.trim();
  }
};

export const detectDecisions = async (xml) => {
  const decisions = [];
  try {
    if (xml) {
      const dmnModdle = new DMNModdle();
      const { elementsById } = await dmnModdle.fromXML(xml);
      Object.values(elementsById)?.forEach((el) => {
        if (isDecision(el)) {
          if (el?.id) {
            decisions.push({ id: el.id, name: el?.name || '' });
          }
        }
      });
    }
  } catch (e) {
    console.info(e);
  }
  return decisions;
};
