/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Button } from 'primitives';
import config from 'utils/config';

import * as Styled from './ErrorDisplay.styled';

export default function ErrorDisplay({ error }) {
  return (
    <Styled.Container>
      <Styled.Heading>Well, that's awkward...</Styled.Heading>
      <Styled.Message>
        Something went wrong on our end. Sorry about that.
        <br />
        Try reloading your browser.
      </Styled.Message>
      <Button component="a" href={config.modelerUrl} data-test="error-boundary-button">
        Go to homepage
      </Button>

      <Styled.Details>
        <summary>Show error</summary>
        <Styled.Code>{error.message}</Styled.Code>
      </Styled.Details>
    </Styled.Container>
  );
}
