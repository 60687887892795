/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { createBrowserHistory } from 'history';

import config from 'utils/config';

function urlWithoutBasePath(url) {
  return url.replace(config.modelerBasePath, '');
}

function removeBasePath(to, state, callback) {
  if (typeof to === 'string') {
    to = urlWithoutBasePath(to);
  } else if (typeof to === 'object' && to.pathname) {
    to.pathname = urlWithoutBasePath(to.pathname);
  }

  if (state?.pathname) {
    to.pathname = urlWithoutBasePath(state.pathname);
  }

  return callback(to, state);
}

/**
 * This overrides the history object to remove the base path from the URL.
 * We do this here to avoid having to do it in every component that uses the history object.
 */
export default (() => {
  const history = createBrowserHistory(
    config.modelerBasePath && config.modelerBasePath !== '/' && config.modelerBasePath !== ''
      ? { basename: config.modelerBasePath }
      : {}
  );

  const originalPush = history.push;
  const originalReplace = history.replace;
  history.push = (to, state = undefined) => removeBasePath(to, state, originalPush);
  history.replace = (to, state = undefined) => removeBasePath(to, state, originalReplace);

  return history;
})();
