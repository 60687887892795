/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { WithExternalLinkIcon } from 'primitives';

import * as Styled from './Notification.styled';

export default function Notification({ message, link, linkText }) {
  return (
    <div>
      <div>{message}</div>
      {link && (
        <Styled.Link href={link} target="_blank" className="bold">
          <WithExternalLinkIcon label={linkText} />
        </Styled.Link>
      )}
    </div>
  );
}
