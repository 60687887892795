/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Wrapper = styled.span`
  svg {
    ${({ $iconColor }) => $iconColor && `fill: ${$iconColor} !important;`}
    vertical-align: middle;
  }
`;

export const Label = styled.span`
  margin-right: 3px;
  vertical-align: middle;
`;
