/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function BPMNIcon({ type }) {
  switch (type) {
    case 'PROCESS':
      return <span className="bpmn-icon-participant" />;
    case 'START_EVENT':
      return <span className="bpmn-icon-start-event-none" />;
    case 'SEQUENCE_FLOW':
      return <span className="bpmn-icon-connection" />;
    case 'SERVICE_TASK':
      return <span className="bpmn-icon-service-task" />;
    case 'EXCLUSIVE_GATEWAY':
      return <span className="bpmn-icon-gateway-xor" />;
    case 'INCLUSIVE_GATEWAY':
      return <span className="bpmn-icon-gateway-or" />;
    case 'PARALLEL_GATEWAY':
      return <span className="bpmn-icon-gateway-parallel" />;
    case 'EVENT_BASED_GATEWAY':
      return <span className="bpmn-icon-gateway-eventbased" />;
    case 'SUB_PROCESS':
      return <span className="bpmn-icon-subprocess-expanded" />;
    case 'EVENT_SUB_PROCESS':
      return <span className="bpmn-icon-event-subprocess-expanded" />;
    case 'INTERMEDIATE_CATCH_EVENT':
      return <span className="bpmn-icon-intermediate-event-none" />;
    case 'INTERMEDIATE_THROW_EVENT':
      return <span className="bpmn-icon-intermediate-event-none" />;
    case 'BOUNDARY_EVENT':
      return <span className="bpmn-icon-intermediate-event-none" />;
    case 'END_EVENT':
      return <span className="bpmn-icon-end-event-none" />;
    case 'RECEIVE_TASK':
      return <span className="bpmn-icon-receive-task" />;
    case 'USER_TASK':
      return <span className="bpmn-icon-user-task" />;
    case 'MANUAL_TASK':
      return <span className="bpmn-icon-manual-task" />;
    case 'MULTI_INSTANCE_BODY':
      return <span className="bpmn-icon-parallel-mi-marker" />;
    case 'CALL_ACTIVITY':
      return <span className="bpmn-icon-subprocess-collapsed" />;
    case 'BUSINESS_RULE_TASK':
      return <span className="bpmn-icon-business-rule-task" />;
    case 'SCRIPT_TASK':
      return <span className="bpmn-icon-script-task" />;
    case 'SEND_TASK':
      return <span className="bpmn-icon-send-task" />;
    default:
      return '?';
  }
}
