/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observe } from 'mobx';

import { commentsStore } from 'stores';

import prepareOverlays from './overlays';

export default function CommentsExtension(eventBus, bpmnjs) {
  let overlays = null;
  let disposer = null;

  /**
   * Initializes the specification feature by registering all elements
   * that have a documentation property.
   * These elements will display the specification icon once the diagram has been loaded.
   */
  function init() {
    overlays = prepareOverlays(bpmnjs);
    overlays.render();

    /* We need to observe the commentsStore's state, since comments are loaded
      asynchronously. This means that the overlay render could execute before
      all elements with comments are being defined, leaving out important overlays.
      Hence, once all comments are fetched we need to re-render the overlays, because there
      could be more elements that need an tooltip to be rendered. */
    disposer = observe(commentsStore.state, (change) => {
      if (change.type === 'update' && change.name === 'isFetchingComments' && change.newValue === false) {
        overlays.render();
      }
    });

    // Set the diagram root as current element.
    setRootElement();
  }

  /**
   * Sets the diagram root as the current element in the store.
   * If root already has text, it's provided as well.
   */
  function setRootElement() {
    commentsStore.setCurrentElement({});
  }

  /**
   * Gets called whenever a user selects / deselects a BPMN element.
   *
   * If a user selects an element (newElement), its documentation will be read
   * on saved to the store to display and edit.
   *
   * If a user leaves an element (oldElement), the function checks if
   * its documentation has changed. If true, the XML will be updated.
   *
   * @param {InternalEvent} evt The BPMN `selection.changed` event.
   */
  function handleSelectionChange(evt) {
    const oldElement = evt.oldSelection[0];
    const newElement = evt.newSelection[0];

    if (!oldElement && !newElement) {
      return;
    }

    if (newElement) {
      // Labels are considered as elements, but we don't want
      // them to have documentation.
      if (newElement.type === 'label') {
        return;
      }

      overlays.add(newElement);

      // Set the currently selected element as active.
      commentsStore.setCurrentElement({
        shape: newElement
      });
    } else {
      // Set the root element as active in the store.
      setRootElement();
    }

    if (oldElement && !commentsStore.elementIds.includes(oldElement.id)) {
      overlays.remove(oldElement);
    }
  }

  /**
   * Gets called each time an element is deleted. Calls a function
   * in the store to remove all the associated comments.
   *
   * @param {InternalEvent} evt The BPMN `commandStack.elements.delete.executed` event.
   */
  function handleShapeRemoved(evt) {
    commentsStore.removeUnusedComments(evt.context.elements);
  }

  /**
   * Gets called once the user closes the diagram or reloads the page.
   * Removes event listeners to prevent memory leaks and resets
   * the specification store.
   */
  function handleCanvasDestroy() {
    disposer();
  }

  // Event listeners
  eventBus.on('selection.changed', handleSelectionChange);
  eventBus.on('import.done', init);
  eventBus.on('canvas.destroy', handleCanvasDestroy);
  eventBus.on('commandStack.elements.delete.executed', handleShapeRemoved);
}

CommentsExtension.$inject = ['eventBus', 'bpmnjs', 'moddle', 'elementRegistry'];
