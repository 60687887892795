/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { InlineEditable } from 'components/Form';

export const Milestone = styled.li`
  display: flex;
  padding: 10px;
  position: relative;

  font-size: 12px;

  color: var(--grey-darken-33);

  border-left: 6px solid transparent;

  cursor: pointer;

  button {
    margin-left: auto;
    opacity: 0;
    flex-shrink: 0;
  }

  &:hover button {
    opacity: 1;
  }

  ${(props) =>
    props.$isPrimary &&
    `
    border-left-color: var(--cds-blue-60);
    background-color: var(--silver-darken-94);
  `}

  ${(props) =>
    props.$isSecondary &&
    `
    border-left-color: var(--cds-blue-60);
    background-color: var(--silver-darken-94);

    &:hover {
      background-color: var(--silver-base-97);
    }
  `}

  ${(props) => {
    if (!props.$isSecondary && !props.$isPrimary) {
      return `
        &:hover {
          background-color: var(--silver-base-97);
        }
      `;
    }
  }}
`;

export const MilestoneContent = styled.div`
  ${({ $isTemplate }) =>
    $isTemplate &&
    `
        display: grid;
        grid-template-columns: 2fr 1.5fr;
        grid-column-gap: 20px;
        grid-row-gap: 0;

        .cds--tag {
            height: 28px;
        }
      `}
  margin: 0 15px;
  line-height: 1.5;
  flex: 1;
  min-width: 0;
`;

export const MilestoneTitle = styled(InlineEditable)`
  font-weight: bold;
  ${({ $isTemplate }) => $isTemplate && 'width: 145px;'}

  input {
    font-size: 12px;
    padding: 5px 8px;
    height: 28px;
  }
`;

export const MilestoneParagraph = styled.p`
  margin: 0;

  font-size: 11px;

  color: var(--grey-lighten-50);
`;

export const PlaceholderAvatar = styled.span`
  flex-shrink: 0;
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 50%;
  background-color: ${({ $isPrimary }) => ($isPrimary ? 'white' : 'var(--silver-darken-94)')};
`;
