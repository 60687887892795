/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { TextInput, Form, Button } from '@carbon/react';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(253, 253, 254);
  border-right: 1px solid rgb(216, 220, 227);
  border-top: 1px solid rgb(216, 220, 227);
`;

export const TableContainer = styled.div`
  flex: 1;
  overflow: auto;

  ${({ isEmpty }) =>
    isEmpty &&
    `
    display: flex;
    align-items: center;
    
  `}
`;

export const EmptyMessage = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  flex: 1;
  text-align: center;
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 8px;
  width: calc(100% - 16px);
  margin: 8px;
`;

export const THead = styled.thead`
  text-align: left;
  color: var(--text-text-secondary, #525252);

  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
`;

export const TBody = styled.tbody`
  color: var(--text-text-primary, #161616);

  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;

  td {
    height: 32px;
    vertical-align: middle;
  }
`;

export const NameColumn = styled.th`
  width: 276px;
`;

export const ValueColumn = styled.th`
  width: auto;
`;

export const ActionColumn = styled.th`
  width: 80px;
`;

export const RowActions = styled.td`
  text-align: right;
`;

export const EditVariableButton = styled(Button)`
  margin-top: -15px;
  transform: translateY(9px);
`;

export const AddVariableContainer = styled.div``;

export const AddVariableForm = styled(Form)`
  display: flex;
`;

export const AddVariableNameField = styled(TextInput)`
  flex: 0 0 20%;
  display: inline-block;
  margin-right: 25px;
`;

export const EditVariableValueField = styled(TextInput)`
  display: inline;
`;

export const AddVariableValueField = styled(TextInput)`
  flex: 1;
  display: inline-block;
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Loader = styled.span`
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 8px 15px;
  border: 3px solid var(--cds-blue-60);
  border-bottom-color: transparent;
  animation: ${spin} 1.2s linear infinite;
`;

export const SmallLoader = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 5px;
  border: 2px solid var(--cds-blue-60);
  border-bottom-color: transparent;
  animation: ${spin} 1.2s linear infinite;
`;
