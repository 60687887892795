/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { callActivityLinkStore } from 'App/Pages/Diagram/CallActivityLinking';
import { businessRuleTaskLinkStore } from 'App/Pages/Diagram/BusinessRuleTaskLinking';

import { LinkedFormsList } from './LinkedFormsList';
import * as Styled from './DeployLinkedDiagramInfo.styled';

const DeployLinkedDiagramInfo = () => {
  const hasLinkedCallActivities = callActivityLinkStore?.linkedCallActivities?.length > 0;
  const hasLinkedBusinessRuleTasks = businessRuleTaskLinkStore?.linkedBusinessRuleTasks?.length > 0;

  const getOtherResourcesMessage = () => {
    const hasOnlyCallActivities = hasLinkedCallActivities && !hasLinkedBusinessRuleTasks;
    const hasOnlyBusinessRuleTasks = !hasLinkedCallActivities && hasLinkedBusinessRuleTasks;

    let message = '';
    if (hasOnlyCallActivities) {
      message = 'Call Activity';
    } else if (hasOnlyBusinessRuleTasks) {
      message = 'Business Rule Task';
    } else if (hasLinkedCallActivities && hasLinkedBusinessRuleTasks) {
      message = 'Call Activity and Business Rule Task';
    }

    return (
      <>
        The diagram has {message} elements. They have to be&nbsp;<strong>deployed separately.</strong>
      </>
    );
  };

  return (
    <>
      <LinkedFormsList />
      {(hasLinkedCallActivities || hasLinkedBusinessRuleTasks) && (
        <Styled.OtherResources>
          <Styled.SectionHeader>Other resources</Styled.SectionHeader>
          <Styled.InfoMessage kind="info" lowContrast hideCloseButton>
            {getOtherResourcesMessage()}
          </Styled.InfoMessage>
        </Styled.OtherResources>
      )}
    </>
  );
};

export default observer(DeployLinkedDiagramInfo);
