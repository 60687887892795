/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from 'primitives';
import { Dropdown, TargetSelector } from 'components';
import { diagramExtensionStore, notificationStore, projectStore } from 'stores';
import { fileService } from 'services';
import { Dots } from 'icons';
import { isFile, isDiagram } from 'utils/helpers';
import hasAccess, { actions } from 'utils/user-access';

export default function EntityAction({ entity }) {
  const history = useHistory();
  const { project, folder, isFolder } = projectStore;
  const [anchorEl, setAnchorEl] = useState(null);
  const [moveAnchorEl, setMoveAnchorEl] = useState(null);

  const handleDelete = () => {
    setAnchorEl(null);

    projectStore.deleteEntities(entity);
  };

  const handleDuplicate = () => {
    setAnchorEl(null);

    projectStore.duplicateFiles(entity);
  };

  const handleMove = async (target) => {
    setAnchorEl(null);
    setMoveAnchorEl(null);

    if (target.projectId) {
      if (await projectStore.moveEntities(entity, target.projectId, target.id)) {
        history.push(`/folders/${target.id}`);
      }
    } else {
      if (await projectStore.moveEntities(entity, target.id)) {
        history.push(`/projects/${target.id}`);
      }
    }
  };

  const handleShare = () => {
    setAnchorEl(null);
    fileService
      .fetchById(entity.id)
      .then((diagram) => {
        diagramExtensionStore.setDiagram(diagram);
        diagramExtensionStore.showShareModal();
      })
      .catch(() => notificationStore.showError('Could not get the diagram information.'));
  };

  const items = [];

  if (hasAccess(project, actions.SHARE_DIAGRAM) && isDiagram(entity)) {
    items.push(
      <Dropdown.ListItem key="share-diagram" onClick={handleShare} data-test="share-diagram">
        Share or embed
      </Dropdown.ListItem>
    );
  }

  if (hasAccess(project, actions.DELETE_DIAGRAM)) {
    items.push(
      <Dropdown.ListItem key="delete-diagram" onClick={handleDelete} data-test="delete-diagram">
        Delete
      </Dropdown.ListItem>
    );
  }

  if (hasAccess(project, actions.MODIFY_DIAGRAM)) {
    if (isFile(entity)) {
      items.push(
        <Dropdown.ListItem key="duplicate-diagram" onClick={handleDuplicate} data-test="duplicate-diagram">
          Duplicate
        </Dropdown.ListItem>
      );
    }

    items.push(
      <Fragment key="move-diagrams">
        <Dropdown.ListItem
          hasSubDropdown
          data-test="move-diagram"
          onClick={(evt) => setMoveAnchorEl(moveAnchorEl ? null : evt.currentTarget)}
        >
          Move
        </Dropdown.ListItem>
        <TargetSelector
          anchorEl={moveAnchorEl}
          open={Boolean(moveAnchorEl)}
          startingPoint={isFolder ? folder : project}
          invalidTargetIds={[entity.id, isFolder ? folder.id : project.id]}
          onSubmit={handleMove}
        />
      </Fragment>
    );
  }

  if (items.length > 0) {
    return (
      <Fragment>
        <IconButton
          $isActive={Boolean(anchorEl)}
          aria-label="Entity Context Action"
          aria-haspopup="true"
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          data-test="entity-context-dropdown"
          $noRadius
        >
          <Dots />
        </IconButton>

        <Dropdown
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            setMoveAnchorEl(null);
          }}
        >
          {items}
        </Dropdown>
      </Fragment>
    );
  }

  return null;
}
