/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Track = styled.span`
  position: relative;
  width: 34px;
  height: 14px;
  display: inline-block;
  background-color: ${({ checked }) => (checked ? `var(--cds-blue-40)` : `var(--silver-darken-87)`)};
  border-radius: 7px;
  margin: 0 10px 0 3px;
  transition: background-color 0.15s linear;
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Thumb = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  background-color: ${({ checked }) => (checked ? 'var(--cds-blue-60)' : 'var(--grey-lighten-56)')};
  position: absolute;
  top: -3px;
  left: ${({ checked }) => (checked ? '17px' : '-3px')};
  transition:
    left 0.15s ease-out,
    background-color 0.15s linear;
`;
