/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Tabs, Tab, TabList, Button } from '@carbon/react';
import { Add, Close, Checkmark, Edit } from '@carbon/react/icons';
import { useContext, useEffect, useRef, useState } from 'react';

import notificationStore from 'stores/NotificationStore';

import PlayContext from '../PlayContext';
import * as Styled from './Variables.styled';
import { REQUEST_FAILURE } from '../utils/constants';

export default function Variables({ selectedScope, isActive }) {
  const context = useContext(PlayContext);
  const [isAddingVariable, setIsAddingVariable] = useState(false);
  const [isEditingVariable, setIsEditingVariable] = useState(false);
  const [newVariableName, setNewVariableName] = useState('');
  const [newVariableValue, setNewVariableValue] = useState('');

  const [isVariableNamePristine, setIsVariableNamePristine] = useState(true);
  const [isVariableValuePristine, setIsVariableValuePristine] = useState(true);
  const [isVariableValueValid, setIsVariableValueValid] = useState(false);
  const [isVariableNameValid, setIsVariableNameValid] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);

  const isUpdating = context.isUpdating;

  const variablesInScope = context.variables?.filter((variable) => variable.scope.key === selectedScope) ?? [];

  useEffect(() => {
    if (!isActive) {
      setIsAddingVariable(false);
      setIsEditingVariable(false);
    }
  }, [isActive]);

  useEffect(() => {
    if (!isUpdating) {
      setIsAddingVariable(false);
      setIsEditingVariable(false);
    }
  }, [isUpdating]);

  useEffect(() => {
    try {
      JSON.parse(newVariableValue);
      setIsVariableValueValid(newVariableValue.trim() !== '');
    } catch (e) {
      setIsVariableValueValid(false);
    }
  }, [newVariableValue]);

  useEffect(() => {
    setIsVariableNameValid(
      !newVariableName.includes('"') &&
        !newVariableName.includes(' ') &&
        newVariableName !== '' &&
        !variablesInScope.some(({ name }) => name === newVariableName)
    );
  }, [newVariableName, variablesInScope]);

  useEffect(() => {
    setIsEditingVariable(false);
  }, [selectedScope]);

  const valuePristineTimer = useRef();

  return (
    <Styled.Container>
      <Tabs>
        <TabList aria-label="Variables">
          <Tab>Variables</Tab>
        </TabList>
      </Tabs>
      <Styled.TableContainer isEmpty={variablesInScope.length === 0}>
        {variablesInScope.length === 0 && <Styled.EmptyMessage>The Flow Node has no Variables</Styled.EmptyMessage>}
        {variablesInScope.length > 0 && (
          <Styled.Table>
            <Styled.THead>
              <tr>
                <Styled.NameColumn>Name</Styled.NameColumn>
                <Styled.ValueColumn>Value</Styled.ValueColumn>
                <Styled.ActionColumn />
              </tr>
            </Styled.THead>
            <Styled.TBody>
              {variablesInScope.map((variable) => (
                <tr key={variable.name}>
                  <td>{variable.name}</td>
                  <td>
                    {isEditingVariable === variable.name ? (
                      <>
                        <Styled.EditVariableValueField
                          autoFocus
                          id="edit-variable-value"
                          hideLabel
                          labelText="Value"
                          placeholder="Value"
                          size="sm"
                          value={newVariableValue}
                          invalid={!isVariableValueValid}
                          disabled={context.isUpdating || isInProgress}
                          onChange={(evt) => {
                            setNewVariableValue(evt.target.value);
                          }}
                        />
                      </>
                    ) : (
                      variable.value
                    )}
                  </td>
                  <Styled.RowActions>
                    {isEditingVariable === variable.name ? (
                      context.isUpdating || isInProgress ? (
                        <Styled.SmallLoader />
                      ) : (
                        <>
                          <Button
                            onClick={() => setIsEditingVariable(false)}
                            hasIconOnly
                            renderIcon={Close}
                            iconDescription="Exit"
                            size="sm"
                            kind="ghost"
                          />
                          <Button
                            onClick={async () => {
                              if (!isVariableValueValid) return;

                              setIsInProgress(true);

                              const isSuccess = await context.setVariables(
                                selectedScope,
                                JSON.stringify({ [isEditingVariable]: JSON.parse(newVariableValue) })
                              );

                              if (!isSuccess) {
                                notificationStore.showError(REQUEST_FAILURE);
                              }

                              setIsInProgress(false);
                            }}
                            hasIconOnly
                            disabled={!isVariableValueValid}
                            renderIcon={Checkmark}
                            iconDescription="Save"
                            size="sm"
                            kind="ghost"
                          />
                        </>
                      )
                    ) : (
                      <Styled.EditVariableButton
                        onClick={() => {
                          setIsAddingVariable(false);
                          setIsEditingVariable(variable.name);
                          setNewVariableValue(variable.value);
                        }}
                        disabled={!isActive}
                        hasIconOnly
                        renderIcon={Edit}
                        iconDescription="Edit"
                        size="sm"
                        kind="ghost"
                      />
                    )}
                  </Styled.RowActions>
                </tr>
              ))}
            </Styled.TBody>
          </Styled.Table>
        )}
      </Styled.TableContainer>
      <Styled.AddVariableContainer>
        {isAddingVariable ? (
          <Styled.AddVariableForm>
            <Styled.AddVariableNameField
              autoFocus
              id="new-variable-name"
              hideLabel
              labelText="Name"
              placeholder="Name"
              value={newVariableName}
              invalid={!isVariableNameValid && !isVariableNamePristine}
              disabled={context.isUpdating || isInProgress}
              onChange={(evt) => setNewVariableName(evt.target.value)}
              onBlur={() => setIsVariableNamePristine(false)}
            />
            <Styled.AddVariableValueField
              id="new-variable-value"
              hideLabel
              labelText="Value"
              placeholder="Value"
              value={newVariableValue}
              invalid={!isVariableValueValid && !isVariableValuePristine}
              disabled={context.isUpdating || isInProgress}
              onChange={(evt) => {
                setNewVariableValue(evt.target.value);
                if (isVariableValuePristine) {
                  clearTimeout(valuePristineTimer.current);
                  valuePristineTimer.current = setTimeout(() => setIsVariableValuePristine(false), 1000);
                }
              }}
            />
            {context.isUpdating || isInProgress ? (
              <Styled.Loader />
            ) : (
              <>
                <Button
                  onClick={() => setIsAddingVariable(false)}
                  hasIconOnly
                  renderIcon={Close}
                  iconDescription="Exit"
                  size="md"
                  kind="ghost"
                />
                <Button
                  onClick={async () => {
                    if (!isVariableValueValid || !isVariableNameValid) return;

                    setIsInProgress(true);

                    const isSuccess = await context.setVariables(
                      selectedScope,
                      JSON.stringify({ [newVariableName]: JSON.parse(newVariableValue) })
                    );

                    if (!isSuccess) {
                      notificationStore.showError(REQUEST_FAILURE);
                    }

                    setIsInProgress(false);
                  }}
                  hasIconOnly
                  disabled={!isVariableValueValid || !isVariableNameValid}
                  renderIcon={Checkmark}
                  iconDescription="Save"
                  size="md"
                  kind="ghost"
                />
              </>
            )}
          </Styled.AddVariableForm>
        ) : (
          <Button
            onClick={() => {
              setIsAddingVariable(true);
              setNewVariableName('');
              setNewVariableValue('');
              setIsVariableNamePristine(true);
              setIsVariableValuePristine(true);
            }}
            renderIcon={Add}
            iconDescription="Add Variable"
            size="md"
            kind="ghost"
            disabled={!isActive || isEditingVariable}
          >
            Add Variable
          </Button>
        )}
      </Styled.AddVariableContainer>
    </Styled.Container>
  );
}
