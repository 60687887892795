/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import callingProcessesStore from './CallingProcessesStore';

function CallingProcessesExtension(eventBus) {
  eventBus.on(['selection.changed'], (event) => {
    const element = event.newSelection[0];
    callingProcessesStore.setSelectedElement(element);
  });
}

CallingProcessesExtension.$inject = ['eventBus'];

export default {
  __init__: ['callingProcessesExtension'],
  callingProcessesExtension: ['type', CallingProcessesExtension]
};
