/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { userStore } from 'stores';
import { AvatarList } from 'primitives';
import pluralize from 'utils/pluralize';

export const getCollaborators = (project) => {
  const collaborators = project.collaborators
    ?.slice()
    .sort((collaborator) => {
      return collaborator.permissionAccess === 'ADMIN' ? -1 : 1;
    })
    .map((collaborator) => {
      if (userStore.isCurrentUser(collaborator)) {
        return { ...collaborator, tooltipName: 'You' };
      }

      return collaborator;
    });

  return <AvatarList collaborators={collaborators ?? []} />;
};

export const getItemsCount = (project) => {
  return `${project.filesCount} ${pluralize('file', project.filesCount)}`;
};
