/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { autorun, observable, action, runInAction, computed, makeObservable } from 'mobx';

import BrowserDetector from 'utils/browser-detector';
import { notificationStore, userStore } from 'stores';
import { preferenceService } from 'services';
import localStorage from 'utils/localstorage';

const DOWNTIME_START_DATE = new Date(Date.UTC(2023, 6, 27, 6, 0));
const DOWNTIME_END_DATE = new Date(Date.UTC(2023, 6, 27, 6, 30));
const DOWNTIME_STORAGE_KEY = `modeler.downtime.seen.${DOWNTIME_END_DATE.valueOf()}`;

const DEFAULT = {
  downTimeBannerSeen: false
};

/**
 * Data store reposnsible for user preferences.
 * Should be used to fetch and update preferences.
 */
class PreferenceStore {
  preferences = DEFAULT;
  loading = false;
  isPreferenceLoaded = false;

  hasSeenOutdatedBrowserMessage = !!localStorage.getItem('hasSeenOutdatedBrowserMessage');

  constructor() {
    makeObservable(this, {
      preferences: observable,
      loading: observable,
      isPreferenceLoaded: observable,
      hasSeenOutdatedBrowserMessage: observable,
      loadPreferences: action,
      hideOutdatedBrowserMessage: action,
      isOutdatedBrowserMessageVisible: computed,
      hideDownTimeBanner: action,
      isDownTimeBannerVisible: computed
    });

    this.#observeStores();
  }

  reset() {
    this.preferences = DEFAULT;
    this.loading = false;
    this.isPreferenceLoaded = false;
    this.hasSeenOutdatedBrowserMessage = !!localStorage.getItem('hasSeenOutdatedBrowserMessage');
  }

  #observeStores() {
    autorun((reaction) => {
      if (userStore.userId) {
        this.loadPreferences();
        reaction.dispose();
      }
    });
  }

  loadPreferences = async () => {
    this.loading = true;
    preferenceService
      .fetch()
      .then((preferences) => {
        runInAction(() => {
          this.preferences = preferences;
          this.isPreferenceLoaded = true;
        });
      })
      .catch(() => notificationStore.showError('Could not fetch preferences.'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  async updatePreferences(newPreferences) {
    this.loading = true;
    preferenceService
      .update(newPreferences)
      .then(() => this.loadPreferences())
      .catch(() => notificationStore.showError('Could not update your preferences. Please try again later.'))
      .finally(() => runInAction(() => (this.loading = false)));
  }

  hideOutdatedBrowserMessage = () => {
    localStorage.setItem('hasSeenOutdatedBrowserMessage', new Date());
    this.hasSeenOutdatedBrowserMessage = true;
  };

  hideDownTimeBanner = () => {
    localStorage.setItem(DOWNTIME_STORAGE_KEY, 'true');

    this.preferences.downTimeBannerSeen = true;
  };

  get isOutdatedBrowserMessageVisible() {
    return !this.hasSeenOutdatedBrowserMessage && !new BrowserDetector().isSupported();
  }

  get isDownTimeBannerVisible() {
    return (
      localStorage.getItem(DOWNTIME_STORAGE_KEY) !== 'true' &&
      !this.preferences.downTimeBannerSeen &&
      Date.now() < DOWNTIME_END_DATE.getTime()
    );
  }

  get downTimeSchedule() {
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    };

    return {
      startTime: new Intl.DateTimeFormat('en', options).format(DOWNTIME_START_DATE),
      endTime: new Intl.DateTimeFormat('en', options).format(DOWNTIME_END_DATE),
      startDate: new Intl.DateTimeFormat('en', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }).format(DOWNTIME_START_DATE),
      endDate: new Intl.DateTimeFormat('en', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }).format(DOWNTIME_END_DATE)
    };
  }
}

export default new PreferenceStore();
