/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observable, action, computed, makeObservable } from 'mobx';
import { KEY_ESCAPE, KEY_A } from 'keycode-js';

import { getColorForName } from 'utils/color-functions';
import getClickCoordinates from 'utils/getClickCoordinates';
import createAttentionGrabberElement from 'utils/createAttentionGrabberElement';
import { fileService } from 'services';
import { userStore } from 'stores';

const DEFAULT_OVERLAY_TIMEOUT = 45000;

const DEFAULT_STATE = {
  isAttentionGrabberActive: false
};

class AttentionGrabberStore {
  state = Object.assign({}, DEFAULT_STATE);

  modeler = null;
  overlays = [];
  currentDiagramId = null;
  isInProjectContext = false;

  constructor() {
    makeObservable(this, {
      state: observable,
      reset: action,
      hideAttentionGrabber: action,
      toggleAttentionGrabberActive: action,
      isActive: computed
    });
  }

  reset = () => {
    this.state = Object.assign({}, DEFAULT_STATE);
    this.overlays = [];
    this.currentDiagramId = null;
    this.isInProjectContext = false;
    if (this.modeler) {
      this.modeler.off('import.done', this.redraw);
      this.modeler = null;
    }
    document.removeEventListener('keydown', this.handleKeyDown);
  };

  hideAttentionGrabber() {
    this.state.isAttentionGrabberActive = false;
  }

  toggleAttentionGrabberActive = () => {
    this.state.isAttentionGrabberActive = !this.state.isAttentionGrabberActive;
    if (!this.state.isAttentionGrabberActive) {
      this.publishAttentionGrabberDeletion();
    }
  };

  handleKeyDown = (event) => {
    const { keyCode, target, metaKey, shiftKey, ctrlKey, altKey } = event;

    const isModifierPressed = metaKey || shiftKey || ctrlKey || altKey;

    // if hitting "A"
    // or hitting "ESC" while the attentionGrabber is active
    // on an element which is not a textarea, select or input
    if (['TEXTAREA', 'SELECT', 'INPUT'].indexOf(target.tagName) === -1 && target.contentEditable !== 'true') {
      if (
        (keyCode === KEY_A && !isModifierPressed) ||
        (keyCode === KEY_ESCAPE && this.state.isAttentionGrabberActive)
      ) {
        const toolManager = this.modeler.get('toolManager');
        toolManager.setActive(null);
        this.toggleAttentionGrabberActive();
      }
    }
  };

  setModeler = (modeler) => {
    this.modeler = modeler;
    document.addEventListener('keydown', this.handleKeyDown);

    this.modeler.on('import.done', this.redraw);
  };

  publishAttentionGrabberCreation = (event) => {
    const coordinates = getClickCoordinates(event, this.modeler);
    const color = getColorForName(userStore.userName);

    // if diagram is contained in a project, publish through socket (remove remotely)
    if (this.isInProjectContext) {
      fileService.createAttentionGrabber(this.currentDiagramId, {
        coordinates,
        color
      });
    } else {
      // draw directly
      this.createOverlayFromSocket(
        JSON.stringify({
          authorUserId: userStore.userId,
          coordinates,
          color
        })
      );
    }
  };

  publishAttentionGrabberDeletion = () => {
    // if diagram is contained in a project, publish through socket (remove remotely)
    if (this.isInProjectContext) {
      fileService.destroyAttentionGrabber(this.currentDiagramId);
    } else {
      // remove locally
      this.deleteOverlayFromSocket(JSON.stringify({ authorUserId: userStore.userId }));
    }
  };

  createOverlayFromSocket = (payloadString) => {
    const payload = JSON.parse(payloadString);
    const { coordinates, color, authorUserId } = payload;
    this.addOverlay({ coordinates, color, authorUserId });
  };

  deleteOverlayFromSocket = (payloadString) => {
    const payload = JSON.parse(payloadString);
    const { authorUserId } = payload;

    const foundOverlay = this.overlays.find((overlay) => overlay.authorUserId === authorUserId);

    if (foundOverlay) {
      this.removeOverlay(foundOverlay.overlayId, {
        removeTimeout: true
      });
    }
  };

  addOverlaysToModeler = (attentionGrabberEl, coordinates) => {
    const root = this.modeler.get('canvas').getRootElement();
    return this.modeler.get('overlays').add(root, 'attention', {
      position: {
        top: coordinates.y,
        left: coordinates.x
      },
      show: {
        maxZoom: 4,
        minZoom: 0.2
      },
      html: attentionGrabberEl
    });
  };

  addOverlay = ({ coordinates, color, authorUserId }) => {
    // remove already existing overlays from that user (color)
    const alreadyExistingOverlay = this.overlays.find((overlay) => overlay.authorUserId === authorUserId);

    if (alreadyExistingOverlay) {
      this.removeOverlay(alreadyExistingOverlay.overlayId, {
        removeTimeout: true
      });
    }

    const attentionGrabber = createAttentionGrabberElement({ color });

    const overlayId = this.addOverlaysToModeler(attentionGrabber, coordinates);
    const timeout = setTimeout(() => {
      this.removeOverlay(overlayId);
    }, DEFAULT_OVERLAY_TIMEOUT);

    this.overlays.push({
      authorUserId,
      overlayId,
      timeout,
      coordinates,
      color,
      createdAt: new Date().getTime()
    });
  };

  removeOverlay = (overlayId, { removeTimeout } = {}) => {
    // remove the overlay from the modelers overlays
    const modelerOverlays = this.modeler.get('overlays');
    modelerOverlays.remove(overlayId);

    const foundOverlay = this.overlays.find((overlay) => overlay.overlayId === overlayId);

    // remove the overlay from the internal array of overlays
    if (foundOverlay) {
      this.overlays.splice(this.overlays.indexOf(foundOverlay), 1);
      if (removeTimeout) {
        clearTimeout(foundOverlay.timeout);
      }
    }
  };

  redraw = () => {
    this.overlays.forEach((overlay) => {
      const attentionGrabber = createAttentionGrabberElement({
        color: overlay.color
      });

      const overlayId = this.addOverlaysToModeler(attentionGrabber, overlay.coordinates);
      overlay.overlayId = overlayId;

      clearTimeout(overlay.timeout);
      const MODIFIED_TIMEOUT = DEFAULT_OVERLAY_TIMEOUT - (new Date().getTime() - overlay.createdAt);
      const timeout = setTimeout(() => {
        this.removeOverlay(overlayId);
      }, MODIFIED_TIMEOUT);
      overlay.timeout = timeout;
    });
  };

  get isActive() {
    return this.state.isAttentionGrabberActive;
  }
}

export default new AttentionGrabberStore();
