/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Header = styled.div`
  color: var(--text-text-secondary, #525252);

  /* Utility styles/label-01 */
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.32px;
`;

export const SecretName = styled.div`
  color: var(--text-text-primary, #161616);

  /* Fixed heading styles/heading-03 */
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */

  margin-bottom: 16px;
`;
