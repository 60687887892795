/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default class MixpanelIntegration {
  registerUser() {}

  get isMixpanelEnabled() {
    return false;
  }

  get isAppcuesEnabled() {
    return false;
  }

  get stage() {
    return '';
  }

  setOrganization() {}

  track() {}

  increment() {}
}
