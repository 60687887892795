/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const List = styled.ul`
  margin: 0px;
  padding: 0px;

  font-size: 14px;
  line-height: 1.1;

  list-style: none;

  > li:not(:last-child) {
    margin-bottom: var(--spacing-small);
  }
`;
