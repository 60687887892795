/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function contentContainsURL(content) {
  return new RegExp(
    `([a-zA-Z0-9.+-]+://|www\\.)([-a-zA-Z0-9+$&@#/%?=~_|!:,;'*()]{0,63}\\.)+([a-zA-Z0-9-:]{1,63})|([-a-zA-Z0-9+$&@#/%?=~_|!:,;'*()]{1,63}\\.)+([a-zA-Z0-9-:]{1,63})/`
  ).test(content);
}
