/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

import { currentDiagramStore } from 'stores';
import createPermission from 'utils/createPermission';
import { BPMN } from 'utils/constants';

class DeploymentPermissionsStore {
  isAdminOrEditor = false;

  constructor() {
    makeAutoObservable(this);
  }

  init() {
    const { project } = currentDiagramStore.state;
    const permission = createPermission(project?.permissionAccess);
    this.isAdminOrEditor = permission.is(['ADMIN', 'WRITE']);
  }

  reset() {
    this.isAdminOrEditor = false;
  }

  get isAllowedToStartInstance() {
    return this.isAdminOrEditor && currentDiagramStore.state.diagram.type === BPMN;
  }

  get isExternalApplicationLinksMenuItemPresent() {
    return false;
  }

  get isDeployOrStartInstanceButtonPresent() {
    return this.isAdminOrEditor;
  }
}

export default new DeploymentPermissionsStore();
