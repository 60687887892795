/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect } from 'react';

import { INPUT_KEY } from 'App/Pages/Diagram/Deployment/executeModal/sm/constants';
import { useLocalStorage } from 'hooks';

export default (clusterInfo, setClusterInfo, open) => {
  const [areCredentialsRemembered, setAreCredentialsRemembered] = useLocalStorage('areCredentialsRemembered', true);

  const clearCredentialsIfNecessary = () => {
    if (!areCredentialsRemembered) {
      setClusterInfo({ ...clusterInfo, [INPUT_KEY.CLIENT_ID]: '', [INPUT_KEY.CLIENT_SECRET]: '' });
    }
  };

  useEffect(() => {
    if (!open) {
      clearCredentialsIfNecessary();
    }
  }, [open]);

  return { areCredentialsRemembered, setAreCredentialsRemembered };
};
