/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useMemo } from 'react';
import { loader, useMonaco } from '@monaco-editor/react';

import config from 'utils/config';

export default () => {
  const monaco = useMonaco();
  loader.config({ paths: { vs: `${config.modelerBasePath}/min/vs` } });

  return useMemo(
    () => [
      {
        provideHover: function (model, position) {
          if (!monaco) {
            return null;
          }

          const versionIndex = model.getValue().indexOf('"version"');
          // does not contain version
          if (versionIndex === -1) {
            return null;
          }

          const versionLineNumber = model.getPositionAt(versionIndex).lineNumber;
          const range = new monaco.Range(
            versionLineNumber,
            0,
            versionLineNumber,
            model.getLineLength(versionLineNumber) - 1
          );

          if (range.containsPosition(position)) {
            return {
              range,
              contents: [
                {
                  supportHtml: true,
                  value:
                    'Connector Template versions are managed by Web Modeler, see <a href="https://docs.camunda.io/docs/next/components/connectors/manage-connector-templates/#versioning-connector-templates" target="_blank" rel="noreferrer">documentation</a>.'
                }
              ]
            };
          }

          return null;
        }
      }
    ],
    [monaco]
  );
};
