/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState } from 'react';

import notificationStore from 'stores/NotificationStore';

import ActionButton from './ActionButton';
import PlayContext from './PlayContext';
import Clock from './icons/svgr/Clock.svg';
import Retry from './icons/svgr/Retry.svg';
import { NEW_PROCESS_INSTANCE, REQUEST_FAILURE } from './utils/constants';

export default function TriggerTimerButton({ timer, isRestartMode, shouldWaitForInstanceCreation }) {
  const context = useContext(PlayContext);
  const [isInProgress, setIsInProgress] = useState(false);

  const Icon = isRestartMode ? Retry : Clock;

  return (
    <ActionButton
      disabled={isInProgress}
      actions={[
        {
          label: isRestartMode ? 'Restart process' : 'Trigger Timer',
          renderIcon: () => <Icon color="white" />,
          onClick: async () => {
            setIsInProgress(true);
            const isSuccess = await context.timeTravel(timer.dueDate, shouldWaitForInstanceCreation);

            if (!isSuccess) {
              notificationStore.showError(REQUEST_FAILURE);
            } else {
              if (isRestartMode) {
                notificationStore.showSuccess(NEW_PROCESS_INSTANCE);
              }
            }

            setIsInProgress(false);
          }
        }
      ]}
    />
  );
}
