/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled, { css } from 'styled-components';

const CommonHandle = css`
  position: absolute;
  z-index: var(--z-resizable-panel-handle);
  transition: border-color 0.2s ease-in-out;

  ${({ $position, open }) => {
    if (!open) {
      return '';
    }

    if ($position === 'left' || $position === 'right') {
      return 'cursor: ew-resize;';
    } else {
      return 'cursor: ns-resize;';
    }
  }};

  ${({ open }) => {
    return open ? `&:hover { border-color: var(--cm-color-blue-base);}` : '';
  }};

  ${({ $isDragging }) => {
    if ($isDragging) {
      return 'border-color: var(--cm-color-blue-base);';
    }
  }}
`;

const handleSize = 10;
const handleOffset = handleSize / 2;

export const Panel = styled.div`
  position: relative;

  ${({ open, position, $sizeClosed }) => {
    const measure = position === 'left' || position === 'right' ? 'width' : 'height';

    if (!open) {
      return `${measure}: ${$sizeClosed}px !important;`;
    }
  }}

  ${({ $background }) => `background: ${$background};`}
`;

export const LeftHandle = styled.div`
  width: ${handleSize}px;
  height: 100%;

  left: 0;
  border-left: ${handleOffset}px solid transparent;

  ${CommonHandle}
`;

export const RightHandle = styled.div`
  width: ${handleSize}px;
  height: 100%;

  right: 0;
  border-right: ${handleOffset}px solid transparent;

  ${CommonHandle}
`;

export const TopHandle = styled.div`
  height: ${handleSize}px;
  width: 100%;

  top: 0;
  border-top: ${handleOffset}px solid transparent;

  ${CommonHandle}
`;

export const BottomHandle = styled.div`
  height: ${handleSize}px;
  width: 100%;

  bottom: 0;
  border-bottom: ${handleOffset}px solid transparent;

  ${CommonHandle}
`;
