/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { action, computed, makeObservable, observable } from 'mobx';

import { currentDiagramStore } from 'stores';
import { businessRuleTaskLinkStore } from 'App/Pages/Diagram/BusinessRuleTaskLinking';
import { callActivityLinkStore } from 'App/Pages/Diagram/CallActivityLinking';

class CallingProcessesStore {
  selectedElement = null;

  constructor() {
    makeObservable(this, {
      selectedElement: observable,
      setSelectedElement: action,
      showCallingProcesses: computed,
      callingProcesses: computed
    });
  }

  setSelectedElement = (element) => {
    this.selectedElement = element;
  };

  get showCallingProcesses() {
    if (this.callingProcesses?.length) {
      if (currentDiagramStore.isBPMN) {
        if (!callActivityLinkStore.selectedElement) {
          return true;
        } else if (callActivityLinkStore.selectedElement.businessObject) {
          const { businessObject } = callActivityLinkStore.selectedElement;
          if (businessObject.processRef?.isExecutable || businessObject.isExecutable) {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    return false;
  }

  get callingProcesses() {
    if (currentDiagramStore.isBPMN) {
      return currentDiagramStore.calledBy;
    } else {
      if (businessRuleTaskLinkStore.currentView === 'drd') {
        return currentDiagramStore.calledBy?.[this.selectedElement?.id];
      } else {
        return currentDiagramStore.calledBy?.[businessRuleTaskLinkStore.openedDecisionId];
      }
    }
  }
}

export default new CallingProcessesStore();
