/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function getElements(elements = {}, step) {
  elements[step.id] = step;

  if (step.flowElements) {
    step.flowElements.forEach((element) => getElements(elements, element));
  }
  if (step.artifacts) {
    step.artifacts.forEach((element) => getElements(elements, element));
  }
  if (step.participants) {
    step.participants.forEach((participant) => getElements(elements, participant));
  }
  if (step.messageFlows) {
    step.messageFlows.forEach((messageFlow) => getElements(elements, messageFlow));
  }

  return elements;
}
