/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const FileInput = styled.input`
  display: none;
`;

export const FileLabel = styled.label`
  cursor: pointer;
  width: 100%;
  display: block;
  padding: 7px 20px;
`;
