/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const RelatedDiagramList = styled.ul`
  padding: 0;
  margin: 0 0 42px;
  flex: 1;

  list-style: none;

  overflow-y: auto;
`;
