/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Dropdown } from 'components';
import { IconButton } from 'primitives';
import { Dots } from 'icons';
import { userStore, projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import currentUserIsACollaborator from 'utils/currentUserIsACollaborator';
import { getAllRoles } from 'utils/member-roles';

import * as Styled from './Collaborators.styled';

export function CollaboratorsAction({ collaborator }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { project, collaborators } = projectStore;

  const isLoggedInUser = userStore.userId === collaborator.id;
  const admins = collaborators.filter(({ permissionAccess }) => permissionAccess === 'ADMIN');

  const handleDelete = () => {
    setAnchorEl(null);

    projectStore.deleteCollaborator(collaborator.email);
  };

  const handlePermissionChange = (permissionAccess) => {
    setAnchorEl(null);

    projectStore.updateCollaboratorPermission(collaborator.email, permissionAccess);
  };

  const handleProjectLeave = async () => {
    setAnchorEl(null);

    if (await projectStore.leaveProject(collaborator.email)) {
      history.push('/');
    }
  };

  const dropdownOptions = [];

  if (hasAccess(project, actions.MODIFY_COLLABORATORS)) {
    if (!isLoggedInUser) {
      dropdownOptions.push(
        <Dropdown.ListItem key="remove" onClick={handleDelete} data-test="remove-collaborator">
          Remove
        </Dropdown.ListItem>
      );
    }

    if (!isLoggedInUser || admins.length > 1) {
      dropdownOptions.push(
        <Dropdown.ListItem key="change-role" hasSubDropdown>
          Change role
          <Dropdown.SubDropdown>
            {getAllRoles().map((role) => (
              <Dropdown.ListItem
                onClick={() => handlePermissionChange(role.id)}
                title={role.description}
                key={role.id}
                data-test={`change-permission-${role.id}`}
              >
                <Styled.Icon>{role.icon}</Styled.Icon>
                {role.title}
              </Dropdown.ListItem>
            ))}
          </Dropdown.SubDropdown>
        </Dropdown.ListItem>
      );
    }
  }

  if (isLoggedInUser && currentUserIsACollaborator(collaborators)) {
    if ((collaborator.permissionAccess === 'ADMIN' && admins.length > 1) || collaborator.permissionAccess !== 'ADMIN') {
      dropdownOptions.push(
        <Dropdown.ListItem key="leave" onClick={handleProjectLeave} data-test="leave-project">
          Leave project
        </Dropdown.ListItem>
      );
    }
  }

  if (dropdownOptions.length > 0) {
    return (
      <>
        <IconButton
          $isActive={Boolean(anchorEl)}
          aria-haspopup="true"
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          data-test="entity-context-dropdown"
        >
          <Dots />
        </IconButton>

        <Dropdown anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {dropdownOptions}
        </Dropdown>
      </>
    );
  }

  return null;
}

export default observer(CollaboratorsAction);
