/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { without } from 'min-dash';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import CommandInterceptor from 'diagram-js/lib/command/CommandInterceptor';

import { getUserTaskFormWithOptions as getUserTaskForm } from 'App/Pages/Diagram/FormLinking/utils';
import { getRootElement } from 'utils/web-modeler-diagram-parser/get-root-element';

/**
 * NOTE:
 * This has been copied from https://github.com/camunda/camunda-bpmn-js-behaviors/blob/master/lib/camunda-cloud/FormsBehavior.js and adapted to our needs.
 */
class NoneStartEventCommandInterceptor extends CommandInterceptor {
  constructor(eventBus, modeling) {
    super(eventBus);

    function removeUserTaskForm(element, moddleElement, userTaskForm) {
      const extensionElements = moddleElement.get('extensionElements');

      const values = without(extensionElements.get('values'), userTaskForm);

      modeling.updateModdleProperties(element, extensionElements, {
        values
      });

      if (!values.length) {
        modeling.updateModdleProperties(element, moddleElement, {
          extensionElements: undefined
        });
      }
    }

    /**
     * Remove zeebe:UserTaskForm on start event removed.
     */
    this.postExecute(
      'shape.delete',
      function (context) {
        const { oldParent, shape } = context;

        const rootElement = getRootElement(oldParent);

        const userTaskForm = getUserTaskForm(shape, { rootElement });

        if (!is(shape, 'bpmn:StartEvent') || !userTaskForm) {
          return;
        }

        removeUserTaskForm(shape, rootElement, userTaskForm);
      },
      true
    );
  }
}

NoneStartEventCommandInterceptor.$inject = ['eventBus', 'modeling'];

export default {
  __init__: ['noneStartEventCommandInterceptor'],
  noneStartEventCommandInterceptor: ['type', NoneStartEventCommandInterceptor]
};
