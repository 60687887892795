/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { milestoneStore } from 'stores';
import { publishedOn, PUBLISHED_ON } from 'utils/milestones/published-on';

import MilestoneMenu from './MilestoneMenu';
import MilestoneList from './MilestoneList';
import Milestone from './Milestone';

const MilestonesSection = () => {
  const { isLoading, isEditingMilestone, isPrimarySelected, isSecondarySelected, getAuthor, diagramMilestones, go } =
    milestoneStore;

  return (
    <MilestoneList isLoading={isLoading} navigate={go}>
      {publishedOn(diagramMilestones).map((milestone) => {
        const canPublishToOrg =
          milestone.organizationPublic === false && milestone.publishedOn === PUBLISHED_ON.PROJECT;

        return (
          <Milestone
            key={milestone.id || milestone}
            milestone={milestone}
            name={milestone.name}
            author={getAuthor(milestone)}
            authorFullName={milestone.authorName}
            isPrimarySelection={isPrimarySelected(milestone.id)}
            isSecondarySelection={isSecondarySelected(milestone.id)}
            isEditing={isEditingMilestone(milestone.id)}
            isTemplate={milestoneStore.state?.isTemplate}
            selectMilestone={(milestoneId) => milestoneStore.select([milestoneId])}
            setEditingMilestone={milestoneStore.setEditingMilestone}
            renameMilestone={(milestone, newName) => milestoneStore.rename(milestone, newName)}
          >
            <MilestoneMenu milestone={milestone} canPublishToOrg={canPublishToOrg} />
          </Milestone>
        );
      })}
    </MilestoneList>
  );
};

export default observer(MilestonesSection);
