/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import detectUserTasksStore, { containsUserTasks } from './DetectUserTasksStore';

export default function DetectUserTasks(eventBus, elementRegistry) {
  eventBus.on('canvas.destroy', () => {
    detectUserTasksStore.setContainsUserTasks(false);
  });

  eventBus.on('import.done', () => {
    checkUserTasks();
  });

  eventBus.on('commandStack.changed', () => {
    checkUserTasks();
  });

  const checkUserTasks = () => {
    let hasUserTasks = containsUserTasks(elementRegistry);
    detectUserTasksStore.setContainsUserTasks(hasUserTasks);
    return hasUserTasks;
  };
}

DetectUserTasks.$inject = ['eventBus', 'elementRegistry'];
