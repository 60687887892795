/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Error, Close } from '@carbon/react/icons';

import * as Styled from './ProblemDiagramOverlay.styled';

export default function ProblemDiagramOverlay({ element, problem }) {
  return (
    <Styled.Container style={{ height: element.height + 10 + 'px', width: element.width + 10 + 'px' }}>
      <Styled.IconContainer>
        {problem.status === 'not found' && <Close />}
        {problem.status === 'deployment error' && <Error />}
      </Styled.IconContainer>
    </Styled.Container>
  );
}
