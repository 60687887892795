/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { ElementMenuButton as _ElementMenuButton, Spinner as _Spinner, Link as _Link } from 'primitives';

export const Container = styled.div`
  position: absolute;
  right: 0px;
`;

export const ElementMenuButton = styled(_ElementMenuButton)`
  background-color: var(--cds-blue-60);

  &:hover,
  &:focus {
    background-color: var(--cds-blue-60);
  }
`;

export const LinkContainer = styled.li`
  padding: var(--spacing-small) var(--spacing-medium) var(--spacing-small) var(--spacing-medium);

  ul {
    padding-inline-start: var(--spacing-medium);
    list-style: circle;
  }
`;

export const Link = styled(_Link)`
  cursor: pointer;
  text-decoration: none;
  display: block;
  font-weight: 500;
  overflow-wrap: break-word;
  margin-bottom: var(--spacing-tiny);
`;

export const InfoResourceId = styled.div`
  padding: var(--spacing-small) var(--spacing-medium) var(--spacing-small);
`;

export const InfoMessage = styled.div`
  padding: 0 var(--spacing-medium) var(--spacing-medium) var(--spacing-medium);
  font-style: italic;
`;

export const Spinner = styled(_Spinner)`
  height: 50px;
`;

export const Footer = styled.div`
  height: 40px;
  background-color: var(--silver-base-97);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 5px;
  border-radius: 0px 0px 4px 4px;
  border-top: 1px solid var(--silver-darken-87);
`;

export const FooterActions = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;
