/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Stack } from '@carbon/react';

import { containsSearchWord } from 'components/EntityTable/utils';

import * as Styled from './NameRenderer.styled';
import { Link } from './LinkRenderer.styled';

function NameRenderer({ name, icon, link, onClick }) {
  return (
    <Stack orientation="horizontal">
      <Styled.Icon>{icon}</Styled.Icon>
      <Styled.Title orientation="vertical">
        <Link className="cds--link" to={link} title={name?.content} onClick={onClick}>
          {name?.content}
        </Link>
        {name?.pretitle && <Styled.Type>{name?.pretitle}</Styled.Type>}
      </Styled.Title>
    </Stack>
  );
}

NameRenderer.containsSearchWord = (cell, searchWord) =>
  containsSearchWord(cell?.name?.content, searchWord) || containsSearchWord(cell?.name?.pretitle, searchWord);

NameRenderer.getComparableValue = (cell) => cell?.name?.content;

NameRenderer.getDataTest = (cell) => `entity-${cell?.name?.content}`;

export default NameRenderer;
