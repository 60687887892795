/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Stack, Checkmark } from './CompletedOverlay.styled';

const CompletedOverlay = () => {
  return (
    <Stack orientation="horizontal" gap={2}>
      <Checkmark />
      <div>Completed</div>
    </Stack>
  );
};

export { CompletedOverlay };
