/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'primitives';

import * as Styled from './DropdownButton.styled';

const DropdownButton = forwardRef(
  ({ component, children, disabled, loading, onMainButtonClick, onDropdownClick, ...props }, ref) => (
    <Styled.Wrapper>
      <Button
        as={component}
        ref={ref}
        $isDropdown={false}
        disabled={loading || disabled}
        onClick={onMainButtonClick}
        {...props}
      >
        {children}
      </Button>
      <Button
        $isDropdown
        disabled={loading || disabled}
        onClick={onDropdownClick}
        {...props}
        data-test={props['data-test'] ? `${props['data-test']}-button` : undefined}
      />
    </Styled.Wrapper>
  )
);

DropdownButton.propTypes = {
  ...Button.propTypes,
  onMainButtonClick: PropTypes.func,
  onDropdownClick: PropTypes.func
};

DropdownButton.defaultProps = {
  ...Button.defaultProps
};

export default DropdownButton;
