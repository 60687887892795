/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { Dropdown, TopBar, Breadcrumb, FileUpload, VersionPublishDialog } from 'components';
import { breadcrumbStore, formStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import buildSlug from 'utils/buildSlug';

export const Header = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const history = useHistory();
  const { form, project, loading } = formStore;

  return (
    <TopBar.Breadcrumbs>
      {loading ? (
        <Breadcrumb title="Loading..." variant="text" />
      ) : (
        <Fragment>
          <Breadcrumb data-test="breadcrumb-home" title="Home" variant="link" to="/" />
          <Breadcrumb
            title={project.name}
            variant="link"
            data-test="breadcrumb-project"
            to={`/projects/${project.id}`}
          />
          {Boolean(form.folder) && (
            <Fragment>
              {form.folder.parentId && <Breadcrumb title="..." variant="text" data-test="parent-dots" />}
              <Breadcrumb
                title={form.folder.name}
                variant="link"
                to={`/folders/${buildSlug(form.folder.id, form.folder.name)}`}
                data-test="parent-folder"
              />
            </Fragment>
          )}
          {hasAccess(project, actions.MODIFY_FORM) ? (
            <Fragment>
              <Breadcrumb
                title={form.name}
                variant="dropdown"
                data-test="breadcrumb-form"
                isBold
                forPage="form"
                handleSubmit={formStore.rename}
              >
                <Dropdown.ListItem
                  onClick={() => {
                    breadcrumbStore.toggleDropdownVisibility();
                    setIsDialogOpen(true);
                  }}
                  data-test="create-milestone"
                >
                  Create milestone
                </Dropdown.ListItem>
                <Dropdown.ListItem
                  onClick={() => {
                    breadcrumbStore.toggleDropdownVisibility();
                    history.push(`/forms/${form.id}/milestones`);
                  }}
                  data-test="show-milestone-history"
                >
                  Show milestone history
                </Dropdown.ListItem>

                <hr />

                <Dropdown.ListItem
                  onClick={() => {
                    breadcrumbStore.toggleDropdownVisibility();
                    breadcrumbStore.toggleEditingFor('form');
                  }}
                  data-test="rename-form"
                >
                  Edit name
                </Dropdown.ListItem>
                <Dropdown.ListItem data-test="upload-form" noPadding>
                  <FileUpload onFiles={formStore.upload} label="Replace via upload" accept=".form, .json" />
                </Dropdown.ListItem>
                <Dropdown.ListItem onClick={formStore.duplicate} data-test="duplicate-form">
                  Duplicate
                </Dropdown.ListItem>
                <Dropdown.ListItem onClick={formStore.delete} data-test="delete-form">
                  Delete
                </Dropdown.ListItem>
              </Breadcrumb>

              <VersionPublishDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                file={form}
                origin="breadcrumb"
              />
            </Fragment>
          ) : (
            <Breadcrumb data-test="breadcrumb-form" title={form.name} variant="text" isBold />
          )}
        </Fragment>
      )}
    </TopBar.Breadcrumbs>
  );
};

export default observer(Header);
