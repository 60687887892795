/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import RelatedDiagram from './RelatedDiagram';
import * as Styled from './RelatedDiagramList.styled';

export default function RelatedDiagramList({ versions }) {
  return (
    <Styled.RelatedDiagramList data-test="version-list">
      {versions.map((version) => (
        <RelatedDiagram version={version} key={version.id} />
      ))}
    </Styled.RelatedDiagramList>
  );
}
