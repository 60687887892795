/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Service from './Service';

class EmailNotificationService extends Service {
  /**
   * Fetches all project subscriptions for a specific organization.
   *
   * @returns {Promise}
   */
  fetchByOrganizationId(organizationId) {
    return this.get(`/internal-api/organizations/${organizationId}/subscriptions`);
  }

  /**
   * Subscribes the user to a given project, so that he receives notification
   * emails when he is mentioned in a diagram comment.
   *
   * @param {Object} payload
   * @param {String} payload.projectId
   * @returns {Promise}
   */
  update(payload) {
    return this.patch('/internal-api/subscriptions', payload);
  }

  /**
   * Unsubscribes the user from a given project, so that he no longer receives
   * notification emails when he is mentioned in a diagram comment.
   *
   * @param {Object} payload
   * @param {String} payload.projectId
   * @returns {Promise}
   */
  destroy(payload) {
    return this.delete('/internal-api/subscriptions', payload);
  }
}

export default new EmailNotificationService();
