/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 15px 20px;
  display: flex;
  cursor: pointer;
  margin-bottom: -1px;
  ${({ includeBottomBorder }) => includeBottomBorder && 'border-bottom: 1px solid var(--silver-darken-94)'};
`;

export const Label = styled.span`
  font-size: 13px;
  margin-left: 6px;
`;

export const Hyperlink = styled.span`
  color: var(--cds-blue-60);
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: var(--cds-blue-70);
  }
`;
