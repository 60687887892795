/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

const OVERLAY_ICON_WIDTH = 20;
const OVERLAY_ICON_HEIGHT = 20;
const OVERLAY_Y_OFFSET = 8;

const addFirstOverlayFromRight = (overlays, overlayType, element) =>
  addBottomRightOverlay({ overlays, overlayType, element, rightPosition: -8 });

const addSecondOverlayFromRight = (overlays, overlayType, element) =>
  addBottomRightOverlay({ overlays, overlayType, element, rightPosition: 14 });

/**
 * Add overlay to the bottom of the element, if the element is too small, add it to the right;
 * otherwise, add it to the bottom.
 */
const addBottomOverlayAdaptively = (overlays, overlayType, element) => {
  const currentOverlays = overlays.get({ element: element.id });
  const currentOverlaysCount = currentOverlays.length;

  const totalOverlaysWidth = OVERLAY_ICON_WIDTH * currentOverlaysCount;
  let rightPosition = 14;
  let bottomPosition = -5;

  // We remove the icon width from the element width taking for granted
  // that we need to ensure space on the left for at least 1 other icon (e.g. error linting icon)
  if (element.width - OVERLAY_ICON_WIDTH < totalOverlaysWidth) {
    rightPosition = -8;
    bottomPosition = -(OVERLAY_ICON_HEIGHT + OVERLAY_Y_OFFSET);
  }

  return addBottomRightOverlay({ overlays, overlayType, element, rightPosition, bottomPosition });
};

const addBottomRightOverlay = ({ overlays, overlayType, element, rightPosition, bottomPosition = -5 }) => {
  const target = document.createElement('div');
  target.classList.add(overlayType);
  target.style.position = 'relative';

  return overlays.add(element, overlayType, {
    position: {
      bottom: bottomPosition,
      right: rightPosition
    },
    show: {
      minZoom: 0.7
    },
    html: target
  });
};

const removeOverlay = (overlays, overlayType, element) => {
  try {
    overlays.remove({ element: element.id, type: overlayType });
  } catch (err) {
    console.warn(err);
  }
};

const removeOverlayById = (overlays, overlayId) => {
  try {
    overlays.remove(overlayId);
  } catch (err) {
    console.warn(err);
  }
};

export {
  addFirstOverlayFromRight,
  addSecondOverlayFromRight,
  addBottomOverlayAdaptively,
  removeOverlay,
  removeOverlayById
};
