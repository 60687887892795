/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Table, TableBody, TableHead, TableHeader, TableRow, TableCell } from '@carbon/react';

import { Link } from 'primitives';
import buildSlug from 'utils/buildSlug';

import EditConnectorModal from './EditConnectorModal';
import * as Styled from './ErrorPanel.styled';

export default function ErrorPanel({ filesStatus, connectorInfo }) {
  const problems = filesStatus
    .filter((file) => file.status !== 'success')
    .concat(
      connectorInfo.connectorElementsWithMissingSecrets.map(({ element, missingSecrets }) => {
        return {
          element,
          status: 'missing connector',
          id: '-',
          missingSecrets
        };
      })
    );

  if (problems.length === 0) {
    return (
      <Styled.EmptyState>
        <Styled.SuccessIcon />
        <Styled.EmptyStateMessage>No problems found.</Styled.EmptyStateMessage>
      </Styled.EmptyState>
    );
  }

  return (
    <Table size="sm">
      <TableHead>
        <TableRow>
          <TableHeader>Element Name</TableHeader>
          <TableHeader>Error Message</TableHeader>
          <TableHeader>File Name</TableHeader>
          <TableHeader>Actionable Solutions</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {problems.map((problem) => (
          <TableRow key={problem.id + Math.random().toString()}>
            <TableCell>
              {problem.status === 'not found' && <Styled.NotFoundIcon />}
              {problem.status === 'deployment error' && <Styled.DeploymentErrorIcon />}
              {problem.status === 'missing connector' && (
                <Styled.ConnectorIconContainer>
                  <Styled.ConnectorErrorIcon />
                </Styled.ConnectorIconContainer>
              )}
              <b>{problem.element.businessObject.name || problem.element.businessObject.id}</b>
            </TableCell>
            <TableCell>
              {problem.status === 'not found' && 'File not found'}
              {problem.status === 'deployment error' && 'Deployment error'}
              {problem.status === 'missing connector' && 'Connector secret misconfigured'}
            </TableCell>
            <TableCell>{problem.filename || problem.id}</TableCell>
            <TableCell>
              {problem.status === 'not found' && (
                <>
                  Verify file path or check file permissions.{' '}
                  <a
                    href={`https://docs.camunda.io/docs/components/modeler/web-modeler/advanced-modeling/${
                      problem.type === 'decision' ? 'business-rule-task' : 'call-activity'
                    }-linking/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See how
                  </a>
                </>
              )}
              {problem.status === 'deployment error' && (
                <Link href={`/diagrams/${buildSlug(problem.fileId, problem.filename)}`} target="_blank">
                  Go to file and fix problems
                </Link>
              )}
              {problem.status === 'missing connector' && (
                <EditConnectorModal name={problem.missingSecrets[0]} initialValue="">
                  <Styled.UpdateSecretButton variant="text">Update connector secret</Styled.UpdateSecretButton>
                </EditConnectorModal>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
