/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState } from 'react';

import { INPUT_KEY } from 'App/Pages/Diagram/Deployment/executeModal/sm/constants';

export default () => {
  const [isErrorEnabledForField, setIsErrorEnabledForField] = useState(() => {
    const val = {};
    Object.values(INPUT_KEY).forEach((key) => {
      val[key] = false;
    });
    return val;
  });

  const enableErrorForField = (key) => {
    setIsErrorEnabledForField({ ...isErrorEnabledForField, [key]: true });
  };

  const enableErrorsForAllFields = () => {
    const newIsErrorEnabledForField = {};
    Object.values(INPUT_KEY).forEach((key) => {
      newIsErrorEnabledForField[key] = true;
    });
    setIsErrorEnabledForField(newIsErrorEnabledForField);
  };

  const disableErrorsForAllFields = () => {
    const newIsErrorEnabledForField = {};
    Object.values(INPUT_KEY).forEach((key) => {
      newIsErrorEnabledForField[key] = false;
    });
    setIsErrorEnabledForField(newIsErrorEnabledForField);
  };

  return {
    enableErrorForField,
    enableErrorsForAllFields,
    isErrorEnabledForField,
    disableErrorsForAllFields
  };
};
