/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import * as Styled from './styled';

export default function ListGroup({ children, title, ...props }) {
  return (
    <Styled.ListGroup role="group" {...props}>
      {title && <Styled.ListGroupTitle>{title}</Styled.ListGroupTitle>}
      {children}
    </Styled.ListGroup>
  );
}
