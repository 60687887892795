/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';

import { INPUT_KEY } from 'App/Pages/Diagram/Deployment/executeModal/sm/constants';

export default (clusterInfo) => {
  const clusterInfoToValidate = clusterInfo;

  const [errors, setErrors] = useState({});

  const validate = (data, fieldToValidations) => {
    const newErrors = {};
    for (const key in data) {
      if (data.hasOwnProperty(key) && fieldToValidations.hasOwnProperty(key)) {
        const value = data[key];

        fieldToValidations[key].forEach(({ func: validationFunc, msg: errorMsg }) => {
          if (newErrors.hasOwnProperty(key)) {
            return;
          }

          if (!validationFunc(value)) {
            newErrors[key] = errorMsg;
          }
        });
      }
    }
    return newErrors;
  };

  const validateClusterInfo = async (data) => {
    if (clusterInfo[INPUT_KEY.AUTHENTICATION] === 'oauth') {
      setErrors(
        validate(data, {
          [INPUT_KEY.ENDPOINT]: ENDPOINT_VALIDATIONS,
          [INPUT_KEY.CLIENT_ID]: CLIENT_ID_VALIDATIONS,
          [INPUT_KEY.CLIENT_SECRET]: CLIENT_SECRET_VALIDATIONS,
          [INPUT_KEY.OAUTH_URL]: OAUTH_URL_VALIDATIONS,
          [INPUT_KEY.AUDIENCE]: AUDIENCE_VALIDATIONS
        })
      );
    } else {
      setErrors(
        validate(data, {
          [INPUT_KEY.ENDPOINT]: ENDPOINT_VALIDATIONS
        })
      );
    }
  };

  useEffect(() => {
    validateClusterInfo(clusterInfoToValidate);
  }, [clusterInfoToValidate]);

  return { validationErrors: errors };
};

const isNotEmpty = (value) => {
  return value?.length > 0;
};

const isStartingWithProtocol = (value) => {
  if (!/^https?:\/\//.test(value)) {
    return false;
  }
  return true;
};

const ENDPOINT_VALIDATIONS = [
  {
    func: isNotEmpty,
    msg: 'Cluster endpoint must not be empty.'
  },
  {
    func: isStartingWithProtocol,
    msg: 'Cluster endpoint must start with "http://" or "https://".'
  }
];

const CLIENT_ID_VALIDATIONS = [
  {
    func: isNotEmpty,
    msg: 'Client ID must not be empty.'
  }
];

const CLIENT_SECRET_VALIDATIONS = [
  {
    func: isNotEmpty,
    msg: 'Client Secret must not be empty.'
  }
];

const OAUTH_URL_VALIDATIONS = [
  {
    func: isNotEmpty,
    msg: 'OAuth Token URL must not be empty.'
  }
];

const AUDIENCE_VALIDATIONS = [
  {
    func: isNotEmpty,
    msg: 'OAuth Audience must not be empty.'
  }
];
