/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observable } from 'mobx';

export function sort(arr, key) {
  const compareFunc = (a, b) =>
    key === 'name' ? a[key].localeCompare(b[key], 'en', { sensitivity: 'base' }) : b[key] - a[key];

  return observable.array(arr.slice().sort(compareFunc), { deep: true });
}
