/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

const PROBLEM = {
  top: -20,
  left: -30
};
const CONNECTOR = {
  top: -20,
  left: -30
};
const INCIDENT_BUTTON = {
  top: -38,
  left: 0
};
const START_INSTANCE_BUTTON = {
  top: -38,
  left: 0
};

const TRIGGER_TIMER_BUTTON = {
  top: -38,
  left: 0
};

const TRIGGER_MESSAGE_SUBSCRIPTION_BUTTON = {
  top: -38,
  left: 0
};

const SEQUENCE_FLOW_HIGHLIGHTER = {
  top: -38,
  left: 0
};

const INVOKE_CONNECTOR_BUTTON = {
  top: -38,
  left: 0
};

const CHILD_INSTANCE_BUTTON = {
  top: -38,
  left: 0
};

const JOB_COMPLETE_BUTTON = {
  top: -38,
  left: 0
};

const FINISHED_OVERLAY = {
  top: -40,
  left: -30
};

export {
  PROBLEM,
  CONNECTOR,
  INCIDENT_BUTTON,
  START_INSTANCE_BUTTON,
  TRIGGER_TIMER_BUTTON,
  TRIGGER_MESSAGE_SUBSCRIPTION_BUTTON,
  SEQUENCE_FLOW_HIGHLIGHTER,
  INVOKE_CONNECTOR_BUTTON,
  CHILD_INSTANCE_BUTTON,
  JOB_COMPLETE_BUTTON,
  FINISHED_OVERLAY
};
