/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { userStore } from 'stores';

export default function currentUserIsACollaborator(collaborators) {
  return collaborators?.some((collaborator) => collaborator.id === userStore.userId);
}
