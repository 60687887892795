/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';

import * as Styled from './Card.styled';

export default function Card({ title, subtitle, children, ...props }) {
  return (
    <Styled.Wrapper {...props}>
      <Styled.Header>
        <Styled.Title>{title}</Styled.Title>
        {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
      </Styled.Header>

      {children}
    </Styled.Wrapper>
  );
}

Styled.Footer.propTypes = {
  align: PropTypes.oneOf(['right', 'left']),
  $bordered: PropTypes.bool
};

Styled.Footer.defaultProps = {
  align: 'right',
  $bordered: false
};

Card.Footer = Styled.Footer;
Card.Content = Styled.Content;

Card.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node
};
