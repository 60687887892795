/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

import { currentDiagramStore } from 'stores';

class ErrorPanelStore {
  isErrorsTabSelected = true;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.isErrorsTabSelected = true;
  }

  switchToProblemsTab() {
    this.isErrorsTabSelected = true;
    this.expandPanel();
  }

  switchToOutputTab() {
    this.isErrorsTabSelected = false;
    this.expandPanel();
  }

  expandPanel() {
    if (currentDiagramStore.isErrorPanelCollapsed) {
      currentDiagramStore.setIsErrorPanelCollapsed(false);
    }
  }

  get showProblems() {
    return currentDiagramStore.isBPMN && !currentDiagramStore.isErrorPanelCollapsed && this.isErrorsTabSelected;
  }

  get showOutput() {
    return !currentDiagramStore.isErrorPanelCollapsed && !this.isErrorsTabSelected;
  }
}

export default new ErrorPanelStore();
