/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function neutralizeProcessIds(definition) {
  const oldIdMap = {};

  definition.rootElements.forEach((element, index) => {
    const neutralizedId = `neutralizedProcessId_${index}`;
    oldIdMap[neutralizedId] = element.id;
    element.id = neutralizedId;
  });

  return { definition, oldIdMap };
}
