/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import {
  WarningFilled as BaseWarningFilled,
  CheckmarkOutline as BaseCheckmarkOutline,
  RadioButtonChecked as BaseRadioButtonChecked
} from '@carbon/react/icons';
import styled from 'styled-components';

export const WarningFilled = styled(BaseWarningFilled)`
  fill: #da1e28;
`;

export const CheckmarkOutline = styled(BaseCheckmarkOutline)`
  fill: #525252;
`;

export const RadioButtonChecked = styled(BaseRadioButtonChecked)`
  fill: #24a148;
`;
