/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { Checkbox, Sidebar } from 'primitives';
import { milestoneStore } from 'stores';

const MilestonesDiffingToggle = () => {
  if (!milestoneStore.isDMN) {
    return (
      <Sidebar.Footer>
        <Checkbox
          data-test="diffing-toggle"
          label="Show changes"
          onChange={milestoneStore.setDiffingEnabled}
          checked={milestoneStore.state.isDiffingEnabled}
        />
      </Sidebar.Footer>
    );
  }

  return null;
};

export default observer(MilestonesDiffingToggle);
