/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { useLocalStorage } from 'hooks';

import * as Styled from './ResizablePanel.styled';

const MIN_SIZE = 400;
const MAX_SIZE = MIN_SIZE * 3;

/**
 * Resizable panel component
 * @param {boolean} open - Whether the sidebar is open
 * @param {string} position - Position of the sidebar (left, right, top, bottom)
 * @param {string} background - Background color of the sidebar
 * @param {string} panelKey - Key to store the size in local storage (${panelKey}_size, e.g. sidebar_size)
 * @param {number} minSize - Minimum size of the sidebar
 * @param {number} maxSize - Maximum size of the sidebar
 * @param {number} sizeClosed - Size of the sidebar when closed
 * @param {function} onResize - Callback when the sidebar is resized, it will pass the new size as an argument
 */
const ResizablePanel = ({
  open,
  children,
  position,
  background,
  panelKey,
  minSize = MIN_SIZE,
  maxSize = MAX_SIZE,
  sizeClosed = 0,
  onResize
}) => {
  const [size, setSize] = useLocalStorage(`${panelKey}_size`, minSize);
  const [measure, setMeasure] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (position === 'left' || position === 'right') {
      setMeasure('width');
    } else if (position === 'top' || position === 'bottom') {
      setMeasure('height');
    } else {
      throw new Error('Invalid position prop');
    }
  }, [position]);

  /**
   * Dispatch a resize event when the size changes, this is needed to update
   * elements that depend on the size (e.g. the tools palette)
   */
  useEffect(() => {
    if (typeof Event === 'function') {
      // modern browsers
      window.dispatchEvent(new Event('resize'));
    } else {
      // for IE and other old browsers
      var evt = window.document.createEvent('UIEvents');
      evt.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(evt);
    }

    const dispatchSize = open ? size : sizeClosed;
    if (onResize) {
      onResize(dispatchSize);
    }
  }, [size, open]);

  const handleMouseDown = (e) => {
    e.preventDefault();

    if (!open) {
      return;
    }

    setIsDragging(true);

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!measure) {
      throw new Error('Measure not set');
    }

    let newSize = 0;
    if (measure === 'width') {
      if (position === 'left') {
        newSize = e.clientX;
      } else if (position === 'right') {
        newSize = window.innerWidth - e.clientX;
      }
    } else if (measure === 'height') {
      if (position === 'top') {
        newSize = e.clientY;
      } else if (position === 'bottom') {
        newSize = window.innerHeight - e.clientY;
      }
    } else {
      throw new Error('Invalid measure');
    }

    if (newSize < minSize || newSize > maxSize) {
      return;
    }

    setSize(newSize);
  };

  const handleMouseUp = () => {
    setIsDragging(false);

    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const Handle = (props) => {
    if (position === 'left') {
      return <Styled.RightHandle {...props} />;
    } else if (position === 'right') {
      return <Styled.LeftHandle {...props} />;
    } else if (position === 'top') {
      return <Styled.BottomHandle {...props} />;
    } else if (position === 'bottom') {
      return <Styled.TopHandle {...props} />;
    } else {
      throw new Error('Invalid position prop');
    }
  };

  return (
    <Styled.Panel $background={background} open={open} $sizeClosed={sizeClosed} style={{ [measure]: `${size}px` }}>
      <Handle onMouseDown={handleMouseDown} $isDragging={isDragging} open={open} $position={position} />
      {children}
    </Styled.Panel>
  );
};

ResizablePanel.propTypes = {
  open: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']).isRequired,
  panelKey: PropTypes.string.isRequired,
  background: PropTypes.string,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  sizeClosed: PropTypes.number,
  onResize: PropTypes.func
};

export default observer(ResizablePanel);
