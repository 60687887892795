/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { publicationStore } from 'App/Pages/Diagram/stores';
import { isPublicationSupportedByElement } from 'App/Pages/Diagram/FormLinking';

import { createFormGroup } from './utils';

export const getGroups = (injector, registerEvents, unregisterEvents) => (element) => (groups) => {
  if (isPublicationSupportedByElement(element)) {
    /*
      Note that the publication feature is not yet available in SM.
      When it will be, the Publication group in the properties panel will be available as well
     */
    const formGroup = createFormGroup(element, injector);

    groups.splice(2, 0, formGroup);

    registerEvents();
    publicationStore.syncStoreWithExtensionElements();
  } else {
    unregisterEvents();
  }

  return groups;
};
