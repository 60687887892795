/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import debounce from 'lodash/debounce';

import { BPMN_EVENTS_PRIORITY } from 'utils/constants';
import { publicationStore } from 'App/Pages/Diagram/stores';

import { getGroups } from './getGroups';

/**
 * Injects the publication properties group into the properties panel, to enable/disable the public access.
 * It's also responsible for syncing the store with the extension elements properties.
 */
export default function PublicationPropertiesProvider(propertiesPanel, injector, eventBus) {
  const eventsDebounceDelay = 150;
  const events = [
    // Fired when a new element is added to the diagram (e.g. when manually added or after the import)
    'shape.added',
    // Fired after that the element template has been changed (e.g. from the overlay menu of the diagram)
    'commandStack.propertiesPanel.zeebe.changeTemplate.postExecute',
    // Fired after that element properties have been updated (e.g. publicAccess modified via the properties panel)
    'commandStack.element.updateModdleProperties.postExecute'
  ];

  const onChanges = debounce(() => {
    publicationStore.syncStoreWithExtensionElements();
  }, eventsDebounceDelay);

  const registerEvents = () => {
    eventBus.on(events, onChanges);
  };

  const unregisterEvents = () => {
    eventBus.off(events, onChanges);
  };

  this.getGroups = getGroups(injector, registerEvents, unregisterEvents);

  // Use a lower priority to ensure it is loaded after
  // the basic BPMN properties.
  propertiesPanel.registerProvider(BPMN_EVENTS_PRIORITY.LOW, this);
}

PublicationPropertiesProvider.$inject = ['propertiesPanel', 'injector', 'eventBus'];
