/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import 'linkifyjs';
import linkifyHtml from 'linkify-html';

const LINE_BREAKS = new RegExp(/(?:\r\n|\r|\n)/g);

/**
 * Takes a string of text and converts URLs to actual HTML links
 * and formats line breaks correctly.
 *
 * @param {String} text The text to be enhanced.
 * @returns {String} The enhanced text.
 */
export default function textEnhancer(text) {
  const input = text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
  const options = {
    defaultProtocol: 'https',
    attributes: {
      target: '_blank',
      rel: 'noopener',
      title: 'Click to open URL in a new tab'
    }
  };
  return linkifyHtml(input, options).replace(LINE_BREAKS, '<br />');
}
