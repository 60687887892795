/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

/**
 * Parses a string into a XML document tree. Returns `false` if parsing failed.
 *
 * @param {String} xml The XML as a string to be parsed.
 * @returns {XMLDocument|Boolean}
 */
export default function parseXML(xml) {
  if (typeof xml !== 'string') {
    return false;
  }

  const parser = new DOMParser();

  try {
    return parser.parseFromString(xml, 'application/xml');
  } catch (ex) {
    return false;
  }
}
