/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState, Fragment } from 'react';
import { observer } from 'mobx-react';

import { Sidebar, Button, Typography, EmptyState } from 'primitives';
import { InvitationModal } from 'components';
import { User } from 'icons';
import { invitationModalStore, projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { getRoleDetails } from 'utils/member-roles';
import { getEntityAvatar, getCollaboratorName } from 'utils/helpers';
import localStorage from 'utils/localstorage';

import CollaboratorsAction from './CollaboratorsAction';
import * as Styled from './Collaborators.styled';

const COLLABORATOR_SIDEBAR_LOCALSTORAGE_KEY = 'modeler.collaborator_sidebar_visible';

export const Collaborators = () => {
  const [isVisible, setIsVisible] = useState(() => {
    return localStorage.getItem(COLLABORATOR_SIDEBAR_LOCALSTORAGE_KEY) != 'false';
  });
  const { project, collaborators } = projectStore;

  const handleToggleSidebar = () => {
    setIsVisible(!isVisible);

    localStorage.setItem(COLLABORATOR_SIDEBAR_LOCALSTORAGE_KEY, !isVisible);
  };

  return (
    <Styled.SidebarOuter $isVisible={isVisible}>
      <Sidebar.Toggle
        onClick={handleToggleSidebar}
        type="button"
        title={isVisible ? 'Close collaborators' : 'Open collaborators'}
        data-test="collaborator-toggle"
      >
        <User width="20" height="20" />
        Collaborators
      </Sidebar.Toggle>

      {isVisible && (
        <Sidebar.Inner>
          <Styled.CollaboratorSidebarTitle>Collaborators</Styled.CollaboratorSidebarTitle>

          <Styled.CollaboratorSidebarContent>
            <Styled.EntityList
              columns={[
                { name: 'Name', sortable: true, defaultSortOrder: 'asc' },
                { name: 'Role', sortable: true, width: 120 }
              ]}
              entities={collaborators.map((collaborator) => ({
                id: collaborator.id || collaborator.email,
                icon: getEntityAvatar(collaborator),
                action: <CollaboratorsAction collaborator={collaborator} />,
                columns: [
                  getCollaboratorName(collaborator, {
                    tooltip: (
                      <Fragment>
                        {collaborator.name && <Typography>{collaborator.name}</Typography>}
                        <Typography>{collaborator.email}</Typography>
                      </Fragment>
                    )
                  }),
                  getRoleDetails(collaborator.permissionAccess).title
                ]
              }))}
              disableResponsiveness
              isLightTheme
              action={
                hasAccess(project, actions.MODIFY_COLLABORATORS) && (
                  <Button size="small" onClick={invitationModalStore.open} data-test="add-collaborator">
                    Add user
                  </Button>
                )
              }
            />
            {collaborators?.length === 1 && (
              <Styled.EmptyStateWrapper>
                <EmptyState
                  title="You don't have to do it alone"
                  description="Click add user to invite a collaborator"
                />
              </Styled.EmptyStateWrapper>
            )}
          </Styled.CollaboratorSidebarContent>
        </Sidebar.Inner>
      )}

      {invitationModalStore.state.isInvitationModalVisible && <InvitationModal />}
    </Styled.SidebarOuter>
  );
};

export default observer(Collaborators);
