/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const DiagramControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: var(--grey-darken-33);
  padding: 5px;
  background-color: var(--silver-base-97);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 2px;
`;

export const DiagramControlButton = styled.button`
  margin: 0px 5px;
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  height: 20px;
  width: 20px;
  cursor: pointer;
  outline: none;
`;
