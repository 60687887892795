/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { IconButton } from 'primitives';

export const SearchButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

export const SearchForm = styled.form`
  position: relative;
  margin-right: 5px;
`;

export const SearchInput = styled.input`
  padding: 0 36px 0 15px;
  background-color: var(--silver-darken-87);
  height: 36px;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  font-family: inherit;
  font-style: italic;
  color: var(--grey-darken-33);
  outline: none;
  transition:
    width 0.2s ease-out,
    opacity 0.1s linear;

  &::-moz-focus-inner {
    border: 0;
  }

  ${({ $isVisible }) => {
    if ($isVisible) {
      return `width: 170px`;
    }

    return `width: 0; opacity: 0;`;
  }}
`;
