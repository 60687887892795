/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const SectionHeader = styled.header`
  font-size: 16px;
  font-weight: bold;
  padding: 0;
`;

export const Description = styled.div`
  margin-bottom: var(--spacing-small);
`;

export const HelperText = styled.div`
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
  top: -15px;
  position: absolute;
  right: 0;
  white-space: nowrap;
  ${({ $hasError }) => ($hasError ? 'color: var(--cm-color-red-base);' : '')}
`;
