/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Service from './Service';

class PreferenceService extends Service {
  /**
   * Fetches the user's preferences.
   *
   * @returns {Promise}
   */
  fetch() {
    return this.get('/internal-api/preferences');
  }

  /**
   * Updates the user's preferences.
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  update(payload) {
    return this.post('/internal-api/preferences', payload);
  }
}

export default new PreferenceService();
