/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Button as _Button } from 'primitives';

export const SectionHeader = styled.header`
  font-weight: bold;
  padding: var(--spacing-small) 0;
`;

export const Description = styled.div`
  margin-bottom: var(--spacing-medium);
`;

export const ClusterInfo = styled.div`
  margin-bottom: 20px;
`;

export const Field = styled.div`
  margin-bottom: 10px;
`;

export const Radio = styled.span`
  margin-right: 50px;
`;

export const FieldInfo = styled.div`
  margin-top: 5px;
`;

export const Button = styled(_Button)`
  margin-left: 5px;
`;
