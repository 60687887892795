/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { css } from 'styled-components';

import { lightGreen, darkGreen, lightOrange, darkOrange, lightRed, darkRed } from 'utils/diffing';

/**
 * generateDiffingStyle - generates a set of style rules for coloring the diff
 * @param {String} label - the css prefix to style (added or changed)
 * @param {String} brightColor - hex string of a bright color for set of styles
 * @param {String} darkColor - hex string of a dark color for set of styles
 */
const generateDiffingStyle = (label, brightColor, darkColor) => css`
  .diff-${label}:not(.djs-connection) {
    > .djs-visual {
      > :nth-child(1) {
        stroke: ${brightColor} !important;
      }
      > text > tspan {
        fill: ${darkColor} !important;
      }
    }

    &.UserTask > .djs-visual {
      > :nth-child(n + 3) {
        stroke: ${brightColor} !important;
      }
      > :nth-child(5) {
        fill: ${brightColor} !important;
      }
    }

    &.ExclusiveGateway > .djs-visual > path {
      stroke: ${brightColor} !important;
      fill: ${brightColor} !important;
    }

    &.CallActivity > .djs-visual,
    &.SubProcess > .djs-visual {
      > :nth-child(3) {
        stroke: ${brightColor} !important;
      }
      > :nth-child(4) {
        stroke: ${brightColor} !important;
      }
    }

    &.IntermediateCatchEvent > .djs-visual > {
      :nth-child(2) {
        stroke: ${brightColor} !important;
      }
      :nth-child(3) {
        stroke: ${brightColor} !important;
      }
    }

    &.IntermediateThrowEvent > .djs-visual {
      > :nth-child(2) {
        stroke: ${brightColor} !important;
      }
      > path {
        fill: ${brightColor} !important;
        stroke: ${darkColor} !important;
      }
    }

    &.ComplexGateway,
    &.ParallelGateway {
      > .djs-visual > path {
        fill: ${brightColor} !important;
        stroke: ${brightColor} !important;
      }
    }

    &.ServiceTask > .djs-visual {
      > :nth-child(3) {
        stroke: ${brightColor} !important;
      }
      > :nth-child(5) {
        stroke: ${brightColor} !important;
      }
    }

    &.Participant > .djs-visual > polyline {
      stroke: ${brightColor} !important;
    }

    &.SendTask > .djs-visual > path {
      fill: ${brightColor} !important;
    }

    &.BusinessRuleTask > .djs-visual > :nth-child(3) {
      fill: ${brightColor} !important;
    }

    &.ReceiveTask,
    &.ScriptTask,
    &.ManualTask,
    &.StartEvent,
    &.IntermediateCatchEvent,
    &.EventBasedGateway,
    &.TextAnnotation,
    &.BusinessRuleTask {
      > .djs-visual > path {
        stroke: ${brightColor} !important;
      }
    }

    &.StartEvent > .djs-visual > :nth-child(2) {
      stroke: ${brightColor} !important;
    }

    &.EndEvent > .djs-visual > :nth-child(2) {
      fill: ${brightColor} !important;
      stroke: ${brightColor} !important;
    }

    &.InclusiveGateway,
    &.BoundaryEvent,
    &.EndEvent,
    &.EventBasedGateway {
      > .djs-visual > circle {
        stroke: ${brightColor} !important;
      }

      > .djs-visual > path {
        stroke: ${brightColor} !important;
      }
    }

    &.Transaction > .djs-visual > :nth-child(3) {
      stroke: ${brightColor} !important;
    }

    &.TextAnnotation > .djs-visual > rect {
      stroke: none !important;
    }
  }

  .diff-${label}.djs-connection .djs-visual {
    > :nth-child(1) {
      stroke: ${brightColor} !important;
    }
    > text > tspan {
      fill: ${darkColor} !important;
    }
  }

  .diff-${label}-label > .djs-visual > text {
    fill: ${darkColor} !important;
  }
`;

export const diffingAddedStyles = generateDiffingStyle('added', lightGreen, darkGreen);
export const diffingChangedStyles = generateDiffingStyle('changed', lightOrange, darkOrange);
export const diffingRemovedStyles = generateDiffingStyle('removed', lightRed, darkRed);
