/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import { ArrowRight } from '@carbon/react/icons';
import { useHistory } from 'react-router-dom';

import { organizationStore, userStore } from 'stores';
import { tracingService, trackingService } from 'services';

import SuperUserToggle from '../SuperUserToggle';
import URL from '../url';

export default ({ termsOfUseUrl, showCookiePreferences }) => {
  const { isAuthenticated } = userStore;

  const history = useHistory();

  const [entity, setEntity] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const elements = [
    {
      key: 'notification-settings',
      label: 'Email notification settings',
      onClick: () => {
        trackingService.trackTopBarAction('notifications', 'settings');
        history.push('/notifications');
      }
    },
    {
      key: 'terms',
      label: 'Terms of use',
      onClick: () => {
        setIsOpen(false);
        trackingService.trackTopBarAction('tos', 'settings', { url: termsOfUseUrl });
        window.open(termsOfUseUrl, '_blank');
      }
    },
    {
      key: 'privacy',
      label: 'Privacy policy',
      onClick: () => {
        setIsOpen(false);
        trackingService.trackTopBarAction('privacy-policy', 'settings', { url: URL.PRIVACY_POLICY });
        window.open(URL.PRIVACY_POLICY, '_blank');
      }
    },
    {
      key: 'imprint',
      label: 'Imprint',
      onClick: () => {
        setIsOpen(false);
        trackingService.trackTopBarAction('imprint', 'settings', { url: URL.IMPRINT });
        window.open(URL.IMPRINT, '_blank');
      }
    }
  ];

  // remove terms of use if not configured
  if (!termsOfUseUrl) {
    elements.splice(1, 1);
  }

  // add cookie preferences if configured
  if (showCookiePreferences && window.Osano?.cm) {
    elements.splice(1, 0, {
      key: 'cookie',
      label: 'Cookie preferences',
      onClick: () => {
        trackingService.trackTopBarAction('cookie-preferences', 'settings');
        window.Osano?.cm?.showDialog();
      }
    });
  }

  useEffect(() => {
    if (!isAuthenticated) {
      setEntity({
        type: 'user',
        isOpen,
        ariaLabel: 'Settings',
        customElements: {
          profile: {
            label: 'Profile',
            user: { name: 'Viewing as Guest', email: '' }
          }
        },
        elements: elements,
        bottomElements: [
          {
            key: 'login',
            label: 'Log in',
            ariaLabel: 'Log in button',
            renderIcon: ArrowRight,
            kind: 'ghost',
            onClick: () => {
              trackingService.trackTopBarAction('login', 'settings');
              history.push(location.pathname.replace('/share/', '/login?returnUrl=/share/'));
            }
          }
        ]
      });

      return;
    }

    if (isAuthenticated && userStore) {
      const customSection = userStore.isOrgOwner
        ? {
            customSection: <SuperUserToggle />
          }
        : {};

      setEntity({
        type: 'user',
        isOpen,
        ariaLabel: 'Settings',
        customElements: {
          ...customSection,
          profile: {
            label: 'Profile',
            user: { name: userStore.userName, email: userStore.userEmail }
          }
        },
        elements: elements,
        bottomElements: [
          {
            key: 'logout',
            label: 'Log out',
            ariaLabel: 'Log out button',
            renderIcon: ArrowRight,
            kind: 'ghost',
            onClick: () => {
              trackingService.trackTopBarAction('logout', 'settings');
              history.push(`/logout`);
              tracingService.logout();
            }
          }
        ]
      });
    }
  }, [isAuthenticated, userStore, organizationStore.currentOrganization]);

  return entity;
};
