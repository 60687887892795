/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { DMN } from 'utils/constants';

import getRelationId from './get-relation-id';
import getDefinitions from './get-definitions';

/**
 * Reads a given DMN diagram's content and extracts the relation ID.
 *
 * @param {Object} diagram
 * @param {String} [diagram.type] The diagram type. Only DMN diagrams can be parsed.
 * @param {String} [diagram.content] The diagram's content.
 * @returns {Object} An object with the extracted values.
 */
export default async function (diagram) {
  if (diagram.type === DMN) {
    try {
      const definitions = await getDefinitions(diagram.content, DMN);

      return {
        relationId: getRelationId(definitions)
      };
    } catch (ex) {
      return {};
    }
  }

  return {};
}
