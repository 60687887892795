/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled, { keyframes } from 'styled-components';
import { TabList as CarbonTabList } from '@carbon/react';

import { Saving } from 'icons';
import { Button } from 'primitives';

export const ActionBarContainer = styled.header`
  border-bottom: 1px solid var(--silver-darken-87);
`;

export const ActionBar = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  color: var(--grey-lighten-50);
`;

export const Separator = styled.span`
  width: 1px;
  height: 25px;
  background-color: var(--silver-darken-87);
  margin: 0 ${({ isDesignMode }) => (isDesignMode ? 'var(--spacing-small)' : 'var(--spacing-tiny)')} 0
    var(--spacing-tiny);
  display: inline-block;
`;

export const Left = styled.div`
  margin: 0 auto 0 15px;
  display: flex;
  align-items: center;

  & .cds--tabs {
    inline-size: auto !important;
  }
`;

export const Status = styled.div`
  user-select: none;
  display: inline-flex;
  width: 100%;
  font-size: 13px;
  color: var(--grey-darken-33);
  align-items: center;
  margin-left: 10px;

  > svg {
    margin-right: 3px;
  }

  &.warning {
    color: var(--cl-color-error);
  }
`;

export const Label = styled.label`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin-right: 'var(--spacing-medium)';

  * {
    vertical-align: middle;
    cursor: inherit;
  }
`;

const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

export const SavingIcon = styled(Saving)`
  animation: ${spinning} 1.5s linear infinite;
`;

export const TooltipsToggle = styled.div`
  display: flex;
  align-items: center;

  ${Label} {
    margin-right: 3px;
  }
`;

export const PaddedContent = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-small) var(--spacing-tiny);
`;

export const TabList = styled(CarbonTabList)`
  user-select: none;
  height: 56px;
`;

export const ActionButton = styled(Button)`
  height: 30px;
  line-height: 30px;
  margin-right: var(--spacing-tiny);
  border-radius: 2px;
`;
