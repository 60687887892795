/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { userStore } from 'stores';
import { Avatar } from 'primitives';

import * as Styled from './Header.styled';

const Header = () => {
  return (
    <Styled.Header>
      <Avatar fullname={userStore.userName} size="big" />
      <Styled.HeaderText>Welcome, {userStore.userName}</Styled.HeaderText>
    </Styled.Header>
  );
};

export default observer(Header);
