/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import { TableContainer as _TableContainer, TableCell as _TableCell, TableHeader as _TableHeader } from '@carbon/react';

export const TableContainer = styled(_TableContainer)`
  flex: 1;
  padding: 40px;
  min-width: 670px;

  table,
  td,
  .cds--table-toolbar,
  .cds--data-table-header {
    background-color: var(--cm-color-white-base);
  }

  tbody td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cds--table-toolbar button {
    border-radius: initial;
    font-weight: 400;
    height: 100%;
  }

  .cds--data-table td.no-padding {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const TableCell = styled(_TableCell)`
  padding: 8px 16px 8px 0;
`;

export const TableHeader = styled(_TableHeader)`
  ${({ $customWidth }) => {
    if ($customWidth) {
      return `
        width: ${$customWidth} !important; /* need to override a carbon rule */
      `;
    }
  }}
`;
