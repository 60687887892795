/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Comment, Read, Write, Admin } from 'icons';

const permissions = new Map();

permissions.set('ADMIN', {
  title: 'Project Admin',
  description: 'Can edit, comment, & manage this project',
  icon: <Admin />
});

permissions.set('WRITE', {
  title: 'Editor',
  description: 'Can edit & comment',
  icon: <Write />
});

permissions.set('COMMENT', {
  title: 'Commenter',
  description: 'Can only comment',
  icon: <Comment />
});

permissions.set('READ', {
  title: 'Viewer',
  description: 'Can only view files',
  icon: <Read />
});

export const getRoleDetails = (permissionAccess) => {
  if (permissions.has(permissionAccess)) {
    return permissions.get(permissionAccess);
  }

  return {
    title: 'Unknown'
  };
};

export const getAllRoles = () => {
  return Array.from(permissions).map(([id, details]) => ({
    id,
    ...details
  }));
};
