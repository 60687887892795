/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { cloneElement, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { DropTarget } from 'components';
import { Spinner, EmptyState } from 'primitives';
import localStorage from 'utils/localstorage';

import EntityContext from './EntityContext';
import ListHead from './Header/ListHead';
import SearchForm from './Header/SearchForm';
import SortDropdown from './Header/SortDropdown';
import Entity from './Entity';
import * as Styled from './EntityList.styled';

export default function EntityList({
  entities,
  columns,
  onDrop,
  isLoading,
  emptyState,
  action,
  MultiSelection,
  title,
  disableResponsiveness,
  isLightTheme,
  localStorageSortingKey,
  className
}) {
  const [scrolling, setScrolling] = useState(false);
  const [sortedBy, setSortedBy] = useState(() => {
    return columns.findIndex((column) => Boolean(column.defaultSortOrder));
  });
  const [sortOrder, setSortOrder] = useState(() => {
    return columns.find((column) => Boolean(column.defaultSortOrder))?.defaultSortOrder || 'desc';
  });
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const changedSelection = selected.filter((select) => {
      return entities.some((entity) => entity.id == select.id);
    });

    if (changedSelection.length != selected.length) {
      setSelected(changedSelection);
    }
  }, [entities, selected]);

  useEffect(() => {
    const savedSorting = localStorage.getItem(localStorageSortingKey);

    if (savedSorting) {
      const [sortColumn, sortOrder] = savedSorting.split('=');

      // The sort column is represented by a number (it's index), hence we need to parse
      // it first (local storage can only store strings). If it's not a number, we we won't set
      // any initial sorting.
      if (!isNaN(Number(sortColumn))) {
        setSortOrder(sortOrder);
        setSortedBy(sortColumn);
      }
    }
  }, []);

  useEffect(() => {
    if (localStorageSortingKey && sortedBy >= 0) {
      localStorage.setItem(localStorageSortingKey, `${sortedBy}=${sortOrder}`);
    }
  }, [sortedBy, sortOrder, localStorageSortingKey]);

  const filteredEntities = useMemo(() => {
    const searchWord = search.trim().toLowerCase();
    const containsSearchWord = (value) => formatColumnContent(value).includes(searchWord);
    const formatColumnContent = (value) => value.trim().toLowerCase();

    return entities
      .filter((entity) => {
        return entity.columns.some((column) => {
          if (typeof column === 'string') {
            return containsSearchWord(column);
          }

          if (typeof column === 'object' && column.content && column.pretitle) {
            return containsSearchWord(column.content) || containsSearchWord(column.pretitle);
          }

          if (typeof column === 'object' && column.content) {
            return containsSearchWord(column.content);
          }

          return false;
        });
      })
      .sort((a, b) => {
        if (a.sortingPosition != b.sortingPosition) {
          return a.sortingPosition - b.sortingPosition;
        }

        if (sortedBy != -1) {
          const row1 = a.columns[sortedBy];
          const row2 = b.columns[sortedBy];
          let before, after;

          if (typeof row1 == 'string') {
            before = row1;
          } else if (typeof row1 == 'object' && row1.content) {
            before = row1.content;
          }

          if (typeof row2 == 'string') {
            after = row2;
          } else if (typeof row2 == 'object' && row2.content) {
            after = row2.content;
          }

          if (before && after) {
            if (before.toUpperCase() < after.toUpperCase()) {
              return sortOrder == 'asc' ? -1 : 1;
            }

            if (before.toUpperCase() > after.toUpperCase()) {
              return sortOrder == 'asc' ? 1 : -1;
            }
          }
        }
      });
  }, [entities, search, sortedBy, sortOrder]);

  const isCheckboxChecked = useMemo(() => {
    if (selected.length === 0) {
      return false;
    } else if (search) {
      return selected.length === filteredEntities.length;
    }

    return selected.length === entities.length;
  }, [selected, entities]);

  const isEntitySelected = (entity) => Boolean(selected.find((item) => item.id == entity.id));
  const isSortedBy = (column) => sortedBy == column;

  const handleSort = (column) => {
    if (sortedBy == -1) {
      setSortedBy(column);
    } else {
      if (isSortedBy(column)) {
        setSortOrder(sortOrder == 'asc' ? 'desc' : 'asc');
      } else {
        setSortOrder('asc');
        setSortedBy(column);
      }
    }
  };

  const handleSelect = (entity) => {
    if (!isEntitySelected(entity)) {
      setSelected([...selected, entity]);
    } else {
      setSelected(selected.filter((item) => item.id != entity.id));
    }
  };

  const handleSelectAll = () => {
    if (isCheckboxChecked) {
      setSelected([]);
    } else if (search) {
      setSelected(filteredEntities);
    } else {
      setSelected(entities);
    }
  };

  return (
    <Styled.EntityListWrapper className={className} onScroll={(evt) => setScrolling(evt.target.scrollTop > 0)}>
      <Styled.EntityListInner>
        <EntityContext.Provider
          value={{
            selected,
            columns,
            MultiSelection,
            entities: filteredEntities,
            isSortedBy,
            disableResponsiveness,
            isLightTheme
          }}
        >
          <Styled.Header $isSticky={scrolling}>
            <Styled.ActionArea>
              {typeof title == 'object' ? (
                title
              ) : typeof title == 'string' ? (
                <Styled.Title data-test="entity-title">{title}</Styled.Title>
              ) : null}

              {entities.length > 0 && (
                <>
                  <SearchForm onSearch={setSearch} />
                  <SortDropdown onSort={handleSort} />
                </>
              )}

              {selected.length > 0 && MultiSelection && (
                <MultiSelection
                  clearSelection={() => setSelected([])}
                  selection={selected.map((entity) => entity.meta)}
                />
              )}

              {action &&
                cloneElement(action, {
                  variant: selected.length > 0 ? 'secondary' : 'primary'
                })}
            </Styled.ActionArea>

            <ListHead
              onSort={handleSort}
              onSelect={handleSelectAll}
              isCheckboxChecked={isCheckboxChecked}
              sortOrder={sortOrder}
            />
          </Styled.Header>

          <DropTarget isDisabled={!onDrop} onDrop={onDrop}>
            {isLoading ? (
              <Spinner />
            ) : filteredEntities.length == 0 ? (
              emptyState || <EmptyState title={entities.length === 0 ? 'No data available.' : 'No entries found.'} />
            ) : (
              <Styled.List data-test="entity-list">
                {filteredEntities.map((entity) => (
                  <Entity key={entity.id} entity={entity} onSelect={handleSelect} isEntitySelected={isEntitySelected} />
                ))}
              </Styled.List>
            )}
          </DropTarget>
        </EntityContext.Provider>
      </Styled.EntityListInner>
    </Styled.EntityListWrapper>
  );
}

EntityList.propTypes = {
  entities: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onDrop: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isLoading: PropTypes.bool,
  emptyState: PropTypes.node,
  action: PropTypes.node,
  multiSelection: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  localStorageSortingKey: PropTypes.string,
  disableResponsiveness: PropTypes.bool,
  isLightTheme: PropTypes.bool,
  className: PropTypes.string
};
