/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';
import BPMNModdle from 'bpmn-moddle';

import { currentDiagramStore } from 'stores';
import { projectService, fileService } from 'services';
import { getCalledDecisionId, isBusinessRuleTask } from 'App/Pages/Diagram/model-parser-util';
import { isExpression } from 'App/Pages/Diagram/properties-panel-util';
import { getCalledElementProcessId } from 'utils/web-modeler-diagram-parser';

export default function collectRelatedFiles() {
  const elementRegistry = currentDiagramStore.modeler.get('elementRegistry');

  const decisions = elementRegistry
    .filter((element) => isBusinessRuleTask(element))
    .map((element) => ({ element, id: getCalledDecisionId(element.businessObject?.get('extensionElements')) }))
    .filter(({ id: decisionId }) => Boolean(decisionId) && !isExpression(decisionId));

  const callActivities = elementRegistry
    .filter((element) => is(element, 'bpmn:CallActivity'))
    .map((element) => ({ element, id: getCalledElementProcessId(element.businessObject?.get('extensionElements')) }))
    .filter(({ id: processId }) => Boolean(processId));

  if (decisions.length === 0 && callActivities.length === 0) return;

  return new Promise((resolve) => {
    const relatedFiles = {};

    const decisionFetchers = decisions.map(({ id, element }) => collectFile('decision', id, relatedFiles, element));
    const callFetchers = callActivities.map(({ id, element }) => collectFile('process', id, relatedFiles, element));

    Promise.all([...decisionFetchers, ...callFetchers]).then(() =>
      Promise.all(Object.values(relatedFiles)).then(resolve)
    );
  });
}

function collectFile(type, id, dict, element) {
  const key = type + '_' + id;

  if (dict[key]) return;

  dict[key] = new Promise((resolve) => {
    const projectId = currentDiagramStore.state.project.id;

    (async () => {
      let file;

      if (type === 'decision') {
        file = await projectService.fetchFilesByDecisionId(projectId, id);
      } else {
        file = await projectService.fetchFilesByProcessId(projectId, id);
      }

      let content = null;
      if (file?.[0]?.id) {
        content = await fileService.fetchById(file[0].id);
      }

      if (type === 'process' && content?.content) {
        // collect transitive files
        const bpmnModdle = new BPMNModdle();
        const { elementsById } = await bpmnModdle.fromXML(content.content);

        const decisionFetchers = Object.values(elementsById)
          .filter((element) => isBusinessRuleTask(element))
          .map((element) => getCalledDecisionId(element?.get('extensionElements')))
          .filter((decisionId) => Boolean(decisionId) && !isExpression(decisionId))
          .map((decisionId) => collectFile('decision', decisionId, dict, element));

        const callFetchers = Object.values(elementsById)
          .filter((element) => is(element, 'bpmn:CallActivity'))
          .map((element) => getCalledElementProcessId(element?.get('extensionElements')))
          .filter((processId) => Boolean(processId))
          .map((processId) => collectFile('process', processId, dict, element));

        await Promise.all([...decisionFetchers, ...callFetchers]);
      }

      resolve({
        type,
        id,
        element,
        filename: file?.[0]?.name ?? null,
        fileId: file?.[0]?.id ?? null,
        content: content?.content ?? null,
        status: 'waiting'
      });
    })();
  });

  return dict[key];
}
