/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Loader } from 'primitives';

export const ProjectActionItem = styled.div`
  display: flex;
  align-items: center;

  ${Loader},
  svg {
    margin-right: 8px;
  }
`;

export const FileUploadContainer = styled.div`
  display: inline-block;
  pointer-events: none;

  label {
    padding: 0;
  }
`;
