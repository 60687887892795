/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Wrapper = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;

  ${({ $isDisabled }) => {
    if (!$isDisabled) {
      return `
        cursor: pointer;

        &:hover > div,
        &:focus > div {
          border-color: var(--cds-blue-60);
        }
      `;
    } else {
      return `cursor: not-allowed;`;
    }
  }}
`;

export const Input = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  opacity: 0;
`;

export const Display = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 18px;
  height: 18px;
  border: 1px solid var(--silver-darken-80);
  background-color: ${({ $isDisabled }) => ($isDisabled ? 'var(--silver-darken-94)' : 'white')};
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  pointer-events: none;
  margin-right: 8px;

  ${({ checked }) => {
    if (checked) {
      return `
        background-color: var(--cds-blue-60);
        border-color: var(--cds-blue-60)
      `;
    }
  }};
`;
