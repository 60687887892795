/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { createElement } from 'utils/web-modeler-diagram-parser/create-element';
import { createUpdateModdlePropertiesCommand } from 'utils/web-modeler-diagram-parser/create-update-moddle-properties-command';

/**
 * @param {Injector} injector
 * @param {Element} element
 *
 * @returns { {
 *   commands: Commands,
 *   extensionElements: ModdleElement
 * } }
 */
export function getOrCreateExtensionElements(injector, element, moddleElement) {
  const businessObject = moddleElement || getBusinessObject(element);

  let extensionElements = businessObject.get('extensionElements');

  if (extensionElements) {
    return {
      commands: [],
      extensionElements
    };
  }

  const bpmnFactory = injector.get('bpmnFactory');

  extensionElements = createElement(
    'bpmn:ExtensionElements',
    {
      values: []
    },
    businessObject,
    bpmnFactory
  );

  return {
    commands: [
      createUpdateModdlePropertiesCommand(element, businessObject, {
        extensionElements
      })
    ],
    extensionElements
  };
}
