/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { PUBLISHED_ON } from './published-on';

const LABELS = {
  ADDITIONAL_HINT_WITH_PREVIOUS_VERSIONS:
    'Additionally, any applied instances of this version will no longer receive updates.',
  WARNING_WHEN_LATEST_VERSION: (
    connectorName
  ) => `Unpublishing this version of "${connectorName}" will remove access for all members,
                 and they will no longer be able to use it in their diagrams.`
};

/**
 * Generates the properties used for displaying the confirmation dialog to the user when they try to unpublish the given connector version
 * @param connectorName {string} the name of the connector
 * @param milestones {object[]} list of milestone versions; the first one is the most recent
 * @param toBeUnpublished {string} the identifier of the milestone to be unpublished
 * @returns {{confirmLabel: string, text: string, title: string, isPrimaryActionDanger: boolean}}
 */
export const generateDialogPropsForUnpublishingConnector = (connectorName, milestones, toBeUnpublished) => {
  const dialogProps = {
    isPrimaryActionDanger: true,
    title: 'Unpublish connector template from organization',
    confirmLabel: 'Unpublish template',
    text: 'If you delete this connector template version, it will no longer be available in your history.'
  };

  if (!connectorName || !milestones?.length || !toBeUnpublished) {
    return dialogProps;
  }

  let isCurrentlyPublished = false;
  let thereAreOtherPublished = false;

  for (let i = 0; i < milestones.length; i++) {
    const version = milestones[i];

    if (thereAreOtherPublished) {
      break;
    }

    if (!version.organizationPublic) {
      continue;
    }

    if (version.id === toBeUnpublished && version.publishedOn === PUBLISHED_ON.ORGANIZATION) {
      isCurrentlyPublished = true;
    }

    if (version.id !== toBeUnpublished) {
      thereAreOtherPublished = true;
    }
  }

  if (isCurrentlyPublished && thereAreOtherPublished) {
    dialogProps.text = `${LABELS.WARNING_WHEN_LATEST_VERSION(connectorName)}
                 They will, however, be able to use versions previously published to the organization.
                 ${LABELS.ADDITIONAL_HINT_WITH_PREVIOUS_VERSIONS}`;
  } else if (isCurrentlyPublished && !thereAreOtherPublished) {
    dialogProps.text = `${LABELS.WARNING_WHEN_LATEST_VERSION(connectorName)}
                 Additionally, any applied instances of the template will no longer receive updates.`;
  } else {
    dialogProps.text = `This version of "${connectorName}" was previously published to the organization.
                 Unpublishing it will remove access for all members, and they will no longer be able to use it in their diagrams.
                 ${LABELS.ADDITIONAL_HINT_WITH_PREVIOUS_VERSIONS}`;
  }

  return dialogProps;
};
