/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Ids from 'ids';

const ids = new Ids([32, 36, 1]);

export default function generateId({ prefix = '' } = {}) {
  if (prefix) {
    return ids.nextPrefixed(prefix);
  }
  return ids.next();
}
