/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { is } from 'bpmn-js/lib/util/ModelUtil';

import { currentDiagramStore } from 'stores';
import { addFirstOverlayFromRight, addSecondOverlayFromRight, removeOverlay } from 'App/Pages/Diagram/overlay-util';
import { getCalledElementProcessId } from 'utils/web-modeler-diagram-parser';
import createPermission from 'utils/createPermission';

import callActivityLinkStore from './CallActivityLinkStore';

const OVERLAY_TYPE = 'call-activity-menu';

function isCallActivity(element) {
  return is(element, 'bpmn:CallActivity');
}

export function CallActivityLinkExtension(eventBus, overlays, elementRegistry) {
  eventBus.on('import.done', () => {
    callActivityLinkStore.setLinkedCallActivities(getLinkedCallActivities(elementRegistry));
  });

  eventBus.on(['selection.changed'], (event) => {
    const element = event.newSelection[0];
    if (isCallActivity(element)) {
      callActivityLinkStore.setSelectedCallActivity(element);
    } else {
      callActivityLinkStore.setSelectedCallActivity(null);
    }
    callActivityLinkStore.setSelectedElement(element ? element : null);
  });

  eventBus.on('element.changed', () => {
    /**
     * For changes in elements, re-compute which CallActivity elements are linked
     * to decide whether to show an overlay or not.
     */
    /**
     * Set to null first to force a re-render of the overlays. Otherwise the overlay does not update
     * if the list itself does not change and the change is only in the processId of the calledElement (deep change)
     * which happens when the calledElement is changed in the properties panel
     */
    callActivityLinkStore.setLinkedCallActivities(null);

    callActivityLinkStore.setLinkedCallActivities(getLinkedCallActivities(elementRegistry));
  });

  eventBus.on(['shape.added', 'connection.added'], (event) => {
    if (isCallActivity(event.element)) {
      const permission = createPermission(currentDiagramStore.state?.project?.permissionAccess);
      if (permission.is(['WRITE', 'ADMIN', 'COMMENT'])) {
        // Comment overlay present at first position from right
        addSecondOverlayFromRight(overlays, OVERLAY_TYPE, event.element);
      } else {
        addFirstOverlayFromRight(overlays, OVERLAY_TYPE, event.element);
      }
    }
  });

  eventBus.on(['shape.remove'], (event) => {
    if (isCallActivity(event.element)) {
      removeOverlay(overlays, OVERLAY_TYPE, event.element);
    }
  });

  eventBus.on('commandStack.element.updateLabel.executed', (event) => {
    const elementWhoseLabelWasUpdated = event?.context?.element;
    if (callActivityLinkStore?.selectedCallActivity?.id === elementWhoseLabelWasUpdated?.id) {
      callActivityLinkStore.setSelectedCallActivity(elementWhoseLabelWasUpdated);
    }
  });

  const getLinkedCallActivities = (elementRegistry) => {
    const callActivities = elementRegistry.filter(isCallActivity);

    return callActivities.filter((element) => {
      const extensionElements = element.businessObject?.get('extensionElements');
      return Boolean(getCalledElementProcessId(extensionElements));
    });
  };
}

CallActivityLinkExtension.$inject = ['eventBus', 'overlays', 'elementRegistry'];

export default {
  __init__: ['callActivityLinkExtension'],
  callActivityLinkExtension: ['type', CallActivityLinkExtension]
};
