/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';
import { Button } from '@carbon/react';
import { ArrowRight, ChevronDown, ChevronUp, Link } from '@carbon/react/icons';
import { Link as RouterLink } from 'react-router-dom';
import { Fragment, useState } from 'react';

import { formStore } from 'stores';
import { Editor, FormDesign, FormValidate, Info, Saved } from 'icons';
import hasAccess, { actions } from 'utils/user-access';
import { Dropdown } from 'components';
import buildSlug from 'utils/buildSlug';
import { ConnectToDiagramGuidance } from 'App/Pages/Form/ConnectToDiagramGuidance';

import ActionsMenu from './ActionsMenu';
import * as Styled from './ActionBar.styled';

const getStatusIcon = (status) => {
  switch (status) {
    case 'progress':
      return <Styled.Spinner width="20" height="20" />;
    case 'done':
      return <Saved width="22" height="22" />;
    default:
      return <Info width="16" height="16" />;
  }
};

const ConnectToDiagramHelper = () => {
  return (
    <>
      <Styled.CalledBySection data-test="connect-to-diagram-helper">
        <ConnectToDiagramGuidance />
      </Styled.CalledBySection>
    </>
  );
};

const ActionBar = ({ showModeSwitcher, mode, switchMode, keyboardShortcut, calledBy }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { loading, project, status } = formStore;

  const getDiagramLink = (diagram) => `/diagrams/${buildSlug(diagram.id, diagram.name)}`;

  const renderCalledBySection = () => {
    if (!calledBy?.length) {
      return <ConnectToDiagramHelper />;
    }

    if (calledBy.length === 1) {
      const diagramName = calledBy[0].name;
      return (
        <Styled.CalledBySection data-test="called-by-section-single-diagram">
          <Link />
          <span>Used in "{diagramName}"</span>
          <RouterLink to={getDiagramLink(calledBy[0])} data-test={`go-to-${diagramName}`} className="cds--link">
            <Button size="sm" renderIcon={ArrowRight}>
              Go to diagram
            </Button>
          </RouterLink>
        </Styled.CalledBySection>
      );
    }

    return (
      <Styled.CalledBySection data-test="called-by-section-multiple-diagrams">
        <Link />
        <span>Used in {calledBy.length} diagrams</span>
        <Button
          size="sm"
          renderIcon={(props) => (anchorEl ? <ChevronUp {...props} /> : <ChevronDown {...props} />)}
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
        >
          Go to diagram
        </Button>
        <Dropdown open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
          {calledBy.map((diagram, index, { length }) => {
            const listItem = (
              <RouterLink
                to={getDiagramLink(diagram)}
                key={diagram.id}
                data-test={`go-to-${diagram.name}`}
                className="cds--link"
              >
                <Dropdown.ListItem onClick={() => setAnchorEl(null)}>{diagram.name}</Dropdown.ListItem>
              </RouterLink>
            );
            if (length - 1 !== index) {
              return (
                <Fragment key={diagram.id}>
                  {listItem}
                  <Dropdown.ListItemDivider />
                </Fragment>
              );
            }
            return listItem;
          })}
        </Dropdown>
      </Styled.CalledBySection>
    );
  };

  return (
    <Styled.ActionBar>
      {showModeSwitcher && (
        <Styled.ModeSwitcher>
          <Styled.Mode
            selected={mode === 'design'}
            onClick={() => switchMode('design', 'toolBar')}
            aria-label="Design mode"
            title={`Open design mode (${keyboardShortcut})`}
          >
            <FormDesign />
          </Styled.Mode>
          <Styled.Mode
            selected={mode === 'validate'}
            onClick={() => switchMode('validate', 'toolBar')}
            aria-label="Validate mode"
            title={`Open validate mode (${keyboardShortcut})`}
          >
            <FormValidate />
          </Styled.Mode>
          <Styled.Mode
            selected={mode === 'jsonEditor'}
            onClick={() => switchMode('jsonEditor', 'toolBar')}
            aria-label="Editor mode"
            title={`Open code editor (${keyboardShortcut})`}
          >
            <Editor width="16" height="16" />
          </Styled.Mode>
        </Styled.ModeSwitcher>
      )}
      {Boolean(status) && (
        <Styled.Status data-test="autosave">
          {getStatusIcon(status.status)}
          {status.message}
        </Styled.Status>
      )}
      {!hasAccess(project, actions.MODIFY_FORM) && !loading && (
        <Styled.Status data-test="collaborator-info">
          {getStatusIcon()} Only collaborators with write access may modify forms.
        </Styled.Status>
      )}
      <Styled.RightSection>
        {renderCalledBySection()}
        <ActionsMenu />
      </Styled.RightSection>
    </Styled.ActionBar>
  );
};

export default observer(ActionBar);
