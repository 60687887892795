/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function Clipboard() {}

Clipboard.prototype.set = function (data) {
  window.__clipboard = data;
};

Clipboard.prototype.get = function () {
  return window.__clipboard;
};

Clipboard.prototype.clear = function () {
  const data = window.__clipboard;

  delete window.__clipboard;

  return data;
};

Clipboard.prototype.isEmpty = function () {
  return !window.__clipboard;
};
