/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

class TracingService {
  constructor() {
    this.isSentryEnabled = false;
  }

  registerUser() {}

  handleWindowError = () => {
    return true;
  };

  handleRejectionError = (event) => {
    event.preventDefault();
  };

  traceError = () => {};

  capture() {}

  logout = () => {};
}

export default new TracingService();
