/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext } from 'react';

import StateIcon from '../StateIcon';
import PlayContext from '../PlayContext';
import * as Styled from './InstanceHeader.styled';
import { formatStartTime } from '../utils/formatStartTime';
import { ErrorBanner } from '../ErrorBanner';

export default function InstanceHeader({ leaveInstance }) {
  const context = useContext(PlayContext);

  if (context.hasError)
    return (
      <Styled.Container>
        <ErrorBanner open={context.hasError} onClick={context.reloadFailedAPIs} />
      </Styled.Container>
    );

  if (!context.instanceId || !context.instanceDetails) {
    return null;
  }

  const state = context.instanceDetails.incidents.length > 0 ? 'INCIDENT' : context.instanceDetails.state;

  return (
    <Styled.Container>
      <Styled.InstanceHeader>
        <StateIcon state={state} size={24} />
        <Styled.Table>
          <thead>
            <tr>
              <Styled.Th>Process Instance Key</Styled.Th>
              <Styled.Th>Started at</Styled.Th>
              <Styled.Th>Process Instances</Styled.Th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Styled.Td>{context.instanceId}</Styled.Td>
              <Styled.Td>{formatStartTime(context.instanceDetails.startTime)}</Styled.Td>
              <Styled.Td>
                <Styled.ViewAllButton variant="text" onClick={leaveInstance}>
                  View all
                </Styled.ViewAllButton>
              </Styled.Td>
            </tr>
          </tbody>
        </Styled.Table>
      </Styled.InstanceHeader>

      <ErrorBanner open={context.hasError} onClick={context.reloadFailedAPIs} />
    </Styled.Container>
  );
}
