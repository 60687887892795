/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

import localStorage from 'utils/localstorage';

class IsModesTooltipVisibleStore {
  isVisible = true;

  #storageKey = 'modes_tooltip_shown_once';

  constructor() {
    makeAutoObservable(this);
  }

  hide() {
    this.#setIsVisible(false);
  }

  #setIsVisible = (val) => {
    this.isVisible = val;
    localStorage.setItem(this.#storageKey, val ? 'false' : 'true');
  };

  get shouldBeShown() {
    return !localStorage.getItem(this.#storageKey)
      ? this.isVisible
      : localStorage.getItem(this.#storageKey) === 'false';
  }
}

export default new IsModesTooltipVisibleStore();
