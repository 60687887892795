/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export const parseClusterGenerationName = (name) => {
  const result = name.match(/(\d+)/g);

  if (result) {
    return {
      major: parseInt(result[0]),
      minor: parseInt(result[1]),
      patch: parseInt(result[2]),
      detail: parseInt(result[3])
    };
  }
};

export const getClusterVersion = (generationName = '') => {
  const versionInfo = parseClusterGenerationName(generationName);
  if (versionInfo) {
    const { major, minor, patch } = versionInfo;
    return `${major}.${minor}.${patch}`;
  }
  return '';
};

export const getClusterVersionWithoutPatch = (generationName = '') => {
  const versionInfo = parseClusterGenerationName(generationName);
  if (versionInfo) {
    const { major, minor } = versionInfo;
    return `${major}.${minor}`;
  }
  return '';
};
