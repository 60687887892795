/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { StatsigProvider } from 'statsig-react';

import { organizationStore } from 'stores';
import config from 'utils/config';
import { TARGET_ENV_INT, TARGET_ENV_PROD } from 'utils/constants';

export default function WithStatsig({ children, condition, hashedUserId }) {
  const { currentOrganizationId, currentOrganizationInfo } = organizationStore;

  let tier = 'development';
  if (TARGET_ENV_INT === config.targetEnv) {
    tier = 'staging';
  } else if (TARGET_ENV_PROD === config.targetEnv) {
    tier = 'production';
  }

  if (condition) {
    return (
      <StatsigProvider
        sdkKey="client-k7XIfCdC1i7gLJIp2KBEkmgxKliK8bQQW2Fcta7tpNo"
        waitForInitialization
        user={{
          userID: hashedUserId,
          customIDs: {
            orgId: currentOrganizationId
          },
          custom: {
            orgId: currentOrganizationId,
            salesPlan: currentOrganizationInfo?.salesPlan.type
          }
        }}
        options={{
          environment: { tier }
        }}
      >
        {children}
      </StatsigProvider>
    );
  } else {
    return children;
  }
}
