/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { CrossCircle, Warning, InformationCircle } from 'icons';

import Errors from './Errors';

export default function ErrorIcon({ category }) {
  return (
    <Errors.Icon>
      {category === 'error' && <CrossCircle title="Error" />}
      {category === 'warn' && <Warning title="Warning" />}
      {category === 'info' && <InformationCircle title="Information" />}
    </Errors.Icon>
  );
}
