/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import TopBarCommon from './TopBar.common';

const TopBar = () => {
  return (
    <TopBarCommon showAppBar={false} showOrganizations={false} showClusters={false} showCookiePreferences={false} />
  );
};

TopBar.Breadcrumbs = TopBarCommon.Breadcrumbs;
TopBar.SubHeader = TopBarCommon.SubHeader;

export default TopBar;
