/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { InlineNotification } from '@carbon/react';
import styled from 'styled-components';

export const InfoMessage = styled(InlineNotification)`
  margin-top: var(--spacing-tiny);
  margin-bottom: var(--spacing-tiny);
`;

export const SectionHeader = styled.header`
  font-size: 16px;
  font-weight: bold;
  padding: 0;
`;

export const SectionSubHeader = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: var(--spacing-small);
`;

export const OtherResources = styled.div`
  margin-bottom: var(--spacing-medium);
`;
