/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  position: relative;
`;

export const DragHandle = styled.div`
  position: absolute;
  top: -3px;
  height: 6px;
  width: 100%;
  z-index: 1;

  cursor: ns-resize;
  transition: background-color 0.2s;

  ${({ isResizing }) => isResizing && 'background-color: var(--cm-color-blue-base);'};

  &:hover {
    background-color: var(--cm-color-blue-base);
  }
`;

export const HistoryTree = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(253, 253, 254);
  border-right: 1px solid rgb(216, 220, 227);
  border-top: 1px solid rgb(216, 220, 227);
`;

export const Header = styled.div`
  padding: 11px 16px;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
`;

export const TreeViewContainer = styled.div`
  overflow: auto;

  .cds--tree-node__label {
    position: relative;
    > span:first-child {
      margin-inline-start: 30px;
    }
    > .cds--tree-parent-node__toggle:first-child {
      margin-inline-start: 22px;
    }
  }
`;

export const TreeNodeLabel = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-left: 12px;
`;

export const Icon = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  left: 16px;
  padding-top: 1px;
`;

export const IconContainer = styled.span`
  font-size: 16px;
  margin-top: -3px;
  transform: translateY(1px);
`;
