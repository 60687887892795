/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const getChevronDown = (color = 'white') => {
  return `'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><polygon fill="${color}" points="12.5 16.45 7.55 11.5 8.964 10.086 12.5 13.621 16.036 10.086 17.45 11.5"/></svg>'`;
};

export const Wrapper = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-family: inherit;
  font-size: 14px;
  padding: 0 21px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  color: inherit;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  .cds--btn::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &[disabled] {
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
  }

  .loader {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  ${({ $variant, $isDropdown }) => {
    if ($variant === 'primary') {
      return `
        background-color: var(--cds-blue-60);
        ${$isDropdown ? `background-image: url(${getChevronDown()}); padding-right: 40px;` : ''};
        color: white;
        font-weight: bold;

        &:not([disabled]):hover {
          background-color: var(--cds-blue-70);
        }

        &:not([disabled]):focus {
          border-color: var(--cds-blue-60);
          box-shadow: inset 0 0 0 1px var(--cds-blue-60),inset 0 0 0 2px white
        }

        &:not([disabled]):active {
          background-color: var(--cds-blue-80)
        }

        &[disabled] {
          background-color: var(--cds-blue-40);
        }
      `;
    } else if ($variant === 'danger-primary') {
      return `
        border-size: 1px;
        border-color: var(--cm-color-button-red3);
        background-color: var(--cm-color-red-base);
        color: white;
        font-weight: bold;
      `;
    } else if ($variant === 'secondary') {
      return `
        background-color: var(--silver-base-97);
        ${$isDropdown ? `background-image: url(${getChevronDown('hsl(0,0%,33%)')}); padding-right: 40px;` : ''};
        color: var(--grey-darken-30);
        border: 1px solid var(--silver-darken-80);
        font-weight: bold;

        &:not([disabled]):hover, &:not([disabled]):focus {
          background-color: var(--silver-darken-94);
          border-color: var(--silver-darken-80);
        }

        &:not([disabled]):active {
          background-color: var(--silver-darken-87);
          border-color: var(--silver-darken-80);
        }

        &[disabled] {
          background-color: var(--silver-base-97);
          border-color: var(--silver-darken-87);
          color: var(--grey-lighten-56);
        }
      `;
    } else if ($variant === 'text') {
      return `
        font-weight: 500;
        background-color: transparent;
        ${$isDropdown ? `background-image: url(${getChevronDown('hsl(0,0%,33%)')});` : ''};
        border: none;
        padding: ${$isDropdown ? '0 35px 0 0' : '0'};
        margin: 0;
      `;
    }
  }}

  ${({ size }) => {
    if (size == 'small') {
      return `
        height: 30px;
        border-radius: 2px;
        line-height: 26px;
        font-size: 13px;
        `;
    }

    return `
      height: 36px;
      line-height: 32px;
      border-radius: 3px;
    `;
  }}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  &.is-loading {
    opacity: 0.35;
  }
`;
