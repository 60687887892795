/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { EXECUTION_PLATFORM } from 'utils/constants';

const hasComponentsArray = (json) => json.hasOwnProperty('components') && Array.isArray(json.components);
const hasType = (json) => json.hasOwnProperty('type') && typeof json.type === 'string';
const hasValidExecutionPlatform = (json) =>
  !json.hasOwnProperty('executionPlatform') ||
  (json.hasOwnProperty('executionPlatform') && json.executionPlatform === EXECUTION_PLATFORM);

export default function isValidForm(json) {
  try {
    const parsed = typeof json === 'object' ? json : JSON.parse(json);

    return hasComponentsArray(parsed) && hasType(parsed) && hasValidExecutionPlatform(parsed);
  } catch (err) {
    return false;
  }
}
