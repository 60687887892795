/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { ComposedModal, ModalBody } from '@carbon/react';
import styled from 'styled-components';

export const ImportDialog = styled(ComposedModal)`
  .cds--modal-container {
    background: white;
  }

  .cds--modal-content {
    margin-bottom: 0;
  }

  // Workaround because of https://github.com/carbon-design-system/carbon/issues/6288
  &.overflow-visible {
    overflow-y: auto;

    .cds--modal-container,
    .cds--modal-content {
      overflow: visible;
    }

    .cds--modal-container {
      max-height: max-content;
      height: max-content;
      margin: auto;
    }
  }
`;

export const ModalTitle = styled.div`
  color: var(--cm-color-black-primary-text);
  font-weight: 400;
  font-size: 20px;
`;

export const ModalSubtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  margin-bottom: var(--spacing-small);
`;

export const ImportModalBody = styled(ModalBody)`
  padding-top: 0px !important;
  padding-bottom: 48px !important;
`;

export const ResourcesList = styled.div`
  max-height: 83px;
  overflow-y: auto;
  margin-bottom: 24px;
`;

export const ProjectsListContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  h6 {
    color: var(--grey-darken-30);
    margin-bottom: var(--spacing-small);
  }

  > div {
    width: 100%;
    padding: 16px;
    background-color: #f4f4f4;

    h4 {
      color: var(--cm-color-black-primary-text);
      font-size: 20px;
      font-weight: 400;
      margin-bottom: var(--spacing-medium);
    }

    p {
      margin-bottom: var(--spacing-medium);
    }
  }
`;

export const CopySupportInfo = styled.div`
  margin-top: var(--spacing-small);
  display: flex;
  gap: 5px;
  color: var(--cds-blue-60);

  cursor: pointer;
`;
