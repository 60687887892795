/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState, useEffect } from 'react';
import { Connect } from '@carbon/icons-react';

import notificationStore from 'stores/NotificationStore';

import ActionButton from '../ActionButton';
import PlayContext from '../PlayContext';
import VariablesInputDialog from '../VariablesInputDialog';
import { REQUEST_FAILURE } from '../utils/constants';

export default function InvokeConnectorButton({ element }) {
  const context = useContext(PlayContext);
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);

  const myJob = context.jobs.find(
    (job) => job.elementInstance.element.elementId === element.id && job.state === 'ACTIVATABLE'
  );

  useEffect(() => {
    setIsInProgress(false);
  }, [myJob]);

  // if we are not in an instance view, do not show
  if (!context.instanceId) return null;

  if (!myJob) {
    // if there is no open job for the current element, do not show
    return null;
  }

  return (
    <>
      <ActionButton
        disabled={isInProgress}
        actions={[
          {
            label: 'Invoke connector',
            renderIcon: () => <Connect color="white" />,
            onClick: async () => {
              setIsInProgress(true);
              const isSuccess = await context.invokeConnector(myJob.jobType, myJob.key);

              if (!isSuccess) {
                notificationStore.showError(REQUEST_FAILURE);
                setIsInProgress(false);
              }
            }
          },
          {
            label: 'Complete',
            onClick: () => {
              setIsVariableModalOpen(true);
            }
          }
        ]}
      />

      <VariablesInputDialog
        title="Complete Job"
        isOpen={isVariableModalOpen}
        onClose={() => setIsVariableModalOpen(false)}
        onSubmit={async (variableModalContent) => {
          setIsVariableModalOpen(false);
          setIsInProgress(true);

          const isSuccess = await context.completeJob(myJob.key, variableModalContent);

          if (!isSuccess) {
            notificationStore.showError(REQUEST_FAILURE);
          }
        }}
        submitLabel="Complete"
      />
    </>
  );
}
