/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { ProgressBar, Stack } from '@carbon/react';
import { useState, useEffect, useRef } from 'react';

import { currentDiagramStore } from 'stores';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import deploymentErrorsStore from 'App/Pages/Diagram/stores/DeploymentErrorsStore';

import PlayIntro from './play-intro.mp4';
import getClusterInfo from '../getClusterInfo';
import { getHelperText } from './getHelperText';
import { trackDeployment } from '../tracking';
import collectRelatedFiles from './collectRelatedFiles';
import embedLinkedForms from './embedLinkedForms';
import { removeBioc } from './removeBioc';
import FailMunda from '../icons/svgr/FailMunda.svg';
import zeebePlayService from '../ZeebePlayService';
import * as Styled from '../StartModal.styled';
import { isValidJSON } from '../utils/isValidJSON';
import { parseDiagramXML } from '../utils/parseDiagramXML';

export default function StartModal({ isLoadingModeler, connectorInfo, close, onClusterReady }) {
  const [hasDiagramError, setHasDiagramError] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [isRedeployment, setIsRedeployment] = useState(false);

  const [isCreatingEnvironment, setIsCreatingEnvironment] = useState(false);
  const [filesStatus, setFilesStatus] = useState();
  const [endPoint, setEndpoint] = useState();

  const videoRef = useRef(null);

  const hasProblems =
    filesStatus?.some((file) => file.status !== 'success') ||
    connectorInfo?.connectorElementsWithMissingSecrets?.length > 0;

  const { diagram } = currentDiagramStore.state;

  const isReady = Boolean(filesStatus) && connectorInfo?.ready;
  const isPlayEnvironmentReady = endPoint && !hasDiagramError && isReady && !hasProblems;

  const setupEnvironment = async () => {
    setIsCreatingEnvironment(true);
    currentDiagramStore.setExecutionPlatformVersion('8.2.0');

    const filesFetcher = collectRelatedFiles();

    try {
      const [endpoint, filesInfo] = await Promise.all([getClusterInfo(), filesFetcher]);

      await ensureClusterIsReady(endpoint);

      setEndpoint(endpoint);

      const filesStatus = await deployFiles(endpoint, filesInfo);
      const result = await deployProcess(endpoint);

      setFilesStatus(filesStatus);

      onClusterReady({
        endpoint,
        filesStatus,
        playId: result?.playId,
        elementsById: result?.elementsById,
        definitions: result?.definitions
      });
    } catch (e) {
      console.error('Uncaught cluster creation error', e);
    }
  };

  useEffect(() => {
    if (isCreatingEnvironment || isLoadingModeler) return;

    setupEnvironment();
  }, [isLoadingModeler]);

  useEffect(() => {
    if (isPlayEnvironmentReady && isRedeployment) {
      close?.();
    }
  }, [isPlayEnvironmentReady, isRedeployment, close]);

  function deployFiles(endpoint, filesInfo = []) {
    return Promise.all(
      filesInfo.map(async (file) => {
        if (file.content) {
          const content = removeBioc(file.content);

          const response = await zeebePlayService.deploy(
            endpoint,
            await embedLinkedForms(diagram.id, content),
            file.type === 'decision'
          );

          if (!isValidJSON(response.payload)) {
            setHasServerError(true);

            return {
              ...file,
              element: {
                id: file.element.id,
                businessObject: { id: file.element.businessObject.id, name: file.element.businessObject.name }
              },
              status: 'server error'
            };
          }

          const payload = JSON.parse(response.payload || '{}');

          return {
            ...file,
            element: {
              id: file.element.id,
              businessObject: { id: file.element.businessObject.id, name: file.element.businessObject.name }
            },
            playId: payload?.deployedProcesses?.[0]?.processKey,
            status: response.success ? 'success' : 'deployment error'
          };
        } else {
          return {
            ...file,
            element: {
              id: file.element.id,
              businessObject: { id: file.element.businessObject.id, name: file.element.businessObject.name }
            },
            status: 'not found'
          };
        }
      })
    );
  }

  async function deployProcess(endPoint) {
    setHasDiagramError(false);

    const content = removeBioc((await currentDiagramStore.modeler.saveXML({ format: true })).xml);
    const { processId } = diagram;

    const redeployment = Boolean(getDeployment(processId)); // if we already have a deployment for the current diagram, its a redeployment

    const xmlWithLinkedForms = await embedLinkedForms(diagram.id, content);

    const { rootElement: definitions, elementsById } = await parseDiagramXML(xmlWithLinkedForms);

    const response = await zeebePlayService.deploy(endPoint, xmlWithLinkedForms);

    setIsCreatingEnvironment(false);
    setIsRedeployment(redeployment);

    if (!isValidJSON(response.payload)) {
      setHasServerError(true);
      return;
    }

    if (response.success) {
      const payload = JSON.parse(response.payload || '{}');

      const playId = payload.deployedProcesses[0].processKey;
      addDeployment(processId, playId);

      trackDeployment({ redeployment, success: true });

      return { playId, elementsById, definitions };
    } else {
      setHasDiagramError(true);

      deploymentErrorsStore.reset();
      const payload = JSON.parse(response.payload || '{}');

      trackDeployment({
        redeployment,
        success: false,
        message: payload.message,
        failure_type: payload.failureType,
        details: payload.details
      });

      const errorObject = [
        {
          detail: payload?.details ?? 'Unknown error'
        }
      ];
      deploymentErrorsStore.parseAndSetDeploymentErrors('deploy', errorObject);
    }
  }
  if (hasServerError) {
    return (
      <Styled.Container>
        <Styled.Modal>
          <Styled.ModalBody>
            <Styled.ErrorContainer orientation="horizontal" gap={6}>
              <FailMunda />
              <Stack orientation="vertical" gap={3}>
                <Styled.ErrorTitle>Something went wrong</Styled.ErrorTitle>
                <Styled.ErrorDescription>
                  <div>We were unable to process your request.</div>
                  <div>Click Retry or refresh this browser tab to try again.</div>
                </Styled.ErrorDescription>
              </Stack>
            </Styled.ErrorContainer>
          </Styled.ModalBody>
          <Styled.ModalFooter>
            <Styled.StartButton
              size="xl"
              onClick={() => {
                setFilesStatus(undefined);
                setEndpoint(undefined);
                setHasServerError(false);
                setIsCreatingEnvironment(false);
                setupEnvironment();
              }}
            >
              Retry
            </Styled.StartButton>
          </Styled.ModalFooter>
        </Styled.Modal>
      </Styled.Container>
    );
  }

  return (
    <Styled.Container>
      <Styled.Modal>
        <Styled.ModalBody>
          <Styled.Video
            ref={videoRef}
            autoPlay
            onEnded={() => {
              // Restart the video when it ends
              videoRef.current.currentTime = 0;
              videoRef.current.play();
            }}
          >
            <source src={PlayIntro} type="video/mp4" />
          </Styled.Video>
          <ProgressBar
            label="Progress"
            helperText={getHelperText(endPoint, isReady, hasDiagramError, hasProblems)}
            value={getValue(endPoint, isReady)}
            status={getStatus(isReady, hasDiagramError, hasProblems)}
          />
        </Styled.ModalBody>
        <Styled.ModalFooter>
          {isPlayEnvironmentReady && !isRedeployment && (
            <>
              <Styled.StartButton size="xl" onClick={close}>
                Start a process instance
              </Styled.StartButton>
            </>
          )}
          {endPoint && hasDiagramError && isReady && (
            <>
              <Styled.StartButton
                size="xl"
                onClick={() =>
                  dedicatedModesStore.setViewModeIndex(
                    dedicatedModesStore.availableViewModes.find((mode) => mode.label === 'Implement').index
                  )
                }
              >
                View errors in Implement mode
              </Styled.StartButton>
            </>
          )}
          {endPoint && !hasDiagramError && isReady && hasProblems && (
            <>
              <Styled.StartButton size="xl" onClick={close}>
                View errors
              </Styled.StartButton>
            </>
          )}
        </Styled.ModalFooter>
      </Styled.Modal>
    </Styled.Container>
  );
}

function getValue(endPoint, isReady) {
  if (!endPoint) return 25;
  if (!isReady) return 50;

  return 100;
}

function getStatus(isReady, hasDiagramError, hasProblems) {
  if (!isReady) return 'active';
  if (hasDiagramError || hasProblems) return 'error';
  return 'finished';
}

function getDeployment(name) {
  return getDeployments()[name];
}

function getDeployments() {
  return JSON.parse(localStorage.getItem('zeebePlayDeployments') || '{}');
}

function addDeployment(name, id) {
  const deployments = getDeployments();
  deployments[name] = id;
  localStorage.setItem('zeebePlayDeployments', JSON.stringify(deployments));
}

function ensureClusterIsReady(endpoint) {
  return new Promise((resolve) => {
    const queryReadyStatus = async () => {
      try {
        const response = await zeebePlayService.checkReadiness(endpoint);

        if (response?.ready) {
          resolve();
        } else {
          setTimeout(queryReadyStatus, 1000);
        }
      } catch (e) {
        setTimeout(queryReadyStatus, 1000);
      }
    };
    queryReadyStatus();
  });
}
