/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { computed, observable, runInAction, action, makeObservable } from 'mobx';

import pluralize from 'utils/pluralize';
import isEmailValid from 'utils/validate-email';
import contentContainsURL from 'utils/contentContainsURL';
import { invitationService, projectService, tracingService, trackingService } from 'services';
import { notificationStore, organizationStore } from 'stores';

const ERRORS = {
  INVITE_LIMIT_EXCEEDED: `Oops, it seems that you are trying to invite too many people at the same time.`,
  INVALID_INPUT: 'You have provided invalid input.'
};

const DEFAULT_STATE = {
  isInvitationModalVisible: false,
  project: { id: '', invitations: [] },
  collaborators: [],
  suggestions: [],
  invitees: [],
  error: null,
  permission: 'WRITE',
  message: '',
  inputValue: '',
  suggestionsVisible: false,
  isSendingInvitations: false
};
class InvitationModalStore {
  state = Object.assign({}, DEFAULT_STATE);

  constructor() {
    makeObservable(this, {
      state: observable,
      hide: action,
      open: action,
      reset: action,
      setProject: action,
      handleMessageChange: action,
      handlePermissionChange: action,
      invitees: computed,
      messageLength: computed,
      buttonDisabled: computed,
      buttonText: computed
    });
  }

  /**
   * Hides the InvitationModal.
   */
  hide = () => {
    this.state.isInvitationModalVisible = false;
  };

  /**
   * Shows the InvitationModal.
   */
  open = () => {
    this.state.isInvitationModalVisible = true;
  };

  /**
   * Resets the store to its default state.
   */
  reset = () => {
    this.state = Object.assign({}, DEFAULT_STATE);
  };

  /**
   * Sets the current project.
   *
   * @param {Object} project The project object with a valid id.
   */
  setProject = (project, collaborators) => {
    this.state.project = project;
    this.state.collaborators = collaborators;
  };

  /**
   * Sets the content of the message.
   *
   * @param {KeyboardEvent} evt The triggered event.
   */
  handleMessageChange = (evt) => {
    this.state.message = evt.target.value;
  };

  /**
   * Sets the permission for the current invitation. Only the following
   * three permissions are available: `READ`, `WRITE` and `COMMENT`.
   *
   * @param {String} permission The new permission to be set.
   */
  handlePermissionChange = (permission) => {
    this.state.permission = permission;
  };

  /**
   * Validates a given email address and sets the error variable
   * based on the results. Said error variable toggles an error message
   * in the InvitationModal component.
   *
   * @param {String} email The email to be validated
   * @return {String} Returns an error message if any validation failed.
   */
  validate = (email, { validateOrganizationInvitee = true } = {}) => {
    if (this.isInviteeInList(email)) {
      return `${email} has already been entered.`;
    }

    if (!isEmailValid(email)) {
      return `${email} is not a valid email address.`;
    }

    if (this.isCollaborator(email)) {
      return `${email} is already registered as a collaborator of this project.`;
    }

    if (validateOrganizationInvitee && !this.isInviteeInOrganization(email)) {
      return `${email} is not part of the organization.`;
    }

    const emailDomain = email.match(/@(.*)/)[1];
    if (emailDomain && !organizationStore.isAllowedHostnameForInvites(emailDomain)) {
      return (
        <span>
          Sorry, this email domain can't be invited. Please{' '}
          <a href={organizationStore.organizationManagementPageUrl} target="_blank" rel="noreferrer">
            contact your admin
          </a>{' '}
          for more information.
        </span>
      );
    }
  };

  /**
   * Gets the list of current invitees.
   *
   * @return {Array}
   */
  get invitees() {
    return this.state.invitees;
  }

  /**
   * Returns the amount characters that have been entered
   * into the message field.
   *
   * @returns {Number}
   */
  get messageLength() {
    return this.state.message.length;
  }

  /**
   * Decides whether the button is disabled or not. At least one
   * invitee has to be present for the button to be enabled.
   *
   * @return {Boolean}
   */
  get buttonDisabled() {
    return this.state.invitees.length < 1 || this.state.isSendingInvitations || this.state.message.length > 500;
  }

  /**
   * Returns a string to be rendered inside the button. Depending
   * on the amount of invitees, the text will shift from plural to singular.
   *
   * @return {String} The button text.
   */
  get buttonText() {
    if (!this.state.isSendingInvitations) {
      return pluralize('Send invite', this.state.invitees.length);
    } else {
      return 'Sending invitations';
    }
  }

  /**
   * Checks whether an invitee already is in the list.
   *
   * @param {String} email The invitees email to check for.
   * @return {Boolean}
   */
  isInviteeInList(email) {
    return this.state.invitees.includes(email);
  }

  isInviteeInOrganization(email) {
    return this.state.suggestions.some((suggestion) => suggestion.email === email);
  }

  /**
   * Checks if the given email belongs to someon who is
   * already collaborating on the project.
   *
   * @param {String} email The invitees email to check for.
   * @return {Boolean}
   */
  isCollaborator(email) {
    return this.state.collaborators.some((collaborator) => collaborator.email == email);
  }

  filterSuggestions(suggestions) {
    const { collaborators } = this.state;
    const filtered = [];

    suggestions.forEach((suggestion) => {
      if (!collaborators.some((collaborator) => collaborator.email == suggestion.email)) {
        filtered.push(suggestion);
      }
    });

    return filtered;
  }

  /**
   * Fetches the suggestions to display in the modal.
   */
  fetchInviteSuggestions = ({ searchQuery } = {}) => {
    runInAction(() => {
      this.state.suggestionsVisible = false;
    });

    return invitationService
      .fetchInvitationSuggestions({ organizationId: organizationStore.currentOrganizationId, searchQuery })
      .then((suggestions) => {
        const filteredSuggestions = this.filterSuggestions(suggestions);

        filteredSuggestions.forEach((suggestion) => (suggestion.isSelected = false));

        runInAction(() => {
          this.state.suggestions = filteredSuggestions;
          this.state.suggestionsVisible = true;
        });
      })
      .catch(() => notificationStore.showError('Could not fetch invitation suggestions.'));
  };

  /**
   * Displays an error message to the user and, if no error message
   * was passed, reports the incident to the tracing service.
   *
   * @param {String} error The error message to display.
   */
  handleErrorFromBackend(error) {
    if (!error) {
      tracingService.traceError(new Error('Unkonwn invitation error came back from the ApiServer'));
    }

    notificationStore.showNotification({
      message: error ? error : 'Oops, there was a problem trying to send the invite, try again or contact us.',
      variant: 'error'
    });
  }

  /**
   * Sends an invitation to the invitees and displays
   * an error message in case something failed.
   *
   * In case that everthing worked fine, the modal is closed
   * and resetted.
   */
  handleInviteClick = async (organizationId, externalInvitees) => {
    if (contentContainsURL(this.state.message)) {
      notificationStore.showNotification({
        message: `The invitation message cannot contain a URL.`,
        variant: 'warning'
      });
      return;
    }

    runInAction(() => {
      this.state.isSendingInvitations = true;
    });

    if (externalInvitees?.length > 0) {
      try {
        await this.sendExternalInvitations(organizationId, externalInvitees);
      } catch (error) {
        runInAction(() => {
          this.state.isSendingInvitations = false;
        });

        tracingService.traceError(error);

        return notificationStore.showNotification({
          message: 'Something went wrong while inviting external users',
          variant: 'error'
        });
      }
    }

    try {
      // NOTE: External invitees are already part of the invitees list
      await projectService.createInvitation(this.state.project.id, {
        emails: this.state.invitees,
        text: this.state.message,
        projectAccess: this.state.permission
      });
    } catch (error) {
      if (error.status == 404) {
        return notificationStore.showNotification({
          message: "You don't have permission to invite collaborators",
          variant: 'error'
        });
      }

      runInAction(() => {
        this.state.isSendingInvitations = false;
      });

      return this.handleErrorFromBackend(ERRORS[error[0].reason]);
    }

    const message = `${this.state.invitees.length !== 1 ? 'Invites have' : 'Invite has'} been sent.`;

    notificationStore.showNotification({ message });

    this.#trackProjectInvitations({
      invitees: this.state.invitees,
      externalInvitees,
      projectAccess: this.state.permission.toLowerCase()
    });

    this.hide();
    this.reset();
  };

  #trackProjectInvitations({ invitees, externalInvitees, projectAccess }) {
    trackingService.trackProjectInvite(projectAccess, invitees.length);

    invitees.forEach((invitee) => {
      trackingService.trackSingleProjectInvitation({
        email: invitee,
        projectAccess,
        isExternal: externalInvitees.includes(invitee)
      });
    });
  }

  async sendExternalInvitations(organizationId, emailAddresses) {
    const requests = [];
    emailAddresses.forEach((emailAddress) => {
      requests.push(invitationService.inviteExternalUser(organizationId, emailAddress));
    });
    await Promise.all(requests);
  }
}

export default new InvitationModalStore();
