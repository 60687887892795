/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { fileService } from 'services';

export default async function embedLinkedForms(diagramId, content) {
  // if there are linked forms in the diagram content, convert them to embedded forms before deploying as Play does
  // not support linked forms yet
  // for assessing if there are linked forms in the diagram content, we can use a simple approach of checking if there
  // is a formId attribute in the diagram content as the called conversion endpoint will not change the content if
  // we mistakenly call it

  return content.includes('formId="')
    ? (await fileService.convertLinkedFormsToEmbeddedForms(diagramId)).content
    : content;
}
