/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Checkmark } from '@carbon/react/icons';

import { formLinkStore } from 'App/Pages/Diagram/FormLinking';
import { Button, Dialog, Link, WithExternalLinkIcon } from 'primitives';
import URL from 'components/TopBar/url';
import localStorage from 'utils/localstorage';

import * as Styled from './LinkingImprovementsModal.styled';

const LINKING_IMPROVEMENTS_MODAL = 'linking_improvements_modal_seen';
const SEEN_STATUS = 'seen';

export default function LinkingImprovementsModal({ open, onClose, selectedClusterVersion }) {
  const userHasNotSeenYet = localStorage.getItem(LINKING_IMPROVEMENTS_MODAL) !== SEEN_STATUS;

  const markModalAsSeenAndCloseIt = () => {
    localStorage.setItem(LINKING_IMPROVEMENTS_MODAL, SEEN_STATUS);
    onClose();
  };

  return (
    <Styled.Dialog
      open={open && userHasNotSeenYet && formLinkStore.atLeastOneLinkedForm()}
      onClose={markModalAsSeenAndCloseIt}
    >
      <Dialog.Header>
        <Dialog.Title>Improvements for linked forms</Dialog.Title>
      </Dialog.Header>
      <Styled.Content>
        <section>
          <p>We improved the way how you can link forms to BPMN diagrams:</p>
          <ul>
            <Styled.CheckmarkItem>
              <Checkmark /> <p>Diagrams will have the latest form updates.</p>
            </Styled.CheckmarkItem>
            <Styled.CheckmarkItem>
              <Checkmark /> <p>No need to manually re-link forms or use a JSON configuration.</p>
            </Styled.CheckmarkItem>
            <Styled.CheckmarkItem>
              <Checkmark /> <p>Forms will be automatically deployed with the diagram.</p>
            </Styled.CheckmarkItem>
          </ul>
        </section>
        <p>
          The update requires a <strong>cluster version 8.4</strong> or higher. Find more details in the{' '}
          <Link href={URL.FORM_GUIDE} target="_blank" rel="noreferrer">
            documentation
            <WithExternalLinkIcon />
          </Link>
          .
        </p>
        <p>
          For the selected cluster version {`${selectedClusterVersion ?? ''}`} forms will be embedded using the former
          approach. Read more about form embedding{' '}
          <Link href={URL.FORM_EMBEDDING} target="_blank" rel="noreferrer">
            here
            <WithExternalLinkIcon />
          </Link>
          .
        </p>
      </Styled.Content>
      <Dialog.Footer>
        <Button variant="primary" onClick={markModalAsSeenAndCloseIt}>
          Got it
        </Button>
      </Dialog.Footer>
    </Styled.Dialog>
  );
}
