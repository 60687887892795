/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { LinkCrumb, DropdownCrumb, TextCrumb } from './variants';

export default function Breadcrumb({ variant, ...rest }) {
  switch (variant) {
    case 'link':
      return <LinkCrumb {...rest} />;
    case 'dropdown':
      return <DropdownCrumb {...rest} />;
    default:
      return <TextCrumb {...rest} />;
  }
}
