/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState } from 'react';
import { Form as CarbonForm, TextArea, Stack, TextInput } from '@carbon/react';

import { Dialog, Button } from 'primitives';
import notificationStore from 'stores/NotificationStore';

import PlayContext from './PlayContext';
import { NEW_PROCESS_INSTANCE, REQUEST_FAILURE } from './utils/constants';

export default function PublishMessageModal({
  isOpen,
  initialMessageName,
  initialCorrelationKey,
  onClose,
  shouldShowSuccessNotification
}) {
  const context = useContext(PlayContext);
  const [variables, setVariables] = useState('');
  const [messageName, setMessageName] = useState(initialMessageName);
  const [correlationKey, setCorrelationKey] = useState(initialCorrelationKey);
  const [ttl, setTTL] = useState('');
  const [messageId, setMessageId] = useState('');

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={500}>
      <Dialog.Header>
        <Dialog.Title>Publish Message</Dialog.Title>
      </Dialog.Header>

      <Dialog.Content>
        <CarbonForm>
          <Stack gap={7}>
            <TextInput
              id="message_name"
              labelText="Message Name"
              placeholder="new order"
              value={messageName}
              onChange={(evt) => {
                setMessageName(evt.target.value);
              }}
            />
            <TextInput
              id="correlation_key"
              labelText="Message Correlation Key"
              placeholder="order-key-123"
              value={correlationKey}
              onChange={(evt) => {
                setCorrelationKey(evt.target.value);
              }}
            />
            <TextArea
              labelText="Variables (JSON)"
              placeholder={`{"variableName": "variableValue"}`}
              onChange={(evt) => setVariables(evt.target.value)}
              value={variables}
            />
            <TextInput
              id="ttl"
              labelText="Message Time-To-Live (TTL)"
              placeholder="PT5M"
              value={ttl}
              onChange={(evt) => {
                setTTL(evt.target.value);
              }}
            />
            <TextInput
              id="message_id"
              labelText="Message Id"
              placeholder="tracking-id-123"
              value={messageId}
              onChange={(evt) => {
                setMessageId(evt.target.value);
              }}
            />
          </Stack>
        </CarbonForm>
      </Dialog.Content>
      <Dialog.Footer>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
        <Button
          onClick={async () => {
            onClose();
            const isSuccess = await context.publishMessage(messageName, correlationKey, variables, ttl, messageId);

            if (!isSuccess) {
              notificationStore.showError(REQUEST_FAILURE);
            } else {
              if (shouldShowSuccessNotification) {
                notificationStore.showSuccess(NEW_PROCESS_INSTANCE);
              }
            }
          }}
          variant="primary"
        >
          Publish
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
