/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import { IconButton } from 'primitives';
import { Cross } from 'icons';

import * as Styled from './Dialog.styled';

export default function Dialog({
  open,
  clickOutsideToClose,
  onClose,
  onConfirm,
  children,
  backdropStyle,
  maxWidth,
  ...props
}) {
  const innerRef = useRef();

  useEffect(() => {
    if (open) {
      const focusable = getFocusable();

      if (focusable.length > 0) {
        focusable[0].focus();
      }
    }
  }, [open]);

  const getFocusable = () => {
    if (innerRef.current) {
      return Array.from(innerRef.current.querySelectorAll('select, input, textarea, button, a')).filter(
        (element) => !element.disabled
      );
    }

    return [];
  };

  const handleKeyDown = (evt) => {
    if (evt.key == 'Escape' && typeof onClose == 'function') {
      return onClose(evt);
    }

    if (evt.key == 'Enter' && typeof onConfirm == 'function') {
      return onConfirm(evt);
    }

    const focusable = getFocusable();

    if (evt.key == 'Tab' && focusable.length > 0) {
      const index = focusable.indexOf(document.activeElement);

      if (index == focusable.length - 1) {
        evt.preventDefault();

        focusable[0].focus();
      }
    }
  };

  const handleClick = (evt) => {
    if (evt.currentTarget != evt.target) {
      return;
    }

    if (typeof onClose == 'function' && clickOutsideToClose) {
      onClose(evt);
    }
  };

  if (!open) {
    return null;
  }

  return createPortal(
    <Styled.Background onClick={handleClick} style={backdropStyle}>
      <Styled.Inner
        tabIndex={1}
        onKeyDown={handleKeyDown}
        role="dialog"
        aria-modal="true"
        aria-labelledby="dialog-title"
        ref={innerRef}
        $maxWidth={maxWidth}
        {...props}
      >
        {onClose && (
          <Styled.CloseIcon>
            <IconButton onClick={onClose} title="Close the dialog" data-test="close-dialog">
              <Cross width="16" height="16" />
            </IconButton>
          </Styled.CloseIcon>
        )}
        {children}
      </Styled.Inner>
    </Styled.Background>,
    document.body
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  clickOutsideToClose: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  maxWidth: PropTypes.number,
  backdropStyle: PropTypes.object
};

Dialog.defaultProps = {
  clickOutsideToClose: true,
  maxWidth: 750
};

const DialogHeader = ({ children }) => <Styled.Header>{children}</Styled.Header>;

DialogHeader.propTypes = {
  children: PropTypes.node.isRequired
};

Dialog.Header = DialogHeader;
Dialog.Title = Styled.Title;
Dialog.Subtitle = Styled.Subtitle;
Dialog.Content = Styled.Content;
Dialog.Footer = Styled.Footer;
