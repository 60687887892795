/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { getExtensionElement } from 'App/Pages/Diagram/BpmnJSExtensions/connectorsExtension/utils';

export default function getInboundConnectorType(element) {
  const bo = getBusinessObject(element);
  const extensionElement = getExtensionElement(bo, 'zeebe:Properties');
  return (
    extensionElement?.properties?.find((el) => el.name === 'inbound.category')?.value ||
    (extensionElement?.properties?.find((el) => el.name === 'inbound.type')?.value ?? null)
  );
}
