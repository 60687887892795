/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { homeStore, userStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import currentUserIsACollaborator from 'utils/currentUserIsACollaborator';

export default function getBatchActions() {
  return [
    {
      action: (selection) => homeStore.leaveProjects(selection),
      isAllowed: (selection) =>
        selection.every(({ entityAction: project }) => {
          const atLeastAnotherAdmin =
            project.collaborators?.filter(
              (collaborator) => collaborator.permissionAccess === 'ADMIN' && collaborator.id !== userStore.userId
            ).length > 0;

          return (
            hasAccess(project, actions.LEAVE_PROJECT) &&
            atLeastAnotherAdmin &&
            currentUserIsACollaborator(project.collaborators)
          );
        }),
      title: 'Leave'
    },
    {
      action: (selection) => homeStore.deleteProjects(selection),
      isAllowed: (selection) =>
        selection.every(({ entityAction: project }) => hasAccess(project, actions.DELETE_PROJECT)),
      title: 'Delete'
    }
  ];
}
