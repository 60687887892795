/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observable, runInAction, action, computed, makeObservable } from 'mobx';
import moment from 'moment';

import { milestoneService, fileService, trackingService } from 'services';
import { confirmActionStore, notificationStore, userStore } from 'stores';
import { isBPMN, isDMN, isForm, isTemplate } from 'utils/helpers';
import { drawIcons, drawTargetEngineVersionChange, addCustomDefs, drawMarkers, shadedDiagram } from 'utils/diffing';
import BPMNDiff from 'utils/diffing/BPMNDiff';
import history from 'utils/history';
import formatDateToString from 'utils/date-format';
import localStorage from 'utils/localstorage';
import {
  getCallActivityLinks,
  getDefinitions,
  getRelationId,
  getExecutableProcessId
} from 'utils/web-modeler-diagram-parser';
import { DEFAULT, FILE_TYPE_MAPPING, FOLDER, NO_PROCESS_ID, VARCHAR_MAX } from 'utils/constants';
import { generateDialogPropsForUnpublishingConnector } from 'utils/milestones/unpublish-version';
import { detectDecisions, getBusinessRuleTasksLinksFromDefinitions } from 'App/Pages/Diagram/model-parser-util';
import { PUBLISHED_ON } from 'utils/milestones/published-on';

const DIFFING_KEY = 'modeler.diffing';

const DEFAULT_STATE = {
  diagram: null,
  project: null,
  isForm: false,
  isTemplate: false,
  isKeyboardNavigationLocked: false,
  relatedFiles: [],
  milestones: [],
  primaryJSON: null,
  secondaryJSON: null,
  selection: {
    primary: null,
    secondary: null
  },
  isLoading: true,
  isLatestVersionShown: false,
  editingMilestone: null,
  isDiffingEnabled: false,
  milestoneIds: []
};

const RESTORED_MILESTONE_SUFFIX = ' (restored)';
const RESTORED_MILESTONE_NAME_MAX_LENGTH = VARCHAR_MAX - RESTORED_MILESTONE_SUFFIX.length;

class MilestoneStore {
  state = Object.assign({}, DEFAULT_STATE);

  modeler = null;
  cache = new Map();
  icons = [];
  markers = [];

  constructor() {
    makeObservable(this, {
      state: observable,
      reset: action,
      initDiffing: action,
      init: action,
      setModeler: action,
      setDiffingEnabled: action,
      setEditingMilestone: action,
      milestones: computed,
      relatedFiles: computed,
      milestonesByDate: computed,
      diagramMilestones: computed,
      hasMilestones: computed,
      hasRelatedFiles: computed,
      isLoading: computed,
      isDMN: computed,
      secondaryJSON: computed,
      titles: computed
    });
  }

  /**
   * Resets the store's state and internal variables to the default values.
   * This method only gets called when the Milestones component is unmounted.
   */
  reset() {
    this.state = Object.assign({}, DEFAULT_STATE);
    this.modeler = null;
    this.cache.clear();
    this.icons = [];
    this.markers = [];
  }

  initDiffing = () => {
    if (!this.isDMN) {
      if (localStorage.getItem(DIFFING_KEY) !== 'false') {
        this.state.isDiffingEnabled = true;
      }
    }
  };

  /**
   * Initializes the store with the required parameters.
   *
   * @param {Object} diagram The current diagram.
   * @param {Object} project The diagram's project.
   * @param {Array} relatedFiles An array of other, related files.
   */
  init(diagram, project, relatedFiles, milestoneIds) {
    this.state.isLoading = true;
    this.state.isLatestVersionShown = false;

    this.state.diagram = diagram;
    this.state.project = project;
    this.state.isForm = isForm(diagram);
    this.state.isTemplate = isTemplate(diagram);
    this.state.relatedFiles = relatedFiles;
    this.state.milestoneIds = milestoneIds;

    this.initDiffing();
  }

  /**
   * Saves the modeler as an internal reference and adds custom
   * definitions for the milestone diffing.
   *
   * @param {Object} modeler
   */
  setModeler(modeler) {
    this.modeler = modeler;

    addCustomDefs();
  }

  /**
   * Enables / disables the milestone diffing and persists the current state in
   * localStorage.
   *
   * If diffing is disabled, the view is resetted and the secondary selection
   * is cleared.
   *
   * If diffing is enabled, the secondary selection is set and the view is refreshed
   * to display the changes.
   */
  setDiffingEnabled = () => {
    this.state.isDiffingEnabled = !this.state.isDiffingEnabled;
    this.select([this.state.selection.primary]);

    localStorage.setItem(DIFFING_KEY, this.state.isDiffingEnabled);

    if (!this.state.isDiffingEnabled) {
      this.resetDiff();
      this.state.selection.secondary = null;
      this.state.secondaryJSON = null;
    } else {
      const index = this.getIndex(this.state.selection.primary);

      if (!this.isLast(index)) {
        this.state.selection.secondary = this.state.milestones[index + 1].id;
      }

      this.diff();
    }
  };

  /**
   * Sets the milestone that is currently being edited.
   *
   * @param {String} milestoneId The id of the milestone to be edited.
   */
  setEditingMilestone = (milestoneId = null) => {
    this.state.isKeyboardNavigationLocked = Boolean(milestoneId);
    this.state.editingMilestone = milestoneId;
  };

  /**
   * Selects a milestone, based on the given ID. The selected milestone
   * will be fetched and imported into the modeler.
   *
   * If the given ID is `LATEST_VERSION`, no milestone will be fetched,
   * but instead the current diagram's content will be loaded.
   *
   * @param {String} milestoneId The ID of the milestone to select.
   * @param {Boolean} importContent Whether the modeler should import the new milestone or not.
   */
  async select(milestoneIds, importContent = true) {
    let milestone = null;
    const twoIdsPresent = milestoneIds?.length === 2;
    const milestoneId = twoIdsPresent ? milestoneIds[1] : milestoneIds[0];
    const fromMilestoneId = twoIdsPresent ? milestoneIds[0] : null;

    if (milestoneId !== 'LATEST_VERSION') {
      milestone = await this.fetch(milestoneId);
    }

    // For BPMN and DMN diagrams, we want to use the `importXML` method to display the proper
    // content to the milestone page. If we are inside a form, we don't have XML but
    // JSON and will set the content directly to the state instead.
    if (importContent && !this.state.isForm && !this.state.isTemplate) {
      await this.importXML(milestone ? milestone.content : this.state.diagram.content);
    } else if (this.state.isForm || this.state.isTemplate) {
      runInAction(() => {
        this.state.primaryJSON = milestone;
      });
    }

    const index = this.getIndex(milestoneId);

    runInAction(() => {
      this.state.selection.primary = milestone ? milestone.id : 'LATEST_VERSION';

      if (fromMilestoneId) {
        if (!this.state.isDiffingEnabled) {
          this.state.isDiffingEnabled = true;
          localStorage.setItem(DIFFING_KEY, this.state.isDiffingEnabled);
        }
        this.state.selection.secondary = fromMilestoneId;
      } else if (this.state.isDiffingEnabled && !this.isLast(index)) {
        this.state.selection.secondary = this.state.milestones[index + 1].id;
      }

      if (this.isLast(index) && !fromMilestoneId) {
        this.state.selection.secondary = null;
        this.resetDiff();
      }
    });

    this.diff();
    this.updateURL(milestoneIds);
  }

  getCallLinkActivityDataByMilestoneId(milestoneId) {
    // Call link activities are only available for BPMN diagrams. Hence we don't
    // expect the logic to work on DMN diagrams, or forms and return
    // early with an empty object.
    if (this.isDMN || this.state.isForm || this.state.isTemplate) {
      return {};
    }

    return milestoneService
      .fetchById(milestoneId)
      .then(async (milestone) => {
        const definitions = await getDefinitions(milestone.content);

        return {
          relationId: getRelationId(definitions),
          processId: getExecutableProcessId(definitions),
          callActivityLinks: getCallActivityLinks(definitions),
          businessRuleTaskLinks: getBusinessRuleTasksLinksFromDefinitions(definitions)
        };
      })
      .catch(() => {
        notificationStore.showError('Could not fetch milestone.');
        return {};
      });
  }

  getCallLinkActivityDataByDiagramId(fileId) {
    if (this.isDMN) {
      return {};
    }

    return fileService
      .fetchById(fileId)
      .then(async (file) => {
        const definitions = await getDefinitions(file.content);

        return {
          relationId: getRelationId(definitions),
          processId: getExecutableProcessId(definitions),
          callActivityLinks: getCallActivityLinks(definitions),
          businessRuleTaskLinks: getBusinessRuleTasksLinksFromDefinitions(definitions)
        };
      })
      .catch(() => notificationStore.showError('Could not fetch your diagram.'));
  }

  /**
   * Creates a new milestone.
   *
   * @param {Object} file The file related to the current diagram.
   * @param {String} name The (optional) name of the milestone.
   * @param {Boolean} append An (optional) parameter used to set given milestone as latest one
   * @param {String} origin Used for tracking purpose
   * @param {Boolean} organizationPublic An (optional) parameter for setting the milestone public to the organization
   */
  create({ file, name, append = false, origin, organizationPublic = false }) {
    return milestoneService.create({ fileId: file.id, name, organizationPublic }).then((milestone) => {
      trackingService.trackCreateMilestone(origin, file, organizationPublic);
      if (append) {
        runInAction(() => {
          this.state.milestones.unshift(milestone);
        });

        this.toggleLatestVersion();
        this.select([milestone.id]);
      }
    });
  }

  async createAutosaved(file, origin, payload) {
    const result = await milestoneService.createAutosaved(file.id, payload);
    trackingService.trackCreateMilestone(origin, file, false);
    return result;
  }

  async restoreRelatedDiagram(diagram) {
    await this.createAutosaved(this.state.diagram, 'restore', {
      name: 'Autosaved during restore'
    });

    await this.fetchAll();
    const { content } = await this.fetch(this.state.milestones[0].id);

    const payload = {
      fileId: diagram.id,
      originAppInstanceId: userStore.originAppInstanceId
    };
    if (isBPMN(this.state.diagram)) {
      const { processId, callActivityLinks, businessRuleTaskLinks } = await this.getCallLinkActivityDataByDiagramId(
        diagram.id
      );
      payload.processId = processId || NO_PROCESS_ID;
      payload.callActivityLinks = callActivityLinks;
      payload.businessRuleTaskLinks = businessRuleTaskLinks;
    } else if (isDMN(this.state.diagram)) {
      payload.decisions = await detectDecisions(content);
    }

    milestoneService
      .restoreRelatedDiagram(this.state.diagram.id, payload)
      .then(async () => {
        await this.fetchAll();

        runInAction(() => {
          this.state.diagram.content = content;
          this.state.isLatestVersionShown = false;
        });

        this.select([this.state.milestones[0].id]);

        notificationStore.showSuccess('The selected diagram has been restored as milestone.');
      })
      .catch(({ status }) => {
        if (status === 403 || status === 404) {
          notificationStore.showError("You don't have permissions to use this diagram as milestone.");
        } else {
          notificationStore.showError('Something went wrong, please try again.');
        }
      });
  }

  /**
   * Restores the diagram to the selected milestone.
   *
   * @param {Object} milestone The milestone to be used for restoring.
   */
  async restoreMilestone(milestone) {
    let name = milestone.name;

    await this.createAutosaved(this.state.diagram, 'restore', {
      name: 'Autosaved during restore'
    });

    if (!name) {
      name = `${moment(milestone.created).format('DD MMMM HH:mm')}${RESTORED_MILESTONE_SUFFIX}`;
    } else if (!name.includes(RESTORED_MILESTONE_SUFFIX)) {
      name = `${name.slice(0, RESTORED_MILESTONE_NAME_MAX_LENGTH)}${RESTORED_MILESTONE_SUFFIX}`;
    }

    const { content } = this.fetch(milestone.id);

    const payload = {
      restoredName: name,
      originAppInstanceId: userStore.originAppInstanceId
    };
    if (isBPMN(this.state.diagram)) {
      const { processId, callActivityLinks, businessRuleTaskLinks } = await this.getCallLinkActivityDataByMilestoneId(
        milestone.id
      );
      payload.processId = processId || NO_PROCESS_ID;
      payload.callActivityLinks = callActivityLinks;
      payload.businessRuleTaskLinks = businessRuleTaskLinks;
    } else if (isDMN(this.state.diagram)) {
      payload.decisions = await detectDecisions(content);
    }

    milestoneService
      .restore(milestone.id, payload)
      .then(async () => {
        await this.fetchAll();

        runInAction(() => {
          this.state.diagram.content = content;
          this.state.isLatestVersionShown = false;
        });

        this.select([this.state.milestones[0].id]);

        notificationStore.showSuccess('Milestone has been restored.');
      })
      .catch(() => notificationStore.showError('Could not restore this milestone.'));
  }

  async createFromMilestone(milestone, targetProjectId, targetFolderId) {
    const { diagram, isTemplate } = this.state;

    try {
      const payload = {
        targetProjectId,
        targetFolderId
      };
      if (isBPMN(this.state.diagram)) {
        const { processId, callActivityLinks, businessRuleTaskLinks } = await this.getCallLinkActivityDataByMilestoneId(
          milestone.id
        );
        payload.processId = processId || NO_PROCESS_ID;
        payload.callActivityLinks = callActivityLinks;
        payload.businessRuleTaskLinks = businessRuleTaskLinks;
      } else if (this.isDMN) {
        payload.decisions = await detectDecisions(diagram.content);
      }
      await milestoneService.createDiagramFromMilestone(milestone.id, payload);

      trackingService.trackCreateFile(
        diagram.id,
        'copy-from-milestone',
        diagram.type,
        targetFolderId ? FOLDER : DEFAULT
      );

      if (isTemplate) {
        notificationStore.showSuccess('Connector Template has been created from this version.');
      } else {
        notificationStore.showSuccess('Diagram has been created from this milestone.');
      }

      return true;
    } catch (ex) {
      if (isTemplate) {
        notificationStore.showError(`Oops! We couldn't create the connector template from this version.`);
      } else {
        notificationStore.showError(`Oops! We couldn't create the diagram from this milestone.`);
      }
      return false;
    }
  }

  /**
   * Deletes a specific milestone.
   *
   * @param {Object} milestone The milestone to be deleted.
   */
  async delete(milestone) {
    let confirmProps = {
      title: 'Delete milestone',
      text: 'If you delete this milestone, it will no longer be available in your history.',
      isDangerous: true,
      confirmLabel: `Delete milestone`
    };

    if (this.state.isTemplate) {
      if (userStore.isOrgOwner && milestone.organizationPublic && this.state.diagram?.name) {
        confirmProps = generateDialogPropsForUnpublishingConnector(
          this.state.diagram.name,
          this.state.milestones,
          milestone.id
        );
      } else {
        ['title', 'text', 'confirmLabel'].forEach(
          (prop) => (confirmProps[prop] = confirmProps[prop].replace('milestone', 'version'))
        );
      }
    }

    const confirmed = await confirmActionStore.confirm(confirmProps);

    if (!confirmed) {
      return;
    }

    milestoneService
      .destroy(milestone.id)
      .then(() => {
        runInAction(() => this.state.milestones.remove(milestone));

        if (this.state.milestones.length === 0) {
          this.state.secondaryJSON = null;
        }

        if (this.getIndex(milestone.id) === this.state.milestones.length - 1) {
          this.go('up');
        } else {
          this.go('down');
        }

        if (this.state.isTemplate) {
          notificationStore.showSuccess('Version has been deleted.');
        } else {
          notificationStore.showSuccess('Milestone has been deleted.');
        }

        this.toggleLatestVersion();
      })
      .catch((error) => {
        if (this.state.isTemplate) {
          let errorMsg = 'There was a problem deleting the version';
          if (
            milestone.publishedOn === PUBLISHED_ON.ORGANIZATION &&
            error?.status === 403 &&
            error?.[0]?.reason === 'INVALID_OPERATION'
          ) {
            errorMsg =
              'This version of the connector is organization public. Only organization owners are allowed to delete it.';
          }
          notificationStore.showError(errorMsg);
        } else {
          notificationStore.showError('There was a problem deleting the milestone');
        }
      });
  }

  /**
   * Renames a specific milestone.
   *
   * @param {Object} milestone The milestone to be renamed.
   * @param {String} name The new milestone name.
   */
  rename(milestone, name) {
    this.setEditingMilestone();
    if (name.trim().length === 0 || name.trim() === milestone.name) {
      return;
    }

    milestoneService
      .rename(milestone.id, { name })
      .then(() => {
        runInAction(() => {
          this.state.milestones[this.getIndex(milestone.id)].name = name;
        });
      })
      .catch(() => notificationStore.showError('Could not rename the milestone.'));
  }

  /**
   * Publishes a specific milestone to the organization
   *
   * @param {Object} milestone The Milestone to be published
   */
  publishToOrganization(milestone) {
    milestoneService
      .publishToOrganization(milestone.id)
      .then(() => {
        trackingService.trackPublicationMilestoneToOrganization({
          from: 'publish',
          fileId: this.state.diagram.id,
          fileType: FILE_TYPE_MAPPING.CONNECTOR_TEMPLATE
        });
        runInAction(() => (this.state.milestones[this.getIndex(milestone.id)].organizationPublic = true));
      })
      .catch(() => notificationStore.showError('Could not publish the milestone to the organization.'));
  }

  /**
   * Retrieves and returns a single milestone, based on the given ID. This function
   * will attempt to load the milestone from the cache first.
   * If not possible, a HTTP request will be made to get the milestone from the server.
   * The newly fetched milestone will then be cached for later use.
   *
   * @param {String} milestoneId The ID of the milestone to retrieve.
   * @returns {Object} The retrieved milestone with XML content.
   */
  fetch(milestoneId) {
    if (this.cache.has(milestoneId)) {
      return this.cache.get(milestoneId);
    }

    return milestoneService
      .fetchById(milestoneId)
      .then((milestone) => {
        runInAction(() => {
          this.cache.set(milestone.id, milestone);
        });

        return milestone;
      })
      .catch(() => notificationStore.showError('There was a problem loading the milestone.'));
  }

  /**
   * Fetches all milestones associated to the current diagram. Displays
   * an error message in case the milestones couldn't be fetched.
   *
   * After all milestones have been fetched, the loading state is set to `false`.
   */
  async fetchAll() {
    try {
      const milestones = await milestoneService.fetchByFileId(this.state.diagram.id);
      runInAction(() => {
        this.state.milestones = milestones;
      });
      await this.toggleLatestVersion();
      await this.highlightLatest();
    } catch (e) {
      notificationStore.showError('There was a problem loading the milestone history.');
    }
  }

  trackMilestoneView = (origin, extraProps) => {
    const user = userStore?.userId;

    trackingService.trackViewFile(origin, user, this.state.diagram, this.modeler, extraProps);
  };

  /**
   * Shows the "Latest version" entry in the milestone sidebar if the diagram's current
   * content is different from the latest milestone or if no milestones have been created yet.
   */
  async toggleLatestVersion() {
    if (!this.hasMilestones) {
      runInAction(() => {
        this.state.isLatestVersionShown = true;
      });

      return;
    }

    const latest = await this.fetch(this.state.milestones[0].id);

    // sometimes contents differ only by a '\n'
    // so we trim the strings before comparing them
    const isContentDifferent = latest.content.trim() !== this.state.diagram.content.trim();

    runInAction(() => {
      this.state.isLatestVersionShown = isContentDifferent;
    });
  }

  /**
   * Fetches and selects the secondary selected milestones for milestone diffing.
   *
   * @param {String} milestoneId The id of the milestone to fetch and select.
   */
  setSecondary(milestoneId) {
    fileService
      .fetchById(milestoneId)
      .then((file) => {
        runInAction(() => {
          this.state.selection.secondary = milestoneId;
        });

        if (file) {
          this.diff(file);
        }
      })
      .catch(() => notificationStore.showError('Could not fetch the secondary diagram.'));
  }

  /**
   * If the latest milestone is different from the current diagram's state,
   * this function will set the `isLatestVersionShown` to `true`. This will
   * show a "Latest Version" in milestone list, referring to the current diagram's
   * content.
   */
  async highlightLatest() {
    const idsToSelect = this.state.milestoneIds;
    if (idsToSelect?.length > 0) {
      await this.select(idsToSelect);
    } else {
      await this.select([this.state.isLatestVersionShown ? 'LATEST_VERSION' : this.state.milestones[0].id], false);
    }

    runInAction(() => {
      this.state.isLoading = false;
    });
  }

  /**
   * Navigates to the next or previous milestone in the list.

   * @param {String} direction Either "up" to select the previous or "down"
   *  to select the next milestone.
   */
  go = (direction) => {
    if (!this.hasMilestones) {
      this.select(['LATEST_VERSION']);
      return;
    } else if (this.state.isKeyboardNavigationLocked) {
      return;
    }

    const index = this.state.milestones.findIndex((milestone) => {
      if (!this.state.selection.primary) {
        return;
      }

      return milestone.id === this.state.selection.primary;
    });

    if (direction === 'up') {
      if (index === 0 && this.state.isLatestVersionShown) {
        this.select(['LATEST_VERSION']);
      } else if (index > 0) {
        this.select([this.state.milestones[index - 1].id]);
      }
    } else if (direction === 'down') {
      if (!this.state.selection.primary) {
        this.select([this.state.milestones[0].id]);
      } else if (index !== this.state.milestones.length - 1) {
        this.select([this.state.milestones[index + 1].id]);
      } else if (index === this.state.milestones.length - 1) {
        this.select([this.state.milestones[index].id]);
      }
    }
  };

  /**
   * Updates the browser's URL when a milestone is selected.
   * This feature is used to make a specific milestone shareable through the URL.
   *
   * @param {String} milestoneId The milestone id to put in the URL.
   */
  updateURL(milestoneIds) {
    const url = location.pathname;
    const getUpdatedUrl = (subPath) => {
      const strippedUrl = url.substring(0, url.indexOf(subPath) + subPath.length);
      let updatedUrl = strippedUrl;
      if (milestoneIds[0] !== 'LATEST_VERSION') {
        updatedUrl = `${strippedUrl}/${milestoneIds?.join('...')}`;
      }
      return updatedUrl;
    };

    const MILESTONES_PATH = 'milestones';
    const CONNECTOR_TEMPLATES_PATH = 'versions';
    if (url.includes(MILESTONES_PATH)) {
      history.replace(getUpdatedUrl(MILESTONES_PATH));
    } else if (url.includes(CONNECTOR_TEMPLATES_PATH)) {
      history.replace(getUpdatedUrl(CONNECTOR_TEMPLATES_PATH));
    }
  }

  /**
   * Loads a given XML string into the modeler, which is then
   * displayed on the page.
   *
   * @param {String} content The XML data.
   */
  importXML(content) {
    return this.modeler?.importXML(this.state.isDiffingEnabled ? shadedDiagram(content) : content);
  }

  /**
   * Shows the difference between two milestones or versions.
   *
   * @param {Object} diagram The original diagram to diff with.
   */
  async diff(diagram) {
    const index = this.getIndex(this.state.selection.primary);

    // For files with JSON content (forms and templates) we don't need the BPMN diffing logic
    // and can just set the `secondaryJSON` property. The CodeEditor will take care of diffing.
    if ((this.state.isForm || this.state.isTemplate) && this.state.selection.secondary && this.state.isDiffingEnabled) {
      const second = await this.fetch(this.state.selection.secondary);

      runInAction(() => {
        this.state.secondaryJSON = second;
      });

      return;
    }

    if (
      !this.state.isDiffingEnabled ||
      (!diagram && !this.state.selection.secondary) ||
      (this.isLast(index) && this.hasMilestones && !this.state.selection.secondary) ||
      !this.modeler
    ) {
      return;
    }

    this.resetDiff();

    let current, previous;

    if (this.state.selection.primary === 'LATEST_VERSION') {
      current = this.state.diagram;
    } else {
      current = await this.fetch(this.state.selection.primary);
    }

    if (diagram) {
      previous = diagram;
    } else {
      previous = await this.fetch(this.state.selection.secondary);
    }

    const diffingResponse = await BPMNDiff(previous.content, current.content);

    setTimeout(() => {
      // we maybe navigated away and have no modeler instance anymore
      if (!this.modeler) {
        return;
      }

      const engineVersionChangeOverlay = drawTargetEngineVersionChange(diffingResponse.targetEngineVersion);
      const addedIcons = drawIcons(diffingResponse.new, 'added', this.modeler);
      const modifiedIcons = drawIcons(diffingResponse.modified, 'changed', this.modeler);

      this.icons = addedIcons.concat(modifiedIcons).concat(engineVersionChangeOverlay);
      this.markers = drawMarkers(diffingResponse, this.modeler.get('canvas'));
    });
  }

  /**
   * Resets the diffing and removes all overlays from the modeler.
   */
  resetDiff() {
    this.icons.forEach((iconCleanupCallback) => iconCleanupCallback());
    this.markers.forEach((markerCleanupCallback) => markerCleanupCallback());

    this.markers = [];
    this.icons = [];
  }

  /**
   * Checks whether the given `milestoneId` is currently selected as primary.
   *
   * @param {String} milestoneId
   * @returns {Boolean}
   */
  isPrimarySelected = (milestoneId) => {
    return milestoneId === this.state.selection.primary;
  };

  /**
   * Checks whether the given `milestoneId` is currently selected as secondary.
   *
   * @param {String} milestoneId
   * @returns {Boolean}
   */
  isSecondarySelected = (milestoneId) => {
    return milestoneId === this.state.selection.secondary;
  };

  /**
   * Checks whether the given `milestoneId` is the latest version.
   *
   * @param {String} milestoneId
   * @returns {Boolean}
   */
  isLatestVersion(milestoneId) {
    return milestoneId === 'LATEST_VERSION';
  }

  /**
   * Checks if the given `index` correlates to the last milestone in the list.
   *
   * @param {Number} index
   * @returns {Boolean}
   */
  isLast(index) {
    return index === this.state.milestones.length - 1;
  }

  /**
   * Checks if the given `milestoneId` is currently being edited.
   *
   * @param {String} milestoneId
   * @returns {Boolean}
   */
  isEditingMilestone = (milestoneId) => {
    return this.state.editingMilestone === milestoneId;
  };

  /**
   * Gets the milestone author's name. If the currently logged in user
   * is also the author, "you" is returned.
   *
   * @param {Object} milestone
   * @returns {String}
   */
  getAuthor(milestone) {
    if (userStore.isCurrentUser({ id: milestone.authorId })) {
      return 'you';
    }

    return milestone.authorName;
  }

  /**
   * Returns the index in the list of milestones for a given `milestoneId`.
   *
   * @param {String} milestoneId
   * @returns {Number}
   */
  getIndex(milestoneId) {
    return this.state.milestones.findIndex(({ id }) => id === milestoneId);
  }

  /**
   * Returns a list of milestones, grouped by date and time.
   *
   * @returns {Array}
   */
  get milestones() {
    const sections = [];
    const { milestones, isLatestVersionShown, diagram, isTemplate } = this.state;

    if (isLatestVersionShown) {
      sections.push(`${isTemplate ? 'Unpublished' : 'Autosave'} | ${formatDateToString(diagram.changed)}`);
      sections.push({
        id: 'LATEST_VERSION',
        name: 'Latest version',
        created: diagram.changed
      });
    }

    milestones.forEach((milestone) => {
      const title = formatDateToString(milestone.created);

      if (!sections.includes(title)) {
        sections.push(title);
      }

      sections.push(milestone);
    });

    if (milestones.length > 0) {
      if (isLatestVersionShown) {
        sections[2] = `${isTemplate ? 'Published' : 'Milestones'}  | ${sections[2]}`;
      } else {
        sections[0] = `${isTemplate ? 'Published' : 'Milestones'}  | ${sections[0]}`;
      }
    }

    return sections;
  }

  generateLatestVersion = (diagram) => {
    return {
      id: 'LATEST_VERSION',
      name: 'Latest version',
      created: diagram.changed
    };
  };

  /**
   * Returns the list of saved milestones (or published versions) grouped by date
   *
   * @returns {Array}
   */
  get milestonesByDate() {
    const sections = [];

    const { isTemplate, milestones } = this.state;
    milestones.forEach((milestone) => {
      // For template versions fixed in the course of https://github.com/camunda/web-modeler/issues/5647,
      // we want to show "In the past" instead of "1 January 1970" as section title
      const title =
        isTemplate && moment(milestone.created).year() === 1970 ? 'In the past' : formatDateToString(milestone.created);
      if (!sections.includes(title)) {
        sections.push(title);
      }
      sections.push(milestone);
    });

    if (milestones.length > 0) {
      sections[0] = `${isTemplate ? 'Published' : 'Milestones'}  | ${sections[0]}`;
    }

    return sections;
  }

  get diagramMilestones() {
    const { isLatestVersionShown, diagram } = this.state;

    // Unsaved milestone
    const unsavedMilestone = [];
    if (isLatestVersionShown) {
      unsavedMilestone.push(`${isTemplate ? 'Unpublished' : 'Autosave'}  | ${formatDateToString(diagram.changed)}`);
      unsavedMilestone.push(this.generateLatestVersion(diagram));
    }

    // Already saved milestones
    const savedMilestones = this.milestonesByDate;

    return unsavedMilestone.concat(savedMilestones);
  }

  /**
   * Returns a list of related files, sorted alphabetically.
   *
   * @returns {Array}
   */
  get relatedFiles() {
    return this.state.relatedFiles
      .slice()
      .sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      })
      .map((file) => {
        return {
          name: file.project.name,
          diagram: file.name,
          lastChanged: file.updated,
          id: file.id
        };
      });
  }

  /**
   * Returns whether there are any milestones or not.
   *
   * @returns {Boolean}
   */
  get hasMilestones() {
    return this.state.milestones.length > 0;
  }

  /**
   * Returns whether there are any related files or not.
   *
   * @returns {Boolean}
   */
  get hasRelatedFiles() {
    return this.state.relatedFiles.length > 0;
  }

  /**
   * Returns whether the milestone list is being loaded or not.
   *
   * @returns {Boolean}
   */
  get isLoading() {
    return this.state.isLoading;
  }

  get isDMN() {
    if (this.state.diagram) {
      return this.state.diagram.type === 'DMN';
    }

    return false;
  }

  get primaryJSON() {
    if (this.state.isDiffingEnabled && this.state.secondaryJSON) {
      if (!this.state.selection.secondary) {
        return this.state.primaryJSON.content;
      }

      return this.state.secondaryJSON.content;
    }

    if (this.state.primaryJSON) {
      return this.state.primaryJSON.content;
    }

    if (this.state.diagram) {
      return this.state.diagram.content;
    }

    return null;
  }

  get secondaryJSON() {
    if (!this.state.selection.secondary) {
      return null;
    }

    if (this.state.isDiffingEnabled) {
      if (this.state.selection.primary === 'LATEST_VERSION' && this.state.diagram) {
        return this.state.diagram.content;
      } else if (this.state.primaryJSON) {
        return this.state.primaryJSON.content;
      }
    }

    return null;
  }

  get titles() {
    if (this.state.isLoading) {
      return ['Loading...', 'Loading...'];
    }
    const { selection, milestones } = this.state;
    let left = '';
    let right = '';

    if (selection.primary) {
      if (selection.primary == 'LATEST_VERSION') {
        right = 'Latest version';
      } else {
        const milestone = milestones.find((milestone) => milestone.id == selection.primary);

        right = milestone && milestone.name;
      }
    }

    if (selection.secondary) {
      const milestone = milestones.find((milestone) => milestone.id == selection.secondary);

      left = milestone && milestone.name;
    }

    return [left, right];
  }
}

export default new MilestoneStore();
