/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import * as Styled from './ListTitle.styled';

const ListTitle = () => {
  return (
    <Styled.ListTitle>
      <Styled.Title data-test="entity-title">Projects</Styled.Title>
    </Styled.ListTitle>
  );
};

export default observer(ListTitle);
