/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import { Stack as BaseStack } from '@carbon/react';
import { CheckmarkFilled } from '@carbon/react/icons';

export const Stack = styled(BaseStack)`
  /* background-inverse */
  background-color: #393939;

  /* text-on-color */
  color: #fff;
  padding: 4px 8px 4px 4px;
  border-radius: 24px;
  align-items: center;
`;

export const Checkmark = styled(CheckmarkFilled)`
  /* support-success */
  fill: #24a148;
`;
