/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';
import { ActionableNotification } from '@carbon/react';

import { FormPreview } from 'components';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';

import * as Styled from './StartForm.styled';

const StartForm = ({ setPayload }) => {
  const startEventWithConflicts = formLinkStore.startEventHasUnresolvedFormConflicts;
  const formContent = formLinkStore.startEventFormContent;

  const handleFormPreviewChange = ({ data, errors }) => {
    if (Object.keys(errors).length === 0) {
      setPayload(JSON.stringify(data));
    }
  };

  if (startEventWithConflicts) {
    return (
      <div>
        <ActionableNotification kind="warning" title="Unable to render form preview" inline lowContrast hideCloseButton>
          The form ID associated with the start event is linked to multiple forms sharing the same ID. To proceed,
          select the form you intend to use as the start event.
        </ActionableNotification>
      </div>
    );
  }

  return formContent ? (
    <div>
      <Styled.FormPreviewContainer>
        <FormPreview bordered formDefinition={formContent} onChange={handleFormPreviewChange} />
      </Styled.FormPreviewContainer>
    </div>
  ) : (
    ''
  );
};

export default observer(StartForm);
