/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { DEFAULT_CLUSTER_INFO } from 'App/Pages/Diagram/Deployment/executeModal/sm/constants';
import { useLocalStorage } from 'hooks';

export default () => {
  const [clusterInfo, setClusterInfo] = useLocalStorage('clusterInfo', DEFAULT_CLUSTER_INFO);

  const setClusterInfoField = (key, value) => {
    setClusterInfo({ ...clusterInfo, [key]: value });
  };

  return { clusterInfo, setClusterInfo, setClusterInfoField };
};
