/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Header = styled.div`
  height: 135px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 65px;
  border-bottom: 1px solid var(--silver-darken-87);
  background-color: var(--cm-color-ui-light1);
`;

export const HeaderText = styled.p`
  font-size: 19px;
  color: var(--grey-darken-23);
  margin: 0 0 0 15px;
`;
