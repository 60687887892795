/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Sidebar } from 'primitives';
import { MilestonesDiffingToggle, MilestonesSection } from 'components';

import * as Styled from './Sidebar.styled';

export default function MilestoneSidebar() {
  return (
    <Sidebar $isVisible>
      <Sidebar.Inner>
        <Styled.SidebarSection>
          <Sidebar.Subtitle>Milestones</Sidebar.Subtitle>
          <MilestonesSection />
        </Styled.SidebarSection>
      </Sidebar.Inner>
      <MilestonesDiffingToggle />
    </Sidebar>
  );
}
