/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';

import ProjectActionButton from './ProjectActionButton';

export default function ActionButton({ label, variant, onUpload }) {
  const { project } = projectStore;

  if (!hasAccess(project, actions.CREATE_DIAGRAM)) {
    return null;
  }

  return <ProjectActionButton label={label} variant={variant} onUpload={onUpload} />;
}
