/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import detectConnectorsStore from './DetectConnectorsStore';
import { convertConnectorId } from './utils';

export default function DetectConnectors(eventBus, elementRegistry) {
  eventBus.on('canvas.destroy', () => {
    detectConnectorsStore.setConnectors([]);
  });

  eventBus.on('import.done', () => {
    getConnectors();
  });

  eventBus.on('commandStack.changed', () => {
    getConnectors();
  });

  const getConnectors = () => {
    const connectors = [];
    elementRegistry.forEach((el) => {
      let connector = null;
      if (getBusinessObject(el)?.hasOwnProperty('modelerTemplate')) {
        connector = getBusinessObject(el)?.modelerTemplate;
      } else if (getBusinessObject(el)?.$attrs?.hasOwnProperty('zeebe:modelerTemplate')) {
        connector = getBusinessObject(el)?.$attrs['zeebe:modelerTemplate'];
      }

      connector = convertConnectorId(connector);

      if (connector && !connectors.includes(connector)) {
        connectors.push(connector);
      }
    });
    detectConnectorsStore.setConnectors(connectors);
  };
}

DetectConnectors.$inject = ['eventBus', 'elementRegistry'];
