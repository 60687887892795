/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { notificationStore } from 'stores';
import { trackingService } from 'services';
import { sanitizeName } from 'utils/helpers';

import saveFile from './save-file';

/**
 * Downloads a given form's JSON to the user's computer.
 *
 * @param {Object} form
 * @param {String} [form.id] The form's id
 * @param {String} [form.content] The form's stringified JSON content.
 * @param {String} [form.name] The form's name.
 */
export default function exportForm({ id, content, name }) {
  try {
    const fileName = `${sanitizeName(name)}.form`;

    saveFile(content, fileName);

    notificationStore.showSuccess(`"${fileName}" is being downloaded to your computer.`);
    trackingService.trackFileExport(id, 'FORM', 'json');
  } catch (ex) {
    notificationStore.showError('Yikes! Something went wrong while preparing your download. Please try again later.');
  }
}
