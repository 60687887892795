/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { ActionableNotification } from '@carbon/react';
import styled, { css } from 'styled-components';

const ListedItemStyle = `
  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
  }

  padding: 12px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const FileNameFontStyle = `
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.15px;
`;

const CarbonOverrides = `
  .cds--radio-button-group--vertical .cds--radio-button-wrapper {
    margin-block-end: 0;
  }

  .cds--radio-button__appearance {
    margin-right: 6px;
  }
`;

export const LinkedFormsContainer = styled.div`
  margin: var(--spacing-medium) 0;
  padding: var(--spacing-medium) 0;
  border-color: #c6c6c6;
  border-style: solid;
  border-width: 1px 0;
`;

export const LinkedFormsList = styled.div`
  border: 1px solid #e0e0e0;
  padding: 16px;
`;

export const MultipleFormsGroupTitle = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2px;
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-small);

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--cm-color-black-secondary-text);

  svg {
    margin-right: 13px;
    fill: #f1c21c;
  }
`;

export const MultipleFormsGroup = styled.div`
  ${ListedItemStyle}

  &:first-child {
    ${MultipleFormsGroupTitle} {
      margin-top: 0;
    }
  }

  fieldset {
    margin-top: var(--spacing-small);
    width: 100%;
    // This is needed to make the fieldset expand to the full width of the container.
    min-width: 0;

    & > div {
      width: 100%;
    }
  }

  ${CarbonOverrides}
`;

const ArrowIconOffset = 40;

export const FormName = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;

  white-space: nowrap;
  overflow: hidden;

  ${FileNameFontStyle}

  max-width: calc(100% - ${ArrowIconOffset}px);

  & > span {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const LinkedForm = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  ${ListedItemStyle}

  & .link-icon {
    position: absolute;
    right: ${ArrowIconOffset}px;
  }

  ${(props) =>
    props.$isAnOption &&
    css`
      & .link-icon {
        right: 0px;
      }

      & ${FormName} {
        max-width: 90%;
      }
    `}
`;

export const InfoMessage = styled(ActionableNotification)`
  margin-top: var(--spacing-small);
`;
