/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Dialog as PrimitiveDialog } from 'primitives';

export const Dialog = styled(PrimitiveDialog)`
  width: 630px;
`;

export const Content = styled(PrimitiveDialog.Content)`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const CheckmarkItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;
