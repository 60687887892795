/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { runInAction } from 'mobx';

export const PUBLISHED_ON = {
  PROJECT: 'project',
  ORGANIZATION: 'organization'
};

/**
 * Alters (and returns) the original milestone list, populating for each of them the field publishedOn
 * @param milestones list of milestones: the first one is the most recent
 * @returns {object[]} the altered list of milestones, with the parameter publishedOn set to null if related milestone should not be marked;
 * otherwise set to `PUBLISHED_ON.PROJECT` | `PUBLISHED_ON.ORGANIZATION`
 */
export const publishedOn = (milestones) => {
  if (!milestones || !milestones.length) {
    return [];
  }

  let firstOrganizationPublicMet = true;
  let firstProjectPrivateMet = true;

  for (let i = 0; i < milestones.length; i++) {
    const milestone = milestones[i];
    if (!milestone.id) {
      continue;
    }
    let publishedOnField = null;
    const isOrganizationPublic = milestone.organizationPublic === true;
    const isProjectPrivate = milestone.organizationPublic === false;

    if (isOrganizationPublic && firstOrganizationPublicMet) {
      firstOrganizationPublicMet = false;
      publishedOnField = PUBLISHED_ON.ORGANIZATION;
    } else if (isProjectPrivate && firstProjectPrivateMet && firstOrganizationPublicMet) {
      firstProjectPrivateMet = false;
      publishedOnField = PUBLISHED_ON.PROJECT;
    }

    runInAction(() => (milestone.publishedOn = publishedOnField));
  }

  return milestones;
};
