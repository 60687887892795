/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import minimapModule from 'diagram-js-minimap';

import { Modeler, DiffingDetailsModal, DiagramControls } from 'components';
import { Spinner } from 'primitives';
import { shadedDiagram } from 'utils/diffing';
import { milestoneStore, diagramControlStore, diffingStore, notificationStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { projectService, fileService, trackingService, folderService } from 'services';
import { isDMN } from 'utils/helpers';

import Sidebar from './Sidebar';
import Header from './Header';
import * as Styled from './DiagramMilestones.styled';

const DiagramMilestones = () => {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  const { isDiffingEnabled, diagram } = milestoneStore.state;

  useEffect(() => {
    trackingService.trackPageView('milestone');
    init();

    return () => {
      milestoneStore.reset();
      diffingStore.reset();
      diagramControlStore.reset();
    };
  }, [params.id]);

  const init = async () => {
    setLoading(true);

    try {
      const file = await fileService.fetchById(params.id);
      const requests = [projectService.fetchById({ projectId: file.projectId })];
      if (file.folderId) {
        requests.push(folderService.fetchById(file.folderId));
      }
      const [project, folder] = await Promise.all(requests);
      if (!hasAccess(project, actions.VIEW_VERSIONS)) {
        return history.push(`/diagrams/${params.id}`);
      }
      const relatedFiles = await fileService.fetchRelatedFiles(file.id);
      const milestoneIdsFromUrl = params.slug?.split('...');
      let milestoneIds = milestoneIdsFromUrl;
      if (isDMN(file) && milestoneIds?.length > 1) {
        milestoneIds = [milestoneIdsFromUrl[0]];
      }

      milestoneStore.init({ ...file, folder }, project, relatedFiles, milestoneIds);

      setLoading(false);
    } catch (ex) {
      notificationStore.showError('Yikes! Could not load the milestone data. Please try again later.');
    }
  };

  const additionalModules = () => {
    if (milestoneStore.isDMN) {
      return { drd: [minimapModule] };
    } else {
      return [minimapModule];
    }
  };

  return (
    <Styled.Container>
      {loading ? (
        <Spinner fullHeight />
      ) : (
        <Fragment>
          <Header />
          <Modeler
            diagram={diagram}
            initialContent={isDiffingEnabled ? shadedDiagram(diagram.content) : diagram.content}
            isViewOnly
            additionalModules={additionalModules()}
            bottomRight={<DiagramControls />}
            onModelerLoaded={(modeler) => {
              diagramControlStore.setModeler(modeler);
              milestoneStore.setModeler(modeler);
              milestoneStore.fetchAll();
            }}
            onModelerInit={() => {
              milestoneStore.trackMilestoneView('milestone-history');
            }}
          />
          <Sidebar />
          <DiffingDetailsModal />
        </Fragment>
      )}
    </Styled.Container>
  );
};

export default observer(DiagramMilestones);
