/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

const colors = {
  warning: 'var(--orange-base-60)',
  danger: 'var(--red-base-62)',
  success: 'var(--green-base-44)',
  info: 'var(--cds-blue-60)'
};

export const Wrapper = styled.div`
  display: flex;
  padding: var(--spacing-small);
  border-radius: 4px;
  align-items: center;
  margin-bottom: var(--spacing-small);
  ${({ type }) => `
    border: 1px solid ${colors[type]};
    background: white;
    
    > svg {
      margin-right: var(--spacing-small);
      color: ${colors[type]};
    }
  `};
`;
