/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

class IsDeployedModalVisibleStore {
  isVisible = false;
  processId = null;
  decisionIds = null;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.processId = null;
    this.decisionIds = null;
  };

  setProcessId = (processId) => {
    this.processId = processId;
  };

  setDecisionIds = (decisionIds) => {
    this.decisionIds = Array.isArray(decisionIds) ? decisionIds : [decisionIds];
  };

  show = () => {
    this.#setIsVisible(true);
  };

  hide = () => {
    this.#setIsVisible(false);
  };

  #setIsVisible = (val) => {
    this.isVisible = val;
  };
}

export default new IsDeployedModalVisibleStore();
