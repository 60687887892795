/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';

import * as Styled from './Sidebar.styled';

Styled.Sidebar.propTypes = {
  $isVisible: PropTypes.bool.isRequired
};

Styled.Sidebar.Inner = Styled.Inner;
Styled.Sidebar.Section = Styled.Section;
Styled.Sidebar.Title = Styled.Title;
Styled.Sidebar.Subtitle = Styled.Subtitle;
Styled.Sidebar.Separator = Styled.Separator;
Styled.Sidebar.Toggle = Styled.Toggle;
Styled.Sidebar.Footer = Styled.Footer;

export default Styled.Sidebar;
