/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TargetSelector } from 'components';
import { projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { isFile } from 'utils/helpers';

export default function getBatchActions() {
  const history = useHistory();
  const { folder, isFolder, project } = projectStore;

  const [targetSelectorParams, setTargetSelectorParams] = useState({
    moveAnchorEl: null,
    selection: [],
    invalidTargetIds: []
  });

  const handleMove = async (target) => {
    setTargetSelectorParams((params) => ({ ...params, moveAnchorEl: null }));

    const selection = targetSelectorParams.selection.map((project) => project.entityAction);

    if (target.projectId) {
      if (await projectStore.moveEntities(selection, target.projectId, target.id)) {
        history.push(`/folders/${target.id}`);
      }
    } else {
      if (await projectStore.moveEntities(selection, target.id)) {
        history.push(`/projects/${target.id}`);
      }
    }
  };

  return {
    batchActions: [
      {
        action: (selection, _, evt) => {
          setTargetSelectorParams({
            selection,
            invalidTargetIds: [...selection.map(({ id }) => id), isFolder ? folder.id : project.id],
            moveAnchorEl: evt.target
          });
        },
        isAllowed: () => hasAccess(project, actions.MODIFY_DIAGRAM),
        title: 'Move'
      },
      {
        action: (selection, clearSelection) => {
          projectStore.duplicateFiles(selection);
          clearSelection();
        },
        isAllowed: (selection) =>
          hasAccess(project, actions.MODIFY_DIAGRAM) && selection.every((project) => isFile(project.entityAction)),
        title: 'Duplicate'
      },
      {
        action: (selection) =>
          projectStore.deleteEntities(
            selection.map((project) => project.entityAction),
            true
          ),
        isAllowed: () => hasAccess(project, actions.DELETE_DIAGRAM),
        title: 'Delete'
      }
    ],
    targetSelector: targetSelectorParams.moveAnchorEl && (
      <TargetSelector
        align="bottom"
        anchorEl={targetSelectorParams.moveAnchorEl}
        open={Boolean(targetSelectorParams.moveAnchorEl)}
        startingPoint={isFolder ? folder : project}
        invalidTargetIds={targetSelectorParams.invalidTargetIds}
        onSubmit={handleMove}
        handleClickOutside={() => setTargetSelectorParams((params) => ({ ...params, moveAnchorEl: null }))}
      />
    )
  };
}
