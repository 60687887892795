/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Loading = styled.div`
  position: absolute;
  top: 57px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--silver-base-97);
  z-index: 2;
`;
