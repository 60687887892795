/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';

import { INPUT_KEY } from 'App/Pages/Diagram/Deployment/executeModal/sm/constants';
import zeebeClientService from 'App/Pages/Diagram/Deployment/executeModal/sm/ZeebeClientService';
import { zeebeClientStore } from 'App/Pages/Diagram/Deployment/executeModal/sm';

export default (clusterInfo) => {
  const [connectionErrors, setConnectionErrors] = useState({});

  // Clear connection error when some field changes
  useEffect(() => {
    if (Object.keys(connectionErrors).length) {
      setConnectionErrors({});
    }
  }, [clusterInfo]);

  const checkConnection = async () => {
    const result = await zeebeClientService.checkConnection(clusterInfo);
    if (!result.success) {
      const newConnectionErrors = {};
      [INPUT_KEY.ENDPOINT, INPUT_KEY.OAUTH_URL, INPUT_KEY.CLIENT_ID, INPUT_KEY.CLIENT_SECRET].forEach((fieldName) => {
        const errorMessage = getConnectionError(fieldName, result.reason);
        if (errorMessage) {
          newConnectionErrors[fieldName] = errorMessage;
        }
      });

      setConnectionErrors(newConnectionErrors);
      return newConnectionErrors;
    } else {
      zeebeClientStore.setCheckConnectionResponse(result);
    }
  };

  return { connectionErrors, checkConnection };
};

const ERROR_REASONS = {
  UNKNOWN: 'UNKNOWN',
  CONTACT_POINT_UNAVAILABLE: 'CONTACT_POINT_UNAVAILABLE',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  OAUTH_URL: 'OAUTH_URL',
  UNSUPPORTED_ENGINE: 'UNSUPPORTED_ENGINE',
  INVALID_CLIENT_ID: 'INVALID_CLIENT_ID',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS'
};

const CONNECTION_ERROR_MESSAGES = {
  [ERROR_REASONS.CONTACT_POINT_UNAVAILABLE]: 'Cannot connect to Zeebe cluster.',
  [ERROR_REASONS.CLUSTER_UNAVAILABLE]: 'Cannot connect to Zeebe cluster.',
  [ERROR_REASONS.UNAUTHORIZED]: 'Credentials rejected by server.',
  [ERROR_REASONS.FORBIDDEN]:
    'This user is not permitted to deploy. Please use different credentials or get this user enabled to deploy.',
  [ERROR_REASONS.OAUTH_URL]: 'Cannot connect to OAuth service.',
  [ERROR_REASONS.UNKNOWN]: 'Unknown error. Please check Zeebe cluster status.',
  [ERROR_REASONS.UNSUPPORTED_ENGINE]: 'Unsupported Zeebe version.',
  [ERROR_REASONS.INVALID_CLIENT_ID]: 'Invalid Client ID.',
  [ERROR_REASONS.INVALID_CREDENTIALS]: 'The client secret is not valid for the Client ID provided.'
};

const TROUBLESHOOTING_URL =
  'https://docs.camunda.io/docs/self-managed/modeler/web-modeler/troubleshooting/troubleshoot-zeebe-connection/';

function getConnectionError(fieldName, failureReason) {
  const errorWithTroubleshootingLink = (
    <>
      {CONNECTION_ERROR_MESSAGES[failureReason]}{' '}
      <a href={TROUBLESHOOTING_URL} target="_blank" rel="noreferrer">
        Troubleshoot
      </a>
    </>
  );

  switch (failureReason) {
    case ERROR_REASONS.CONTACT_POINT_UNAVAILABLE:
      return fieldName === INPUT_KEY.ENDPOINT && errorWithTroubleshootingLink;
    case ERROR_REASONS.UNSUPPORTED_ENGINE:
      return [INPUT_KEY.ENDPOINT].includes(fieldName) && CONNECTION_ERROR_MESSAGES[failureReason];
    case ERROR_REASONS.UNAUTHORIZED:
    case ERROR_REASONS.FORBIDDEN:
      return (
        [INPUT_KEY.CLIENT_ID, INPUT_KEY.CLIENT_SECRET].includes(fieldName) && CONNECTION_ERROR_MESSAGES[failureReason]
      );
    case ERROR_REASONS.OAUTH_URL:
      return fieldName === INPUT_KEY.OAUTH_URL && CONNECTION_ERROR_MESSAGES[failureReason];
    case ERROR_REASONS.UNKNOWN:
      return [INPUT_KEY.ENDPOINT].includes(fieldName) && errorWithTroubleshootingLink;
  }
}
