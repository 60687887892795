/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action, runInAction, makeObservable } from 'mobx';
import PropTypes from 'prop-types';

import { Input } from 'primitives';

import { FormContext } from '.';

const withFormContext = (Component) => (props) => (
  <FormContext.Consumer>{(context) => <Component {...context} {...props} />}</FormContext.Consumer>
);

class Field extends Component {
  static defaultProps = {
    type: 'text',
    value: ''
  };

  static propTypes = {
    name: PropTypes.string.isRequired
  };

  error = false;
  value = this.props.value;

  constructor(props) {
    super(props);

    makeObservable(this, {
      error: observable,
      value: observable,
      handleChange: action,
      handleBlur: action
    });
  }

  /**
   * When a TextField is mounted, it is initially validated (since
   * it could receive an invalid default value). It then is registered to
   * its parent form, which will keep track of its status and value.
   */
  componentDidMount() {
    const isValid = Boolean(!this.props.rules || this.props.value);

    this.props.register(this.props.name, this.value, isValid);
  }

  /**
   * When the parent form re-renders, the value prop might still be cached
   * and would not update correctly. Therefore, we need to update the `value`
   * manually if props changed.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      runInAction(() => {
        this.value = this.props.value;
      });
    }
  }

  /**
   * Determines if the TextFields value has changed.
   *
   * @param {String} value The new value to compare.
   * @return {Boolean}
   */
  hasChangedValue = (value) => {
    return value != this.props.value;
  };

  /**
   * Each time the input's value is changed, it is validated and the
   * parent form is updated about its status.
   *
   * Also, if the input is invalid, `error` will be set to true.
   *
   * @param {SyntheticEvent} evt The change event that is fired from the input.
   */
  handleChange = (evt) => {
    this.value = evt.target.value;

    const isValid = evt.target.checkValidity();
    const hasChanged = this.hasChangedValue(this.value);

    if (isValid) {
      this.error = false;
    }

    this.props.update(this.props.name, this.value, isValid, hasChanged);
  };

  /**
   * Each time the input looses focus (e.g. a user clicking somewhere else),
   * it will validate and set `error` to true in case it's not valid.
   *
   * However, this validation only happens if the user has entered at least
   * 1 character.
   *
   * @param {SyntheticEvent} evt The blur event that is fired from the input.
   */
  handleBlur = (evt) => {
    this.error = !evt.target.checkValidity();
  };

  /**
   * If the TextField received a label prop, it will create stripped and cleaned
   * string to use as an ID and reference to this TextField.
   * If no label prop was passed, it'll just use the name prop, which is required.
   *
   * @return {String} The cleaned label.
   */
  get label() {
    if (this.props.label) {
      return this.props.label.toLowerCase().replace(/\s/g, '');
    }

    return this.props.name;
  }

  /**
   * Since the rules are passed as a String, the parent form
   * will convert them into an object, which we'll append to the input.
   * This object contains valid HTML5 validation attributes.
   *
   * @return {Object} The parsed validation attributes.
   */
  get rules() {
    return this.props.getValidationRules(this.props.rules);
  }

  render() {
    /* eslint-disable */
    const { rules, getValidationRules, register, update, isInlined, ...rest } = this.props;
    /* eslint-enable */

    return (
      <Input
        {...rest}
        value={this.value}
        error={this.error}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        {...this.rules}
      />
    );
  }
}

export const TextField = withFormContext(observer(Field));
