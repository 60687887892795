/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import { CloseFilled, ErrorFilled, Connect } from '@carbon/react/icons';

import { Button } from 'primitives';
import { Success } from 'icons';

export const EmptyState = styled.div`
  height: 30px;
  margin: 5px 10px;
`;

export const SuccessIcon = styled(Success)`
  width: 20px;
  vertical-align: middle;
`;

export const EmptyStateMessage = styled.span`
  margin-left: 5px;
  vertical-align: middle;
`;

export const NotFoundIcon = styled(CloseFilled)`
  margin-right: 16px;
  fill: #da1e28;
  float: left;
`;

export const DeploymentErrorIcon = styled(ErrorFilled)`
  margin-right: 16px;
  fill: #da1e28;
  float: left;
`;

export const ConnectorIconContainer = styled.div`
  margin-right: 16px;
  width: 16px;
  height: 16px;
  background-color: #da1e28;
  float: left;
  padding: 1px;
`;

export const ConnectorErrorIcon = styled(Connect)`
  fill: white;
  width: 14px;
  height: 14px;
`;

export const UpdateSecretButton = styled(Button)`
  height: 18px;
  color: var(--link-link-primary, #0f62fe);
  font-weight: 400;
`;
