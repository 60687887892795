/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { action, observable, makeObservable } from 'mobx';

const DEFAULT_STATE = {
  isDialogVisible: false,
  dialogProps: {}
};

class ConfirmActionStore {
  promise = null;

  state = Object.assign(DEFAULT_STATE);

  constructor() {
    makeObservable(this, {
      state: observable,
      cancel: action,
      accept: action,
      confirm: action
    });
  }

  cancel = () => {
    this.state.isDialogVisible = false;
    this.resolve(false);
  };

  accept = () => {
    this.state.isDialogVisible = false;
    this.resolve(true);
  };

  async confirm(dialogProps) {
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });

    this.state.dialogProps = dialogProps;
    this.state.isDialogVisible = true;

    return this.promise;
  }
}

export default new ConfirmActionStore();
