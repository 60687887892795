/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { isKeycloakAuthTypeEnabled } from 'utils/helpers';

import * as Styled from './MultiSelectInput.styled';

export default function UserNotFound() {
  return (
    <>
      <Styled.EmptyStateTitle>User not found!</Styled.EmptyStateTitle>
      <Styled.EmptyStateContent>
        <Styled.EmptyStateSubtitle>
          {isKeycloakAuthTypeEnabled()
            ? 'To invite other people, please get in touch with your administrator to have them added to Web Modeler first.'
            : 'To invite collaborators, they must have logged in to Web Modeler at least once.'}
        </Styled.EmptyStateSubtitle>
      </Styled.EmptyStateContent>
    </>
  );
}
