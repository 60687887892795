/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable, runInAction } from 'mobx';
import { Form } from '@bpmn-io/form-js';
import { createCamundaFormPlayground } from '@camunda/form-playground';

import { performLinting as performLintingUtil } from 'App/Pages/Form/lint-util';
import { fileService, projectService, trackingService, folderService } from 'services';
import { notificationStore, breadcrumbStore, confirmActionStore, userStore } from 'stores';
import history from 'utils/history';
import throttle from 'utils/throttle-async';
import { validateFiles } from 'utils/file-io';
import {
  DEFAULT,
  DEFAULT_ZEEBE_VERSION_FORMS,
  EXECUTION_PLATFORM,
  EXPORTER,
  FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION,
  FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION,
  FORM,
  BPMN_EVENTS_PRIORITY
} from 'utils/constants';
import hasAccess, { actions } from 'utils/user-access';
import { versionShort } from 'utils/version';
import buildSlug from 'utils/buildSlug';

class FormStore {
  executionPlatformVersion = DEFAULT_ZEEBE_VERSION_FORMS;
  project = null;
  form = null;
  loading = true;
  saving = false;
  formEditor = null;
  formPlayground = null;
  lastSaved = null;
  isEmpty = false;
  ignoreNextUpdateCall = false;

  lintErrors = [];

  constructor() {
    makeAutoObservable(this);
  }

  init = async (formId, setLayout, triggeredByRef, editorAdditionalModules) => {
    await this.loadFileFolderAndProject(formId);
    await this.initFormPlaygroundOrViewer(editorAdditionalModules);
    runInAction(() => (this.loading = false));
    this.performLinting();

    if (this.hasEditPermission) {
      this.formEditor.on('changed', BPMN_EVENTS_PRIORITY.LOW, this.update);
      this.formPlayground.on('formPlayground.layoutChanged', ({ layout }) => {
        setLayout(layout);

        const trackLayoutChange = () => {
          const isRestoringPreviousLayout = triggeredByRef.current === 'restore';
          if (!isRestoringPreviousLayout) {
            const triggeredBy = triggeredByRef.current ? triggeredByRef.current : 'previewPanel';
            trackingService.trackFormEditorLayoutChanged(layout, triggeredBy, this.form.id);
          }
          triggeredByRef.current = null;
        };
        trackLayoutChange();
      });
    }
  };

  reset = () => {
    this.trackFormClose();
    this.loading = true;
    this.project = null;
    this.form = null;
    this.saving = false;
    this.ignoreNextUpdateCall = false;
    this.lastSaved = null;
    this.formPlayground?.detach();
    this.formPlayground?.getEditor()?.get('eventBus')?.fire('detach'); // notify current dragula instance to properly destroy form editor
    this.formPlayground = null;
    this.formEditor = null;
    this.isEmpty = false;
    this.resetLintingErrors();
  };

  loadFileFolderAndProject = async (formId) => {
    try {
      const file = await fileService.fetchById(formId);
      const requests = [projectService.fetchById({ projectId: file.projectId })];
      if (file.folderId) {
        requests.push(folderService.fetchById(file.folderId));
      }

      const [project, folder] = await Promise.all(requests);

      runInAction(() => {
        this.form = { ...file, folder, content: file.content };
        this.project = project;
      });
    } catch (ex) {
      notificationStore.showError('Yikes! Could not load your form data. Please try again later.');
    }
  };

  initFormPlaygroundOrViewer = async (editorAdditionalModules) => {
    const parsedContent = this.getParsedContent(this.form?.content);
    if (this.hasEditPermission) {
      await this.initFormPlayground(parsedContent, editorAdditionalModules);
    } else {
      this.initFormViewer();
    }
    this.formEditor.importSchema(parsedContent);
  };

  initFormPlayground = async (parsedContent, editorAdditionalModules) => {
    const formPlayground = await createCamundaFormPlayground({
      exporter: {
        name: EXPORTER,
        version: versionShort
      },
      schema: parsedContent,
      editorAdditionalModules
    });

    runInAction(() => {
      this.formEditor = formPlayground.getEditor();
      this.formPlayground = formPlayground;
    });
  };

  initFormViewer = async () => {
    runInAction(() => {
      this.formEditor = new Form({
        properties: {
          readOnly: true
        }
      });
    });
  };

  get hasEditPermission() {
    return hasAccess(this.project, actions.MODIFY_FORM);
  }

  getParsedContent = (formContent) => {
    try {
      const parsedContent = JSON.parse(formContent);
      this.initExecutionPlaformVersion(parsedContent);
      this.setExecutionPlatformIfNotPresent(parsedContent);
      this.isEmpty = parsedContent?.components?.length === 0;
      return parsedContent;
    } catch (error) {
      notificationStore.showError('Yikes! Something went wrong while parsing the form.');
    }
  };

  resetLintingErrors() {
    this.lintErrors = [];
  }

  trackFormClose() {
    if (!this.form) {
      return;
    }

    trackingService.trackCloseFile(this.form.id, 'FORM', this.form.content);
  }

  update = throttle(async () => {
    if (this.ignoreNextUpdateCall) {
      runInAction(() => {
        this.ignoreNextUpdateCall = false;
      });
      return;
    }

    runInAction(() => (this.saving = true));

    const schema = this.formEditor.saveSchema();

    this.isEmpty = schema?.components?.length === 0;

    try {
      const file = await fileService.update(this.form.id, {
        name: this.form.name,
        content: this.#formatJSON(schema),
        revision: this.form.revision,
        originAppInstanceId: userStore.originAppInstanceId
      });
      trackingService.trackEditFile({ fileId: file.id, type: 'FORM' });

      runInAction(() => {
        this.lastSaved = new Date();
        this.form.revision = file.revision;
        this.form.content = file.content;
      });

      this.performLinting();
    } catch (error) {
      await this.reloadForm();
      if (error.status === 409) {
        notificationStore.showError(FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION);
      } else {
        notificationStore.showError(FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION);
      }
    } finally {
      runInAction(() => (this.saving = false));
    }
  });

  async reloadForm() {
    const file = await fileService.fetchById(this.form.id);

    if (file?.content) {
      runInAction(() => {
        this.form.revision = file.revision;
        this.form.content = file.content;

        // importing the form will trigger a "changed" event on the formEditor
        // this would cause another call to `update`. The payload of this call
        // still has the old form content that would overwrite the change from
        // the other user. We therefore need to ignore the next update call that
        // is triggered by importing the reloaded form
        this.ignoreNextUpdateCall = true;
      });

      const formObj = this.getParsedContent(file.content);
      this.formEditor.importSchema(formObj);

      this.performLinting();
    }
  }

  rename = async (newName) => {
    newName = newName.trim();
    breadcrumbStore.finishEditing();

    if (newName.length === 0 || newName === this.form.name) {
      return;
    }

    try {
      const file = await fileService.update(this.form.id, {
        name: newName,
        revision: this.form.revision,
        originAppInstanceId: userStore.originAppInstanceId
      });

      runInAction(() => {
        this.form.name = newName;
        this.form.revision = file.revision;
      });
    } catch (ex) {
      notificationStore.showError('Yikes! Could not rename your form. Please try again later.');
    }
  };

  delete = async () => {
    breadcrumbStore.toggleDropdownVisibility();

    try {
      await fileService.destroyDryRun([this.form.id]);
    } catch (error) {
      if (error.status == 404) {
        return notificationStore.showError("You don't have permission to delete this form.");
      } else {
        return notificationStore.showError("Yikes! Your form couldn't be deleted. Please try again later.");
      }
    }

    const confirmed = await confirmActionStore.confirm({
      title: 'Deleting form',
      text: 'Are you sure you want to delete this form from the project?',
      confirmLabel: 'Delete form',
      isDangerous: true
    });

    if (confirmed) {
      try {
        await fileService.destroy([this.form.id]);

        notificationStore.showSuccess('Your form has been deleted.');
        trackingService.trackDeleteEntities(DEFAULT, 1);

        if (this.form.folder) {
          history.push(`/folders/${this.form.folder.id}`);
        } else {
          history.push(`/projects/${this.project.id}`);
        }
      } catch (ex) {
        notificationStore.showError("Yikes! Couldn't remove your form. Please try again later.");
      }
    }
  };

  duplicate = async () => {
    breadcrumbStore.toggleDropdownVisibility();

    try {
      const files = await fileService.duplicate([this.form.id]);

      notificationStore.showSuccess('Your form has been duplicated. You are currently in the duplicated version.');
      history.push(`/forms/${buildSlug(files[0].id, files[0].name)}`);
    } catch (ex) {
      notificationStore.showError("Yikes! Couldn't duplicate your form. Please try again later.");
    }
  };

  upload = async (files) => {
    breadcrumbStore.toggleDropdownVisibility();

    if (files.length > 1) {
      return notificationStore.showError('You can only upload one single file to replace this form.');
    }

    const { valid, invalid } = await validateFiles(files, [FORM]);

    if (invalid.length > 0) {
      notificationStore.showError(
        `"${invalid[0].name}" is not a valid Zeebe form and can't be uploaded. Please choose another file.`
      );
    }

    if (valid.length > 0) {
      const confirmed = await confirmActionStore.confirm({
        title: 'File upload',
        text: "Uploading this file will replace the current form and you won't be able to restore it. Do you want to continue?",
        isDangerous: true,
        confirmLabel: 'Yes, replace this form'
      });

      if (confirmed) {
        notificationStore.showSuccess(`"${valid[0].name}" is being uploaded to replace this form.`);

        const formObj = this.getParsedContent(valid[0].content);
        this.formEditor.importSchema(formObj);

        this.performLinting();
      }
    }
  };

  initExecutionPlaformVersion = (formObj) => {
    const { executionPlatformVersion } = formObj;

    if (executionPlatformVersion) {
      this.executionPlatformVersion = executionPlatformVersion;
    }
  };

  setExecutionPlatformIfNotPresent = (formObj) => {
    if (
      !formObj.hasOwnProperty('executionPlatform') ||
      (formObj.hasOwnProperty('executionPlatform') &&
        (formObj.executionPlatform !== EXECUTION_PLATFORM || !formObj.hasOwnProperty('executionPlatformVersion')))
    ) {
      formObj.executionPlatform = EXECUTION_PLATFORM;
      formObj.executionPlatformVersion = DEFAULT_ZEEBE_VERSION_FORMS;
    }
  };

  setExecutionPlatformVersion = async (version) => {
    if (!this.form) {
      notificationStore.showError('Yikes! Something went wrong while validating the form.');
      return;
    }

    const newFormContent = JSON.parse(this.form.content);
    newFormContent.executionPlatform = EXECUTION_PLATFORM;
    newFormContent.executionPlatformVersion = version;

    runInAction(() => {
      this.executionPlatformVersion = version;
      this.form.content = this.#formatJSON(newFormContent);

      this.ignoreNextUpdateCall = true;
    });

    this.formEditor.importSchema(newFormContent);

    await this.update();

    trackingService.trackTargetEngineVersion(this.form, version);
  };

  copyJSON = async () => {
    try {
      await navigator.clipboard.writeText(this.form.content);

      notificationStore.showSuccess('The form has been copied into your clipboard as JSON.');
    } catch (err) {
      notificationStore.showError(
        "Yikes! Couldn't copy the form into your clipboard. Make sure that you gave permissions to the browser."
      );
    }
  };

  trackFormView = (origin, extraProps) => {
    if (!this.form) {
      return;
    }

    const user = userStore?.userId;

    trackingService.trackViewFile(origin, user, this.form, undefined, extraProps);
  };

  get status() {
    if (this.saving) {
      return {
        status: 'progress',
        message: 'Saving...'
      };
    } else if (this.lastSaved) {
      const time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format(this.lastSaved);

      return {
        status: 'done',
        message: `Autosaved at ${time}`
      };
    }

    return null;
  }

  async performLinting() {
    if (!this.form) {
      notificationStore.showError('Yikes! Something went wrong while validating the form.');
      return;
    }

    const errors = await performLintingUtil(this.form.content);

    runInAction(() => {
      this.lintErrors = errors;
    });
  }

  selectFormField(fieldId) {
    this.formEditor?.get('editorActions')?.trigger('selectFormField', { id: fieldId });
  }

  #formatJSON(str) {
    return JSON.stringify(str, null, 2);
  }
}

export default new FormStore();
