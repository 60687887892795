/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 12px;
  ${({ hasMissingSecret }) =>
    hasMissingSecret ? 'border: 2px solid var(--support-support-error, #da1e28);' : 'border: 2px solid transparent;'}

  margin-top: 15px;
  margin-left: 25px;

  position: relative;
`;

export const IconContainer = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;

  cursor: pointer;
  border: none;

  top: -8px;
  right: -9px;

  ${({ hasMissingSecret }) =>
    hasMissingSecret ? 'background-color: var(--support-support-error, #da1e28);' : 'background-color: #78A9FF;'}

  color: white;
  padding: 4px;
`;
