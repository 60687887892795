/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';

import { DEFAULT_CLUSTER_INFO, INPUT_KEY } from 'App/Pages/Diagram/Deployment/executeModal/sm/constants';

export default (deployOnly, clusterInfo, open, authentication) => {
  const [isClusterInfoCollapsed, setIsClusterInfoCollapsed] = useState(false);

  useEffect(() => {
    if (!deployOnly && open && authentication === 'oauth') {
      const isAllFieldsFilled = !Object.keys(DEFAULT_CLUSTER_INFO)
        .filter((key) => key !== INPUT_KEY.TENANT_ID && key !== INPUT_KEY.SCOPE) // tenant id and scope are optional
        .some((key) => clusterInfo[key] === '');
      setIsClusterInfoCollapsed(isAllFieldsFilled);
    } else if (!open) {
      setIsClusterInfoCollapsed(false);
    }
  }, [open]);

  return { isClusterInfoCollapsed, setIsClusterInfoCollapsed };
};
