/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

const VARIANTS = {
  big: 20,
  normal: 10,
  none: 0
};

export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: ${({ variant }) => `${VARIANTS[variant]}px 0`};
  background-color: var(--silver-darken-87);
`;
