/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { clustersStore, organizationStore, userStore } from 'stores';

export default ({ showClusters, showAppBar }) => {
  const consoleApp = {
    key: 'console',
    label: 'Console',
    active: false,
    ...(organizationStore?.consoleDashboardPageUrl ? { href: organizationStore?.consoleDashboardPageUrl } : {}),
    routeProps: {
      className: 'clickable'
    }
  };

  const modelerApp = {
    key: 'modeler',
    label: 'Modeler',
    active: true,
    routeProps: {
      // NOTE: without this, the `active:true` does not take place.
      // This is known to the composite-components team and there is an issue on their side and ours to remove the workaround (#3236).
      to: ''
    },
    onClick: () => {
      if (userStore.isAuthenticated) {
        history.push('/');
      } else {
        window.location.assign('/login');
      }
    }
  };

  const operateApp = {
    key: 'operate',
    label: 'Operate',
    active: false,
    routeProps: {
      className: 'clickable'
    }
  };

  const optimizeApp = {
    key: 'optimize',
    label: 'Optimize',
    active: false,
    routeProps: {
      className: 'clickable'
    }
  };

  const taskListApp = {
    key: 'tasklist',
    label: 'Tasklist',
    active: false,
    routeProps: {
      className: 'clickable'
    }
  };

  const history = useHistory();

  const { clusters } = clustersStore;

  const [elements, setElements] = useState([]);

  const canAppBeListed = (app) => app.subElements?.length || app.href;

  const getHealthyAndAccessibleClusters = (appName, clusters) =>
    clusters.filter(
      (cluster) => cluster.status[appName] === 'Healthy' && organizationStore.getAppClusterPermissions(appName)?.read
    );

  useEffect(() => {
    if (showClusters && showAppBar) {
      if (clusters && clusters.length) {
        [taskListApp, operateApp, optimizeApp].forEach((app) => {
          const renderableClusters = getHealthyAndAccessibleClusters(app.key, clusters);

          if (renderableClusters.length === 1) {
            app.href = renderableClusters[0].urls[app.key];
          } else {
            app.subElements = renderableClusters.map((cluster, index) => ({
              key: `${app.key}-${cluster.uuid}-${index}`,
              label: cluster.name,
              href: cluster.urls[app.key]
            }));
          }
        });

        const elements = [consoleApp, modelerApp];

        if (canAppBeListed(taskListApp)) {
          elements.push(taskListApp);
        }

        if (canAppBeListed(operateApp)) {
          elements.push(operateApp);
        }

        if (canAppBeListed(optimizeApp)) {
          elements.push(optimizeApp);
        }

        setElements(elements);
      } else {
        setElements([
          consoleApp,
          modelerApp,
          {
            ...taskListApp,
            isPreview: true,
            href: `${organizationStore.consoleDashboardPageUrl}/appTeaser`
          },
          {
            ...operateApp,
            href: `${organizationStore.consoleDashboardPageUrl}/appTeaser`,
            isPreview: true
          },
          {
            ...optimizeApp,
            href: `${organizationStore.consoleDashboardPageUrl}/appTeaser`,
            isPreview: true
          }
        ]);
      }
    }
  }, [clusters]);

  return { ariaLabel: 'App Panel', elements, isOpen: false, type: 'app' };
};
