/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';
import {
  Link,
  Toggle,
  Toggletip,
  ToggletipActions,
  ToggletipButton,
  ToggletipContent,
  SwitcherDivider
} from '@carbon/react';
import { Information } from '@carbon/react/icons';

import { preferenceStore } from 'stores';
import history from 'utils/history';

import * as Styled from './SuperUserToggle.styled';

const SuperUserToggle = () => {
  return (
    <>
      <SwitcherDivider />
      <Styled.Wrapper>
        <Toggle
          size="sm"
          id="toggle-super-user-mode"
          defaultToggled={preferenceStore.preferences?.superAdminModeActive}
          onToggle={async (superAdminModeActive) => {
            await preferenceStore.updatePreferences({
              superAdminModeActive
            });
            history.push('/');
          }}
          labelText="Enter Super-user mode"
          hideLabel
        />
        <Toggletip align="bottom-right">
          <ToggletipButton label="Show information">
            <Information />
          </ToggletipButton>
          <ToggletipContent className="super-user-mode__toggletip-content">
            As Org owner, you have extended permissions
            <ToggletipActions>
              <Link
                href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/collaboration/#super-user-mode"
                target="_blank"
              >
                Learn more
              </Link>
            </ToggletipActions>
          </ToggletipContent>
        </Toggletip>
      </Styled.Wrapper>
    </>
  );
};

export default observer(SuperUserToggle);
