/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { EntityList as BaseEntityList } from 'components';
import { Sidebar } from 'primitives';

export const EntityList = styled(BaseEntityList)`
  --header-background: white;
  --padding: 15px;
`;

export const SidebarOuter = styled(Sidebar)`
  width: 450px;
`;

export const CollaboratorSidebarTitle = styled(Sidebar.Title)`
  background-color: var(--cm-color-white-base);
`;

export const CollaboratorSidebarContent = styled.div`
  display: flex;
  height: calc(100% - 40px);
  position: relative;
`;

export const EmptyStateWrapper = styled.div`
  position: absolute;
  top: 185px;
`;

export const Icon = styled.span`
  margin-right: var(--spacing-small);
`;
