/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export const lightGreen = '#47C28B';
export const lightOrange = '#FFC667';
export const lightRed = '#FA8072';

export const darkGreen = '#359168';
export const darkOrange = '#E28F00';
export const darkRed = '#FA8072';
