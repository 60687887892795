/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import BPMNModdle from 'bpmn-moddle';
import modelerModdle from 'modeler-moddle/resources/modeler.json';
import DMNModdle from 'dmn-moddle';

import { BPMN, DMN } from 'utils/constants';

const moddles = {
  [BPMN]: new BPMNModdle({ modeler: modelerModdle }),
  [DMN]: new DMNModdle()
};

export default async function (xml, type = BPMN) {
  if (!xml) {
    throw new Error('You need to pass a xml string');
  }
  const { rootElement } = await moddles[type].fromXML(xml);

  if (!rootElement) {
    throw new Error('Unable to parse definitions from passed xml string.');
  }

  return rootElement;
}
