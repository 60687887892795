/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PusherJs from 'pusher-js';

import BufferedAuthorizer from './BufferedAuthorizer';

const PusherBatchAuthorizer = function (Pusher) {
  /**
   * Add buffered authorizer to Pusher lib
   * Each endpoint and socket id gets its own buffered authorizer
   */
  const authorizers = {};

  return function (channel, callback, options) {
    const { socketId, channelName } = channel;
    const { endpoint: authEndpoint } = options.channelAuthorization;
    const key = `${socketId}:${authEndpoint}`;

    let authorizer = authorizers[key];

    if (!authorizer) {
      authorizer = new BufferedAuthorizer({
        pusher: Pusher,
        socketId: socketId,
        authEndpoint: authEndpoint,
        authDelay: options.authDelay,
        authOptions: options.auth
      });
      authorizers[key] = authorizer;
    }

    authorizer.add(channelName, callback);
  };
};

export default PusherBatchAuthorizer(PusherJs);
