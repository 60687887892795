/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { validateAllZeebe } from '@bpmn-io/element-templates-validator';
import { sanitize } from 'dompurify';

import connectorTemplateStore from 'App/Pages/ConnectorTemplate/ConnectorTemplateStore';
import { projectStore, milestoneStore, userStore, ootbConnectorsStore } from 'stores';
import { tracingService, trackingService } from 'services';
import { CONNECTOR_TEMPLATE } from 'utils/constants';

import BaseImportService from './BaseImportService';

const postfixForDuplicatedNames = ' - Copy';

/**
 * Enum used to determine the import action
 */
export const PublishAction = Object.freeze({
  Publish: 'Publish',
  SaveAsCopy: 'SaveAsCopy',
  Replace: 'Replace'
});

class ImportConnectorTemplateService extends BaseImportService {
  /**
   * Imports the templates into the project. It creates a file for each template and publishes it. If there are duplicates the action defined by publishAction will be executed.
   * @param {*} selectedProject - the project to import the templates into
   * @param {*} resourcesMetadata - the metadata of the resources to be imported
   * @param {*} publishAction - the action to be executed if there are duplicates
   * @param {*} duplicates - the duplicates to be handled
   */
  async import({ selectedProject, resourcesMetadata, publishAction, duplicates }) {
    const templates = [];
    const templateSourceMap = {};
    const urls = [];
    const projectId = selectedProject.id;

    resourcesMetadata.forEach((resource) => {
      // We need to keep track of the URLs to send them to the tracking service.
      urls.push(resource.source);

      /*
       * We need to flatten the templates array because each resource can have multiple templates.
       * We also need to create a map of template ID to resource URL because we need to pass it to the
       * importConnectorTemplateService.import method and persist it.
       */
      resource.templates.forEach((template) => {
        templates.push(template);
        templateSourceMap[template.id] = resource.source;
      });
    });

    await this.#import({
      templates,
      templateSourceMap,
      projectId,
      publishAction,
      duplicates
    });

    trackingService.trackImportedResourcePublish({ urls, projectId });
  }

  async getDuplicatedResources(projectId, templateIdsToCompare) {
    const templateIdsToCompareArray = templateIdsToCompare?.split(',');
    const existingResources = await connectorTemplateStore.getSpecificResourcesInProject(
      projectId,
      templateIdsToCompare
    );

    const templateIds = existingResources.map(({ templateId }) => templateId);
    return existingResources
      .filter(({ templateId }, index) => !templateIds.includes(templateId, index + 1))
      .filter((resource) => templateIdsToCompareArray.includes(resource.templateId));
  }

  getOOTBDuplicatedResources(templateIdsToCompare) {
    const templateIdsToCompareArray = templateIdsToCompare?.split(',');
    const OOTB_TEMPLATES = ootbConnectorsStore.getOOTBConnectors();
    return OOTB_TEMPLATES.map((templates) => {
      let template = Array.isArray(templates) ? templates[0] : templates;

      if (!template) {
        return;
      }

      template.templateId = template.id;

      // during the publish process, we have to distinguish ootb duplicates. We do this by adding a new property to the template object
      // this property will be used in the importConnectorTemplateService.import method to determine the action to be executed
      template.isOOTBDuplicate = true;
      return template;
    }).filter((template) => templateIdsToCompareArray.includes(template?.templateId));
  }

  /**
   * Returns all the duplicated resources in the project and the ootb duplicates.
   * @param {String} projectId - the id of the project
   * @param {String} templateIdsToCompare - the ids of the templates to compare
   * @returns {Object} The duplicated resources and the ootb duplicates
   */
  async getAllDuplicatedResources(projectId, templateIdsToCompare) {
    let duplicates = await this.getDuplicatedResources(projectId, templateIdsToCompare);
    const ootbDuplicates = this.getOOTBDuplicatedResources(templateIdsToCompare);

    /**
     * a Map is created from the duplicates array, with templateId as the key and the entire item as the value.
     * Then, for each item in the ootbDuplicates array, if an item with the same templateId does not already exist in the duplicatesMap, it is added to the duplicatesMap.
     * The values method is then used to get an iterable of the values in the duplicatesMap, and Array.from is used to convert this iterable into an array.
     * The result is an array of unique items based on templateId, with normal duplicates having precedence over ootbDuplicates.
     */
    const duplicatesMap = new Map(duplicates.map((item) => [item.templateId, item]));

    ootbDuplicates.forEach((item) => {
      if (!duplicatesMap.has(item.templateId)) {
        duplicatesMap.set(item.templateId, item);
      }
    });

    const mergedDuplicates = Array.from(duplicatesMap.values());

    return {
      duplicates: mergedDuplicates,
      ootbDuplicates
    };
  }

  /**
   * Prepares the output of the import process. It validates the templates and sanitizes the content. If the content
   * is invalid, it returns the reasons why it's invalid. If the content is valid, it returns the sanitized content.
   * @param {*} templates - the templates to be validated and sanitized
   * @returns {object} - the output of the import process
   */
  prepareOutput(templates) {
    let processedTemplates = [];
    let error;

    processedTemplates = this.#getUniqueTemplates(templates);

    let { valid, results } = validateAllZeebe(processedTemplates);

    let reasons;

    if (!valid) {
      reasons = results.filter((r) => !r.valid);
    }

    processedTemplates?.forEach((template, index) => {
      try {
        processedTemplates[index] = this.#sanitizeContent(template);
      } catch (e) {
        error = 'Invalid content';
        valid = false;
        reasons = ['Sanitization failed'];
      }
    });

    return {
      valid,
      error,
      reasons,
      templates: processedTemplates
    };
  }

  /**
   * Imports the templates into the project. It creates a file for each template and publishes it. If there are duplicates the action defined by publishAction will be executed.
   * @param {*} parameters - the parameters of the import process
   * @returns {Promise} - the promise of the import process
   */
  #import({ templates, templateSourceMap, projectId, publishAction, duplicates }) {
    const duplicateIds = duplicates?.map((duplicate) => duplicate.templateId);

    const createAndPublish = async (template, isDuplicate, publishAction) => {
      const duplicate = duplicates?.find((duplicate) => duplicate.templateId === template.id);
      const isSaveAsCopy = publishAction === PublishAction.SaveAsCopy;
      const isReplace = publishAction === PublishAction.Replace;
      const isOOTBReplace = duplicate?.isOOTBDuplicate && isReplace;
      let source = 'import-dialog';

      const payload =
        isDuplicate && isReplace
          ? {
              revision: duplicate?.revision,
              name: template.name,
              projectId,
              importUrl: templateSourceMap[template.id],
              originAppInstanceId: userStore.originAppInstanceId,
              content: JSON.stringify(template, undefined, 2)
            }
          : {
              name: template.name,
              projectId,
              content: JSON.stringify(template, undefined, 2)
            };

      if (isDuplicate) {
        source = isSaveAsCopy ? 'import-dialog-duplicate' : 'import-dialog-replace';
      }

      if (isDuplicate && isSaveAsCopy && duplicate.name === payload.name) {
        payload.name = duplicate.name + postfixForDuplicatedNames;
      }

      try {
        // Save as copy is essentially the same as a normal publish but without importUrl
        // When we perform the 'save as' action, the new file can be considered as a fork of the initial template, hence we should replace the original ID.
        // If the importUrl is not present in the request, the BE will automatically assign a new ID.
        // The absence of the importUrl will indicate that this template should not be handled as an imported one.
        const file =
          !isDuplicate || isOOTBReplace || isSaveAsCopy
            ? await projectStore.createFile({
                type: CONNECTOR_TEMPLATE,
                file: payload,
                source,
                isFromTemplate: false,
                importUrl: isDuplicate && isSaveAsCopy ? null : templateSourceMap[template.id]
              })
            : await connectorTemplateStore.replace(payload, duplicate?.id, source);

        if (file) {
          const milestonePayload = {
            file: file,
            name: template.version ? `v${template.version} (Marketplace resource)` : 'Marketplace resource',
            append: false,
            origin: 'publish',
            organizationPublic: false
          };
          if (isReplace) {
            return milestoneStore.createAutosaved(file, milestonePayload.origin, milestonePayload);
          }
          return milestoneStore.create(milestonePayload);
        }

        tracingService.traceError(new Error('Could not create file'));
        return Promise.reject();
      } catch (e) {
        tracingService.traceError(e);
        return Promise.reject();
      }
    };

    const requests = [];
    templates.forEach((template) =>
      requests.push(createAndPublish(template, duplicateIds?.includes(template.id), publishAction))
    );

    return Promise.all(requests);
  }

  /**
   * Extracts the unique templates from the file content. If the file content is an array of templates, it returns
   * the unique templates. If the file content is a single template, it wraps it in an array and returns it.
   *
   * @param {*} templates - the templates to be extracted
   * @returns {array} - the unique templates
   */
  #getUniqueTemplates(templates) {
    const visitedIds = [];
    let items = [];

    if (!Array.isArray(templates)) {
      // NOTE: the file content might be an array of templates or a single template,
      // hence if it's not an array, we wrap it in one.
      items = [templates];
    } else {
      templates.forEach((template) => {
        // NOTE: in a single file, we might have templates with the same ID but different versions.
        // We need to extract the unique templates and only import the one with the highest version.
        if (!visitedIds.includes(template.id)) {
          visitedIds.push(template.id);
          items.push(template);
        } else {
          const lastItemIndex = visitedIds.indexOf(template.id);
          if (items[lastItemIndex].version < template.version) {
            items[lastItemIndex] = template;
          }
        }
      });
    }

    return items;
  }

  #sanitizeContent(content) {
    if (content.name) {
      content.name = sanitize(content.name);
    }

    if (content.description) {
      content.description = sanitize(content.description);
    }

    if (content.icon?.contents) {
      content.icon.contents = sanitize(content.icon.contents);
    }

    return content;
  }
}

export default new ImportConnectorTemplateService();
