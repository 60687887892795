/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import { currentDiagramStore, diagramControlStore, realtimeCollaborationStore } from 'stores';
import { dedicatedModesStore, deploymentErrorsStore, publicationStore } from 'App/Pages/Diagram/stores';
import { DiscoverConnectorsModal, discoverConnectorsStore } from 'App/Pages/Diagram/DiscoverConnectorsModal';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';
import lintingStore from 'components/Modeler/LintingStore';
import { Breadcrumb, ImportModal, TopBar } from 'components';
import { EmptyState } from 'primitives';
import { SadFace } from 'icons';
import getIdFromSlug from 'utils/getIdFromSlug';
import createPermission from 'utils/createPermission';
import buildSlug from 'utils/buildSlug';
import { getPageTitle } from 'utils/helpers';
import config from 'utils/config';
import { XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';

import Extensions from './Extensions';
import Header from './Header';
import ActionBar from './ActionBar';
import DmnViewer from './DmnViewer';
import BpmnViewer from './BpmnViewer';
import * as Styled from './Diagram.styled';
import {
  BROWSE_MARKETPLACE_ONCLICK_EVENT,
  BROWSE_MARKETPLACE_ONDESTROY_EVENT,
  browseMarketplaceButtonEventManager
} from './BpmnJSExtensions/browseMarketplaceExtension';

export const Diagram = (props) => {
  const { diagram, project, isFetching } = currentDiagramStore.state;
  const { modeler, isBPMN, lastReloadedContent } = currentDiagramStore;
  const permission = createPermission(project?.permissionAccess);
  const { isImplementMode } = dedicatedModesStore;
  const [isDiscoverConnectorsModalOpen, setIsDiscoverConnectorsModalOpen] = useState(false);
  const location = useLocation();

  const init = async () => {
    await currentDiagramStore.fetchDiagramById(getIdFromSlug(props.match.params.slug));
    dedicatedModesStore.init();
    XMLEditorStore.init();
    consolidateUrlSlug();

    browseMarketplaceButtonEventManager.subscribe(BROWSE_MARKETPLACE_ONCLICK_EVENT, onBrowseMarketplaceButtonClick);
  };

  const reset = () => {
    currentDiagramStore.reset();
    diagramControlStore.reset();
    realtimeCollaborationStore.reset();
    deploymentErrorsStore.reset();
    dedicatedModesStore.reset();
    publicationStore.reset();
    formLinkStore.reset();
    XMLEditorStore.dispose();

    browseMarketplaceButtonEventManager.unsubscribe(BROWSE_MARKETPLACE_ONCLICK_EVENT, onBrowseMarketplaceButtonClick);
    browseMarketplaceButtonEventManager.emit(BROWSE_MARKETPLACE_ONDESTROY_EVENT);
  };

  const consolidateUrlSlug = () => {
    const url = document.location.href;
    const { diagram } = currentDiagramStore.state;

    if (!diagram) {
      return;
    }

    // Prevents faulty URL parsing if the user leaves the diagram
    // page fastly
    if (!url.includes('/diagrams/')) {
      return;
    }

    const slug = url.split('--')[1] ? url.split('--')[1].split('?')[0] : undefined;

    const splitStringSeparator = url.includes('/diagrams/xml/') ? '/diagrams/xml/' : '/diagrams/';

    const currentSlug = url.split(splitStringSeparator)[1].split('?')[0];
    const newSlug = buildSlug(diagram.id, diagram.name);

    if (!slug || currentSlug !== newSlug) {
      // the state has to be changed via the `history` prop,
      // otherwise the change won't be observed by the React Router when listening on history
      props.history.replace(`${splitStringSeparator}${newSlug}${document.location.search}`);
    }
  };

  const onBrowseMarketplaceButtonClick = () => {
    setIsDiscoverConnectorsModalOpen(true);
  };

  const onDiscoverMarketplaceClose = () => {
    setIsDiscoverConnectorsModalOpen(false);
  };

  const resetMarketplaceSelection = () => {
    discoverConnectorsStore.reset();
  };

  const onMarketplaceImportComplete = async () => {
    if (!project) {
      throw new Error('Project is not defined');
    }

    await currentDiagramStore.loadElementTemplates(project.id);
  };

  const getCanvasElements = () => currentDiagramStore.canvas?.getRootElement?.()?.children ?? [];

  useEffect(() => {
    (async () => {
      await init();
    })();

    return () => {
      reset();
    };
  }, [props.match.params.slug]);

  useEffect(() => {
    if (!modeler || !isBPMN) {
      return;
    }
    const canvasElements = getCanvasElements();
    const onlyStartEvent = canvasElements.length === 1;

    if (onlyStartEvent) {
      currentDiagramStore.selection?.select(canvasElements[0]);
    }

    lintingStore.performLinting(modeler, isImplementMode);
  }, [isBPMN, modeler, isImplementMode]);

  useEffect(() => {
    const containsXML = location.pathname.includes('/xml/');

    if (isBPMN && modeler && containsXML && isImplementMode && permission.is(['WRITE', 'ADMIN'])) {
      XMLEditorStore.openEditor();
    }
  }, [isBPMN, modeler, isImplementMode]);

  useCheckIfPublicAccessEnabled(isBPMN, modeler, lastReloadedContent);

  if (isFetching) {
    return <Styled.DiagramLoader />;
  }

  if (!diagram) {
    return (
      <Fragment>
        <TopBar.Breadcrumbs>
          <Breadcrumb title="Home" variant="link" to="/" />
        </TopBar.Breadcrumbs>
        <EmptyState
          title="Diagram not found!"
          description="Sorry, the diagram might have been deleted."
          icon={<SadFace width="48" height="48" />}
        />
      </Fragment>
    );
  }

  const Viewer = diagram.type === 'DMN' ? DmnViewer : BpmnViewer;

  const hasResourcesToImport = !!discoverConnectorsStore.resourcesToImport?.length;

  return (
    <Fragment>
      <Helmet>
        <title>{getPageTitle(diagram.name)}</title>
      </Helmet>
      <Header permission={permission} />

      <ActionBar shouldShowModesTooltip={getCanvasElements().length > 1} />

      {isBPMN && config.marketplace?.enabled && (
        <>
          <ImportModal
            open={hasResourcesToImport}
            currentProject={project}
            showProjects={false}
            autoPublish
            resourcesToImportMetadata={discoverConnectorsStore.resourcesToImport}
            fetchDelay={2000}
            onClose={(evt) => {
              resetMarketplaceSelection();

              if (evt?.gotoMarketplace) {
                setIsDiscoverConnectorsModalOpen(true);
              }
            }}
            onPublishComplete={async () => {
              await onMarketplaceImportComplete();
              resetMarketplaceSelection();
            }}
          />

          <DiscoverConnectorsModal
            isOpen={isDiscoverConnectorsModalOpen}
            onClose={() => {
              onDiscoverMarketplaceClose();
            }}
          />
        </>
      )}

      <Suspense fallback={<Styled.DiagramLoader />}>
        <Viewer permission={permission} />
      </Suspense>
      <Extensions />
    </Fragment>
  );
};

export default withRouter(observer(Diagram));

const useCheckIfPublicAccessEnabled = (isBPMN, modeler, lastReloadedContent) => {
  /**
   * `lastReloadedContent` is needed in the dependency array
   * to update the `publicAccessEnabled` status, when changed during live collaboration
   */
  useEffect(() => {
    if (modeler && isBPMN) {
      publicationStore.checkIfPublicAccessEnabed(modeler);
    }
  }, [isBPMN, modeler, lastReloadedContent]);
};
