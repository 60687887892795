/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState, useEffect } from 'react';

import { Dialog, Button } from 'primitives';

import PlayContext from '../PlayContext';

export default function CalledInstanceCompletedModal() {
  const { elementInstances } = useContext(PlayContext);
  const [completedCalledInstance, setCompletedCalledInstance] = useState();

  const completedElementInstances = elementInstances?.elementInstances?.filter(({ state }) => state === 'COMPLETED');

  useEffect(() => {
    const calledProcesses = JSON.parse(localStorage.getItem('play-calledProcesses') || '[]');
    const completedInstance = completedElementInstances?.find(({ key }) => calledProcesses.includes(key));
    if (completedInstance) {
      setCompletedCalledInstance(completedInstance.key);
    }
  }, [completedElementInstances]);

  function close() {
    const calledProcesses = JSON.parse(localStorage.getItem('play-calledProcesses') || '[]');
    localStorage.setItem(
      'play-calledProcesses',
      JSON.stringify(calledProcesses.filter((entry) => entry !== completedCalledInstance))
    );

    setCompletedCalledInstance();
  }

  return (
    <Dialog open={Boolean(completedCalledInstance)} onClose={close} maxWidth={480}>
      <Dialog.Header>
        <Dialog.Title>Called process completed</Dialog.Title>
      </Dialog.Header>
      <Dialog.Content>You may close this tab and return to the previous process instance</Dialog.Content>
      <Dialog.Footer>
        <Button onClick={close} variant="primary">
          Got it
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
