/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { UserManager } from 'oidc-client-ts';
import { runInAction } from 'mobx';

import history from 'utils/history';
import config from 'utils/config';
import { notificationStore } from 'stores';
import { OIDC_TYPE_GENERIC, OIDC_TYPE_KEYCLOAK, OIDC_TYPE_MICROSOFT } from 'utils/constants';

import BaseAuthService from './AuthService.common';

class AuthService extends BaseAuthService {
  async #init() {
    if (this.isInit) {
      return;
    }

    if (!this.authClient) {
      this.authClient = new UserManager({
        ...this.#getOidcProviderSpecificConfiguration(),
        authority: config.oAuth2.token.issuer,
        client_id: config.oAuth2.clientId,
        disablePKCE: false, // enable PKCE for code flow
        fetchRequestCredentials: config.oAuth2.client?.fetchRequestCredentials,
        post_logout_redirect_uri: `${config.modelerUrl}/login-callback`,
        redirect_uri: `${config.modelerUrl}/login-callback`,
        response_type: 'code'
      });
    }

    await this.authClient
      .signinRedirectCallback()
      .then(() => {
        this.isAuthenticated = true;
      })
      .catch(() => {
        this.isAuthenticated = false;
      });

    this.isInit = true;
  }

  #getOidcProviderSpecificConfiguration = () => {
    const defaultScopes = 'openid email profile';

    if ([OIDC_TYPE_GENERIC, OIDC_TYPE_KEYCLOAK].includes(config?.oAuth2?.type)) {
      return {
        scope: defaultScopes
      };
    } else if (config?.oAuth2?.type === OIDC_TYPE_MICROSOFT) {
      return {
        scope: `${defaultScopes} ${config.oAuth2.clientId}/.default`,
        refreshTokenAllowedScope: ''
      };
    }
  };

  async refreshToken() {
    if (this.authClient) {
      try {
        await this.authClient.signinSilent();
        const user = await this.authClient.getUser();
        this.setToken(user.access_token);

        return new Promise((resolve, reject) => {
          if (!user.access_token) {
            reject(new Error('Missing token'));
            return;
          }

          resolve(user.access_token);
        });
      } catch (error) {
        console.error(error);

        notificationStore.showSuccess('Your session has expired, please log in again.');
        setTimeout(async () => {
          await this.#gracefulLogout();
        }, 5000);
      }
    }
  }

  async fetchJWTUser() {
    try {
      const user = await this.authClient.getUser();
      if (user?.profile) {
        this.jwtUser = { ...user.profile };
      } else {
        this.logout();
      }
    } catch (error) {
      console.error(error);
      this.logout();
    }
  }

  async loginWithRedirect() {
    await this.#init();

    const returnTo = this.getReturnToFromUrl();

    sessionStorage.setItem('returnTo', returnTo);

    if (this.isAuthenticated) {
      this.#handlePersistedSession();
    } else {
      await this.authClient.signinRedirect();
    }
  }

  async #handlePersistedSession() {
    await this.prepareSession();

    const returnTo = sessionStorage.getItem('returnTo');
    sessionStorage.removeItem('returnTo');

    history.push(returnTo || '/');
  }

  async getTokenAndFetchAuthProviderUser() {
    const token = await this.refreshToken();
    if (token) {
      await this.fetchJWTUser();
    }
  }

  async handleRedirectCallback() {
    await this.#init();

    if (!this.isAuthenticated) {
      history.push('/login');
      return;
    }

    const returnTo = sessionStorage.getItem('returnTo');

    try {
      await this.getTokenAndFetchAuthProviderUser();
      await this.createModelerUser();

      runInAction(() => {
        this.isReady = true;
      });

      sessionStorage.removeItem('returnTo');

      history.push(returnTo || '/');
    } catch (error) {
      console.error(error);
      this.logout();
    }
  }

  async #gracefulLogout() {
    this.reset();
    await this.authClient.clearStaleState();
    window.location?.replace(`${config.modelerUrl}/login?returnUrl=${window.location?.pathname}`);
  }

  async logout() {
    await this.#init();

    this.reset();

    await this.authClient.signoutRedirect();
  }
}

export default new AuthService();
