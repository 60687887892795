/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Service from './Service';

class ConsoleService extends Service {
  /**
   * Fetches the permissions for the organization from console
   *
   * @returns {Promise}
   */
  getOrganizationInfo(organizationId) {
    return this.get(`/cloud/accounts/organizations/${organizationId}`);
  }

  /**
   * Fetches the list of organizations which the logged user is assigned to
   *
   * @returns {Promise}
   */
  getUserOrganizations() {
    return this.get(`/cloud/accounts/organizations/my`);
  }
}

export default new ConsoleService();
