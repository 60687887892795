/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable, autorun } from 'mobx';

import { currentDiagramStore } from 'stores';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { trackingService } from 'services';
import history from 'utils/history';

class XMLEditorStore {
  isEditorOpen = false;
  isValidContent = true;
  shouldReopenEditor = false;

  #modesStoreDisposer;

  constructor() {
    makeAutoObservable(this);
  }

  listenToModesStore() {
    this.#modesStoreDisposer = autorun(() => {
      // If the editor was marked to be reopened, reopen it when the user switches to the implementation mode
      if (currentDiagramStore.isBPMN && dedicatedModesStore.isImplementMode && this.shouldReopenEditor) {
        this.openEditor(false);
        this.shouldReopenEditor = false;
        return;
      }

      // If the user switches to a different mode that is not the implementation mode, close the editor.
      // It's also marked to be reopened.
      if (currentDiagramStore.isBPMN && !dedicatedModesStore.isImplementMode && this.isEditorOpen) {
        this.closeEditor({ reopen: true });
        return;
      }
    });
  }

  init() {
    this.listenToModesStore();
  }

  dispose() {
    this.reset();
    this.shouldReopenEditor = false;

    if (this.#modesStoreDisposer) {
      this.#modesStoreDisposer();
    }
  }

  reset() {
    this.isEditorOpen = false;
    this.isValidContent = true;
  }

  openEditor(trackEvent = true) {
    this.#addEditorToURL();
    this.isEditorOpen = true;

    if (trackEvent) {
      trackingService.trackXMLEditorOpen(
        currentDiagramStore.state?.diagram?.id,
        currentDiagramStore.state?.diagram?.type
      );
    }
  }

  closeEditor({ reopen = false } = {}) {
    this.#removeEditorFromURL();
    this.reset();

    this.shouldReopenEditor = reopen;
  }

  async validateAndSaveContent(content) {
    try {
      const { error, warnings } = await currentDiagramStore.dryRunImport(content);
      const canImport = !error && !warnings.length;

      if (canImport) {
        await currentDiagramStore.importContent(content);
        await currentDiagramStore.saveContent();
        this.setValidContent(true);

        // NOTE: This is needed to cover the case when the user changes the execution platform version from the XML editor
        currentDiagramStore.initExecutionPlaformVersion();
      } else {
        this.setValidContent(false);
        console.warn(error, warnings);
        return;
      }
    } catch (err) {
      this.setValidContent(false);
      console.error(err);
    }
  }

  setValidContent(isValid) {
    this.isValidContent = isValid;
  }

  get invalidContentStatusMessage() {
    if (!this.isValidContent) {
      return {
        status: 'warning',
        message: 'This XML file contains errors. Changes will auto-save once errors are fixed.'
      };
    }

    return false;
  }

  #addEditorToURL = () => {
    const pathname = location.pathname;
    if (!pathname.includes('/diagrams/xml/')) {
      history.replace(pathname.replace('/diagrams/', '/diagrams/xml/'));
    }
  };

  #removeEditorFromURL = () => {
    const pathname = location.pathname;
    if (pathname.includes('/diagrams/xml/')) {
      history.replace(pathname.replace('/diagrams/xml/', '/diagrams/'));
    }
  };
}

export default new XMLEditorStore();
