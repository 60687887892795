/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { trackClusterCreation } from './tracking';

async function createCluster() {
  let payload;
  try {
    const resp = await fetch('https://spawn.play.camunda.io/api/cluster', { method: 'POST' });
    payload = await resp.json();
  } catch (e) {
    // retry request (e.g. because spawn API was temporarily unavailable)
    await sleep(5000); // delay retry to avoid ddos-ing our own infrastructure
    return await createCluster();
  }

  trackClusterCreation();

  // store info about cluster in localstorage to avoid unnecessarily creating new ones
  window.localStorage.setItem('zeebePlayCluster', payload.uuid);
  window.localStorage.setItem('zeebePlayDeployments', '{}');
}

async function ensureClusterExists() {
  // check if we already have a cluster
  const cluster = window.localStorage.getItem('zeebePlayCluster');
  if (!cluster) {
    return await createCluster();
  }

  try {
    // make sure the cluster still exists
    const response = await fetch('https://spawn.play.camunda.io/api/cluster/' + cluster);

    if (!response.ok) {
      // if the cluster does not exist anymore, create a new one
      return await createCluster();
    }

    // bump death date of the cluster
    await fetch(`https://spawn.play.camunda.io/api/cluster/${cluster}/extend`, { method: 'PUT' });
  } catch (e) {
    // retry request (e.g. because spawn API was temporarily unavailable)
    await sleep(5000); // delay retry to avoid ddos-ing our own infrastructure
    await ensureClusterExists();
  }
}

// STEP 2: Wait for cluster to be ready
function ensureClusterIsAvailable() {
  return new Promise((resolve) => {
    const cluster = localStorage.getItem('zeebePlayCluster');

    const queryClusterStatus = async () => {
      let condition, clusterInfo;
      try {
        const request = await fetch('https://spawn.play.camunda.io/api/cluster/' + cluster);
        clusterInfo = await request.json();

        localStorage.setItem('zeebePlayClusterDeathDate', new Date(clusterInfo.spec.deathDate).valueOf());
        condition = clusterInfo.status.conditions[0];
      } catch (e) {
        condition = {};
      }

      if (condition?.type === 'Available' && condition?.status === 'True') {
        resolve(clusterInfo.status.httpEndpoint);
      } else {
        // cluster is unavailable, check again in a second
        setTimeout(queryClusterStatus, 1000);
      }
    };
    queryClusterStatus();
  });
}

export default async function getClusterInfo() {
  await ensureClusterExists();
  return await ensureClusterIsAvailable();
}

async function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
