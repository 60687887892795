/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import React, { Suspense } from 'react';

import { Spinner } from 'primitives';
import handleChunkLoadingError from 'utils/handleChunkLoadingError';

import lintingStore from './LintingStore';

const BpmnRenderer = React.lazy(() => import('./bpmn/BpmnRenderer').catch(handleChunkLoadingError));
const DmnRenderer = React.lazy(() => import('./dmn/DmnRenderer').catch(handleChunkLoadingError));

export default function Modeler({ diagram, ...props }) {
  return (
    <Suspense fallback={<Spinner fullHeight />}>
      {diagram.type === 'DMN' ? <DmnRenderer {...props} /> : <BpmnRenderer {...props} />}
    </Suspense>
  );
}

Modeler.lintingStore = lintingStore;
