/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { InlineLoading } from '@carbon/react';
import styled from 'styled-components';

export const InboundConnector = styled.div`
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .cds--snippet-container {
    min-height: 0 !important;
  }

  .cds--snippet--multi .cds--snippet-container pre {
    padding-bottom: 0;
  }
`;

export const Loading = styled(InlineLoading)`
  height: 30px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
`;

export const ActiveInboundConnectorTitle = styled.div`
  margin-bottom: var(--spacing-small);
`;

export const ActiveInboundConnector = styled.div`
  margin-bottom: var(--spacing-small);
`;
