/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { Dialog } from 'primitives';
import { layoverStore } from 'stores';

const Layovers = () => (
  <Dialog
    open={layoverStore.state.isLayoverOpen}
    onClose={layoverStore.hideLayover}
    {...layoverStore.state.dialogProps}
  >
    {layoverStore.state.currentLayoverComponent && (
      <layoverStore.state.currentLayoverComponent {...layoverStore.state.currentLayoverProps} />
    )}
  </Dialog>
);

export default observer(Layovers);
