/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Dialog as PrimitiveDialog } from 'primitives';

export const Dialog = styled(PrimitiveDialog)`
  width: 620px;
  border-radius: initial;
`;

export const Header = styled.div`
  padding: 16px;
`;

export const Title = styled(PrimitiveDialog.Title)`
  color: var(--cm-color-black-primary-text);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
`;

export const Content = styled(PrimitiveDialog.Content)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: var(--cm-color-black-primary-text);
  padding: 0 16px 48px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
  text-align: left;

  label {
    font-size: 12px;
  }

  .cds--tooltip-content {
    font-size: 10px;
  }
`;

export const MultipleSnippets = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Footer = styled(PrimitiveDialog.Footer)`
  background-color: initial;
  border-top: initial;
  padding: 0;
`;
