/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Component, Fragment } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { Dialog, Button, Typography } from 'primitives';
import { shareService } from 'services';
import { shareStore, notificationStore } from 'stores';

const PASSWORD_REMOVED_MESSAGE = 'The password has been removed.';

class RemovePasswordControl extends Component {
  onCancel = this.props.onCancel;

  removePassword = () => {
    shareService
      .destroyPassword(shareStore.state.diagram.shareId)
      .then(() => {
        this.onCancel();
        runInAction(() => (shareStore.state.diagram.passwordProtected = false));

        notificationStore.showSuccess(PASSWORD_REMOVED_MESSAGE);
      })
      .catch(() => notificationStore.showError('Could not remove the password from this share.'));
  };

  render() {
    return (
      <Fragment>
        <Dialog.Header>
          <Dialog.Title data-test="share-title">Remove password protection</Dialog.Title>
        </Dialog.Header>
        <Dialog.Content>
          <Typography>Removing the password will make your diagram available to anyone who has the link.</Typography>
        </Dialog.Content>
        <Dialog.Footer>
          <Button variant="secondary" onClick={this.onCancel}>
            Cancel
          </Button>
          <Button isDanger data-test="remove-share-password" onClick={this.removePassword}>
            Remove password
          </Button>
        </Dialog.Footer>
      </Fragment>
    );
  }
}

export default observer(RemovePasswordControl);
