/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { CodeEditor } from 'components';
import { currentDiagramStore } from 'stores';
import debounce from 'utils/debounce';
import { XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';

import * as Styled from './XMLEditor.styled';

const XMLEditor = () => {
  const { isEditorOpen } = XMLEditorStore;
  const [content, setContent] = useState(null);
  const { modeler } = currentDiagramStore;
  const SAVE_CONTENT_DEBOUNCE_DELAY = 500;

  /**
   * Load the XML content when the editor is opened and the modeler is ready
   */
  useEffect(() => {
    if (!modeler) {
      return;
    }

    (async () => {
      const { xml } = await modeler.saveXML({ format: true });
      setContent(xml);
    })();
  }, [isEditorOpen, modeler]);

  const handleOnChange = debounce(async (content) => {
    if (modeler) {
      await XMLEditorStore.validateAndSaveContent(content);
    }
  }, SAVE_CONTENT_DEBOUNCE_DELAY);

  return (
    isEditorOpen && (
      <Styled.Container>
        {content && (
          <CodeEditor
            value={content}
            hideControls
            wordWrap="on"
            minimap
            readOnly={false}
            language="xml"
            onChange={handleOnChange}
          />
        )}
      </Styled.Container>
    )
  );
};

export default observer(XMLEditor);
