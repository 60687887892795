/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';

import { currentDiagramStore } from 'stores';

import { trackInstanceStart } from './tracking';
import zeebePlayService from './ZeebePlayService';
import DiagramOverlay from './DiagramOverlay';
import PlayContext from './PlayContext';
import InstanceHeader from './instance/InstanceHeader';
import CalledInstanceCompletedModal from './instance/CalledInstanceCompletedModal';
import BottomBar from './BottomBar';
import StartModal from './StartModal';
import SessionExpiredModal from './SessionExpiredModal';
import useConnectors from './useConnectors';
import useInfoSubscription from './useInfoSubscription';
import './PlayMode.scss';

export default observer(function PlayMode({ isLoadingModeler }) {
  const directAccess = localStorage.getItem('play-directAccess');
  let initialEndPoint = undefined;
  let initialPlayId = undefined;
  let initialInstanceId = undefined;
  let initialFilesStatus = [];

  if (directAccess) {
    localStorage.removeItem('play-directAccess');

    const directAccessParsed = JSON.parse(directAccess);

    initialEndPoint = directAccessParsed.endPoint;
    initialPlayId = directAccessParsed.definition;
    initialInstanceId = directAccessParsed.instance;
    initialFilesStatus = directAccessParsed.filesStatus;
  }

  const [endPoint, setEndpoint] = useState(initialEndPoint);
  const [filesStatus, setFilesStatus] = useState(initialFilesStatus);
  const [isReady, setIsReady] = useState(false);
  const [playId, setPlayId] = useState(initialPlayId);
  const [instanceId, setInstanceId] = useState(initialInstanceId);
  const [isStartModalVisible, setIsStartModalVisible] = useState(!directAccess);
  const [hasError, setHasError] = useState(false);
  const [elementsById, setElementsById] = useState(null);
  const [definitions, setDefinitions] = useState(null);

  const connectorInfo = useConnectors(isReady && !isLoadingModeler, endPoint);
  const playInfo = useInfoSubscription(
    endPoint,
    playId,
    instanceId,
    currentDiagramStore.state?.diagram?.processId,
    currentDiagramStore.modeler
  );

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    // reset the isUpdating flag after playInfo changed
    setIsUpdating(false);
  }, [playInfo, playInfo]);

  const { diagram } = currentDiagramStore.state;

  useEffect(() => {
    if (isLoadingModeler) return;

    const { modeler } = currentDiagramStore;

    if (!modeler) {
      return;
    }

    modeler.get('playModeExtension').activate();

    return () => {
      modeler.get('playModeExtension').deactivate();
    };
  }, [isLoadingModeler]);

  function waitForNewInstanceFor(processKey) {
    return new Promise((resolve, reject) => {
      let remainingTries = 6;
      let interval = setInterval(() => {
        zeebePlayService.fetchInstances(endPoint, processKey).then((response) => {
          remainingTries--;

          const newInstance = response?.processInstances?.nodes?.[0];

          if (newInstance) {
            clearInterval(interval);
            return resolve(newInstance.key);
          }
          if (remainingTries === 0) {
            clearInterval(interval);
            return reject();
          }
        });
      }, 250);
    });
  }

  if (isStartModalVisible) {
    return (
      <StartModal
        isLoadingModeler={isLoadingModeler}
        connectorInfo={connectorInfo}
        onClusterReady={({ endpoint, filesStatus, playId, elementsById, definitions }) => {
          setEndpoint(endpoint);
          setFilesStatus(filesStatus);
          setPlayId(playId);
          setIsReady(true);
          setElementsById(elementsById);
          setDefinitions(definitions);
        }}
        close={() => setIsStartModalVisible(false)}
      />
    );
  }

  const context = {
    startInstance: async (variables = '{}') => {
      const result = await zeebePlayService.startInstance(endPoint, playId, variables);
      setInstanceId(result.instanceId);
      trackInstanceStart(diagram?.processId);

      return result?.success !== false;
    },
    completeJob: async (jobKey, variables = '{}') => {
      setIsUpdating(true);

      const result = await zeebePlayService.completeJob(endPoint, jobKey, variables);

      return result?.success !== false;
    },
    invokeConnector: async (jobType, jobKey) => {
      setIsUpdating(true);

      const result = await zeebePlayService.invokeConnector(endPoint, jobType, jobKey);

      return result?.success !== false;
    },
    failJob: async (jobKey, retries, errorMessage) => {
      setIsUpdating(true);

      const result = await zeebePlayService.failJob(endPoint, jobKey, retries, errorMessage);
      return result?.success !== false;
    },
    throwError: async (jobKey, errorCode, errorMessage) => {
      setIsUpdating(true);

      const result = await zeebePlayService.throwError(endPoint, jobKey, errorCode, errorMessage);
      return result?.success !== false;
    },
    setConnectorSecret: connectorInfo.setConnectorSecret,
    timeTravel: async (dateTime, waitForInstanceCreation) => {
      const result = await zeebePlayService.timeTravel(endPoint, dateTime);

      if (result?.success === false) {
        return false;
      }

      if (waitForInstanceCreation) {
        try {
          const newInstanceId = await waitForNewInstanceFor(playId);
          setInstanceId(newInstanceId);
          trackInstanceStart(diagram?.processId);
        } catch (e) {
          console.error('Could not get new instance');
          return false;
        }
      }

      return true;
    },
    publishMessage: async (messageName, correlationKey, variables, timeToLive, messageId) => {
      const result = await zeebePlayService.publishMessage(
        endPoint,
        messageName,
        correlationKey,
        variables,
        timeToLive,
        messageId
      );
      if (result?.success === false) {
        return false;
      }

      try {
        const newInstanceId = await waitForNewInstanceFor(playId);
        setInstanceId(newInstanceId);

        trackInstanceStart(diagram?.processId);
      } catch (e) {
        console.error('Could not get new instance');
        return false;
      }

      return true;
    },
    setVariables: async (scope, variablesString) => {
      setIsUpdating(true);
      const result = await zeebePlayService.setVariables(endPoint, instanceId, scope, variablesString);
      return result?.success !== false;
    },
    updateRetries: async (jobKey, numberOfRetries = 1) => {
      setIsUpdating(true);

      const result = await zeebePlayService.updateRetries(endPoint, jobKey, numberOfRetries);

      return result?.success !== false;
    },
    resolveIncident: (incidentKey) => {
      setIsUpdating(true);
      return zeebePlayService.resolveIncident(endPoint, incidentKey);
    },
    setHasError: (hasError) => {
      setHasError(hasError);
    },
    processId: diagram?.processId,
    playId,
    instanceId,
    endPoint,
    modeler: currentDiagramStore.modeler,
    isUpdating,
    connectorInfo,
    filesStatus,
    ...playInfo,
    hasError: playInfo.hasError || hasError,
    elementsById,
    definitions
  };

  return (
    <PlayContext.Provider value={context}>
      {currentDiagramStore.modeler
        ?.get('playModeExtension')
        .getElements()
        .map((element) => (
          <DiagramOverlay key={element.id} element={element} />
        ))}
      <InstanceHeader
        leaveInstance={() => {
          setInstanceId();
        }}
      />

      <BottomBar
        filesStatus={filesStatus}
        connectorInfo={connectorInfo}
        goToInstance={(instanceId) => {
          setInstanceId(instanceId);
        }}
      />

      <CalledInstanceCompletedModal />

      {playInfo.isConnectionDead && (
        <SessionExpiredModal
          onRefresh={() => {
            setEndpoint(undefined);
            setFilesStatus(undefined);
            setIsReady(false);
            setPlayId(undefined);
            setInstanceId(undefined);
            setIsStartModalVisible(true);
          }}
        />
      )}
    </PlayContext.Provider>
  );
});
