/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { Dialog, Button } from 'primitives';

export default function SessionExpiredModal({ onRefresh }) {
  return (
    <Dialog open maxWidth={480}>
      <Dialog.Header>
        <Dialog.Title>Play session expired</Dialog.Title>
      </Dialog.Header>
      <Dialog.Content>
        This Play session has expired due to inactivity.
        <br />
        Click <b>Re-launch session</b> to start a new session.
      </Dialog.Content>
      <Dialog.Footer>
        <Button
          onClick={() => {
            dedicatedModesStore.setViewModeIndex(
              dedicatedModesStore.availableViewModes.find((mode) => mode.label === 'Implement').index
            );
          }}
          variant="secondary"
        >
          Exit
        </Button>
        <Button onClick={onRefresh} variant="primary">
          Re-launch session
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}
