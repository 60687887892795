/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useCallback } from 'react';
import { observer } from 'mobx-react';

import { commentsStore } from 'stores';
import { detailsPanelTabsStore } from 'App/Pages/Diagram/stores';
import { ElementMenuButton } from 'primitives';

import * as Styled from './CommentsOverlay.styled';

const CommentsOverlay = ({ element, bpmnjs }) => {
  const handleOverlayClick = useCallback(() => {
    const { isSidebarVisible, current } = commentsStore.state;
    const { isCommentsTabSelected } = detailsPanelTabsStore;

    const isClickedElementAlreadySelected = current.shape && element.id === current.shape.id;
    if (!isSidebarVisible || (isSidebarVisible && isClickedElementAlreadySelected && isCommentsTabSelected)) {
      commentsStore.toggleSidebar();
    }
    detailsPanelTabsStore.switchTab(detailsPanelTabsStore.TABS.COMMENTS); // Switch to comments tab

    // Select an element.
    bpmnjs.get('selection').select(element);

    commentsStore.setCurrentElement({
      shape: element
    });
  });

  const button = (
    <ElementMenuButton onClick={handleOverlayClick} data-test="bpmn-overlay">
      <Styled.CommentIcon />
    </ElementMenuButton>
  );

  return button;
};

export default observer(CommentsOverlay);
