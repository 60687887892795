/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import lintingAnnotations from '@camunda/linting/modeler';

import openPropertiesOnAnnotationClick from './openPropertiesOnAnnotationClick';

export default {
  __depends__: [lintingAnnotations, openPropertiesOnAnnotationClick]
};
