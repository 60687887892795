/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import * as Styled from './ConfigurationSavedNotification.styled';

export default function ConfigurationSavedNotification({ link }) {
  return (
    <div>
      <div>You have successfully saved a Connector template!</div>
      {link && (
        <Styled.Link href={link} target="_blank" className="bold">
          View Connector template
        </Styled.Link>
      )}
    </div>
  );
}
