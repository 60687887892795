/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

import { clusterService } from 'services';
import { currentDiagramStore, organizationStore } from 'stores';
import { BPMN } from 'utils/constants';
import { parseClusterGenerationName } from 'App/Pages/Diagram/Deployment/cluster-version-util';

class ClustersStore {
  clusters = null;

  constructor() {
    makeAutoObservable(this);
  }

  async loadClusters() {
    const clusters = await clusterService.getClusters(organizationStore.currentOrganizationId);
    this.setClusters(clusters);
  }

  setClusters(clusters) {
    this.clusters = clusters;
  }

  get availableClusters() {
    return this.clusters?.filter(this.isClusterAvailable) || [];
  }

  get unavailableClusters() {
    return this.clusters?.filter((cluster) => !this.isClusterAvailable(cluster)) || [];
  }

  isClusterAvailable = (cluster) => {
    if (currentDiagramStore.state.diagram?.type === BPMN) {
      // BPMN diagrams can be deployed to all clusters
      return true;
    }

    const versionInfo = parseClusterGenerationName(cluster.generation.name);

    if (!versionInfo) {
      // if the version information cannot be parsed (e.g. for names that do not contain numbers)
      // we assume that dmn deployment is allowed
      return true;
    }

    // DMN diagrams can only be deployed to clusters with version > 1.4.0-alpha2
    const { major, minor, patch, detail } = versionInfo;
    if (
      major > 1 ||
      (major === 1 && minor > 4) ||
      (major === 1 && minor === 4 && patch > 0) ||
      (major === 1 && minor === 4 && patch === 0 && detail !== 1)
    ) {
      return true;
    }
    return false;
  };
}

export default new ClustersStore();
