/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Element = styled.p`
  font-size: 1em;
  margin: 0;

  ${({ $variant }) => {
    switch ($variant) {
      case 'h1':
        return `
          font-size: 19px;
          line-height: 1.2;
        `;
      case 'h2':
        return `
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 1.2;
        `;
      case 'h3':
        return `
          margin-bottom: 7px;
          font-size: 14px;
          line-height: 1.3;
        `;
      case 'caption':
        return `
          color: var(--grey-darken-36);
          font-size: 13px;
        `;
    }
  }}

  ${({ $gutterBottom }) => {
    if ($gutterBottom) {
      return 'margin-bottom: 10px;';
    }
  }}
`;
