/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState, useRef, useEffect } from 'react';
import { Form } from '@bpmn-io/form-js';
import { Form as CarbonForm, TextArea, NumberInput, TextInput, Stack, Button as CarbonButton } from '@carbon/react';
import { Checkmark, Renew } from '@carbon/icons-react';

import { Dialog, Button } from 'primitives';
import notificationStore from 'stores/NotificationStore';

import * as Styled from './JobCompleteButton.styled';
import ActionButton from '../ActionButton';
import PlayContext from '../PlayContext';
import EditForm from '../icons/svgr/EditForm.svg';
import zeebePlayService from '../ZeebePlayService';
import VariablesInputDialog from '../VariablesInputDialog';
import { REQUEST_FAILURE } from '../utils/constants';

export default function JobCompleteButton({ element }) {
  const context = useContext(PlayContext);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const formContainerRef = useRef();
  const taskForm = useRef();
  const resetFct = useRef();
  const [isFailModalOpen, setIsFailModalOpen] = useState(false);
  const [isThrowModalOpen, setIsThrowModalOpen] = useState(false);
  const [retries, setRetries] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const retriesNode = useRef();
  const [isInProgress, setIsInProgress] = useState(false);
  const [hasPrefillError, setHasPrefillError] = useState(false);

  const serviceTaskJob = context.jobs.find(
    (job) => job.elementInstance.element.elementId === element.id && job.state === 'ACTIVATABLE'
  );

  const userTaskJob = context.userTasks?.find(
    (userTask) => userTask.elementInstance.element.elementId === element.id && userTask.state === 'CREATED'
  );

  const myJob = serviceTaskJob || userTaskJob;

  useEffect(() => {
    setIsInProgress(false);
  }, [myJob]);

  const userTaskForm = myJob?.form?.resource;

  const Icon = userTaskForm ? EditForm : Checkmark;

  async function handleFormSubmit(event, { data, errors }) {
    if (Object.keys(errors).length === 0) {
      // no errors, ready to submit

      setIsInProgress(true);
      setIsFormModalOpen(false);
      const isSuccess = await context.completeJob(myJob.key, JSON.stringify(data));

      if (!isSuccess) {
        notificationStore.showError(REQUEST_FAILURE);
      }

      localStorage.setItem(`jobCompletion ${context.processId} ${element.id}`, JSON.stringify(data));
    }
  }

  const renderForm = async () => {
    const variablesByUserTask = await zeebePlayService.fetchVariablesByUserTask(context.endPoint, myJob.key);

    if (variablesByUserTask === undefined) {
      setHasPrefillError(true);
      return;
    }

    const processVariables = variablesByUserTask?.reduce((variables, { name, value }) => {
      variables[name] = JSON.parse(value);
      return variables;
    }, {});

    const cachedData = localStorage.getItem(`jobCompletion ${context.processId} ${element.id}`);
    const cachedVariables = JSON.parse(cachedData || '{}');

    const combinedVariables = {
      ...processVariables,
      ...cachedVariables
    };

    const parsedForm = JSON.parse(userTaskForm);
    const formViewer = new Form();

    taskForm.current = formViewer;

    formViewer.attachTo(formContainerRef.current);
    formViewer.importSchema(parsedForm, combinedVariables);
    formViewer.on('submit', handleFormSubmit);

    resetFct.current = () => {
      taskForm.current.importSchema(parsedForm, processVariables);
    };
  };
  useEffect(() => {
    if (!formContainerRef.current) return;

    renderForm();
  }, [isFormModalOpen]);

  // if we are not in an instance view, do not show
  if (!context.instanceId) return null;

  if (!myJob) {
    // if there is no open job for the current element, do not show
    return null;
  }

  const actions = [
    {
      label: userTaskForm ? 'Open Task Form' : 'Complete Job',
      renderIcon: () => <Icon color="white" />,
      onClick: async () => {
        if (userTaskForm) {
          setIsFormModalOpen(true);
        } else {
          setIsInProgress(true);

          const isSuccess = await context.completeJob(myJob.key);

          if (!isSuccess) {
            notificationStore.showError(REQUEST_FAILURE);
          }
        }
      }
    },
    {
      label: 'Complete with variables',
      onClick: () => {
        setIsVariableModalOpen(true);
      }
    }
  ];

  if (serviceTaskJob) {
    actions.push(
      { type: 'divider', label: 'divider' },
      {
        label: 'Fail',
        onClick: () => {
          setRetries(0);
          setErrorMessage('');
          setIsFailModalOpen(true);
        }
      },
      {
        label: 'Throw Error',
        onClick: () => {
          setErrorCode('');
          setErrorMessage('');
          setIsThrowModalOpen(true);
        }
      }
    );
  }

  return (
    <>
      <ActionButton disabled={isInProgress} actions={actions} />

      <VariablesInputDialog
        title="Complete Job"
        isOpen={isVariableModalOpen}
        onClose={() => setIsVariableModalOpen(false)}
        onSubmit={async (variableModalContent) => {
          setIsVariableModalOpen(false);
          setIsInProgress(true);
          const isSuccess = await context.completeJob(myJob.key, variableModalContent);

          if (!isSuccess) {
            notificationStore.showError(REQUEST_FAILURE);
          }
        }}
        submitLabel="Complete"
      />

      <Dialog open={isFailModalOpen} onClose={() => setIsFailModalOpen(false)}>
        <Dialog.Header>
          <Dialog.Title>Fail Job</Dialog.Title>
        </Dialog.Header>

        <Dialog.Content>
          <CarbonForm>
            <Stack gap={7}>
              <NumberInput
                ref={retriesNode}
                id="retries"
                label="Job Retries"
                value={retries}
                min={0}
                onChange={(evt, { value }) => {
                  setRetries(+value);
                }}
              />
              <TextArea
                labelText="Error Message"
                onChange={(evt) => setErrorMessage(evt.target.value)}
                value={errorMessage}
              />
            </Stack>
          </CarbonForm>
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={() => setIsFailModalOpen(false)} variant="secondary">
            Close
          </Button>
          <Button
            onClick={async () => {
              setIsFailModalOpen(false);
              setIsInProgress(true);

              const isSuccess = await context.failJob(myJob.key, retries, errorMessage);

              if (!isSuccess) {
                notificationStore.showError(REQUEST_FAILURE);
              }
            }}
            variant="primary"
          >
            Fail
          </Button>
        </Dialog.Footer>
      </Dialog>

      <Dialog open={isThrowModalOpen} onClose={() => setIsThrowModalOpen(false)}>
        <Dialog.Header>
          <Dialog.Title>Throw Error</Dialog.Title>
        </Dialog.Header>

        <Dialog.Content>
          <CarbonForm>
            <Stack gap={7}>
              <TextInput
                id="errorCode"
                labelText="Error Code"
                value={errorCode}
                placeholder="ERROR-001"
                onChange={(evt) => {
                  setErrorCode(evt.target.value);
                }}
              />
              <TextArea
                labelText="Error Message"
                onChange={(evt) => setErrorMessage(evt.target.value)}
                value={errorMessage}
              />
            </Stack>
          </CarbonForm>
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={() => setIsThrowModalOpen(false)} variant="secondary">
            Close
          </Button>
          <Button
            onClick={async () => {
              setIsThrowModalOpen(false);
              setIsInProgress(true);

              const isSuccess = await context.throwError(myJob.key, errorCode, errorMessage);

              if (!isSuccess) {
                notificationStore.showError(REQUEST_FAILURE);
              }
            }}
            variant="primary"
          >
            Fail
          </Button>
        </Dialog.Footer>
      </Dialog>

      <Dialog open={isFormModalOpen} onClose={() => setIsFormModalOpen(false)}>
        <Dialog.Header>
          <Dialog.Title>Task Form</Dialog.Title>
        </Dialog.Header>

        <Dialog.Content>
          <>
            {hasPrefillError && (
              <Styled.Error>
                We were unable to load the form
                <CarbonButton
                  kind="tertiary"
                  size="sm"
                  renderIcon={Renew}
                  onClick={() => {
                    setHasPrefillError(false);
                    renderForm();
                  }}
                >
                  Retry
                </CarbonButton>
              </Styled.Error>
            )}

            <div ref={formContainerRef} />
          </>
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={() => resetFct.current?.()} variant="text" style={{ marginRight: 'auto' }}>
            Reset
          </Button>
          <Button onClick={() => setIsFormModalOpen(false)} variant="secondary">
            Close
          </Button>
          <Button onClick={() => taskForm.current?.submit()} variant="primary">
            Complete
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
}
