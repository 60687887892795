/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { action, observable, computed, makeObservable } from 'mobx';

class BreadcrumbStore {
  state = {
    breadcrumbs: null,
    subHeader: null,
    dropdownAnchorEl: null,
    isEditing: null
  };

  constructor() {
    makeObservable(this, {
      state: observable.shallow,
      breadcrumbs: computed,
      subHeader: computed,
      isDropdownVisible: computed,
      setBreadcrumbs: action,
      setSubHeader: action,
      toggleDropdownVisibility: action,
      toggleEditingFor: action,
      finishEditing: action
    });
  }

  get breadcrumbs() {
    if (this.state.breadcrumbs && this.state.breadcrumbs.length > 1) {
      return this.state.breadcrumbs;
    } else {
      return [this.state.breadcrumbs];
    }
  }

  get subHeader() {
    return this.state.subHeader || null;
  }

  get isDropdownVisible() {
    return Boolean(this.state.dropdownAnchorEl);
  }

  setBreadcrumbs = (children) => {
    this.state.breadcrumbs = children;
  };

  setSubHeader = (children) => {
    this.state.subHeader = children;
  };

  toggleDropdownVisibility = ({ currentTarget } = {}) => {
    if (!this.state.dropdownAnchorEl) {
      this.state.dropdownAnchorEl = currentTarget;
    } else {
      this.state.dropdownAnchorEl = null;
    }
  };

  toggleEditingFor(page) {
    this.state.isEditing = page;
  }

  finishEditing() {
    this.state.isEditing = null;
  }
}

export default new BreadcrumbStore();
