/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { autorun, makeObservable, observable, runInAction } from 'mobx';
import { v4 } from 'uuid';

import { JWT_ORGANIZATION_CLAIM } from 'utils/constants';
import { authService, tracingService, trackingService } from 'services';
import { organizationStore, preferenceStore } from 'stores';

class UserStore {
  originAppInstanceId = v4();
  isAuthenticated = false;

  userId = undefined;
  userAuthProviderId = undefined;
  userEmail = '';
  userName = '';

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      userId: observable,
      userAuthProviderId: observable,
      userName: observable,
      userEmail: observable
    });

    this.#observeServices();
  }

  #observeServices() {
    autorun(() => {
      if (authService.isReady) {
        this.#onUserReady();
      } else if (authService.hasReset) {
        this.reset();
      }
    });
  }

  reset() {
    runInAction(() => {
      this.isAuthenticated = false;
    });
  }

  get userOrganizations() {
    return authService.jwtUser ? authService.jwtUser[JWT_ORGANIZATION_CLAIM] : [];
  }

  get userRolesInCurrentOrganization() {
    return this.userOrganizations?.find((org) => org.id === organizationStore.currentOrganizationId)?.roles ?? [];
  }

  get isOrgOwner() {
    return this.userRolesInCurrentOrganization?.includes('owner');
  }

  get isSuperAdminModeActive() {
    return this.isOrgOwner && preferenceStore.preferences?.superAdminModeActive;
  }

  get isLoading() {
    return document.location.href.includes('/login') || document.location.href.includes('/logout');
  }

  /**
   * Compares two user accounts and returns `true` if they
   * are equal or `false` if they are different.
   *
   * @param {Object} user The user to compare with.
   * @returns {Boolean}
   */
  isCurrentUser = (user) => {
    if (!user) {
      return false;
    }

    return this.isAuthenticated && authService.modelerUser?.id === user.id;
  };

  /**
   *
   * @param {*} originAppInstanceId
   */
  isCurrentOriginAppInstanceId(originAppInstanceId) {
    return this.originAppInstanceId === originAppInstanceId;
  }

  #onUserReady() {
    runInAction(() => {
      this.userId = authService.modelerUser?.id;
      this.userEmail = authService.modelerUser?.email;
      this.userName = authService.modelerUser?.name;
      this.userAuthProviderId = authService.jwtUser?.sub;
    });

    trackingService.registerUser({
      user: authService.jwtUser
    });
    tracingService.registerUser({
      modelerUser: authService.modelerUser
    });

    runInAction(() => {
      this.isAuthenticated = true;
    });
    window.__invitation = undefined;
  }
}

export default new UserStore();
