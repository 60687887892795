/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import { Modal } from '@carbon/react';

import { EmptyState as EmptyStatePrimitive } from 'primitives';
import { HorizontalLines } from 'icons';

export const COLLAPSED_PANEL_SIZE = 35;

export const Wrapper = styled.div`
  position: relative;
  top: 1px;
  flex: 1;
  display: flex;
  flex-direction: column;

  // The following overrides are to invalidate the reset rules from Carbon
  // See #4639 for more details
  ol,
  ul {
    margin: 1em 0;
    padding: 0 0 0 40px;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }
`;

export const FormContainer = styled.main`
  flex: 1;
  background-color: white;

  ${({ $bottomPanelHeight }) =>
    $bottomPanelHeight > COLLAPSED_PANEL_SIZE
      ? `max-height: calc(100vh - ${$bottomPanelHeight}px);`
      : `max-height: calc(100vh - 140px);`};
`;

export const EmptyState = styled(EmptyStatePrimitive)`
  position: absolute;
  top: 30%;
  left: calc(50% - 270px);
  pointer-events: none;
  width: 436px;
`;

export const EmptyStateIcon = styled(HorizontalLines)`
  margin-top: -5px;
`;

export const ConnectToDiagramModal = styled(Modal)`
  .cds--modal-container {
    max-width: 520px;
  }

  .cds--modal-content {
    overflow: hidden;
  }
`;
