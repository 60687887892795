/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { Dropdown, TopBar, Breadcrumb, FileUpload } from 'components';
import { breadcrumbStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import buildSlug from 'utils/buildSlug';

import connectorTemplateStore from './ConnectorTemplateStore';

export const Header = () => {
  const { connectorTemplate, project, loading } = connectorTemplateStore;

  return (
    <TopBar.Breadcrumbs>
      {loading ? (
        <Breadcrumb title="Loading..." variant="text" />
      ) : (
        <>
          <Breadcrumb data-test="breadcrumb-home" title="Home" variant="link" to="/" />
          <Breadcrumb
            title={project.name}
            variant="link"
            data-test="breadcrumb-project"
            to={`/projects/${project.id}`}
          />
          {Boolean(connectorTemplate.folder) && (
            <>
              {connectorTemplate.folder.parentId && <Breadcrumb title="..." variant="text" data-test="parent-dots" />}
              <Breadcrumb
                title={connectorTemplate.folder.name}
                variant="link"
                to={`/folders/${buildSlug(connectorTemplate.folder.id, connectorTemplate.folder.name)}`}
                data-test="parent-folder"
              />
            </>
          )}
          {hasAccess(project, actions.MODIFY_CONNECTOR_TEMPLATE) ? (
            <>
              <Breadcrumb
                title={connectorTemplate.name}
                variant="dropdown"
                data-test="breadcrumb-connector-template"
                isBold
                forPage="connector-template"
                handleSubmit={connectorTemplateStore.rename}
              >
                <Dropdown.ListItem
                  onClick={() => {
                    breadcrumbStore.toggleDropdownVisibility();
                    breadcrumbStore.toggleEditingFor('connector-template');
                  }}
                  data-test="rename-connector-template"
                  disabled={connectorTemplate.imported}
                >
                  Edit name
                </Dropdown.ListItem>

                <Dropdown.ListItem
                  data-test="upload-connector-template"
                  disabled={connectorTemplate.imported}
                  noPadding
                >
                  <FileUpload onFiles={connectorTemplateStore.upload} label="Replace via upload" accept=".json" />
                </Dropdown.ListItem>

                <Dropdown.ListItem onClick={connectorTemplateStore.duplicate} data-test="duplicate-connector-template">
                  Duplicate
                </Dropdown.ListItem>
                <Dropdown.ListItem onClick={connectorTemplateStore.delete} data-test="delete-connector-template">
                  Delete
                </Dropdown.ListItem>
              </Breadcrumb>
            </>
          ) : (
            <Breadcrumb
              data-test="breadcrumb-connector-template"
              title={connectorTemplate.name}
              variant="text"
              isBold
            />
          )}
        </>
      )}
    </TopBar.Breadcrumbs>
  );
};

export default observer(Header);
