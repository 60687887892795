/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';
import BPMNModdle from 'bpmn-moddle';
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

export function containsUserTasks(elements) {
  let found = false;
  elements.forEach((el) => {
    if (getBusinessObject(el)?.$type?.toLowerCase() === 'bpmn:usertask') {
      found = true;
    }
  });
  return found;
}

export class DetectUserTasksStore {
  hasUserTasks = false;

  constructor() {
    makeAutoObservable(this);
  }

  setContainsUserTasks = (bool) => {
    this.hasUserTasks = bool;
  };

  detectContainsUserTasks = async (xml) => {
    try {
      if (xml) {
        const bpmnModdle = new BPMNModdle();
        const { elementsById } = await bpmnModdle.fromXML(xml);
        return containsUserTasks(Object.values(elementsById));
      }
    } catch (e) {
      console.info(e);
    }
    return false;
  };
}

export default new DetectUserTasksStore();
