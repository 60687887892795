/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function isEmailValid(email) {
  const regex = new RegExp(
    /^[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*@([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])?$/
  );

  return regex.test(email) && email.length <= 320;
}
