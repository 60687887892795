/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { trackingService } from 'services';

const eventTypes = [
  'tokenSimulation.toggleMode',
  'tokenSimulation.playSimulation',
  'tokenSimulation.pauseSimulation',
  'tokenSimulation.animationSpeedChanged'
];

export default function TokenSimulationTrackingExtension(eventBus) {
  eventTypes.forEach((eventType) => {
    eventBus.on(eventType, () => {
      trackingService.trackTokenSimulation(eventType);
    });
  });
}

TokenSimulationTrackingExtension.$inject = ['eventBus'];
