/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState } from 'react';
import { Tag, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, CodeSnippet } from '@carbon/react';

import { Dialog, Button, Input } from 'primitives';
import notificationStore from 'stores/NotificationStore';

import BPMNIcon from './BPMNIcon';
import PlayContext from '../PlayContext';
import Retry from '../icons/svgr/Retry.svg';
import * as Styled from './IncidentButton.styled';
import ActionButton from '../ActionButton';
import { trackResolveIncident } from '../tracking';
import { REQUEST_FAILURE } from '../utils/constants';

export default function IncidentButton({ incident }) {
  const context = useContext(PlayContext);
  const [isIncidentModalOpen, setIsIncidentModalOpen] = useState(false);
  const [scope, setScope] = useState('global');
  const [variablesToSet, setVariablesToSet] = useState('');

  const hasVariables = context.variables.length > 0;

  return (
    <>
      <ActionButton
        actions={[
          {
            label: 'Handle Incident',
            renderIcon: () => <Retry color="white" />,
            onClick: () => {
              setIsIncidentModalOpen(true);
            }
          }
        ]}
      />
      <Dialog open={isIncidentModalOpen} onClose={() => setIsIncidentModalOpen(false)}>
        <Dialog.Header>
          <Dialog.Title>Resolve Incident</Dialog.Title>
        </Dialog.Header>

        <Dialog.Content>
          <p>Error Message:</p>
          <CodeSnippet copyButtonDescription="Copy" type="multi" wrapText>
            {incident.errorMessage}
          </CodeSnippet>
          <p>
            <b>You may want to set variables in order to resolve this incident:</b>
          </p>
          <Styled.Form>
            <Styled.ScopeSection>
              <label>Scope</label>
              <Input type="text" value={scope} onChange={(evt) => setScope(evt.target.value)} />
            </Styled.ScopeSection>
            <Styled.VariableSetSection>
              <label>Variables (JSON)</label>
              <Styled.Textarea
                multiline
                rows="1"
                placeholder='{"variableName": "variableValue"}'
                value={variablesToSet}
                onChange={(evt) => setVariablesToSet(evt.target.value)}
              />
            </Styled.VariableSetSection>
          </Styled.Form>

          {hasVariables && (
            <details>
              <summary>Existing Variables</summary>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>Name</TableHeader>
                    <TableHeader>Value</TableHeader>
                    <TableHeader>Scope</TableHeader>
                    <TableHeader>Scope Element</TableHeader>
                    <TableHeader>Scope Key</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {context.variables.map((variable) => {
                    const hasGlobalScope = variable.scope.element.bpmnElementType === 'PROCESS';

                    return (
                      <TableRow key={variable.key}>
                        <TableCell>{variable.name}</TableCell>
                        <TableCell>
                          <CodeSnippet type="inline" hideCopyButton>
                            {variable.value}
                          </CodeSnippet>
                        </TableCell>
                        <TableCell>
                          <Tag type={hasGlobalScope ? 'blue' : 'gray'}>{hasGlobalScope ? 'global' : 'local'}</Tag>
                        </TableCell>
                        <TableCell>
                          <BPMNIcon type={variable.scope.element.bpmnElementType} />{' '}
                          {variable.scope.element.elementName || variable.scope.element.elementId}
                        </TableCell>
                        <TableCell>{variable.scope.key}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </details>
          )}
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={() => setIsIncidentModalOpen(false)} variant="secondary">
            Close
          </Button>
          <Button
            onClick={async () => {
              setIsIncidentModalOpen(false);
              let hasVariables = true;
              try {
                hasVariables = Object.keys(JSON.parse(variablesToSet || '{}')).length > 0;
              } catch (e) {
                // unparseable variable input
                // let's assume there are variables and let the backend figure it out
              }

              if (hasVariables) {
                const areVariablesSuccessfullyUpdated = await context.setVariables(
                  scope === 'global' ? context.instanceId : scope,
                  variablesToSet
                );

                if (!areVariablesSuccessfullyUpdated) {
                  notificationStore.showError(REQUEST_FAILURE);
                  return;
                }
              }

              if (incident.job?.key) {
                const isSuccess = await context.updateRetries(incident.job.key);
                if (!isSuccess) {
                  notificationStore.showError(REQUEST_FAILURE);
                  return;
                }
              }

              trackResolveIncident(context.processId);

              const response = await context.resolveIncident(incident.key);

              if (response?.success === false) {
                notificationStore.showError(REQUEST_FAILURE);
              }
            }}
            variant="primary"
          >
            Resolve Incident
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
}
