/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;
  ${(props) => (props.layout === 'inline' ? 'align-items: flex-end' : 'flex-direction: column')};
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  ${(props) => props.layout !== 'inline' && `margin-top: var(--spacing-small)`};

  > button:last-child {
    margin-left: var(--spacing-tiny);
  }
`;
