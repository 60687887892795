/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, useState } from 'react';

import { Dots } from 'icons';
import { Dropdown } from 'components';
import { milestoneStore } from 'stores';

import * as Styled from './RelatedDiagramMenu.styled';

export default function RelatedDiagramMenu({ version }) {
  const [anchorEl, setAnchorEl] = useState();

  const handleDropdownOpen = (evt) => {
    evt.stopPropagation();

    setAnchorEl(evt.currentTarget);
  };

  const handleDropdownClose = (evt) => {
    evt.stopPropagation();

    setAnchorEl();
  };

  const handleRestore = (evt) => {
    evt.stopPropagation();
    milestoneStore.restoreRelatedDiagram(version);
    setAnchorEl();
  };

  return (
    <Fragment>
      <Styled.RelatedDiagramButton data-test="version-menu" onClick={handleDropdownOpen}>
        <Dots />
      </Styled.RelatedDiagramButton>

      <Dropdown
        size="small"
        id="version-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleDropdownClose}
      >
        <Dropdown.ListItem data-test="open-version" to={`/diagrams/${version.id}/milestones`}>
          Open diagram
        </Dropdown.ListItem>
        <Dropdown.ListItem data-test="use-version-as-milestone" onClick={handleRestore}>
          Use as new milestone
        </Dropdown.ListItem>
      </Dropdown>
    </Fragment>
  );
}
