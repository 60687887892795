/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'primitives';

import * as Styled from './Button.styled';

const Button = forwardRef(
  ({ component, children, dropdown, disabled, loading, lightLoader, loaderSize, variant, ...props }, ref) => (
    <Styled.Wrapper
      as={component}
      ref={ref}
      $isDropdown={dropdown}
      disabled={loading || disabled}
      $variant={variant}
      {...props}
    >
      {loading ? (
        <>
          <Loader className="loader" $light={lightLoader} size={loaderSize} />
          <Styled.Content className="is-loading">{children}</Styled.Content>
        </>
      ) : (
        children
      )}
    </Styled.Wrapper>
  )
);

Button.propTypes = {
  dropdown: PropTypes.bool,
  component: PropTypes.any,
  variant: PropTypes.oneOf(['primary', 'danger-primary', 'secondary', 'text']),
  size: PropTypes.oneOf(['small', 'normal']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  lightLoader: PropTypes.bool,
  loaderSize: PropTypes.oneOf(['small', 'normal'])
};

Button.defaultProps = {
  variant: 'primary',
  size: 'normal',
  component: 'button',
  dropdown: false,
  disabled: false,
  loading: false,
  lightLoader: false,
  loaderSize: 'normal'
};

export default Button;
