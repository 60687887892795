/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { toPoint } from 'diagram-js/lib/util/Event';

export default (event, modeler) => {
  const point = toPoint(event.originalEvent);

  const canvas = modeler.get('canvas');
  const viewbox = canvas.viewbox();

  const clientRect = modeler._container.getBoundingClientRect();

  return {
    x: viewbox.x + Math.round((point.x - clientRect.left) / viewbox.scale) - 2,
    y: viewbox.y + Math.round((point.y - clientRect.top) / viewbox.scale) - 1
  };
};
