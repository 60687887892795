/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Properties = styled.div`
  height: 100%;

  &.with-save-as {
    .bio-properties-panel-header-labels {
      margin-right: 96px;
    }
  }
`;
