/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Button as CarbonButton } from '@carbon/react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const MainButton = styled(CarbonButton)`
  width: 42px;
`;

export const Dropdown = styled(CarbonButton)`
  width: 16px;

  > :first-child {
    min-width: 0;
    margin-top: 3px;
  }
`;
