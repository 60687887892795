/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { currentDiagramStore } from 'stores';

import { SaveConnectorConfig } from './SaveConnectorConfig';
import * as Styled from './Properties.styled';

const Properties = () => {
  const propertiesRef = useRef();

  const { modeler } = currentDiagramStore;
  const { isLoadingModeler } = currentDiagramStore.state;

  const attachPropertiesPanel = () => {
    try {
      const propertiesPanel = modeler.get('propertiesPanel');
      propertiesPanel.attachTo(propertiesRef.current);
    } catch (e) {
      // Could happen if the modeler does not have a properties panel.
      // This is the case when opening a dmn decision table from a linking bpmn diagram
      // it first loads the DRD and tries to attach the properties panel here. Meanwhile
      // the decision table is opened, which does not have a properties panel and this
      // function throws an exception.
    }
  };

  useEffect(() => {
    if (modeler && !isLoadingModeler && propertiesRef.current) {
      attachPropertiesPanel();

      /**
       * For DMN diagrams, if properties panel is dynamically attached,
       * it has to re-attached after every import
       **/
      if (!currentDiagramStore.isBPMN) {
        modeler.on('import.done', () => {
          attachPropertiesPanel();
        });
      }
    }
  }, [isLoadingModeler, modeler]);

  const [hasSaveAs, setHasSaveAs] = useState(false);

  return (
    <>
      <SaveConnectorConfig
        onActivate={(isActive) => {
          setHasSaveAs(isActive);
        }}
      />
      <Styled.Properties ref={propertiesRef} data-test="properties-panel" className={hasSaveAs ? 'with-save-as' : ''} />
    </>
  );
};

export default observer(Properties);
