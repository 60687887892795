/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default {
  setItem(key, value) {
    try {
      window.localStorage.setItem(key, value);
    } catch (ex) {
      // we don't notify users when this fails.
    }
  },
  getItem(key) {
    try {
      return window.localStorage.getItem(key);
    } catch (ex) {
      // we don't notify users when this fails.
      return null;
    }
  }
};
