/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Typography } from 'primitives';

export const Status = styled(Typography)`
  margin-right: var(--spacing-small);
`;

export const Action = styled(Typography)`
  color: var(--cds-blue-60);
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
`;
