/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export const BROWSE_MARKETPLACE_ONCLICK_EVENT = 'browseMarketplaceOnClickEvent';
export const BROWSE_MARKETPLACE_ONDESTROY_EVENT = 'browseMarketplaceOnDestroyEvent';

class BrowseMarketplaceButtonEventManager {
  constructor() {
    this.events = {};
  }

  subscribe(eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(callback);
  }

  unsubscribe(eventName, callback) {
    if (!this.events[eventName]) {
      return;
    }

    this.events[eventName] = this.events[eventName].filter((cb) => cb !== callback);
  }

  emit(eventName, ...args) {
    if (!this.events[eventName]) {
      return;
    }

    this.events[eventName].forEach((cb) => cb(...args));
  }
}

export default new BrowseMarketplaceButtonEventManager();
