/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';
import { Modal } from '@carbon/react';

export const ConnectToDiagramModal = styled(Modal)`
  .cds--modal-container {
    max-width: 520px;
  }

  .cds--modal-content {
    overflow: hidden;
  }
`;

export const ConnectFormGuidance = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 24px;
  padding-bottom: 24px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
