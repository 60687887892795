/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Dropdown } from 'components';
import { IconButton } from 'primitives';
import { Dots, Share, Milestone, Png, Svg, Xml, Editor } from 'icons';
import { currentDiagramStore, diagramExtensionStore, milestoneStore } from 'stores';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { trackingService } from 'services';
import hasAccess, { actions } from 'utils/user-access';
import { exportDiagramXML, exportDiagramSVG, exportDiagramPNG } from 'utils/file-io';
import createPermission from 'utils/createPermission';
import config from 'utils/config';
import { XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';

import * as Styled from './ActionsMenu.styled';

const ActionsMenu = () => {
  const iconSize = 18;

  const history = useHistory();
  const { isBPMN, isDMN } = currentDiagramStore;
  const { diagram, project } = currentDiagramStore.state;
  const { isImplementMode } = dedicatedModesStore;
  const { modeler } = currentDiagramStore;
  const { isEditorOpen } = XMLEditorStore;

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState(null);

  const shouldShowShareItem = isBPMN && isImplementMode && hasAccess(project, actions.SHARE_DIAGRAM);

  const isDeploymentEnabled = isBPMN ? config?.zeebe?.bpmnDeploymentEnabled : config?.zeebe?.dmnDeploymentEnabled;
  const isXMLEditorEnabled =
    ((isBPMN && isImplementMode) || isDMN) && hasAccess(project, actions.MODIFY_DIAGRAM) && !isEditorOpen;

  const historyIsPrimaryAction = isBPMN ? isImplementMode && !isDeploymentEnabled : !isDeploymentEnabled;

  const handleDownloadXML = () => {
    exportDiagramXML(modeler, diagram);

    const permission = createPermission(project?.permissionAccess);

    if (permission.is(['WRITE', 'ADMIN'])) {
      milestoneStore.createAutosaved(diagram, 'export', {
        name: 'Autosaved during export'
      });
    }
  };

  const handleEditXML = () => {
    XMLEditorStore.openEditor();
  };

  const trackMenuItemClick = (item) => {
    trackingService.trackActionsMenuItemClick(
      item,
      diagram.id,
      diagram.type,
      isBPMN ? dedicatedModesStore.selectedModeLabel : undefined
    );
  };

  return (
    <>
      <IconButton
        $isActive={Boolean(actionsMenuAnchorEl)}
        aria-haspopup="true"
        title="Browse more actions"
        onClick={(event) => {
          setActionsMenuAnchorEl(event.currentTarget);
          trackingService.trackActionsMenuOpening(
            diagram.id,
            diagram.type,
            isBPMN ? dedicatedModesStore.selectedModeLabel : undefined
          );
        }}
        data-test="actions-context-dropdown-button"
      >
        <Dots />
      </IconButton>

      <Dropdown
        anchorEl={actionsMenuAnchorEl}
        open={Boolean(actionsMenuAnchorEl)}
        onClose={() => setActionsMenuAnchorEl(null)}
        data-test="actions-context-dropdown"
      >
        {shouldShowShareItem && (
          <Dropdown.ListGroup>
            <Styled.ActionItem
              data-test="share"
              title="Share"
              onClick={() => {
                setActionsMenuAnchorEl(null);
                diagramExtensionStore.showShareModal();
                trackMenuItemClick('share');
              }}
            >
              <Share width={iconSize} height={iconSize} />
              Share
            </Styled.ActionItem>
          </Dropdown.ListGroup>
        )}

        {hasAccess(project, actions.VIEW_VERSIONS) && !historyIsPrimaryAction && (
          <Dropdown.ListGroup>
            <Styled.ActionItem
              data-test="milestone-history"
              title="Show milestones history"
              onClick={() => {
                history.push(`/diagrams/${diagram.id}/milestones`);
                trackMenuItemClick('open-milestones');
              }}
            >
              <Milestone width={iconSize} height={iconSize} />
              History
            </Styled.ActionItem>
          </Dropdown.ListGroup>
        )}

        <Dropdown.ListGroup>
          {isBPMN && (
            <>
              <Styled.ActionItem
                data-test="export-png"
                onClick={() => {
                  setActionsMenuAnchorEl(null);
                  exportDiagramPNG(modeler, diagram);
                  trackMenuItemClick('export-png');
                }}
              >
                <Png width={iconSize} height={iconSize} />
                Export as PNG image
              </Styled.ActionItem>
              <Styled.ActionItem
                data-test="export-svg"
                onClick={() => {
                  setActionsMenuAnchorEl(null);
                  exportDiagramSVG(modeler, diagram);
                  trackMenuItemClick('export-svg');
                }}
              >
                <Svg width={iconSize} height={iconSize} />
                Export as SVG image
              </Styled.ActionItem>
            </>
          )}

          <Styled.ActionItem
            data-test="export-xml"
            onClick={() => {
              setActionsMenuAnchorEl(null);
              handleDownloadXML();
              trackMenuItemClick('export-xml');
            }}
          >
            <Xml width={iconSize} height={iconSize} />
            Download as {isBPMN ? 'BPMN 2.0' : 'DMN 1.3'} XML
          </Styled.ActionItem>
        </Dropdown.ListGroup>

        {isXMLEditorEnabled && (
          <Dropdown.ListGroup>
            <Styled.ActionItem
              data-test="edit-xml"
              onClick={() => {
                setActionsMenuAnchorEl(null);
                handleEditXML();
                trackMenuItemClick('edit-xml');
              }}
            >
              <Editor width={iconSize} height={iconSize} />
              Edit XML
            </Styled.ActionItem>
          </Dropdown.ListGroup>
        )}
      </Dropdown>
    </>
  );
};

export default observer(ActionsMenu);
