/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Notification from './Notification';

export default function InstanceStartedNotification({ link }) {
  return <Notification message="Instance started!" link={link} linkText="View process instance" />;
}
