/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Sidebar } from 'primitives';

export const SidebarSection = styled(Sidebar.Section)`
  height: calc(100% - 39px);
`;
