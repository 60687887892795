/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { domify } from 'min-dom';

function Help(tokenSimulationPalette) {
  this._tokenSimulationPalette = tokenSimulationPalette;

  this._init();
}

Help.prototype._init = function () {
  this._paletteEntry = domify(`
    <a class="bts-entry" title="Learn more about Token Simulation on docs.camunda.io" href='https://docs.camunda.io/docs/components/modeler/web-modeler/token-simulation/' target="_blank">   
      <span class="bts-icon">
        <svg width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.21308 14C4.96998 14 5.60177 13.4045 5.60177 12.6626C5.60177 11.9332 4.96998 11.3314 4.21308 11.3314C3.44993 11.3314 2.82517 11.9332 2.82517 12.6626C2.82517 13.4045 3.44993 14 4.21308 14ZM4.95676 9.6758H3.65251C3.60145 9.67636 3.5508 9.6666 3.50364 9.64712C3.45647 9.62763 3.41376 9.59883 3.37809 9.56244C3.34243 9.52606 3.31454 9.48285 3.29612 9.43544C3.2777 9.38802 3.26914 9.33737 3.27094 9.28656C3.34991 7.60974 3.91282 6.84275 5.22958 5.93972C6.03887 5.3839 6.77543 4.74166 6.77543 3.74522C6.77543 2.56194 5.7691 1.87611 4.52898 1.87611C3.60788 1.87611 2.49364 2.39847 2.23326 3.55761C2.19182 3.76001 2.02449 3.92115 1.81806 3.92115H0.375416C0.153351 3.92115 -0.0264909 3.73665 0.00322208 3.51713C0.290969 1.36621 2.21293 0 4.52898 0C7.20627 0 9 1.59197 9 3.76546C9 5.27024 8.16569 6.21608 6.89194 7.00934C6.45395 7.32516 6.0878 7.72941 5.81739 8.19568C5.54697 8.66195 5.38952 9 5.36726 9.28656C5.3592 9.39046 5.3128 9.48771 5.237 9.55957C5.16121 9.63144 5.06138 9.67284 4.95676 9.6758Z" fill="currentColor"/>
        </svg>
      </span>
    </a>
  `);

  this._tokenSimulationPalette.addEntry(this._paletteEntry, 5);
};

Help.$inject = ['tokenSimulationPalette'];

export default {
  __init__: ['help'],
  help: ['type', Help]
};
