/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState } from 'react';

import { Dropdown } from 'components';

import DropdownIcon from './icons/svgr/Dropdown.svg';
import * as Styled from './ActionButton.styled';

export default function ActionButton({ actions, disabled }) {
  const [anchorEl, setAnchorEl] = useState();

  const [primaryAction, ...secondaryActions] = actions;

  return (
    <Styled.Container>
      <Styled.MainButton
        hasIconOnly
        iconDescription={primaryAction.label}
        size="sm"
        kind={primaryAction.secondary ? 'secondary' : 'primary'}
        disabled={disabled || primaryAction.disabled}
        renderIcon={primaryAction.renderIcon}
        onClick={primaryAction.onClick}
      />
      {secondaryActions.length > 0 && (
        <>
          <Styled.Dropdown
            hasIconOnly
            kind="secondary"
            iconDescription="Additional Actions"
            size="sm"
            disabled={disabled}
            onClick={(evt) => {
              setAnchorEl(evt.currentTarget);
            }}
            renderIcon={() => <DropdownIcon color="white" />}
          />
          <Dropdown align="left" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {secondaryActions.map((action) => {
              if (action.type === 'divider') return <Dropdown.ListItemDivider key={action.label} />;

              return (
                <Dropdown.ListItem
                  key={action.label}
                  onClick={(...args) => {
                    setAnchorEl(null);
                    return action?.onClick(...args);
                  }}
                >
                  {action.label}
                </Dropdown.ListItem>
              );
            })}
          </Dropdown>
        </>
      )}
    </Styled.Container>
  );
}
