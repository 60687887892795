/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { IconButton } from 'primitives';

export const RelatedDiagramButton = styled(IconButton)`
  position: absolute;
  right: var(--spacing-small);
  top: 17px;
  opacity: 0;
`;
