/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { action, makeObservable, observable } from 'mobx';

import { performLinting } from 'App/Pages/Diagram/lint-util';

export class LintingStore {
  lintErrors = [];

  constructor() {
    makeObservable(this, {
      lintErrors: observable,
      reset: action,
      setLintErrors: action
    });
  }

  reset = () => {
    this.lintErrors = [];
  };

  setLintErrors = (arr) => {
    this.lintErrors = arr;
  };

  performLinting = async (modeler, isImplementMode) => {
    // NOTE: there might be racing conditions when the linting is performed but the user has already left the page; this is why we catch the error here and we avoid to break the app.
    try {
      const lintErrors = await performLinting(modeler, isImplementMode);

      this.setLintErrors(lintErrors);

      const linting = modeler.get('linting', false);

      if (linting) {
        const canvasLintErrors = lintErrors.filter((e) => e.category !== 'info');

        linting.setErrors(canvasLintErrors);
        linting.activate();
      }

      return lintErrors;
    } catch (err) {
      console.info(err);
    }
  };
}

export default new LintingStore();
