/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState, useEffect, useRef } from 'react';
import { TextInput, Form } from '@carbon/react';

import { Dialog, Button } from 'primitives';
import notificationStore from 'stores/NotificationStore';

import PlayContext from '../PlayContext';
import * as Styled from './EditConnectorModal.styled';
import { REQUEST_FAILURE } from '../utils/constants';

export default function EditConnectorModal({ children, name, initialValue }) {
  const context = useContext(PlayContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [value, setValue] = useState(initialValue);
  const secretInputField = useRef();

  useEffect(() => {
    if (isModalOpen) {
      setValue(initialValue);
      setIsInProgress(false);
      secretInputField.current.focus();
    }
  }, [isModalOpen]);

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth={640}>
        <Dialog.Content>
          <Styled.Header>Update Connector Secret</Styled.Header>
          <Styled.SecretName>{name}</Styled.SecretName>
          <Form
            onSubmit={async (evt) => {
              evt.preventDefault();
              setIsInProgress(true);
              const isSuccess = await context.setConnectorSecret(name, value);

              if (!isSuccess) {
                notificationStore.showError(REQUEST_FAILURE);
              }

              setIsInProgress(false);
              setIsModalOpen(false);
            }}
          >
            <TextInput.PasswordInput
              id="secret-password"
              type="password"
              value={value}
              onChange={(evt) => setValue(evt.target.value)}
              labelText="New Value"
              autoComplete="false"
              ref={secretInputField}
              disabled={isInProgress}
            />
          </Form>
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={() => setIsModalOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={isInProgress}
            onClick={async () => {
              setIsInProgress(true);
              const isSuccess = await context.setConnectorSecret(name, value);

              if (!isSuccess) {
                notificationStore.showError(REQUEST_FAILURE);
              }

              setIsInProgress(false);
              setIsModalOpen(false);
            }}
            variant="primary"
          >
            Update
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
}
