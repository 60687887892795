/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

/**
 * Accepts a valid bpmn 2.0 xml and returns all the bpmn colors (bioc) replaced with white background
 * and black border (like the default, without bpmn colors).
 * We use this to remove colors while diffing a diagram, so the colors do not disturb while looking at diffing colors
 * and effects.
 * @param {*} xml
 */
export default function shadedDiagram(xml) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const shapes = xmlDoc.getElementsByTagName('bpmndi:BPMNShape');

  for (let item of shapes) {
    const fill = item.getAttribute('bioc:fill');
    const stroke = item.getAttribute('bioc:stroke');

    if (fill) {
      item.setAttribute('bioc:fill', '#FFF');
    }
    if (stroke) {
      item.setAttribute('bioc:stroke', '#000');
    }
  }

  return new XMLSerializer().serializeToString(xmlDoc);
}
