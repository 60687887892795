/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default {
  TOS_SAAS: 'https://camunda.com/legal/terms/camunda-platform/camunda-platform-8-saas-trial',
  BPMN_GUIDE: 'https://camunda.com/bpmn',
  DMN_GUIDE: 'https://docs.camunda.io/docs/components/modeler/dmn',
  FORM_EMBEDDING:
    'https://docs.camunda.io/docs/next/components/modeler/web-modeler/advanced-modeling/form-linking/#using-the-properties-panel',
  FORM_GUIDE: 'https://docs.camunda.io/docs/next/guides/utilizing-forms',
  FORM_LINKING: 'https://docs.camunda.io/docs/next/components/modeler/web-modeler/advanced-modeling/form-linking',
  FORM_REFERENCE: 'https://docs.camunda.io/docs/components/modeler/forms/camunda-forms-reference',
  CONNECTOR_TEMPLATE_GUIDE: 'https://docs.camunda.io/docs/components/connectors/introduction-to-connectors',
  DOCUMENTATION: 'https://docs.camunda.io',
  ACADEMY: 'https://academy.camunda.com',
  JIRA_SUPPORT: 'https://jira.camunda.com/projects/SUPPORT/queues',
  FORUM_SUPPORT: 'https://forum.camunda.io',
  SLACK: 'https://camunda-slack-invite.herokuapp.com',
  PRIVACY_POLICY: 'https://camunda.com/legal/privacy',
  IMPRINT: 'https://camunda.com/legal/imprint'
};
