/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { InvitationModal, ShareView } from 'components';
import { Dialog } from 'primitives';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { diagramExtensionStore, currentDiagramStore, invitationModalStore } from 'stores';

import KeyboardShortcuts from './KeyboardShortcuts';

const Extensions = () => {
  const { hideShortcutModal, hideShareModal } = diagramExtensionStore;
  const { isShortcutModalVisible, isShareModalVisible } = diagramExtensionStore.state;
  const { project, diagram } = currentDiagramStore.state;
  const { isDesignMode } = dedicatedModesStore;

  return (
    <div>
      <Dialog open={isShortcutModalVisible} onClose={hideShortcutModal}>
        <Dialog.Header>
          <Dialog.Title>Keyboard shortcuts</Dialog.Title>
        </Dialog.Header>

        <Dialog.Content>
          <KeyboardShortcuts diagram={diagram} isDesignMode={isDesignMode} />
        </Dialog.Content>
      </Dialog>
      {invitationModalStore.state.isInvitationModalVisible && (
        <InvitationModal project={project} onClose={invitationModalStore.hide} />
      )}
      {isShareModalVisible && <ShareView diagram={diagram} onClose={hideShareModal} />}
    </div>
  );
};

export default observer(Extensions);
