/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  height: 100%;
`;

export const Header = styled.header`
  padding: 16px;
`;

export const Footer = styled.footer`
  padding: 16px;
  display: flex;
  align-items: center;

  ${({ align }) => {
    if (align == 'right') {
      return `
        justify-content: flex-end;
      
        > button,
        > a {
          margin-left: var(--spacing-small);
        }
      `;
    }
  }}

  ${({ $bordered }) => {
    if ($bordered) {
      return `
        border-top: 1px solid var(--silver-darken-94);
      `;
    }
  }}
`;

export const Content = styled.div`
  padding: 0 16px;
  flex: 1;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 16px;
`;

export const Subtitle = styled.p`
  margin: 0;
  color: var(--grey-base-40);
`;
