/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useHistory } from 'react-router-dom';
import { Button as CarbonButton } from '@carbon/react';

import createForm from './createForm';

export default function CreateNewForm({ target }) {
  const history = useHistory();

  return (
    <CarbonButton size="sm" kind="ghost" onClick={() => createForm(target, history)}>
      + Create new form
    </CarbonButton>
  );
}
