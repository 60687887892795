/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';

import { INPUT_KEY } from 'App/Pages/Diagram/Deployment/executeModal/sm/constants';

export default (validationErrors, connectionErrors) => {
  const [inputKeyToErrorMessages, setInputKeyToErrorMessages] = useState({});
  useEffect(() => {
    const mapping = {};
    Object.values(INPUT_KEY).forEach((fieldName) => {
      mapping[fieldName] = validationErrors[fieldName] || connectionErrors[fieldName];
    });
    setInputKeyToErrorMessages(mapping);
  }, [validationErrors, connectionErrors]);

  return { inputKeyToErrorMessages };
};
