/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { FormLinter } from '@camunda/form-linting';

export const performLinting = async (formDefinition) => {
  return await new FormLinter().lint(formDefinition);
};
