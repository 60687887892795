/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Tab, TabPanels, Tabs } from '@carbon/react';

import { currentDiagramStore, commentsStore } from 'stores';
import { detailsPanelTabsStore, inboundConnectorStore, publicationStore } from 'App/Pages/Diagram/stores';
import { trackingService } from 'services';
import { Details } from 'icons';
import { Sidebar, Tooltip } from 'primitives';
import capitalize from 'utils/capitalize';
import { CallingProcesses, callingProcessesStore } from 'App/Pages/Diagram/CallingProcesses';

import CommentList from './CommentList';
import CommentForm from './CommentForm';
import Properties from './Properties';
import { InboundConnector } from './InboundConnector';
import { Publication } from './Publication';
import * as Styled from './DetailsPanel.styled';

export const DetailsPanel = ({ permission, allowComments, showOnlyComments, showSidebarTitle }) => {
  const { isSidebarVisible } = commentsStore.state;
  const { selectedTabIndex, isInboundConnectorTabSelected, isPublicationTabSelected } = detailsPanelTabsStore;
  const { isSelectedElementInboundConnector, selectedInboundFunctionality } = inboundConnectorStore;
  const { isSelectedElementStartEvent } = publicationStore;
  const [shouldAutofocus, setShouldAutofocus] = useState(true);

  if (permission.is(['COMMENT']) && !allowComments) {
    // if the user is only allowed to comment (and has no access to the properties tab)
    // but commenting is not allowed via props, we don't render anything
    return null;
  }

  const { showCallingProcesses } = callingProcessesStore;

  const deriveAutofocusOnClick = (event) => {
    const { target } = event;
    setShouldAutofocus(!target.classList.contains('mode-tab') && !target.classList.contains('sidebar-tab'));
  };

  useEffect(() => {
    document.body.addEventListener('click', deriveAutofocusOnClick, true);

    return () => {
      document.body.removeEventListener('click', deriveAutofocusOnClick, true);
    };
  }, []);

  useEffect(() => {
    detailsPanelTabsStore?.init();

    return () => {
      detailsPanelTabsStore?.reset();
    };
  }, [detailsPanelTabsStore]);

  const { tabsList, tabsPanels } = renderTabs({
    isSelectedElementInboundConnector,
    isSelectedElementStartEvent,
    isInboundConnectorTabSelected,
    isPublicationTabSelected,
    selectedInboundFunctionality,
    showCallingProcesses,
    shouldAutofocus,
    showSidebarTitle
  });
  const hasVisibleTabs = tabsList && tabsPanels;

  return (
    <Sidebar $isVisible={isSidebarVisible} data-test="diagram-sidebar">
      <Sidebar.Toggle
        onClick={commentsStore.toggleSidebar}
        type="button"
        title={isSidebarVisible ? 'Close Details Panel' : 'Open Details Panel'}
        data-test="details-panel-toggle"
      >
        <Details width="20" height="20" />
        Details
      </Sidebar.Toggle>

      {isSidebarVisible && (
        <Sidebar.Inner data-test="details-panel-wrapper">
          <CallingProcesses type={`${currentDiagramStore.isBPMN ? 'process' : 'decision'}`} />

          {allowComments ? (
            permission.is(['COMMENT']) || showOnlyComments ? (
              <Styled.CommentsSection $showCallingProcesses={showCallingProcesses}>
                <CommentsSection shouldAutofocus={shouldAutofocus} showSidebarTitle={showSidebarTitle} />
              </Styled.CommentsSection>
            ) : (
              hasVisibleTabs && (
                <Styled.TabsContainer>
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    onChange={(e) => detailsPanelTabsStore.switchTab(e.selectedIndex)}
                  >
                    <Styled.TabList aria-label="Details panel tabs" activation="manual">
                      {tabsList.map((tab) => tab)}
                      <Styled.SpaceFiller />
                    </Styled.TabList>

                    <TabPanels>{tabsPanels.map((panel) => panel)}</TabPanels>
                  </Tabs>
                </Styled.TabsContainer>
              )
            )
          ) : (
            <Styled.PropertiesSection $showSidebarTitle={showSidebarTitle} $showCallingProcesses={showCallingProcesses}>
              <Properties />
            </Styled.PropertiesSection>
          )}
        </Sidebar.Inner>
      )}
    </Sidebar>
  );
};

const CommentsSection = observer(({ showSidebarTitle, shouldAutofocus }) => {
  return (
    <>
      {showSidebarTitle && (
        <Tooltip title={commentsStore.sidebarTitle} showOnlyOnOverflow>
          <Styled.CommentsTitle data-test="details-panel-title" className="overflow-ellipsis">
            {commentsStore.sidebarTitle}
          </Styled.CommentsTitle>
        </Tooltip>
      )}
      <Styled.CommentsWrapper>
        <CommentList />
        <CommentForm autofocus={shouldAutofocus} />
      </Styled.CommentsWrapper>
    </>
  );
});

const renderTabs = ({
  isSelectedElementInboundConnector,
  isSelectedElementStartEvent,
  isInboundConnectorTabSelected,
  isPublicationTabSelected,
  selectedInboundFunctionality,
  showCallingProcesses,
  shouldAutofocus,
  showSidebarTitle
}) => {
  const tabsList = [
    <Tab className="sidebar-tab" title="Properties" key="properties-tab">
      Properties
    </Tab>,
    <Tab className="sidebar-tab" title="Comments" key="comments-tab">
      Comments
    </Tab>
  ];

  const tabsPanels = [
    <Styled.TabPanel key="properties-panel" $showCallingProcesses={showCallingProcesses}>
      <Properties />
    </Styled.TabPanel>,
    <Styled.TabPanel key="comments-panel" $showCallingProcesses={showCallingProcesses}>
      <CommentsSection shouldAutofocus={shouldAutofocus} showSidebarTitle={showSidebarTitle} />
    </Styled.TabPanel>
  ];

  if (isSelectedElementInboundConnector) {
    tabsList.push(
      <Tab
        className="sidebar-tab"
        title={selectedInboundFunctionality}
        key={`${selectedInboundFunctionality}-tab`}
        onClick={() => {
          trackingService.trackInboundConnectorTabClick();
        }}
      >
        {capitalize(selectedInboundFunctionality)}
      </Tab>
    );

    tabsPanels.push(
      <Styled.TabPanel key={`${selectedInboundFunctionality}-panel`}>
        {isInboundConnectorTabSelected && <InboundConnector />}
      </Styled.TabPanel>
    );
  }

  if (!isSelectedElementInboundConnector && isSelectedElementStartEvent) {
    tabsList.push(
      <Tab
        className="sidebar-tab"
        title="Publication"
        key="publication-tab"
        onClick={() => {
          trackingService.trackPublicationTabClick();
        }}
      >
        Publication
      </Tab>
    );

    tabsPanels.push(
      <Styled.TabPanel key="publication-panel">{isPublicationTabSelected && <Publication />}</Styled.TabPanel>
    );
  }

  return {
    tabsList,
    tabsPanels
  };
};

export default observer(DetailsPanel);
