/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useHistory } from 'react-router-dom';

import { Button } from 'primitives';
import * as Styled from 'components/TargetSelector/EmptyState.styled';

import { FormPreview } from './icons';
import createForm from './createForm';

export default function FormLinkEmptyState({ target }) {
  const history = useHistory();

  return (
    <Styled.Container>
      <Styled.Content>
        <FormPreview />
        <Styled.Title>
          <strong>No form has been created in this project</strong>
        </Styled.Title>
        <div>To link a form into your element, you first need to create one.</div>
      </Styled.Content>
      <Button onClick={() => createForm(target, history)}>Create new form</Button>
    </Styled.Container>
  );
}
