/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const ElementMenuButton = styled.button`
  padding: 0;
  border: none;
  border-radius: 2px;
  color: white;
  outline: none;
  cursor: pointer;
  background-color: var(--cds-blue-60);

  &:hover,
  &:focus {
    background-color: var(--cds-blue-60);
  }

  &:active {
    background-color: var(--cds-blue-70);
  }

  > svg {
    vertical-align: middle;
  }
`;
