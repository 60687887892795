/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';

import * as Styled from './Divider.styled';

Styled.Divider.propTypes = {
  variant: PropTypes.oneOf(['big', 'normal', 'none'])
};

Styled.Divider.defaultProps = {
  variant: 'normal'
};

export default Styled.Divider;
