/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment } from 'react';

import { diffingStore } from 'stores';

import * as Styled from './ListOfChanges.styled';

export const changeText = (property, before, after, removed) => {
  if (before && after) {
    return (
      <Fragment>
        <strong>{property}</strong> changed from <strong>{before}</strong> to <strong>{after}</strong>{' '}
        {removed && `(got removed)`}
      </Fragment>
    );
  }

  if (!before && after) {
    return (
      <Fragment>
        <strong>{property}</strong> was set{' '}
        {after !== true && (
          <Fragment>
            to <strong>{after}</strong>
          </Fragment>
        )}
      </Fragment>
    );
  }

  if (before && !after) {
    return (
      <Fragment>
        <strong>{property}</strong> with value <strong>{before}</strong> was removed
      </Fragment>
    );
  }

  if (!before && !after) {
    return (
      <Fragment>
        <strong>{property}</strong> was removed
      </Fragment>
    );
  }
};

const generateMultiPhrase = (originalItems) => {
  const items = JSON.parse(JSON.stringify(originalItems));

  if (items.length === 0) {
    return null;
  }
  if (items.length === 1) {
    return (
      <Fragment>
        <strong>{items[0]}</strong> has been changed.
      </Fragment>
    );
  }
  if (items.length === 2) {
    return (
      <Fragment>
        <strong>{items[0]}</strong> and <strong>{items[1]}</strong> have been changed.
      </Fragment>
    );
  }
  if (items.length > 2) {
    const lastItem = items.splice(-1);
    return (
      <Fragment>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <strong>{item}</strong>
            {index !== items.length - 1 && ', '}
          </Fragment>
        ))}{' '}
        and <strong>{lastItem}</strong> have been changed.
      </Fragment>
    );
  }
};

const DiffSummary = ({ items, handleLinkClick }) => {
  const changedProperties = {};

  items.forEach(({ before, after, property }) => {
    if (before || after) {
      changedProperties[property] = true;
    }
  });

  const changedPropertiesArray = Object.keys(changedProperties);
  const changedPropertiesText =
    items.length <= 3 ? (
      generateMultiPhrase(changedPropertiesArray)
    ) : (
      <Fragment>
        <strong>{items.length}</strong> properties have been changed.
      </Fragment>
    );

  return (
    <div>
      {changedPropertiesText}
      <br />
      <br />
      <Styled.LinkText onClick={handleLinkClick}>MORE&hellip;</Styled.LinkText>
    </div>
  );
};

export default function ListOfChanges({ items, elementType }) {
  const handleLinkClick = () => {
    diffingStore.diffingDetailsContent = items;
    diffingStore.showDiffingDetails();
  };

  return (
    <Styled.List data-test="list-of-changes">
      {items && items.length === 1 && changeText(items[0].property, items[0].before, items[0].after, items[0].removed)}

      {items && items.length > 1 && <DiffSummary items={items} handleLinkClick={handleLinkClick} />}

      {!items && (
        <Fragment>
          <strong>{elementType}</strong> was added
        </Fragment>
      )}
    </Styled.List>
  );
}
