/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { milestoneStore } from 'stores';
import { Tooltip } from 'primitives';
import { Diagram } from 'icons';

import * as Styled from './RelatedDiagram.styled';
import RelatedDiagramMenu from './RelatedDiagramMenu';

class RelatedDiagram extends Component {
  handleClick = () => {
    if (milestoneStore.state.isDiffingEnabled) {
      milestoneStore.setSecondary(this.props.version.id);
    }
  };

  handleNavigation = () => {
    this.props.history.push(`/diagrams/${this.props.version.id}/milestones`);
  };

  render() {
    const { version } = this.props;
    const { isSecondarySelected } = milestoneStore;

    return (
      <Styled.RelatedDiagramEntry
        isSelectable={milestoneStore.state.isDiffingEnabled}
        isSelected={isSecondarySelected(version.id)}
        onClick={this.handleClick}
        data-test="version"
      >
        <Diagram width="24" height="24" />

        <Styled.RelatedDiagramContent>
          <Tooltip title={version.diagram} align="bottom" justify="left" showOnlyOnOverflow>
            <Styled.RelatedDiagramTitle>{version.diagram}</Styled.RelatedDiagramTitle>
          </Tooltip>
          <Tooltip title={`Project: ${version.name}`} align="bottom" justify="left" showOnlyOnOverflow>
            <Styled.RelatedDiagramParagraph>Project: {version.name}</Styled.RelatedDiagramParagraph>
          </Tooltip>
          <Styled.RelatedDiagramParagraph>
            Last changed:
            {moment(version.lastChanged).format('D MMMM YYYY')}
          </Styled.RelatedDiagramParagraph>
        </Styled.RelatedDiagramContent>

        <RelatedDiagramMenu version={version} />
      </Styled.RelatedDiagramEntry>
    );
  }
}

export default withRouter(observer(RelatedDiagram));
