/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import {
  lightGreen,
  lightOrange,
  lightRed,
  diffingAddedStyles,
  diffingChangedStyles,
  diffingRemovedStyles
} from 'utils/diffing';

export const Container = styled.div`
  display: flex;
  flex: 1;

  .added {
    background-color: ${lightGreen};
  }
  .changed {
    background-color: ${lightOrange};
  }

  .removed {
    background-color: ${lightRed};
  }

  .rounded {
    border-radius: 50%;
    padding: 6px;
    color: white;
    width: 28px;
    height: 28px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
    border: 2px solid white;
  }

  ${diffingAddedStyles};
  ${diffingChangedStyles};
  ${diffingRemovedStyles};
`;
