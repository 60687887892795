/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Checkbox, Dialog, IconButton, Input, Radio } from 'primitives';
import { Pencil } from 'icons';
import { isLinkingImprovementsModalVisibleStore } from 'App/Pages/Diagram/stores';
import { deploymentStore, deploymentPermissionsStore } from 'App/Pages/Diagram/Deployment/stores';
import { AddVariable, useExecutionPayload } from 'App/Pages/Diagram/Deployment/executeModal/AddVariable';
import DeployLinkedDiagramInfo from 'App/Pages/Diagram/Deployment/executeModal/DeployLinkedDiagramInfo';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';

import { INPUT_KEY } from './constants';
import {
  useCheckConnection,
  useClusterInfo,
  useCollapseClusterInfo,
  useDisableErrorsForAllFieldsOnClose,
  useEnableErrorForField,
  useInputKeyToErrorMessage,
  useRememberCredentials,
  useValidation
} from './hooks';
import * as Styled from './ExecuteModal.styled';
import ZeebeClientService from './ZeebeClientService';

export const ExecuteModal = ({ open, onClose, deployOnly }) => {
  // Cluster info (data from input fields, in one object)
  const { clusterInfo, setClusterInfo, setClusterInfoField } = useClusterInfo();
  // Collapse cluster details by default (for start instance) if all details are properly filled in
  const { isClusterInfoCollapsed, setIsClusterInfoCollapsed } = useCollapseClusterInfo(
    deployOnly,
    clusterInfo,
    open,
    clusterInfo[INPUT_KEY.AUTHENTICATION]
  );
  const { areCredentialsRemembered, setAreCredentialsRemembered } = useRememberCredentials(
    clusterInfo,
    setClusterInfo,
    open
  );

  const { validationErrors } = useValidation(clusterInfo);
  const { connectionErrors, checkConnection } = useCheckConnection(clusterInfo);
  const { inputKeyToErrorMessages } = useInputKeyToErrorMessage(validationErrors, connectionErrors);
  const { isErrorEnabledForField, enableErrorForField, enableErrorsForAllFields, disableErrorsForAllFields } =
    useEnableErrorForField(validationErrors, connectionErrors);
  useDisableErrorsForAllFieldsOnClose(disableErrorsForAllFields, open);

  const [payload, setPayload, isValidPayload] = useExecutionPayload();
  const { isLoading: isFormLinkingLoading } = formLinkStore;

  /**
   * If we have the cluster info at the beginning, we check the connection.
   */
  useEffect(() => {
    if (open && clusterInfo) {
      checkConnectionIfNoValidationError();
    }
  }, [open]);

  const checkConnectionIfNoValidationError = async () => {
    if (!isErrorPresent(validationErrors) && isValidPayload) {
      try {
        const errors = await checkConnection();
        if (isErrorPresent(errors)) {
          setIsClusterInfoCollapsed(false);
        }
        return errors;
      } catch (e) {
        return {};
      }
    }
    return {};
  };

  const handleBlur = async (fieldName) => {
    enableErrorForField(fieldName);
    checkConnectionIfNoValidationError();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDeployClick = async () => {
    setIsSubmitting(true);
    const connectionErrors = await checkConnectionIfNoValidationError();
    const canBeDeployed = isValidPayload && !isErrorPresent(validationErrors) && !isErrorPresent(connectionErrors);
    if (canBeDeployed) {
      if (deployOnly) {
        deploymentStore.deploy(clusterInfo);
      } else {
        deploymentStore.execute(clusterInfo, payload.trim() ? JSON.parse(payload) : {});
      }
      // We need to perform an additional call in order to retrieve the cluster version (hook is not retrieving it)
      const { clusterVersion } = await ZeebeClientService.checkConnection(clusterInfo);
      isLinkingImprovementsModalVisibleStore.setSelectedClusterVersion(clusterVersion);
      onClose({
        hasBeenExecutedOnClusterLT8_4: isLinkingImprovementsModalVisibleStore.isClusterVersionLT8_4(clusterVersion)
      });
    } else {
      enableErrorsForAllFields();
    }
    setIsSubmitting(false);
  };

  function getDisabledButtonText() {
    if (formLinkStore.diagramHasConflictingLinkedForms) {
      return 'Diagram has conflicting linked forms';
    }

    return '';
  }

  const { isAdminOrEditor, isAllowedToStartInstance } = deploymentPermissionsStore;
  const isAllowed = deployOnly ? isAdminOrEditor : isAllowedToStartInstance;

  return (
    <Dialog open={open} onClose={onClose} clickOutsideToClose={false}>
      <Dialog.Header>
        <Dialog.Title>{deployOnly ? 'Deploy diagram' : 'Start instance'}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Content>
        <Styled.SectionHeader>Diagram deployment endpoint configuration</Styled.SectionHeader>
        <Styled.ClusterInfo>
          {isClusterInfoCollapsed ? (
            <>
              <Styled.Field>
                <Input
                  label={'Cluster endpoint'}
                  value={clusterInfo[INPUT_KEY.ENDPOINT]}
                  onChange={(evt) => setClusterInfoField(INPUT_KEY.ENDPOINT, evt.target.value)}
                  error={isErrorEnabledForField[INPUT_KEY.ENDPOINT] && inputKeyToErrorMessages[INPUT_KEY.ENDPOINT]}
                  errorMessage={inputKeyToErrorMessages[INPUT_KEY.ENDPOINT]}
                  disabled
                  addon={
                    <IconButton
                      aria-label="Edit cluster info"
                      title="Edit cluster info"
                      onClick={() => setIsClusterInfoCollapsed(false)}
                    >
                      <Pencil width="16" height="16" />
                    </IconButton>
                  }
                  onBlur={() => handleBlur(INPUT_KEY.ENDPOINT)}
                />
              </Styled.Field>
              {clusterInfo[INPUT_KEY.AUTHENTICATION] === 'oauth' && (
                <Styled.Field>
                  <Input
                    label={'Tenant ID'}
                    value={clusterInfo[INPUT_KEY.TENANT_ID]}
                    onChange={(evt) => setClusterInfoField(INPUT_KEY.TENANT_ID, evt.target.value)}
                    error={isErrorEnabledForField[INPUT_KEY.TENANT_ID] && inputKeyToErrorMessages[INPUT_KEY.TENANT_ID]}
                    errorMessage={inputKeyToErrorMessages[INPUT_KEY.TENANT_ID]}
                    disabled
                    addon={
                      <IconButton
                        aria-label="Edit tenant identifier"
                        title="Edit tenant identifier"
                        onClick={() => setIsClusterInfoCollapsed(false)}
                      >
                        <Pencil width="16" height="16" />
                      </IconButton>
                    }
                    onBlur={() => handleBlur(INPUT_KEY.TENANT_ID)}
                  />
                </Styled.Field>
              )}
            </>
          ) : (
            <>
              <Styled.Field>
                <Input
                  label={'Cluster endpoint'}
                  value={clusterInfo[INPUT_KEY.ENDPOINT]}
                  onChange={(evt) => setClusterInfoField(INPUT_KEY.ENDPOINT, evt.target.value)}
                  error={isErrorEnabledForField[INPUT_KEY.ENDPOINT] && inputKeyToErrorMessages[INPUT_KEY.ENDPOINT]}
                  errorMessage={inputKeyToErrorMessages[INPUT_KEY.ENDPOINT]}
                  onBlur={() => handleBlur(INPUT_KEY.ENDPOINT)}
                  disabled={isSubmitting}
                />
              </Styled.Field>
              {clusterInfo[INPUT_KEY.AUTHENTICATION] === 'oauth' && (
                <Styled.Field>
                  <Input
                    label={'Tenant ID'}
                    placeholder={'Optional'}
                    value={clusterInfo[INPUT_KEY.TENANT_ID]}
                    onChange={(evt) => setClusterInfoField(INPUT_KEY.TENANT_ID, evt.target.value)}
                    error={isErrorEnabledForField[INPUT_KEY.TENANT_ID] && inputKeyToErrorMessages[INPUT_KEY.TENANT_ID]}
                    errorMessage={inputKeyToErrorMessages[INPUT_KEY.TENANT_ID]}
                    onBlur={() => handleBlur(INPUT_KEY.TENANT_ID)}
                    disabled={isSubmitting}
                  />
                </Styled.Field>
              )}
              <Styled.Field>
                {'Authentication'}
                <div>
                  <Styled.Radio>
                    <Radio
                      name="none"
                      value="none"
                      label="None"
                      selected={clusterInfo[INPUT_KEY.AUTHENTICATION]}
                      onSelect={(val) => setClusterInfoField(INPUT_KEY.AUTHENTICATION, val)}
                      disabled={isSubmitting}
                    />
                  </Styled.Radio>
                  <Styled.Radio>
                    <Radio
                      name="oauth"
                      value="oauth"
                      label="OAuth"
                      selected={clusterInfo[INPUT_KEY.AUTHENTICATION]}
                      onSelect={(val) => setClusterInfoField(INPUT_KEY.AUTHENTICATION, val)}
                      disabled={isSubmitting}
                    />
                  </Styled.Radio>
                </div>
              </Styled.Field>
              {clusterInfo[INPUT_KEY.AUTHENTICATION] === 'oauth' && (
                <>
                  <Styled.Field>
                    <Input
                      label={'Client ID'}
                      value={clusterInfo[INPUT_KEY.CLIENT_ID]}
                      onChange={(evt) => setClusterInfoField(INPUT_KEY.CLIENT_ID, evt.target.value)}
                      error={
                        isErrorEnabledForField[INPUT_KEY.CLIENT_ID] && inputKeyToErrorMessages[INPUT_KEY.CLIENT_ID]
                      }
                      errorMessage={inputKeyToErrorMessages[INPUT_KEY.CLIENT_ID]}
                      onBlur={() => handleBlur(INPUT_KEY.CLIENT_ID)}
                      disabled={isSubmitting}
                    />
                  </Styled.Field>
                  <Styled.Field>
                    <Input
                      label={'Client secret'}
                      type="password"
                      value={clusterInfo[INPUT_KEY.CLIENT_SECRET]}
                      onChange={(evt) => setClusterInfoField(INPUT_KEY.CLIENT_SECRET, evt.target.value)}
                      error={
                        isErrorEnabledForField[INPUT_KEY.CLIENT_SECRET] &&
                        inputKeyToErrorMessages[INPUT_KEY.CLIENT_SECRET]
                      }
                      errorMessage={inputKeyToErrorMessages[INPUT_KEY.CLIENT_SECRET]}
                      onBlur={() => handleBlur(INPUT_KEY.CLIENT_SECRET)}
                      disabled={isSubmitting}
                    />
                  </Styled.Field>
                  <Styled.Field>
                    <Input
                      label={'OAuth Token URL'}
                      value={clusterInfo[INPUT_KEY.OAUTH_URL]}
                      onChange={(evt) => setClusterInfoField(INPUT_KEY.OAUTH_URL, evt.target.value)}
                      error={
                        isErrorEnabledForField[INPUT_KEY.OAUTH_URL] && inputKeyToErrorMessages[INPUT_KEY.OAUTH_URL]
                      }
                      errorMessage={inputKeyToErrorMessages[INPUT_KEY.OAUTH_URL]}
                      onBlur={() => handleBlur(INPUT_KEY.OAUTH_URL)}
                      disabled={isSubmitting}
                    />
                  </Styled.Field>
                  <Styled.Field>
                    <Input
                      label={'OAuth Audience'}
                      value={clusterInfo[INPUT_KEY.AUDIENCE]}
                      onChange={(evt) => setClusterInfoField(INPUT_KEY.AUDIENCE, evt.target.value)}
                      error={isErrorEnabledForField[INPUT_KEY.AUDIENCE] && inputKeyToErrorMessages[INPUT_KEY.AUDIENCE]}
                      errorMessage={inputKeyToErrorMessages[INPUT_KEY.AUDIENCE]}
                      onBlur={() => handleBlur(INPUT_KEY.AUDIENCE)}
                      disabled={isSubmitting}
                    />
                  </Styled.Field>
                  <Styled.Field>
                    <Input
                      label={'OAuth Scope'}
                      value={clusterInfo[INPUT_KEY.SCOPE]}
                      placeholder="Optional"
                      onChange={(evt) => setClusterInfoField(INPUT_KEY.SCOPE, evt.target.value)}
                      error={isErrorEnabledForField[INPUT_KEY.SCOPE] && inputKeyToErrorMessages[INPUT_KEY.SCOPE]}
                      errorMessage={inputKeyToErrorMessages[INPUT_KEY.SCOPE]}
                      onBlur={() => handleBlur(INPUT_KEY.SCOPE)}
                      disabled={isSubmitting}
                    />
                  </Styled.Field>
                </>
              )}
            </>
          )}
        </Styled.ClusterInfo>
        <DeployLinkedDiagramInfo />
        {!deployOnly && (
          <AddVariable
            payload={payload}
            setPayload={setPayload}
            isValidPayload={isValidPayload}
            disabled={isSubmitting}
          />
        )}
      </Dialog.Content>
      <Dialog.Footer justify="space-between">
        <div>
          {clusterInfo[INPUT_KEY.AUTHENTICATION] === 'oauth' && (
            <Checkbox
              label="Remember credentials"
              onChange={() => setAreCredentialsRemembered(!areCredentialsRemembered)}
              checked={areCredentialsRemembered}
            />
          )}
        </div>

        <div>
          <Styled.Button variant={'secondary'} onClick={onClose}>
            Cancel
          </Styled.Button>
          <Styled.Button
            onClick={handleDeployClick}
            loading={isSubmitting}
            disabled={!isAllowed || isFormLinkingLoading || formLinkStore.diagramHasConflictingLinkedForms}
            lightLoader
            title={formLinkStore.diagramHasConflictingLinkedForms ? getDisabledButtonText() : ''}
          >
            {deployOnly ? 'Deploy' : 'Run'}
          </Styled.Button>
        </div>
      </Dialog.Footer>
    </Dialog>
  );
};

const isErrorPresent = (errors) => {
  return errors && typeof errors === 'object' && Object.keys(errors).length > 0;
};

ExecuteModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  deployOnly: PropTypes.bool
};

ExecuteModal.defaultProps = {
  open: false,
  onClose: () => {}
};

export default observer(ExecuteModal);
