/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 37px 0 37px;
  text-align: center;
`;

export const Content = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.div`
  margin-top: 10px;
`;
