/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Loader = styled.span`
  display: inline-block;
  width: ${({ size }) => (size == 'small' ? '20px' : '40px')};
  height: ${({ size }) => (size == 'small' ? '20px' : '40px')};
  border-radius: 50%;
  border: ${({ size }) => (size == 'small' ? '2px' : '4px')} solid
    ${({ $light }) => ($light ? 'white' : 'var(--cds-blue-60)')};
  border-bottom-color: transparent;
  animation: ${spin} 1.2s linear infinite;
`;
