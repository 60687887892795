/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

class DeploymentErrorsStore {
  deploymentErrors = null;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.deploymentErrors = null;
  }

  parseAndSetDeploymentErrors(on, e) {
    const currentdate = new Date();
    const datetime = `${currentdate.getDate()}/${
      currentdate.getMonth() + 1
    }/${currentdate.getFullYear()} ${currentdate.getHours()}:${currentdate.getMinutes()}:${currentdate.getSeconds()}`;

    const errorString = `${getErrorString(e)}[ deploy-error ] ${datetime}`;

    if (on && errorString) {
      this.deploymentErrors = { on, errors: errorString };
    } else {
      this.deploymentErrors = null;
    }
    return { errorString, errorCount: parseErrorString(errorString).length };
  }
}

export default new DeploymentErrorsStore();

const getErrorString = (e) => e?.[0]?.detail ?? '';

export const parseErrorString = (errorString) => {
  const errors = [];

  if (errorString) {
    const index = errorString.indexOf('Element:');
    const hasElementErrors = index !== -1;
    if (hasElementErrors) {
      const lines = errorString.substring(index).split('\n');
      if (Array.isArray(lines)) {
        let i = 0;
        while (i < lines.length) {
          const line = lines[i];
          if (line?.includes('Element: ') && line.split('Element: ').length === 2) {
            const id = line.split('Element: ')[1];
            i++;
            while (lines[i]?.includes('ERROR: ')) {
              const lineWithError = lines[i];
              if (lineWithError.split('ERROR: ').length === 2) {
                const message = lineWithError.split('ERROR: ')[1];
                errors.push({ category: 'error', id, message });
                i++;
              }
            }
          } else {
            i++;
          }
        }
      }
    } else {
      const lines = errorString.split('\n');
      if (Array.isArray(lines)) {
        if (lines.length === 2) {
          errors.push({ category: 'error', id: '', message: lines[1] });
        } else if (lines.length === 1) {
          errors.push({ category: 'error', id: '', message: lines[0] });
        }
      }
    }
  }

  return errors;
};
