/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { notificationStore } from 'stores';
import { trackingService } from 'services';
import { sanitizeName } from 'utils/helpers';
import { CONNECTOR_TEMPLATE } from 'utils/constants';

import saveFile from './save-file';

/**
 * Downloads a given connector template to the user's computer.
 *
 * @param {Object} template
 * @param {String} [template.id] The connector template's id
 * @param {String} [template.content] The connector template's stringified JSON content.
 * @param {String} [template.name] The connector template's name.
 */
export default function exportConnectorTemplate({ id, content, name }) {
  try {
    const fileName = `${sanitizeName(name)}.json`;

    saveFile(content, fileName);

    notificationStore.showSuccess(`"${fileName}" is being downloaded to your computer.`);
    trackingService.trackFileExport(id, CONNECTOR_TEMPLATE, 'json');
  } catch (ex) {
    notificationStore.showError('Yikes! Something went wrong while preparing your download. Please try again later.');
  }
}
