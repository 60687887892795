/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

import zeebePlayService from './ZeebePlayService';
import { trackBpmnElementCompletion, trackIncident } from './tracking';

export default function useInfoSubscription(endPoint, playId, instanceId, process_id, modeler) {
  const [instanceDetails, setInstanceDetails] = useState();
  const [jobs, setJobs] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [timers, setTimers] = useState([]);
  const [messageSubscriptions, setMessageSubscriptions] = useState([]);
  const [definitionTimers, setDefinitionTimers] = useState([]);
  const [definitionMessageSubscriptions, setDefinitionMessageSubscriptions] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [variables, setVariables] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [elementInstances, setElementInstances] = useState({});
  const [childInstances, setChildInstances] = useState([]);
  const [isConnectionDead, setIsConnectionDead] = useState(false);
  const lastConnectionReset = useRef(0);

  function openSubscription(subscription, handler) {
    setIsConnectionDead(false);
    let url;
    if (endPoint.startsWith('http')) {
      const parsedEndpoint = new URL(endPoint);
      url = (parsedEndpoint.protocol === 'https' ? 'wss' : 'ws') + '://' + parsedEndpoint.host + '/graphql';
    } else {
      url = 'wss://' + endPoint + '/graphql';
    }

    const socket = new WebSocket(url);

    socket.addEventListener('open', () => {
      socket.send(JSON.stringify({ type: 'connection_init', payload: {} }));
      socket.send(
        JSON.stringify({
          // since we only have one subscription on the socket,
          // the id is irrelevant and can be a single character to reduce traffic
          id: '1',
          type: 'subscribe',
          payload: {
            variables: {},
            extensions: {},
            operationName: null,
            query: `subscription { ${subscription} }`
          }
        })
      );
    });

    socket.addEventListener('message', (event) => {
      const response = JSON.parse(event.data);
      if (response.type === 'next') {
        handler(response);
      }
    });

    socket.addEventListener('error', (error) => {
      console.error('GraphQL subscription error', error);
    });

    const closeHandler = () => {
      const now = Date.now();
      if (now - lastConnectionReset.current < 10000) {
        // last connection reset was less than 10 seconds ago. The environment probably was removed
        setIsConnectionDead(true);
        lastConnectionReset.current = 0;
      } else {
        lastConnectionReset.current = now;

        // the connection was closed. This can happen due to inactivity, let's re-establish the connection
        openSubscription(subscription, handler);
      }
    };

    socket.addEventListener('close', closeHandler);

    return () => {
      socket.removeEventListener('close', closeHandler);
      socket.close();
    };
  }

  const updateProcessInstanceViewDebounced = debounce(async function updateInstanceView(instanceId) {
    const [
      details,
      jobs,
      userTasks,
      timers,
      messageSubscriptions,
      incidents,
      variables,
      elementInstances,
      childInstances
    ] = await Promise.all([
      zeebePlayService.fetchInstanceDetails(endPoint, instanceId),
      zeebePlayService.fetchJobs(endPoint, instanceId),
      zeebePlayService.fetchUserTasks(endPoint, instanceId),
      zeebePlayService.fetchTimers(endPoint, instanceId),
      zeebePlayService.fetchMessageSubscriptions(endPoint, instanceId),
      zeebePlayService.fetchIncidents(endPoint, instanceId),
      zeebePlayService.fetchVariables(endPoint, instanceId),
      zeebePlayService.fetchElementInstances(endPoint, instanceId),
      zeebePlayService.fetchChildInstances(endPoint, instanceId)
    ]);

    if (
      details === undefined ||
      jobs === undefined ||
      userTasks === undefined ||
      timers === undefined ||
      messageSubscriptions === undefined ||
      incidents === undefined ||
      variables === undefined ||
      elementInstances === undefined ||
      childInstances === undefined
    ) {
      setHasError(true);
    }
    setInstanceDetails(details);
    setJobs(jobs ?? []);
    setUserTasks(userTasks);
    setTimers(timers ?? []);
    setMessageSubscriptions(messageSubscriptions ?? []);
    setIncidents(incidents ?? []);
    incidents?.forEach((incident) => trackIncident(incident, process_id));
    setVariables(variables ?? []);
    setElementInstances(elementInstances ?? {});
    elementInstances?.elementInstances?.forEach((elementInstance) => {
      if (elementInstance.state === 'COMPLETED') trackBpmnElementCompletion(elementInstance, process_id, modeler);
    });
    setChildInstances(childInstances);
  }, 150);

  const updateProcessDefinitionViewDebounced = debounce(async function updateDefinitionView(playId) {
    const [timers, messageSubscriptions] = await Promise.all([
      zeebePlayService.fetchProcessTimers(endPoint, playId),
      zeebePlayService.fetchProcessMessageSubscriptions(endPoint, playId)
    ]);
    if (timers === undefined || messageSubscriptions === undefined) {
      setHasError(true);
    }
    setDefinitionTimers(timers ?? []);
    setDefinitionMessageSubscriptions(messageSubscriptions ?? []);
  }, 150);

  const reloadFailedAPIs = async function reloadFailedAPIs() {
    setHasError(false);
    updateProcessInstanceViewDebounced(instanceId);
    updateProcessDefinitionViewDebounced(playId);
  };

  useEffect(() => {
    if (instanceId) {
      // load info for process instance
      updateProcessInstanceViewDebounced(instanceId);

      return openSubscription(
        `processInstanceUpdates(filter: {processInstanceKey: ${instanceId}}) {
        updateType
      }`,
        () => updateProcessInstanceViewDebounced(instanceId)
      );
    } else if (playId) {
      // load info for process definition
      updateProcessDefinitionViewDebounced(playId);

      return openSubscription(
        `processInstanceUpdates(filter: {processKey: ${playId}}) {
        updateType
      }`,
        () => updateProcessDefinitionViewDebounced(playId)
      );
    }
  }, [instanceId, playId]);

  useEffect(() => {
    if (!instanceId) {
      setInstanceDetails();
      setJobs([]);
      setUserTasks([]);
      setTimers([]);
      setMessageSubscriptions([]);
      setIncidents([]);
      setVariables([]);
      setElementInstances([]);
      setChildInstances([]);
      setHasError(false);
    }
  }, [instanceId]);

  return {
    instanceDetails,
    jobs,
    userTasks,
    timers,
    definitionTimers,
    messageSubscriptions,
    definitionMessageSubscriptions,
    incidents,
    variables,
    childInstances,
    elementInstances,
    isConnectionDead,
    hasError,
    reloadFailedAPIs
  };
}
