/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import EmptyStateBase from './EmptyStateBase';

export function EmptyState() {
  return <EmptyStateBase description="Start by creating a BPMN diagram or a blank file to design your process." />;
}

export default EmptyState;
