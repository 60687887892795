/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { TreeView, TreeNode } from '@carbon/react';
import { useRef, useState, useEffect } from 'react';

import BPMNIcon from './BPMNIcon';
import StateIcon from '../StateIcon';
import Variables from './Variables';
import * as Styled from './InstanceHistory.styled';

export default function InstanceHistory({ flowNodeInstances }) {
  const [selectedNode, setSelectedNode] = useState();

  const [containerHeight, setContainerHeight] = useState(() => +localStorage.getItem('play-bottom-bar-height') || 255);
  const [isResizing, setIsResizing] = useState(false);
  const dragInfo = useRef();

  useEffect(() => {
    localStorage.setItem('play-bottom-bar-height', containerHeight);
  }, [containerHeight]);

  const keyMap = {};
  const rootNodes = [];

  for (let i = 0; i < flowNodeInstances.length; i++) {
    const instance = flowNodeInstances[i];

    const entry = { instance, children: [] };
    keyMap[instance.key] = entry;

    if (!instance.scope?.key) {
      rootNodes.push(entry);
    } else {
      keyMap[instance.scope.key].children.push(entry);
    }
  }

  function renderTreeLevel({ instance, children }) {
    return (
      <TreeNode
        key={instance.key}
        renderIcon={() => (
          <Styled.IconContainer>
            <BPMNIcon type={instance.element.bpmnElementType} />
          </Styled.IconContainer>
        )}
        onSelect={() => setSelectedNode(instance.key)}
        label={
          <Styled.TreeNodeLabel>
            <Styled.Icon>
              <StateIcon state={instance.state} size={16} />
            </Styled.Icon>
            {instance.element.elementName || instance.element.elementId}
          </Styled.TreeNodeLabel>
        }
        isExpanded
      >
        {children.length && children.map(renderTreeLevel)}
      </TreeNode>
    );
  }

  const variableScope = selectedNode || rootNodes?.[0]?.instance.key;

  function startDragging(evt) {
    evt.preventDefault();
    setIsResizing(true);
    dragInfo.current = { originalSize: evt.target.parentNode.clientHeight, dragStart: evt.pageY };

    const updater = (evt) => {
      setContainerHeight(Math.max(10, dragInfo.current.originalSize + dragInfo.current.dragStart - evt.pageY));
    };
    const cleanup = () => {
      setIsResizing(false);
      document.removeEventListener('mousemove', updater);
      document.removeEventListener('mouseup', cleanup);
    };
    document.addEventListener('mousemove', updater);
    document.addEventListener('mouseup', cleanup);
  }

  return (
    <Styled.Container style={{ height: containerHeight + 'px' }}>
      <Styled.DragHandle isResizing={isResizing} onMouseDown={startDragging} />
      <Styled.HistoryTree>
        <Styled.Header>Instance History</Styled.Header>
        <Styled.TreeViewContainer>
          <TreeView hideLabel label="Instance History">
            {rootNodes.map(renderTreeLevel)}
          </TreeView>
        </Styled.TreeViewContainer>
      </Styled.HistoryTree>
      <Variables isActive={keyMap[variableScope]?.instance.state === 'ACTIVATED'} selectedScope={variableScope} />
    </Styled.Container>
  );
}
