/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Button } from 'primitives';

export const Container = styled.header`
  top: 0;
  position: absolute;
  width: 100%;
  background: white;

  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--cds-border-subtle-01);
`;

export const InstanceHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 10px 6px 20px;
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  margin-left: 14px;
  width: 768px;
`;

export const Th = styled.th`
  color: #525252;
  font-size: 12px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  word-wrap: break-word;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 256px;
`;

export const Td = styled.td`
  color: #525252;
  font-size: 14px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 256px;
`;

export const ViewAllButton = styled(Button)`
  color: #0f62fe;
  font-size: 14px;
  font-family: IBM Plex Sans;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
  word-wrap: break-word;
  height: 20px;
`;
