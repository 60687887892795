/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { isEmpty } from 'utils/helpers';
import { BPMN_DIAGRAM_DEFAULT_NAME } from 'utils/file-mapper/file-mapper';

export const selectAndScrollToElement = (modeler, elementId) => {
  const canvas = modeler.get('canvas'),
    elementRegistry = modeler.get('elementRegistry'),
    selection = modeler.get('selection');
  const element = elementRegistry.get(elementId);
  if (element !== canvas.getRootElement()) {
    canvas.scrollToElement(element);
  }
  selection.select(element);
};

export const checkAndSyncProcessName = (modeler, name) => {
  const modeling = modeler.get('modeling');
  const canvas = modeler.get('canvas');

  const currentName = canvas?.getRootElement()?.businessObject?.get('name');
  const proposedNameIsNotDefaultOne = !isEmpty(name) && name !== BPMN_DIAGRAM_DEFAULT_NAME;
  if (isEmpty(currentName) && proposedNameIsNotDefaultOne) {
    modeling?.updateProperties(canvas?.getRootElement(), { name });
  }
};
