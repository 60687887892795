/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Button, Stack } from '@carbon/react';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(22, 22, 22, 0.5);
  z-index: 1;
`;

export const ErrorContainer = styled(Stack)`
  background-color: #f4f4f4;
  width: 100%;
  padding: 88px;
`;

export const ErrorTitle = styled.h4`
  color: var(--cds-text-secondary, #161616);

  /* Fixed heading styles/heading-03 */
  font-family: IBM Plex Sans;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important; /* 140% */
`;

export const ErrorDescription = styled.div`
  color: var(--cds-text-secondary, #525252);

  /* Fixed Body styles/body-01 */
  font-family: IBM Plex Sans;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.16px !important;
`;

export const Modal = styled.div`
  background-color: white;
  width: 640px;
  margin: 100px auto;

  .cds--progress-bar__label-text {
    visibility: hidden;
  }
`;

export const ModalBody = styled.div`
  padding: 16px;
`;

export const ModalFooter = styled.div`
  text-align: right;
`;

export const StartButton = styled(Button)`
  width: 320px;
`;

export const ImageContainer = styled.div`
  background: #f6f8f5;
  height: 253px;
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  color: #000;

  text-align: center;
  /* Body styles/body-02 */
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  margin-bottom: 8px;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
`;
