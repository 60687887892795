/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';

import * as Styled from './Checkbox.styled';

export default function Checkbox({ className, label, disabled = false, checked, ...props }) {
  return (
    <Styled.Wrapper className={className} $isDisabled={disabled}>
      <Styled.Input {...props} disabled={disabled} checked={checked} />

      <Styled.Display checked={checked} $isDisabled={disabled}>
        {checked && (
          <svg viewBox="0 0 24 24" width="18" height="18">
            <path fill="white" d="M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z" />
          </svg>
        )}
      </Styled.Display>

      {label}
    </Styled.Wrapper>
  );
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired
};
