/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';
import { SkeletonIcon } from '@carbon/react';
import { Launch } from '@carbon/icons-react';

import { Tooltip } from 'primitives';

import * as Styled from './TemplateItem.styled';

const TemplateItem = ({ id, name, icon, source, hasDuplicates, duplicates }) => {
  return (
    <Styled.ResourceItem href={source} target="_blank">
      {hasDuplicates && duplicates?.some((duplicate) => duplicate.templateId === id) && <Styled.YellowWarningIcon />}
      {icon ? <Styled.ResourceLogo src={icon} alt={`${name} logo`} /> : <SkeletonIcon />}

      <Tooltip title={name} showOnlyOnOverflow>
        <Styled.ResourceName>{name}</Styled.ResourceName>
      </Tooltip>

      <Tooltip title={source} showOnlyOnOverflow>
        <Styled.ResoureUrl>{source}</Styled.ResoureUrl>
      </Tooltip>

      <Styled.ResourceLink>
        <Launch />
      </Styled.ResourceLink>
    </Styled.ResourceItem>
  );
};

TemplateItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  source: PropTypes.string,
  hasDuplicates: PropTypes.bool,
  duplicates: PropTypes.array
};

export default TemplateItem;
