/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Link } from '@carbon/react';

import { Arrow } from 'icons';
import { currentDiagramStore } from 'stores';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { ErrorPanel } from 'components';

import VersionSelector from './VersionSelector';
import errorPanelStore from './ErrorPanelStore';
import Errors from './Errors';
import * as Styled from './DiagramErrorPanel.styled';

const DiagramErrorPanel = ({ lintErrors, deployErrors }) => {
  const { isErrorsTabSelected, showProblems, showOutput } = errorPanelStore;
  const { isErrorPanelCollapsed } = currentDiagramStore;
  const deploymentErrors = deployErrors?.errors;

  const { isImplementMode } = dedicatedModesStore;

  const diagnosticsCount = useMemo(() => {
    return (lintErrors || []).length;
  }, [lintErrors]);

  useEffect(() => {
    if (!deployErrors) {
      errorPanelStore.reset();
    }
  }, [deployErrors]);

  const handleProblemsTabClick = () => {
    if (isErrorPanelCollapsed || !isErrorsTabSelected) {
      errorPanelStore.switchToProblemsTab();
    } else {
      currentDiagramStore.setIsErrorPanelCollapsed(true);
    }
  };

  const handleOutputTabClick = () => {
    if (isErrorPanelCollapsed || isErrorsTabSelected) {
      errorPanelStore.switchToOutputTab();
    } else {
      currentDiagramStore.setIsErrorPanelCollapsed(true);
    }
  };

  return (
    <Styled.Container role="tablist" aria-expanded={!isErrorPanelCollapsed} data-test="diagram-error-panel">
      <ErrorPanel.Title
        onClick={(e) =>
          e.target === e.currentTarget && currentDiagramStore.setIsErrorPanelCollapsed(!isErrorPanelCollapsed)
        }
        data-test="error-panel-title"
      >
        {currentDiagramStore.isBPMN && (
          <ErrorPanel.Tab
            role="tab"
            aria-selected={isErrorsTabSelected}
            onClick={handleProblemsTabClick}
            $active={isErrorsTabSelected}
            $isErrorPanelCollapsed={isErrorPanelCollapsed}
          >
            <ErrorPanel.StatusText>Problems</ErrorPanel.StatusText>
            <ErrorPanel.Badge>{diagnosticsCount}</ErrorPanel.Badge>
          </ErrorPanel.Tab>
        )}
        {deploymentErrors && isImplementMode && (
          <ErrorPanel.Tab
            role="tab"
            aria-selected={!isErrorsTabSelected}
            onClick={handleOutputTabClick}
            $active={!isErrorsTabSelected}
            $isErrorPanelCollapsed={isErrorPanelCollapsed}
          >
            <ErrorPanel.StatusText>Output</ErrorPanel.StatusText>
          </ErrorPanel.Tab>
        )}
        <ErrorPanel.MenuRight>
          {currentDiagramStore.isBPMN && !currentDiagramStore.state.isLoadingModeler && isImplementMode && (
            <VersionSelector
              message="Your diagram is checked against the selected engine version."
              executionPlatformVersion={currentDiagramStore.executionPlatformVersion}
              onSelectExecutionPlatformVersion={currentDiagramStore.setExecutionPlatformVersion}
            />
          )}

          {currentDiagramStore.isBPMN && !isImplementMode && (
            <Styled.ModelingGuidelinesInfo data-test="validation-note">
              Checked against{' '}
              <Link
                href="https://docs.camunda.io/docs/next/components/modeler/reference/modeling-guidance/"
                target="_blank"
                title="Your diagram is checked against the modeling guidelines. Click to learn more."
              >
                modeling guidelines
              </Link>
            </Styled.ModelingGuidelinesInfo>
          )}

          <ErrorPanel.Collapse
            $isCollapsed={isErrorPanelCollapsed}
            onClick={() => currentDiagramStore.setIsErrorPanelCollapsed(!isErrorPanelCollapsed)}
            data-test="error-panel-collapse"
          >
            <Arrow width="12" />
          </ErrorPanel.Collapse>
        </ErrorPanel.MenuRight>
      </ErrorPanel.Title>

      {showProblems && (
        <ErrorPanel.Errors>
          <Errors
            errors={lintErrors}
            emptyMessage={
              isImplementMode ? 'No problems found. You can deploy your diagram now.' : 'No problems found.'
            }
          />
        </ErrorPanel.Errors>
      )}
      {showOutput && (
        <ErrorPanel.Errors>
          <ErrorPanel.DeploymentErrors>{deploymentErrors}</ErrorPanel.DeploymentErrors>
        </ErrorPanel.Errors>
      )}
    </Styled.Container>
  );
};

export default observer(DiagramErrorPanel);
