/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';

import { Loader } from 'primitives';

import * as Styled from './Spinner.styled';

export default function Spinner({ fullHeight, ...props }) {
  return (
    <Styled.Wrapper $fullHeight={fullHeight} {...props} data-test="loader">
      <Loader />
    </Styled.Wrapper>
  );
}

Spinner.propTypes = {
  fullHeight: PropTypes.bool
};

Spinner.defaultProps = {
  fullHeight: false
};
