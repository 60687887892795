/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useContext, useState, useRef, useEffect } from 'react';
import { Form } from '@bpmn-io/form-js';

import { Dialog, Button } from 'primitives';
import { getExecutableProcess } from 'utils/web-modeler-diagram-parser';
import notificationStore from 'stores/NotificationStore';

import PlayContext from '../PlayContext';
import EditForm from '../icons/svgr/EditForm.svg';
import Play from '../icons/svgr/Play.svg';
import ActionButton from '../ActionButton';
import VariablesInputDialog from '../VariablesInputDialog';
import { NEW_PROCESS_INSTANCE, REQUEST_FAILURE } from '../utils/constants';
import Retry from '../icons/svgr/Retry.svg';

export default function StartInstanceButton({ element, isRestartMode }) {
  const context = useContext(PlayContext);
  if (context.elementsById === null) {
    return;
  }
  const elementById = context.elementsById[element.id];

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const formContainerRef = useRef();
  const taskForm = useRef();
  const resetFct = useRef();
  const [isInProgress, setIsInProgress] = useState(false);
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);

  const startEventExtensionElements = elementById?.get('extensionElements');
  const startEventFormDefinition = startEventExtensionElements
    ?.get('values')
    ?.find((elem) => elem.$type === 'zeebe:formDefinition');

  const startEventFormKey = startEventFormDefinition?.formKey;

  const executableProcess = getExecutableProcess(context.definitions);
  const extensionElements = executableProcess?.get('extensionElements');

  const formBody = extensionElements
    ?.get('values')
    ?.find((elem) => 'zeebe:userTaskForm' === elem.$type && startEventFormKey?.includes(elem.id))?.$body;

  const Icon = isRestartMode ? Retry : formBody ? EditForm : Play;

  async function handleFormSubmit(event, { data, errors }) {
    if (Object.keys(errors).length === 0) {
      setIsInProgress(true);

      // no errors, ready to submit
      localStorage.setItem(`jobCompletion ${context.processId} ${element.id}`, JSON.stringify(data));

      const isSuccess = await context.startInstance(JSON.stringify(data));

      setIsFormModalOpen(false);

      if (!isSuccess) {
        notificationStore.showError(REQUEST_FAILURE);
      } else {
        if (isRestartMode) {
          notificationStore.showSuccess(NEW_PROCESS_INSTANCE);
        }
      }
    }
  }

  useEffect(() => {
    if (!formContainerRef.current) return;

    (async () => {
      const cachedData = localStorage.getItem(`jobCompletion ${context.processId} ${element.id}`);
      const cachedVariables = JSON.parse(cachedData || '{}');

      const parsedForm = JSON.parse(formBody);
      const formViewer = new Form();

      taskForm.current = formViewer;

      formViewer.attachTo(formContainerRef.current);
      formViewer.importSchema(parsedForm, cachedVariables);
      formViewer.on('submit', handleFormSubmit);

      resetFct.current = () => {
        taskForm.current.importSchema(parsedForm, {});
      };
    })();
  }, [isFormModalOpen]);

  return (
    <>
      <ActionButton
        disabled={isInProgress}
        actions={[
          {
            label: isRestartMode ? 'Restart process' : formBody ? 'Open Start Form' : 'Start Instance',
            renderIcon: () => <Icon color="white" />,
            onClick: async () => {
              if (formBody) {
                setIsFormModalOpen(true);
              } else {
                setIsInProgress(true);
                const isSuccess = await context.startInstance();

                if (!isSuccess) {
                  notificationStore.showError(REQUEST_FAILURE);
                  setIsInProgress(false);
                  return;
                }

                if (isRestartMode) {
                  notificationStore.showSuccess(NEW_PROCESS_INSTANCE);
                }
              }
            }
          },
          {
            label: 'Start Instance with variables',
            onClick: () => {
              setIsVariableModalOpen(true);
            }
          }
        ]}
      />
      <VariablesInputDialog
        title="New Instance"
        isOpen={isVariableModalOpen}
        onClose={() => setIsVariableModalOpen(false)}
        onSubmit={async (variableModalContent) => {
          setIsVariableModalOpen(false);
          setIsInProgress(true);
          const isSuccess = await context.startInstance(variableModalContent);
          if (!isSuccess) {
            notificationStore.showError(REQUEST_FAILURE);
            setIsInProgress(false);
            return;
          }

          if (isRestartMode) {
            notificationStore.showSuccess(NEW_PROCESS_INSTANCE);
          }
        }}
        submitLabel="Create"
      />

      <Dialog open={isFormModalOpen} onClose={() => setIsFormModalOpen(false)}>
        <Dialog.Header>
          <Dialog.Title>Start Form</Dialog.Title>
        </Dialog.Header>

        <Dialog.Content>
          <div ref={formContainerRef} />
        </Dialog.Content>
        <Dialog.Footer>
          <Button onClick={() => resetFct.current?.()} variant="text" style={{ marginRight: 'auto' }}>
            Reset
          </Button>
          <Button onClick={() => setIsFormModalOpen(false)} variant="secondary">
            Close
          </Button>
          <Button onClick={() => taskForm.current?.submit()} variant="primary">
            Start Instance
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  );
}
