/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Error } from '@carbon/react/icons';

import * as Styled from './StateIcon.styled';

const stateIconsMap = {
  ACTIVATED: Styled.RadioButtonChecked,
  COMPLETED: Styled.CheckmarkOutline,
  INCIDENT: Styled.WarningFilled,
  CANCELED: Error,
  TERMINATED: Error
};

export default function StateIcon({ state, ...props }) {
  const TargetComponent = stateIconsMap[state] || Error;
  return <TargetComponent {...props} />;
}
