/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button } from '@carbon/react';

import { Link as Hyperlink } from 'primitives';
import { Link } from 'icons';
import { Dropdown, TargetSelector } from 'components';
import { commentsStore, currentDiagramStore, notificationStore } from 'stores';
import { selectElementAndFocusField } from 'App/Pages/Diagram/properties-panel-util';
import { getCalledElement } from 'utils/web-modeler-diagram-parser';
import { trackingService } from 'services';
import buildSlug from 'utils/buildSlug';
import * as Styled from 'App/Pages/Diagram/LinkOverlay.styled';

import callActivityLinkStore from './CallActivityLinkStore';

const Overlay = observer(({ element, readOnly }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkedDiagrams, setLinkedDiagrams] = useState({});
  const [projectDetails, setProjectDetails] = useState(null);
  const { project, diagram: currentDiagram } = currentDiagramStore.state;
  const { isElementLinked } = callActivityLinkStore;

  const getLinkedDiagrams = async () => {
    const diagrams = await callActivityLinkStore.getExistingLinks(element);
    setLinkedDiagrams(diagrams);
  };

  const getProjectDetails = async () => {
    const updatedProject = await currentDiagramStore.updateProject(project.id);
    setProjectDetails(updatedProject);
  };

  useEffect(() => {
    if (anchorEl) {
      if (isElementLinked(element)) {
        getLinkedDiagrams();
      } else {
        getProjectDetails();
      }
    }
  }, [anchorEl, element]);

  const trackResourceLinking = (action) => {
    trackingService.trackResourceLinking({ action, resource: 'BPMN' });
  };

  const link = (diagram) => {
    setCalledElementInXml(element, diagram.processId);
    focusTargetProcessIdField(element);
    setAnchorEl(null);
    notificationStore.showSuccess('Call activity has been successfully linked.');
    trackResourceLinking('link');
  };

  const unlink = () => {
    setCalledElementInXml(element, '');
    focusTargetProcessIdField(element);
    setAnchorEl(null);
    notificationStore.showSuccess('Call activity has been unlinked.');
    trackResourceLinking('unlink');
  };

  const setCalledElementInXml = async (element, processId) => {
    const extensionElements = element.businessObject.get('extensionElements');
    const calledElement = getCalledElement(extensionElements);
    if (processId) {
      calledElement.processId = processId;
    } else {
      delete calledElement.processId;
    }

    const modeling = currentDiagramStore.modeler.get('modeling');
    modeling.updateProperties(element, { extensionElements: extensionElements });
  };

  const focusTargetProcessIdField = async (element) => {
    commentsStore.makePropertiesVisible();
    await selectElementAndFocusField({
      modeler: currentDiagramStore.modeler,
      element: element,
      groupId: 'group-calledElement',
      fieldId: '#bio-properties-panel-targetProcessId'
    });
  };

  const deselect = () => {
    const modeler = currentDiagramStore.modeler,
      selection = modeler.get('selection');
    selection?.deselect(element);
  };

  const handleClick = (evt) => {
    const completeLabelEditingOnMenuOpen = () => {
      if (!anchorEl && !readOnly) {
        currentDiagramStore.modeler?.get('directEditing')?.complete();
      }
    };
    completeLabelEditingOnMenuOpen();

    if (!anchorEl) {
      trackResourceLinking('openMenu');
    }

    setAnchorEl(anchorEl ? null : evt.currentTarget);
  };

  const { links, broken, expression } = linkedDiagrams;
  const isCalledProcessPresent = links?.length > 0 || broken || expression;

  return (
    <Styled.Container>
      <Styled.ElementMenuButton onClick={handleClick} data-test="call-activity-link-button">
        <Link width="20" height="20" />
      </Styled.ElementMenuButton>
      {isElementLinked(element) ? (
        <Dropdown
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          width={360}
          align="left"
          noPadding
        >
          <Dropdown.Title noPadding>Calls process:</Dropdown.Title>
          {isCalledProcessPresent ? (
            <>
              {links?.length > 0 && (
                <Styled.LinkContainer>
                  <ul>
                    {links?.map((diagram) => (
                      <li key={diagram.id}>
                        <Styled.Link
                          data-test={`linked-diagram-${diagram.name}`}
                          onClick={() => {
                            if (currentDiagram?.id === diagram.id) {
                              deselect();
                              setAnchorEl(null);
                            }
                            history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
                          }}
                        >
                          {diagram.name}
                        </Styled.Link>
                      </li>
                    ))}
                  </ul>
                </Styled.LinkContainer>
              )}

              {links?.length > 1 && (
                <Styled.InfoMessage>
                  During execution, the most recently deployed diagram having the linked process ID will be called.
                </Styled.InfoMessage>
              )}
              {broken && (
                <>
                  <Styled.InfoResourceId>{broken}</Styled.InfoResourceId>
                  <Styled.InfoMessage>
                    A process with this ID could not be found in the current project.
                  </Styled.InfoMessage>
                </>
              )}
              {expression && (
                <>
                  <Styled.InfoResourceId>{expression}</Styled.InfoResourceId>
                  <Styled.InfoMessage>
                    The process ID is defined as an{' '}
                    <Hyperlink href="https://docs.camunda.io/docs/components/concepts/expressions/" target="_blank">
                      expression
                    </Hyperlink>{' '}
                    that will be evaluated at runtime.
                  </Styled.InfoMessage>
                </>
              )}
              {!readOnly && (
                <Styled.Footer>
                  <Styled.FooterActions>
                    <Button kind="danger--ghost" size="sm" onClick={unlink} data-test="unlink-button">
                      Unlink
                    </Button>
                  </Styled.FooterActions>
                </Styled.Footer>
              )}
            </>
          ) : (
            <Styled.Spinner />
          )}
        </Dropdown>
      ) : (
        <>
          {projectDetails && (
            <TargetSelector
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              startingPoint={projectDetails}
              onSubmit={link}
              action="link"
              showFiles
              description="Link a process"
            />
          )}
        </>
      )}
    </Styled.Container>
  );
});

const CallActivityLinkOverlay = ({ ...props }) => {
  const selector = `.djs-overlays[data-container-id="${props.element.id}"] .call-activity-menu`;
  const container = document.querySelector(selector);
  if (!container) {
    return null;
  }
  return ReactDOM.createPortal(<Overlay {...props} />, container);
};

export default CallActivityLinkOverlay;
