/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observable, action, makeObservable } from 'mobx';

import BrowserDetector from 'utils/browser-detector';

const browserDetector = new BrowserDetector();

const DEFAULT_STATE = {
  isFullscreenActive: false,
  isMinimapActive: false,
  fontSize: 13,
  hasModeler: false,
  hasMinimap: true,
  hasResetViewport: true,
  hasFullscreen: true,
  // 'initial' or 'fit-viewport'
  resetViewportStrategy: 'initial'
};

class DiagramControlStore {
  modeler = null;

  constructor() {
    makeObservable(this, {
      state: observable,
      reset: action,
      toggleMinimapActive: action,
      handleFullscreenToggle: action,
      exitFullscreen: action,
      setModeler: action,
      setMinimapSupport: action,
      setResetViewportSupport: action,
      setFullscreenSupport: action,
      setResetViewportStrategy: action,
      zoom: action
    });
  }

  state = Object.assign({}, DEFAULT_STATE);

  reset = () => {
    this.state = Object.assign({}, DEFAULT_STATE, {
      isFullscreenActive: this.state.isFullscreenActive
    });
    this.modeler = null;
  };

  toggleMinimapActive = () => {
    this.state.isMinimapActive = !this.state.isMinimapActive;

    this.modeler.get('minimap').toggle();
  };

  handleFullscreenToggle = () => {
    if (browserDetector.isSafari) {
      if (document.webkitFullscreenEnabled) {
        if (!document.webkitFullscreenElement) {
          document.documentElement.webkitRequestFullscreen();
          this.state.isFullscreenActive = true;
        } else {
          document.webkitExitFullscreen();
          this.exitFullscreen();
        }
      }
    } else {
      if (document.fullscreenEnabled) {
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen();
          this.state.isFullscreenActive = true;
        } else {
          document.exitFullscreen();
          this.exitFullscreen();
        }
      }
    }
  };
  /**
   * Forces a recalculation of the root container which
   * most of the times fixes a Safari viewport height bug.
   *
   * Shall only be used in Safari
   */
  forceRootResize = () => {
    const rootElement = document.querySelector('#root');
    rootElement.style.height = '99.9vh';

    requestAnimationFrame(() => {
      rootElement.style.height = '100vh';
    });
  };

  exitFullscreen() {
    this.state.isFullscreenActive = false;

    if (browserDetector.isSafari) {
      this.forceRootResize();
    }
  }

  setModeler = (modeler) => {
    this.modeler = modeler;
    this.state.hasModeler = true;
  };

  setMinimapSupport = (flag) => {
    this.state.hasMinimap = flag;
  };

  setResetViewportSupport = (flag) => {
    this.state.hasResetViewport = flag;
  };

  setFullscreenSupport = (flag) => {
    this.state.hasFullscreen = flag;
  };

  setResetViewportStrategy = (strategy) => {
    this.state.resetViewportStrategy = strategy;
  };

  zoom(isPositive) {
    if (this.modeler) {
      const modeler = this.modeler;
      const zoomScroll = modeler.get('zoomScroll');
      zoomScroll.stepZoom(isPositive ? 0.1 : -0.1);
    } else {
      if (isPositive) {
        this.state.fontSize++;
      } else {
        this.state.fontSize--;
      }
    }
  }

  handleZoomIn = () => this.zoom(true);
  handleZoomOut = () => this.zoom(false);

  /**
   * Resets the viewbox to { x: 0, y: 0, scale: 1 } if the resetViewportStrategy is 'initial' otherwise
   * it fits the viewport if 'fit-viewport' is set.
   *
   * @return {Promise} resolved on viewbox reset
   */
  handleViewboxReset = async () => {
    const modeler = this.modeler;
    const canvas = modeler.get('canvas');
    const eventBus = modeler.get('eventBus');

    if (this.state.resetViewportStrategy === 'initial') {
      const outerViewbox = canvas.viewbox().outer;
      canvas.viewbox({
        x: 0,
        y: 0,
        width: outerViewbox.width,
        height: outerViewbox.height
      });
    } else if (this.state.resetViewportStrategy === 'fit-viewport') {
      canvas.zoom('fit-viewport', true);
    } else {
      console.warn(`Unknown reset viewport strategy '${this.state.resetViewportStrategy}'`);
    }

    // await single, debounced
    // view box change notification
    return await new Promise((resolve) => eventBus.once('canvas.viewbox.changed', resolve));
  };
}

export default new DiagramControlStore();
