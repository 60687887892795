/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export const INPUT_KEY = {
  ENDPOINT: 'endPoint',
  TENANT_ID: 'tenantID',
  AUTHENTICATION: 'authentication',
  CLIENT_ID: 'clientID',
  CLIENT_SECRET: 'clientSecret',
  OAUTH_URL: 'oAuthURL',
  AUDIENCE: 'audience',
  SCOPE: 'scope'
};

export const DEFAULT_CLUSTER_INFO = {
  [INPUT_KEY.ENDPOINT]: '',
  [INPUT_KEY.TENANT_ID]: '',
  [INPUT_KEY.AUTHENTICATION]: 'none',
  [INPUT_KEY.CLIENT_ID]: '',
  [INPUT_KEY.CLIENT_SECRET]: '',
  [INPUT_KEY.OAUTH_URL]: '',
  [INPUT_KEY.AUDIENCE]: '',
  [INPUT_KEY.SCOPE]: ''
};
