/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

import { currentDiagramStore, notificationStore } from 'stores';
import { deploymentErrorsStore } from 'App/Pages/Diagram/stores';
import InstanceStartedNotification from 'App/Pages/Diagram/Deployment/InstanceStartedNotification';
import { errorPanelStore } from 'App/Pages/Diagram/ErrorPanel';
import { zeebeClientStore } from 'App/Pages/Diagram/Deployment/executeModal/sm';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';

import deploymentService from './DeploymentService';

class DeploymentStore {
  constructor() {
    makeAutoObservable(this);
  }

  async deploy(clusterInfo) {
    deploymentErrorsStore.reset();

    try {
      await deploymentService.deploy(
        currentDiagramStore.state.diagram.id,
        this.#mapClusterInfo(clusterInfo),
        formLinkStore.formResolutions
      );
      notificationStore.showSuccess('Diagram deployed!');
    } catch (e) {
      deploymentErrorsStore.parseAndSetDeploymentErrors('deploy', e);
      notificationStore.showError('Diagram could not be deployed. Check the output tab for more details.');
      currentDiagramStore.setIsErrorPanelCollapsed(false);
      errorPanelStore.switchToOutputTab();
    }
  }

  async execute(clusterInfo, payload) {
    deploymentErrorsStore.reset();

    try {
      const data = await deploymentService.execute(
        currentDiagramStore.state.diagram.id,
        this.#mapClusterInfo(clusterInfo),
        payload,
        formLinkStore.formResolutions
      );
      notificationStore.showSuccess(
        <InstanceStartedNotification
          link={
            this.latestDeployedCluster?.urls?.operate
              ? `${this.latestDeployedCluster.urls.operate}/processes/${data.processInstanceKey}`
              : null
          }
        />
      );
    } catch (e) {
      deploymentErrorsStore.parseAndSetDeploymentErrors('execute', e);
      notificationStore.showError('Instance could not be started. Check the output tab for more details.');
      currentDiagramStore.setIsErrorPanelCollapsed(false);
      errorPanelStore.switchToOutputTab();
    }
  }

  #mapClusterInfo(clusterInfo) {
    const { audience, authentication, clientID, clientSecret, endPoint, scope, tenantID, oAuthURL } = clusterInfo;
    if (authentication === 'oauth') {
      return {
        clusterUrl: endPoint,
        ...(tenantID && { tenantId: tenantID }),
        authorization: { serverUrl: oAuthURL, audience, clientId: clientID, clientSecret, ...(scope && { scope }) }
      };
    } else {
      return {
        clusterUrl: endPoint
      };
    }
  }

  get selectedClusterVersion() {
    return zeebeClientStore.checkConnectionResponse?.clusterVersion;
  }
}

export default new DeploymentStore();
