/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Service from './Service';

class ProcessTemplateService extends Service {
  /**
   * Fetches the available templates.
   *
   * @returns {Promise}
   */
  fetchAvailableTemplates() {
    return this.get('/internal-api/process-templates');
  }
}

export default new ProcessTemplateService();
