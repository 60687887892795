/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';

import * as Styled from './IconButton.styled';

Styled.IconButton.propTypes = {
  component: PropTypes.element,
  $noRadius: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal']),
  children: PropTypes.node.isRequired
};

Styled.IconButton.defaultProps = {
  size: 'normal'
};

export default Styled.IconButton;
