/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Linter } from '@camunda/linting';
import { Linter as ModelingLinter } from 'bpmnlint';
import { CloudElementTemplatesLinterPlugin } from 'bpmn-js-element-templates';

import bpmnLinterConfig from './.bpmnlintrc';

async function lintDesign(contents) {
  // cf. https://github.com/bpmn-io/bpmnlint#api
  const bpmnLinter = new ModelingLinter(bpmnLinterConfig);

  const reportMap = await bpmnLinter.lint(contents);

  return Object.entries(reportMap)
    .map(([rule, results]) => {
      return results.map((result) => ({ ...result, rule }));
    })
    .flat();
}

async function lintImplement(contents, modeler) {
  const templates = modeler.get('elementTemplates').getAll();

  const linter = new Linter({
    modeler: 'web',
    plugins: [CloudElementTemplatesLinterPlugin(templates)]
  });

  return await linter.lint(contents);
}

/**
 * @param {BpmnModeler} modeler
 * @param {number} mode
 *
 * @return {any[]} lint results
 */
export const performLinting = async (modeler, isImplementMode) => {
  const contents = modeler.getDefinitions();

  const results = await Promise.all([lintDesign(contents), isImplementMode ? lintImplement(contents, modeler) : []]);

  const categories = ['error', 'warn', 'info'];

  // sort based on category precedence
  return results.flat().sort((a, b) => {
    return categories.indexOf(a.category) - categories.indexOf(b.category);
  });
};
