/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { isValidBpmnDiagram, isValidDmnFile, isZeebeProcess } from 'utils/validate-diagram';
import isValidForm from 'utils/validate-form';
import isValidConnectorTemplate from 'utils/validate-connector-template';
import { FORM, DMN, CONNECTOR_TEMPLATE } from 'utils/constants';

const MAX_FILE_UPLOAD_SIZE = 3 * 1024 * 1024;

/**
 * Validates an array of files based on their file size, file extension, and content.
 *
 * @param {Object[]} files The files to validate.
 * @param {String[]} acceptedFileTypes An array of accepted file types.
 * @returns {Object} A object with `invalid` and `valid` properties, both are arrays containing the results.
 */
export default async function validateFiles(files, acceptedFileTypes = []) {
  const invalid = [];
  const valid = [];

  await files.forEach(async (file) => {
    const hasAcceptableSize = file.size < MAX_FILE_UPLOAD_SIZE;
    const hasValidExtension = acceptedFileTypes.includes(file.type);
    let hasValidContent = true;

    switch (file.type) {
      case FORM:
        hasValidContent = isValidForm(file.content);
        break;
      case DMN:
        hasValidContent = isValidDmnFile(file.content);
        break;
      case CONNECTOR_TEMPLATE:
        hasValidContent = isValidConnectorTemplate(file.content);
        break;
      default:
        hasValidContent = (await isValidBpmnDiagram(file.content)) && isZeebeProcess(file.content);
    }

    if (hasAcceptableSize && hasValidExtension && hasValidContent) {
      valid.push(file);
    } else {
      invalid.push(file);
    }
  });

  return { valid, invalid };
}
