/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Service from './Service';

class FileService extends Service {
  /**
   * Fetches a file (diagram) based on a given `fileId`.
   *
   * @param {String} fileId
   * @returns {Promise}
   */
  fetchById(fileId) {
    return this.get(`/internal-api/files/${fileId}`);
  }

  /**
   * Fetches all related files of a given file.
   *
   * @param {String} fileId
   * @returns {Promise}
   */
  fetchRelatedFiles(fileId) {
    return this.get(`/internal-api/files/${fileId}/related`);
  }

  /**
   * Creates a new file.
   *
   * @param {Object} payload
   * @param {String} payload.name The filename.
   * @param {String} payload.type The type of file. Either "BPMN", "DMN".
   * @param {String} payload.content The file's content.
   * @param {String} payload.projectId The parent project's ID.
   * @param {String} [payload.source] The creation source, like file upload.
   * @param {String} [payload.relationId]
   * @param {String} [payload.processId]
   * @returns {Promise}
   */
  create(payload) {
    return this.post('/internal-api/files', payload);
  }

  /**
   * Creates a new attention grabber.
   *
   * @param {String} fileId The diagram in which the attention grabber should be created.
   * @param {Object} payload
   * @param {Object} payload.coordinates An object with x and y properties.
   * @param {String} payload.color The attention grabber's color.
   * @returns {Promise}
   */
  createAttentionGrabber(fileId, payload) {
    return this.post(`/internal-api/files/${fileId}/attention`, payload);
  }

  /**
   * Duplicates a number of files, based on the given `fileIds` array.
   *
   * @param {String[]} payload An array with all file IDs that should be duplicated.
   * @returns {Promise}
   */
  duplicate(payload) {
    return this.post('/internal-api/files/duplicate', payload);
  }

  /**
   * Updates a file's content or properties, based on the given `fileId`.
   *
   * @param {String} fileId The file's ID.
   * @param {Object} payload
   * @param {Number} payload.revision An incremented number, representing the current revision.
   * @param {String} [payload.content] The new file content.
   * @param {String} [payload.name] The new filename.
   * @param {String} [payload.relationId] The new relation ID (only for DMN and BPMN diagrams).
   * @param {String} [payload.processId] The new process ID (only for DMN and BPMN diagrams).
   */
  update(fileId, payload) {
    return this.patch(`/internal-api/files/${fileId}`, payload);
  }

  /**
   * Checks if the given files or folders can be removed by the user.
   *
   * @param {String[]} fileIds An array of file IDs.
   * @param {String[]} folderIds An array of folder IDs.
   * @returns {Promise}
   */
  destroyDryRun(fileIds = [], folderIds = []) {
    const fileParams = fileIds.map((id) => `fileId=${id}`).join('&');
    const folderParams = folderIds.map((id) => `folderId=${id}`).join('&');

    return this.get(`/internal-api/dry-run/delete?${fileParams}&${folderParams}`);
  }

  /**
   * Removes a number of files, based on the given `fileIds` array.
   *
   * @param {String[]} payload An array with all file IDs that should be removed.
   * @returns {Promise}
   */
  destroy(payload) {
    return this.delete('/internal-api/files', payload);
  }

  /**
   * Removes an existing attention grabber.
   *
   * @param {String} fileId
   * @returns {Promise}
   */
  destroyAttentionGrabber(fileId) {
    return this.delete(`/internal-api/files/${fileId}/attention`);
  }

  /**
   * Moves a file into a different project.
   *
   * @param {Object} payload
   * @param {String} payload.targetFolderId Target folder ID
   * @param {String} payload.targetProjectId Target project ID.
   * @param {String[]} payload.fileIds An array of file IDs.
   * @returns {Promise}
   */
  move(payload) {
    return this.post(`/internal-api/files/move`, payload);
  }

  /**
   * Checks if the given files or folders can be moved into a different project by the user.
   *
   * @param {String[]} fileIds An array of file IDs.
   * @param {String[]} folderIds An array of folder IDs.
   * @returns {Promise}
   */
  moveDryRun(fileIds = [], folderIds = []) {
    const fileParams = fileIds.map((id) => `fileId=${id}`).join('&');
    const folderParams = folderIds.map((id) => `folderId=${id}`).join('&');

    return this.get(`/internal-api/dry-run/move?${fileParams}&${folderParams}`);
  }

  /**
   * Get the specific existing resources in a project.
   *
   * @param {String} projectId ID of the project.
   * @param {String} templateIds Comma separated string of resource IDs.
   * @returns {Promise}
   */
  getSpecificResourcesInProject(projectId, templateIds) {
    return this.get(`/internal-api/projects/${projectId}/files?templateId=${templateIds}`);
  }

  convertLinkedFormsToEmbeddedForms(fileId) {
    return this.get(`/internal-api/files/${fileId}/embedded-forms`);
  }
}

export default new FileService();
