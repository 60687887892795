/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import lodashDebounce from 'lodash/debounce';

/**
 - Lodash docs:
    https://lodash.com/docs/4.17.5#debounce

 - Debouncing & throttling explained, with leading & trailing options
    https://css-tricks.com/debouncing-throttling-explained-examples/
 */
export default function debounce(fn, ...rest) {
  // no debouncing / async during tests
  if (process.env.NODE_ENV === 'test') {
    fn.cancel = () => {};
    return fn;
  }

  return lodashDebounce(fn, ...rest);
}
