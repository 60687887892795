/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Success } from 'icons';

export const Errors = styled.div`
  color: var(--grey-darken-23);
`;

export const DocumentationLink = styled.a`
  margin-left: 0.5em;
  display: inline-flex;
`;

export const Error = styled.div`
  display: flex;
  background: white;
  border-bottom: 1px solid var(--silver-darken-94);

  &:hover {
    background: var(--silver-darken-94);
    cursor: pointer;
  }
`;

export const Icon = styled.div`
  display: inline-block;
  margin-left: 4px;

  svg {
    vertical-align: middle;
  }
`;

export const Type = styled.div`
  display: inline-block;
  margin-left: 9px;
`;

export const Element = styled.div`
  display: flex;
  padding: 6px 8px;
  font-weight: bold;
  width: 200px;

  border-right: 1px solid var(--silver-darken-94);
`;

export const ElementId = styled.span`
  display: inline-block;
  max-width: 150px;
  margin-left: var(--spacing-small);
  vertical-align: middle;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Message = styled.div`
  display: flex;
  padding: 6px 8px;
  align-items: center;
  width: calc(100% - 200px);
`;

export const EmptyState = styled.div`
  height: 30px;
  margin: 5px 10px;
`;

export const SuccessIcon = styled(Success)`
  width: 20px;
  vertical-align: middle;
`;

export const EmptyStateMessage = styled.span`
  margin-left: 5px;
  vertical-align: middle;
`;
