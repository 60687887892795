/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { Button } from 'primitives';

export const NewCommentForm = styled.form`
  display: flex;
  align-items: flex-end;

  margin: var(--spacing-small);
`;

export const NewCommentFormButton = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0;
  margin-left: var(--spacing-tiny);

  > svg {
    margin: 0;
  }
`;
