/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Wrapper = styled.main`
  max-width: 400px;
  width: 100%;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
