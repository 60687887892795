/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

import { DropdownButton, Button } from 'primitives';
import { Dropdown } from 'components';

export const DeploymentActions = styled.div`
  display: flex;
  align-items: center;
  margin: 0 var(--spacing-tiny);
`;

export const PrimaryButton = styled(Button)`
  margin-right: var(--spacing-tiny);

  svg {
    width: 24px;
    height: 24px;
    margin-right: var(--spacing-tiny);
  }
`;

export const StartInstanceButton = styled(DropdownButton)`
  height: 30px;
  line-height: 30px;
  vertical-align: top;

  svg {
    width: 24px;
    height: 24px;
    margin-right: var(--spacing-tiny);
  }
`;

export const DropdownListItem = styled(Dropdown.ListItem)`
  padding: var(--spacing-tiny) var(--spacing-tiny);
  margin: 0 var(--spacing-tiny) 0 var(--spacing-tiny);

  & > div {
    display: flex;
    align-items: center;
    gap: var(--spacing-tiny);
  }

  &[disabled] {
    opacity: 1;
  }
`;

export const ClusterDropdownListItem = styled(DropdownListItem)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const DropdownListGroup = styled(Dropdown.ListGroup)`
  & > div {
    padding: 0 var(--spacing-small);
  }

  a {
    text-decoration: none;
  }
`;

export const StartInstanceMessage = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-tiny);
  padding: var(--spacing-tiny) var(--spacing-small);

  svg {
    fill: var(--cds-blue-60);
  }
`;
