/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

function isAnyAction(actions, action) {
  return actions.indexOf(action) > -1;
}
const HIGH_PRIORITY = 10001;
const TOGGLE_MODE_EVENT = 'playMode.toggleMode';

function PlayModeExtension(
  canvas,
  eventBus,
  contextPad,
  dragging,
  directEditing,
  editorActions,
  modeling,
  palette,
  elementRegistry,
  overlays
) {
  this._canvas = canvas;
  this._eventBus = eventBus;
  this._elementRegistry = elementRegistry;
  this._overlays = overlays;

  this.activeOverlays = [];

  let modelingDisabled = false;

  eventBus.on(TOGGLE_MODE_EVENT, HIGH_PRIORITY, (event) => {
    modelingDisabled = event.active;

    if (modelingDisabled) {
      directEditing.cancel();
      contextPad.close();
      dragging.cancel();
    }

    palette._update();
  });

  function intercept(obj, fnName, cb) {
    const fn = obj[fnName];
    obj[fnName] = function () {
      return cb.call(this, fn, arguments);
    };
  }

  function ignoreIfModelingDisabled(obj, fnName) {
    intercept(obj, fnName, function (fn, args) {
      if (modelingDisabled) {
        return;
      }

      return fn.apply(this, args);
    });
  }

  function throwIfModelingDisabled(obj, fnName) {
    intercept(obj, fnName, function (fn, args) {
      if (modelingDisabled) {
        throw new Error('model is read-only');
      }

      return fn.apply(this, args);
    });
  }

  ignoreIfModelingDisabled(contextPad, 'open');

  ignoreIfModelingDisabled(dragging, 'init');

  ignoreIfModelingDisabled(directEditing, 'activate');

  ignoreIfModelingDisabled(dragging, 'init');

  ignoreIfModelingDisabled(directEditing, 'activate');

  throwIfModelingDisabled(modeling, 'moveShape');
  throwIfModelingDisabled(modeling, 'updateAttachment');
  throwIfModelingDisabled(modeling, 'moveElements');
  throwIfModelingDisabled(modeling, 'moveConnection');
  throwIfModelingDisabled(modeling, 'layoutConnection');
  throwIfModelingDisabled(modeling, 'createConnection');
  throwIfModelingDisabled(modeling, 'createShape');
  throwIfModelingDisabled(modeling, 'createLabel');
  throwIfModelingDisabled(modeling, 'appendShape');
  throwIfModelingDisabled(modeling, 'removeElements');
  throwIfModelingDisabled(modeling, 'distributeElements');
  throwIfModelingDisabled(modeling, 'removeShape');
  throwIfModelingDisabled(modeling, 'removeConnection');
  throwIfModelingDisabled(modeling, 'replaceShape');
  throwIfModelingDisabled(modeling, 'pasteElements');
  throwIfModelingDisabled(modeling, 'alignElements');
  throwIfModelingDisabled(modeling, 'resizeShape');
  throwIfModelingDisabled(modeling, 'createSpace');
  throwIfModelingDisabled(modeling, 'updateWaypoints');
  throwIfModelingDisabled(modeling, 'reconnectStart');
  throwIfModelingDisabled(modeling, 'reconnectEnd');

  intercept(editorActions, 'trigger', function (fn, args) {
    const action = args[0];

    if (
      modelingDisabled &&
      isAnyAction(
        [
          'undo',
          'redo',
          'copy',
          'paste',
          'removeSelection',
          'spaceTool',
          'lassoTool',
          'globalConnectTool',
          'distributeElements',
          'alignElements',
          'directEditing'
        ],
        action
      )
    ) {
      return;
    }

    return fn.apply(this, args);
  });
}

PlayModeExtension.prototype.activate = function () {
  this._canvas.getContainer()?.parentNode.classList.add('playMode');

  this._eventBus.fire(TOGGLE_MODE_EVENT, {
    active: true
  });
};

PlayModeExtension.prototype.addOverlay = function (element, type, position) {
  this._overlays.remove({ type, element });

  const node = document.createElement('div');

  this.activeOverlays.push(
    this._overlays.add(element.id, type, {
      position,
      html: node
    })
  );

  return node;
};

PlayModeExtension.prototype.getElements = function () {
  const out = [];
  this._elementRegistry.forEach((element) => {
    out.push(element);
  });

  return out;
};

PlayModeExtension.prototype.deactivate = function () {
  this._canvas.getContainer()?.parentNode.classList.remove('playMode');

  this._eventBus.fire(TOGGLE_MODE_EVENT, {
    active: false
  });

  this.activeOverlays.forEach((overlay) => {
    this._overlays.remove(overlay);
  });
  this.activeOverlays = [];
};

PlayModeExtension.$inject = [
  'canvas',
  'eventBus',
  'contextPad',
  'dragging',
  'directEditing',
  'editorActions',
  'modeling',
  'palette',
  'elementRegistry',
  'overlays'
];

export default {
  __init__: ['playModeExtension'],
  playModeExtension: ['type', PlayModeExtension]
};
