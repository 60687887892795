/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import * as Styled from './VersionSelector.styled';

export default function Selector({ executionPlatformVersion, setAnchorEl, selectionRef }) {
  return (
    <Styled.SelectionWithLabel
      onClick={(e) => {
        e.preventDefault();
        setAnchorEl(selectionRef.current);
      }}
      data-test="version-selector"
    >
      <Styled.SelectionLabel>Check problems against:</Styled.SelectionLabel>
      <Styled.Selection ref={selectionRef}>Zeebe {executionPlatformVersion.substring(0, 3)}</Styled.Selection>
    </Styled.SelectionWithLabel>
  );
}
