/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable, runInAction } from 'mobx';
import { validateZeebe } from '@bpmn-io/element-templates-validator';

import { fileService, projectService, trackingService, folderService } from 'services';
import { notificationStore, userStore, breadcrumbStore, confirmActionStore, projectStore } from 'stores';
import { validateFiles } from 'utils/file-io';
import {
  CONNECTOR_TEMPLATE,
  DEFAULT,
  FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION,
  FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION
} from 'utils/constants';
import history from 'utils/history';
import throttle from 'utils/throttle-async';
import buildSlug from 'utils/buildSlug';
import createPermission from 'utils/createPermission';
import generateId from 'utils/generate-id';

class ConnectorTemplateStore {
  project = null;
  connectorTemplate = null;
  loading = true;
  saving = false;
  forceRefresh = false;
  lastSaved = null;
  showDuplicateDialog = false;

  constructor() {
    makeAutoObservable(this);
  }

  init = async (connectorTemplateId) => {
    try {
      const file = await fileService.fetchById(connectorTemplateId);
      const requests = [projectService.fetchById({ projectId: file.projectId })];

      if (file.folderId) {
        requests.push(folderService.fetchById(file.folderId));
      }

      const [project, folder] = await Promise.all(requests);

      runInAction(() => {
        const content = this.#fixIdAndName(file);
        this.connectorTemplate = { ...file, folder, content };

        this.project = project;
      });
    } catch (ex) {
      notificationStore.showError('Yikes! Could not load your connector template data. Please try again later.');
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  // makes sure that the id and name in the JSON is identical to the id and name of the file
  #fixIdAndName = (file) => {
    const content = JSON.parse(file.content);

    let overridableProperties = ['$schema', 'name'];

    const out = {
      $schema: 'https://unpkg.com/@camunda/zeebe-element-templates-json-schema/resources/schema.json',
      name: file.name
    };

    // only override the id if it is not imported, see #5891
    if (!file.imported) {
      overridableProperties = [...overridableProperties, 'id'];
      out.id = file.id;
    }

    // doing it this way ensures that the protected properties always are at the top of the string
    for (let property in content) {
      if (!overridableProperties.includes(property)) {
        out[property] = content[property];
      }
    }

    return JSON.stringify(out, undefined, 2);
  };

  // updates the name in the JSON (called by the rename operation)
  #updateNameInJSON = (content, newName) => {
    const newContent = JSON.parse(content);

    newContent.name = newName;

    return JSON.stringify(newContent, undefined, 2);
  };

  reset = () => {
    this.loading = true;
    this.project = null;
    this.connectorTemplate = null;
    this.saving = false;
    this.lastSaved = null;
    this.forceRefresh = false;
    this.showDuplicateDialog = false;
  };

  confirmRefresh = () => {
    this.forceRefresh = false;
  };

  update = (newContent, forceRefresh = false) => {
    this.connectorTemplate.content = newContent;
    this.forceRefresh = forceRefresh;
    this.save(newContent, forceRefresh);
  };

  save = throttle(async (content) => {
    // we cannot save invalid JSON
    if (!this.isValid(content)) {
      return;
    }

    runInAction(() => (this.saving = true));

    try {
      const file = await fileService.update(this.connectorTemplate.id, {
        name: this.connectorTemplate.name,
        content: content,
        revision: this.connectorTemplate.revision,
        originAppInstanceId: userStore.originAppInstanceId
      });
      trackingService.trackEditFile({ fileId: file.id, type: CONNECTOR_TEMPLATE });

      runInAction(() => {
        this.lastSaved = new Date();
        this.connectorTemplate.revision = file.revision;
      });
    } catch (error) {
      await this.reloadConnectorTemplate();
      if (error.status === 409) {
        notificationStore.showError(FILE_UPDATE_CONFLICT_ERROR_NOTIFICATION);
      } else {
        notificationStore.showError(FILE_UPDATE_UNEXPECTED_ERROR_NOTIFICATION);
      }
    } finally {
      runInAction(() => (this.saving = false));
    }
  });

  async reloadConnectorTemplate() {
    const file = await fileService.fetchById(this.connectorTemplate.id);

    if (file && file.content) {
      runInAction(() => {
        this.connectorTemplate.revision = file.revision;
        this.connectorTemplate.content = file.content;
        this.forceRefresh = true;
      });
    }
  }

  replace = async (payload, fileId, source) => {
    try {
      const file = await fileService.update(fileId, payload);

      trackingService.trackEditFile({ fileId: file.id, type: CONNECTOR_TEMPLATE, source });

      return file;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not replace your connector template. Please try again later.');
    }
  };

  rename = async (newName) => {
    newName = newName.trim();
    breadcrumbStore.finishEditing();

    if (newName.length === 0 || newName === this.connectorTemplate.name) {
      return;
    }

    try {
      const content = this.#updateNameInJSON(this.connectorTemplate.content, newName);

      const file = await fileService.update(this.connectorTemplate.id, {
        name: newName,
        content,
        revision: this.connectorTemplate.revision,
        originAppInstanceId: userStore.originAppInstanceId
      });

      runInAction(() => {
        this.connectorTemplate.name = newName;
        this.connectorTemplate.revision = file.revision;
        this.connectorTemplate.content = content;
        this.forceRefresh = true;
      });
    } catch (ex) {
      notificationStore.showError('Yikes! Could not rename your connector template. Please try again later.');
    }
  };

  delete = async () => {
    breadcrumbStore.toggleDropdownVisibility();

    let confirmationDialogProps = {
      title: 'Deleting connector template',
      confirmLabel: 'Delete connector template'
    };

    try {
      const response = await fileService.destroyDryRun([this.connectorTemplate.id]);
      if (response?.warnings?.includes('PUBLIC_MILESTONE')) {
        const hasOrganizationPublicVersionText = `This connector template has at least one organization's public version.`;
        if (userStore.isOrgOwner) {
          confirmationDialogProps.text = `${hasOrganizationPublicVersionText}
          Deleting it will remove access for all members, and they will no longer be able to use it in their diagrams.`;
          confirmationDialogProps.isPrimaryActionDanger = true;
        } else {
          return notificationStore.showError(
            `${hasOrganizationPublicVersionText} Only organization owners are allowed to delete it.`
          );
        }
      } else {
        confirmationDialogProps.text = 'Are you sure you want to delete this connector template from the project?';
        confirmationDialogProps.isDangerous = true;
      }
    } catch (error) {
      if (error.status === 404) {
        return notificationStore.showError("You don't have permission to delete this connector template.");
      } else {
        return notificationStore.showError(
          "Yikes! Your connector template couldn't be deleted. Please try again later."
        );
      }
    }
    const confirmed = await confirmActionStore.confirm(confirmationDialogProps);

    if (confirmed) {
      try {
        await fileService.destroy([this.connectorTemplate.id]);

        notificationStore.showSuccess('Your connector template has been deleted.');
        trackingService.trackDeleteEntities(DEFAULT, 1);

        if (this.connectorTemplate.folder) {
          history.push(`/folders/${this.connectorTemplate.folder.id}`);
        } else {
          history.push(`/projects/${this.project.id}`);
        }
      } catch (ex) {
        notificationStore.showError("Yikes! Couldn't remove your connector template. Please try again later.");
      }
    }
  };

  duplicate = async () => {
    breadcrumbStore.toggleDropdownVisibility();

    try {
      const files = await fileService.duplicate([this.connectorTemplate.id]);

      notificationStore.showSuccess(
        'Your connector template has been duplicated. You are currently in the duplicated version.'
      );
      history.push(`/connector-templates/${buildSlug(files[0].id, files[0].name)}`);
    } catch (ex) {
      notificationStore.showError("Yikes! Couldn't duplicate your connector template. Please try again later.");
    }
  };

  /**
   * Duplicates an imported connector template.
   * @param {*} options
   * @returns {Promise<string>} the link to the duplicated connector template
   */
  duplicateImportedTemplate = async ({ projectId, templateName, templateDescritpion }) => {
    const template = JSON.parse(this.connectorTemplate.content);

    template.id = `ConnectorTemplate_${generateId()}`;
    template.name = templateName.trim();
    template.description = templateDescritpion.trim();

    const payload = {
      projectId,
      name: template.name,
      content: JSON.stringify(template, undefined, 2)
    };

    const file = await projectStore.createFile({
      type: CONNECTOR_TEMPLATE,
      file: payload,
      source: 'duplicate-imported-template',
      isFromTemplate: false
    });

    if (file) {
      return `/connector-templates/${buildSlug(file.id, file.name)}`;
    } else {
      throw new Error('File could not be saved.');
    }
  };

  upload = async (files) => {
    breadcrumbStore.toggleDropdownVisibility();

    if (files.length > 1) {
      return notificationStore.showError('You can only upload one single file to replace this connector template.');
    }

    const { valid, invalid } = await validateFiles(files, [CONNECTOR_TEMPLATE]);

    if (invalid.length > 0) {
      notificationStore.showError(
        `"${invalid[0].name}" is not a valid connector template and can't be uploaded. Please choose another file.`
      );
    }

    if (valid.length > 0) {
      const confirmed = await confirmActionStore.confirm({
        title: 'File upload',
        text: "Uploading this file will replace the current connector template and you won't be able to restore it. Do you want to continue?",
        isDangerous: true,
        confirmLabel: 'Yes, replace this connector template'
      });

      if (confirmed) {
        notificationStore.showSuccess(`"${valid[0].name}" is being uploaded to replace this connector template.`);

        const content = this.#fixIdAndName({
          ...this.connectorTemplate,
          content: valid[0].content
        });

        this.update(content, true);
      }
    }
  };

  get status() {
    if (this.loading) {
      return null;
    }
    if (!this.isValid()) {
      return {
        status: 'error',
        message: 'Your JSON is invalid and will not be saved.'
      };
    } else if (this.saving) {
      return {
        status: 'progress',
        message: 'Saving...'
      };
    } else if (this.lastSaved) {
      const time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format(this.lastSaved);

      return {
        status: 'done',
        message: `Autosaved at ${time}`
      };
    }

    return null;
  }

  isValid = (content = this.connectorTemplate?.content) => {
    try {
      const connectorTemplate = JSON.parse(content);
      const { valid } = validateZeebe(connectorTemplate);
      return valid;
    } catch (e) {
      return false;
    }
  };

  get isReadOnly() {
    const permission = createPermission(this.project?.permissionAccess);
    return !permission.is(['ADMIN', 'WRITE']) || this.connectorTemplate?.imported;
  }

  get readOnlyRanges() {
    const lines = this.connectorTemplate?.content?.split('\n');

    return this.isReadOnly
      ? [
          {
            // all lines are read-only
            startLineNumber: 0,
            endLineNumber: lines.length + 1,
            startColumn: 0,
            endColumn: 0
          }
        ]
      : [
          {
            // first four/five lines have opening curly bracket, $schema, name, id and version (optional)
            // and are read-only
            startLineNumber: 0,
            endLineNumber: lines[4].trim().startsWith('"version"') ? 6 : 5,
            startColumn: 0,
            endColumn: 0
          },
          {
            // last line has closing curly bracket and is read-only
            startLineNumber: lines.length,
            endLineNumber: lines.length + 1,
            startColumn: 0,
            endColumn: 0
          }
        ];
  }

  uploadImage = async () => {
    if (!this.isValid()) {
      return notificationStore.showError('The template is invalid. Icon upload is only possible for valid templates.');
    }

    const SUPPORTED_TYPES = ['image/png', 'image/svg+xml'];

    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', SUPPORTED_TYPES.join(','));

    fileInput.addEventListener('change', () => {
      const file = fileInput.files[0];

      if (SUPPORTED_TYPES.indexOf(file.type) === -1) {
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('load', (e) => {
        const fileContent = e.target.result;

        let newContent = JSON.parse(this.connectorTemplate.content);
        newContent.icon = {
          contents: fileContent
        };

        this.update(JSON.stringify(newContent, undefined, 2), true);
      });
      reader.readAsDataURL(file);
    });

    fileInput.click();
  };

  trackConnectorTemplateView = (origin, extraProps) => {
    const user = userStore?.userId;

    trackingService.trackViewFile(origin, user, this.connectorTemplate, undefined, extraProps);
  };

  getSpecificResourcesInProject = async (projectId, templateIds) => {
    return fileService.getSpecificResourcesInProject(projectId, templateIds);
  };

  setShowDuplicateDialog = (flag) => {
    runInAction(() => {
      this.showDuplicateDialog = flag;
    });
  };
}

export default new ConnectorTemplateStore();
