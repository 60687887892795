/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import PropTypes from 'prop-types';

import * as Styled from './Loader.styled';

Styled.Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'normal']),
  $light: PropTypes.bool
};

Styled.Loader.defaultProps = {
  size: 'normal',
  $light: false
};

export default Styled.Loader;
