/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

const findElement = (selector, parent = document) => {
  return new Promise((resolve) => {
    if (parent.querySelector(selector)) {
      return resolve(parent.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (parent.querySelector(selector)) {
        resolve(parent.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(parent.body, {
      childList: true,
      subtree: true
    });
  });
};

const openGroup = async (groupId) => {
  const group = await findElement(`[data-group-id="${groupId}"]`);
  const groupHeader = group.querySelector('.bio-properties-panel-group-header');
  if (!groupHeader.matches('.open')) {
    groupHeader.click();
  }
};

export const selectElementAndFocusField = async ({ modeler, element, groupId, fieldId }) => {
  modeler.get('selection').select(element);

  await openGroup(groupId);

  const field = await findElement(fieldId);
  field.focus();
};

export const isExpression = (str) => {
  return str?.trim()?.startsWith('=');
};
