/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Stack } from '@carbon/react';
import styled from 'styled-components';

export const Type = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: var(--cds-text-secondary);
`;

export const Icon = styled.div`
  width: 48px;
  height: 48px;
  padding: 15px 10px 10px;
`;

export const Title = styled(Stack)`
  align-items: center;
`;
