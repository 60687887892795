/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
`;

export const Container = styled.main`
  padding: 0 65px 20px;
  overflow-x: auto;
`;

export const Title = styled.h2`
  margin-top: 40px;
  font-size: 19px;
`;
