/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import * as Styled from './Radio.styled';

export default function Radio({ name, value, label, selected, onSelect }) {
  return (
    <>
      <Styled.Radio
        type="radio"
        id={name}
        name={name}
        value={value}
        checked={selected === value}
        onChange={(e) => onSelect(e.target.value)}
      />
      <Styled.Label htmlFor={name}>{label}</Styled.Label>
    </>
  );
}
