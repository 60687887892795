/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Service } from 'services';

class DeploymentService extends Service {
  deploy(diagramId, clusterInfo, formResolutions) {
    return this.post(`/internal-api/files/${diagramId}/deploy`, { ...clusterInfo, formResolutions });
  }

  execute(diagramId, clusterInfo, payload, formResolutions) {
    return this.post(`/internal-api/files/${diagramId}/execute`, {
      ...clusterInfo,
      formResolutions,
      variables: payload
    });
  }
}

export default new DeploymentService();
