/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const RelatedDiagramEntry = styled.li`
  display: flex;
  padding: var(--spacing-small);
  position: relative;

  border-left: 6px solid transparent;

  &:hover {
    button {
      opacity: 1;
    }
  }

  ${(props) =>
    props.isSelectable &&
    `
      cursor: pointer;  
    `}

  ${(props) => {
    if (!props.isSelected && props.isSelectable) {
      return `
        &:hover {
          background-color: rgba(245, 245, 245, 0.6);
        }
      `;
    }
  }}

  ${(props) =>
    props.isSelected &&
    `
      border-left-color: var(--cds-blue-60);
      background-color: rgba(230, 230, 230, 0.45);
    `}
`;

export const RelatedDiagramContent = styled.div`
  flex: 1;
  margin: 0 40px 0 15px;
  min-width: 0;

  line-height: 1.5;
`;

export const RelatedDiagramTitle = styled.h5`
  margin: 0;

  font-size: 12px;

  color: var(--grey-darken-33);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RelatedDiagramParagraph = styled.p`
  margin: 0;

  font-size: 11px;

  color: var(--grey-lighten-50);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
