/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Tabs, Tab, Tooltip as CarbonTooltip } from '@carbon/react';

import { AvatarList, Switch } from 'primitives';
import { userStore, realtimeCollaborationStore, currentDiagramStore, diagramExtensionStore } from 'stores';
import { dedicatedModesStore, isModesTooltipVisibleStore } from 'App/Pages/Diagram/stores';
import { XMLEditorStore } from 'App/Pages/Diagram/XMLEditor';
import { Danger, Info, Saved } from 'icons';
import hasAccess, { actions } from 'utils/user-access';
import { isBPMN as isBPMNUtil, isDMN as isDMNUtil } from 'utils/helpers';
import config from 'utils/config';

import DeploymentActions from './Deployment/DeploymentActions';
import * as Styled from './ActionBar.styled';
import tokenSimulationStore from './BpmnJSExtensions/tokenSimulationExtension/TokenSimulationStore';
import ActionsMenu from './ActionsMenu';

const getStatusIcon = (status) => {
  switch (status) {
    case 'progress':
      return <Styled.SavingIcon width="16" height="16" />;
    case 'done':
      return <Saved width="20" height="20" />;
    case 'warning':
      return <Danger width="18" height="18" />;
    case 'info':
      return <Info width="14" height="14" />;
    default:
      return <Info width="14" height="14" />;
  }
};

const useTokenSimulation = () => {
  const { isTokenSimulationOn, onTokenSimulationChange } = tokenSimulationStore;

  tokenSimulationStore.init();

  useEffect(() => {
    return () => {
      tokenSimulationStore.reset();
    };
  }, []);

  return { isTokenSimulationOn, onTokenSimulationChange };
};

const useModesTooltip = () => {
  useEffect(() => {
    const handleClick = () => {
      isModesTooltipVisibleStore.hide();
    };

    document.body.addEventListener('click', handleClick, true);

    return () => {
      document.body.removeEventListener('click', handleClick, true);
    };
  }, []);
};

const ActionBar = ({ shouldShowModesTooltip }) => {
  const history = useHistory();
  const { autosaveMessage } = currentDiagramStore;
  const { diagram, project } = currentDiagramStore.state;
  const { selectedModeIndex, setViewModeIndex, allowedViewModes, hasPlayMode, isDesignMode, isImplementMode } =
    dedicatedModesStore;
  const collaborators = realtimeCollaborationStore.onlineCollaborators.filter(
    (collaborator) => collaborator.id !== userStore.userId
  );
  const { shouldBeShown: isModesTooltipVisible } = isModesTooltipVisibleStore;
  const { isEditorOpen, invalidContentStatusMessage } = XMLEditorStore;

  const { isTokenSimulationOn, onTokenSimulationChange } = useTokenSimulation();

  const isBPMN = diagram && isBPMNUtil(diagram);
  const isDMN = diagram && isDMNUtil(diagram);

  const isDeploymentEnabled = isBPMN ? config?.zeebe?.bpmnDeploymentEnabled : config?.zeebe?.dmnDeploymentEnabled;

  const shouldShowDeploymentActions =
    isDeploymentEnabled && ((isBPMN && isImplementMode) || isDMN) && hasAccess(project, actions.MODIFY_DIAGRAM);
  const shouldShowShareButton = ((isBPMN && isDesignMode) || isDMN) && hasAccess(project, actions.SHARE_DIAGRAM);
  const shareButtonVariant = isDMN ? 'secondary' : 'primary';
  const shouldShowNavigationModes = isBPMN && allowedViewModes?.length > 0;

  const isSelectedModeAvailable = allowedViewModes.some(({ index }) => index === selectedModeIndex);

  const modesTabs = (
    <Tabs
      selectedIndex={isSelectedModeAvailable ? selectedModeIndex : 0}
      onChange={(evt) => {
        setViewModeIndex(evt.selectedIndex);
      }}
    >
      <Styled.TabList aria-label="List of modes" activation="manual">
        {allowedViewModes.map((viewMode) => (
          <Tab
            className="mode-tab"
            key={`view-mode-${viewMode.index}`}
            title={viewMode.label}
            data-test={`mode-tab-${viewMode.label.toLowerCase()}`}
          >
            {viewMode.label}
          </Tab>
        ))}
      </Styled.TabList>
    </Tabs>
  );

  useModesTooltip();

  const renderableAutosaveMessage = invalidContentStatusMessage || autosaveMessage;

  return (
    <Styled.ActionBarContainer data-test="action-bar">
      <Styled.ActionBar>
        <Styled.Left>
          {shouldShowNavigationModes &&
            (shouldShowModesTooltip && isModesTooltipVisible ? (
              <CarbonTooltip
                data-test="modes-tooltip"
                label={
                  hasPlayMode
                    ? 'Switch modes to design, implement or simulate a process in play mode.'
                    : 'Switch modes easily between designing and implementing a process.'
                }
                align="bottom-left"
                defaultOpen={isModesTooltipVisible}
              >
                {modesTabs}
              </CarbonTooltip>
            ) : (
              modesTabs
            ))}

          {Boolean(renderableAutosaveMessage) && (
            <Styled.Status className={renderableAutosaveMessage.status} data-test="autosave">
              {getStatusIcon(renderableAutosaveMessage.status)}
              {renderableAutosaveMessage.message}
            </Styled.Status>
          )}
        </Styled.Left>

        <Styled.PaddedContent>
          {isBPMN && isDesignMode && (
            <Styled.Label>
              <Switch checked={isTokenSimulationOn} onChange={onTokenSimulationChange} data-test="token-simulation" />
              Token Simulation
            </Styled.Label>
          )}

          {hasAccess(project, actions.VIEW_COLLABORATORS) && (
            <>
              <AvatarList collaborators={collaborators} />
              {collaborators.length > 0 && <Styled.Separator isDesignMode={isDesignMode} />}
            </>
          )}

          {!isEditorOpen && (
            <>
              {shouldShowDeploymentActions && <DeploymentActions />}

              {isImplementMode && !isDeploymentEnabled && (
                <Styled.ActionButton
                  title="Show milestones history"
                  onClick={() => {
                    history.push(`/diagrams/${diagram.id}/milestones`);
                  }}
                  variant="primary"
                >
                  History
                </Styled.ActionButton>
              )}

              {shouldShowShareButton && (
                <Styled.ActionButton
                  onClick={() => diagramExtensionStore.showShareModal()}
                  title="Share"
                  data-test="share"
                  variant={shareButtonVariant}
                >
                  Share
                </Styled.ActionButton>
              )}
            </>
          )}

          {isEditorOpen && (
            <Styled.ActionButton
              onClick={() => XMLEditorStore.closeEditor()}
              title="Back to modeling view"
              data-test="goto-modeling-view"
              variant="primary"
            >
              Back to modeling view
            </Styled.ActionButton>
          )}

          <ActionsMenu />
        </Styled.PaddedContent>
      </Styled.ActionBar>
    </Styled.ActionBarContainer>
  );
};

export default observer(ActionBar);
