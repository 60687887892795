/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

/**
 * Checks if the submited change object has
 * a property key of value Target or Source and if the remove property is true
 * @param {*} change
 */
export default function checkForRemovedTargetOrSource(change) {
  return ['Target', 'Source'].includes(change.property) && change.removed === true;
}
