/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { Spinner } from 'primitives';
import { milestoneStore } from 'stores';

import RelatedDiagramList from './RelatedDiagramList';

const RelatedDiagramsSection = () => {
  if (milestoneStore.isLoading) {
    return <Spinner fullHeight />;
  }

  return <RelatedDiagramList versions={milestoneStore.relatedFiles} />;
};

export default observer(RelatedDiagramsSection);
