/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Connect } from '@carbon/react/icons';
import { useContext } from 'react';

import PlayContext from '../PlayContext';
import * as Styled from './ConnectorDiagramOverlay.styled';
import EditConnectorModal from './EditConnectorModal';

export default function ConnectorDiagramOverlay({ element, connector }) {
  const context = useContext(PlayContext);

  const missingSecret = context.connectorInfo.connectorElementsWithMissingSecrets.find(
    (connector) => connector.element.id === element.id
  )?.missingSecrets?.[0];
  const secret = connector.secrets[0];

  const hasMissingSecret = Boolean(missingSecret);
  const secretToUpdate = missingSecret || secret;

  return (
    <Styled.Container
      hasMissingSecret={hasMissingSecret}
      style={{ height: element.height + 10 + 'px', width: element.width + 10 + 'px' }}
    >
      <EditConnectorModal
        name={secretToUpdate}
        initialValue={context.connectorInfo.secrets.find(({ name }) => name === secretToUpdate)?.value ?? ''}
      >
        <Styled.IconContainer hasMissingSecret={hasMissingSecret}>
          <Connect />
        </Styled.IconContainer>
      </EditConnectorModal>
    </Styled.Container>
  );
}
