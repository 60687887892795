/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 12px;
  border: 2px solid var(--support-support-error, #da1e28);

  margin-top: 15px;
  margin-left: 25px;

  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 12px;

  bottom: -8px;
  left: -8px;

  background-color: var(--support-support-error, #da1e28);
  color: white;
  padding: 4px;
`;
