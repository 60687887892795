/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';
import { getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';

import { currentDiagramStore } from 'stores';

import zeebePlayService from './ZeebePlayService';

const secretsRegex = /["|{]?secrets\.((\w|-)+)["|}]?/gm;

export default function useConnectors(isReady, endPoint) {
  const [connectorElements, setConnectorElements] = useState([]);
  const [connectorElementsWithSecrets, setConnectorElementsWithSecrets] = useState([]);
  const [connectorElementsWithMissingSecrets, setConnectorElementsWithMissingSecrets] = useState([]);
  const [secrets, setSecrets] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!isReady) return;

    (async () => {
      setReady(false);
      const connectorElements = [];
      currentDiagramStore.elementRegistry.forEach((el) => {
        const bo = getBusinessObject(el);
        if (bo?.hasOwnProperty('modelerTemplate') || bo?.$attrs?.hasOwnProperty('zeebe:modelerTemplate')) {
          connectorElements.push(el);
        }
      });

      const connectorElementsWithSecrets = [];
      connectorElements.forEach((el) => {
        const bo = getBusinessObject(el);

        const referencedSecrets = bo.extensionElements.values
          .filter((value) => value.$type === 'zeebe:IoMapping')
          .reduce((acc, curr) => {
            const secrets =
              curr?.inputParameters
                .map((parameter) => secretsRegex.exec(parameter?.source ?? '')?.[1])
                ?.filter((entry) => Boolean(entry)) ?? [];

            acc.push(...secrets);

            return acc;
          }, []);

        if (referencedSecrets.length) {
          connectorElementsWithSecrets.push({ element: el, secrets: referencedSecrets });
        }
      });

      setConnectorElements(connectorElements);
      setConnectorElementsWithSecrets(connectorElementsWithSecrets);

      const response = await zeebePlayService.getConnectorSecrets(endPoint);

      const existingSecrets = response?.secrets || [];
      setSecrets(existingSecrets);

      const connectorElementsWithMissingSecrets = [];
      connectorElementsWithSecrets.forEach(({ element, secrets }) => {
        const missingSecrets = secrets.filter(
          (secret) => !existingSecrets.some(({ name, value }) => name === secret && value.trim() !== '')
        );
        if (missingSecrets.length > 0) {
          connectorElementsWithMissingSecrets.push({ element, missingSecrets });
        }
      });
      setConnectorElementsWithMissingSecrets(connectorElementsWithMissingSecrets);
      setReady(true);
    })();
  }, [isReady, endPoint]);

  async function setConnectorSecret(name, value) {
    const setConnectorSecretResponse = await zeebePlayService.setConnectorSecret(endPoint, name, value);
    if (setConnectorSecretResponse?.success === false) {
      return false;
    }

    const newSecrets = [...secrets.filter((secret) => secret.name !== name), { name, value }];

    const connectorElementsWithMissingSecrets = [];
    connectorElementsWithSecrets.forEach(({ element, secrets }) => {
      const missingSecrets = secrets.filter(
        (secret) => !newSecrets.some(({ name, value }) => name === secret && value.trim() !== '')
      );
      if (missingSecrets.length > 0) {
        connectorElementsWithMissingSecrets.push({ element, missingSecrets });
      }
    });
    setConnectorElementsWithMissingSecrets(connectorElementsWithMissingSecrets);
    setSecrets(newSecrets);

    return true;
  }

  return {
    connectorElements,
    connectorElementsWithSecrets,
    connectorElementsWithMissingSecrets,
    secrets,
    ready,
    setConnectorSecret
  };
}
