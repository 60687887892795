/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import parseXML from './parse-xml';
import stringifyXML from './stringify-xml';

/**
 * Writes a new XML property to the root element `<bpmn:definitions>`,
 * which has the value of the provided ID parameter. This is used to
 * identify related diagrams.
 *
 * @param {String} xml The XML in which the `relationId` should be added.
 * @param {String} relationId The `relationId` to be added.
 * @param {Boolean} override Whether to override an already existing `relationId` property.
 * @returns {String} The processed XML, now containing the `relationId` property.
 */

const CAMUNDA_RELATION_ID_ATTRIBUTE_KEY = 'camunda:diagramRelationId';

const CAMUNDA_NAMESPACE_ATTRIBUTE_KEY = 'xmlns:camunda';
const CAMUNDA_NAMESPACE_ATTRIBUTE_VALUE = 'http://camunda.org/schema/1.0/bpmn';

export default function appendRelationId(xml, relationId, override = false) {
  const dom = parseXML(xml);

  if (!dom) {
    return xml;
  }

  const root = dom.firstElementChild;

  if (!root.hasAttribute(CAMUNDA_RELATION_ID_ATTRIBUTE_KEY) || override) {
    root.setAttribute(CAMUNDA_RELATION_ID_ATTRIBUTE_KEY, relationId);

    if (!root.hasAttribute(CAMUNDA_NAMESPACE_ATTRIBUTE_KEY)) {
      root.setAttribute(CAMUNDA_NAMESPACE_ATTRIBUTE_KEY, CAMUNDA_NAMESPACE_ATTRIBUTE_VALUE);
    }
  }

  return stringifyXML(dom);
}
