/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Button } from '@carbon/react';
import { Renew } from '@carbon/icons-react';

import * as Styled from './ErrorBanner.styled';

const ErrorBanner = (props) => (
  <Styled.Container {...props}>
    <div>We were not able to retrieve some of the information required to proceed</div>{' '}
    <Button kind="tertiary" size="sm" renderIcon={Renew} onClick={props.onClick}>
      Retry
    </Button>
  </Styled.Container>
);

export { ErrorBanner };
