/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Button as CarbonButton } from '@carbon/react';
import styled from 'styled-components';

import { Input } from 'primitives';

export const Container = styled.div`
  display: flex;
`;
export const MainButton = styled(CarbonButton)`
  width: 42px;
`;

export const ErrorMessage = styled.pre`
  background-color: #eee;
  padding: 1em;
  white-space: pre-wrap;
  font-family: monospace;
  margin: 10px 0;
`;

export const Form = styled.form`
  margin-top: 10px;
  display: flex;
  gap: 20px;
`;

export const ScopeSection = styled.div`
  width: 25%;
`;

export const VariableSetSection = styled.div`
  flex-grow: 1;
`;

export const Textarea = styled(Input)`
  resize: vertical;
`;
