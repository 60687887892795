/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { makeAutoObservable } from 'mobx';

class IsExecuteModalVisibleStore {
  isVisible = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsVisible = (val) => {
    this.isVisible = val;
  };
}

export default new IsExecuteModalVisibleStore();
