/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { containsSearchWord } from 'components/EntityTable/utils';

import * as Styled from './LinkRenderer.styled';

function LinkRenderer({ value, link }) {
  return (
    <Styled.Wrapper>
      <Styled.Link className="cds--link" to={link} title={value}>
        {value}
      </Styled.Link>
    </Styled.Wrapper>
  );
}

LinkRenderer.containsSearchWord = (cell, searchWord) => containsSearchWord(cell?.value, searchWord);

LinkRenderer.getComparableValue = (cell) => cell?.value;

LinkRenderer.getDataTest = (cell) => `entity-${cell?.value}`;

export default LinkRenderer;
