/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const Header = styled.div`
  height: 135px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 65px;
  border-top: 1px solid var(--silver-darken-87);
`;

export const HeaderText = styled.p`
  font-size: 19px;
  color: var(--grey-darken-23);
  margin: 0 0 0 15px;
`;

export const ListTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Title = styled.h2`
  margin: 0 var(--spacing-medium) 0 0;
  font-weight: 400;
  font-size: 21px;
  color: var(--grey-darken-23);
`;
