/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { commentsStore, currentDiagramStore } from 'stores';

function OpenPropertiesOnAnnotationClick(eventBus) {
  eventBus.on('lintingAnnotations.click', function () {
    commentsStore.makePropertiesVisible();
    currentDiagramStore.setIsErrorPanelCollapsed(false);
  });
}

OpenPropertiesOnAnnotationClick.$inject = ['eventBus'];

export default {
  __init__: ['openPropertiesOnAnnotationClick'],
  openPropertiesOnAnnotationClick: ['type', OpenPropertiesOnAnnotationClick]
};
