/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Fragment, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Dropdown, FileUpload } from 'components';
import { breadcrumbStore, projectStore } from 'stores';
import { Button, Loader } from 'primitives';
import { BPMN, FORM, DMN, CONNECTOR_TEMPLATE, FOLDER } from 'utils/constants';
import { Diagram, Table, Folder, Form, ConnectorTemplate, FileUpload as FileUploadIcon } from 'icons';
import buildSlug from 'utils/buildSlug';

import * as Styled from './ProjectActionButton.styled';
import { dedicatedModesStore } from '../Diagram/stores/index';

export default function ProjectActionButton({ onUpload, variant, children, label = 'New' }) {
  const entityIconSize = 16;

  const [isCreatingFileType, setIsCreatingFileType] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const fileUploadRef = useRef();

  const handleCreateBpmn = async () => {
    setIsCreatingFileType(BPMN);

    const diagram = await projectStore.createFile({ type: BPMN });

    setIsCreatingFileType(null);

    if (diagram) {
      breadcrumbStore.toggleEditingFor('diagram');
      history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
    }
  };

  const handleUpload = (files, source) => {
    setAnchorEl(null);

    onUpload(files, source);
  };

  const handleCreateForm = async () => {
    setIsCreatingFileType(FORM);

    const form = await projectStore.createFile({ type: FORM });

    setIsCreatingFileType(null);

    if (form) {
      breadcrumbStore.toggleEditingFor('form');
      history.push(`/forms/${buildSlug(form.id, form.name)}`);
    }
  };

  const handleCreateConnector = async () => {
    setIsCreatingFileType(CONNECTOR_TEMPLATE);

    const connectorTemplate = await projectStore.createFile({ type: CONNECTOR_TEMPLATE });

    setIsCreatingFileType(null);

    if (connectorTemplate) {
      breadcrumbStore.toggleEditingFor('connector-template');
      history.push(`/connector-templates/${buildSlug(connectorTemplate.id, connectorTemplate.name)}`);
    }
  };

  const handleCreateFolder = async () => {
    setIsCreatingFileType(FOLDER);

    const folder = await projectStore.createFolder();

    setIsCreatingFileType(null);

    if (folder) {
      breadcrumbStore.toggleEditingFor('folder');
      history.push(`/folders/${buildSlug(folder.id, folder.name)}`);
    }
  };

  const handleCreateDecision = async () => {
    setIsCreatingFileType(DMN);

    const diagram = await projectStore.createFile({ type: DMN });

    setIsCreatingFileType(null);

    if (diagram) {
      breadcrumbStore.toggleEditingFor('diagram');
      history.push(`/diagrams/${buildSlug(diagram.id, diagram.name)}`);
    }
  };

  const WithLoader = ({ type, Icon, label }) =>
    isCreatingFileType === type ? (
      <>
        <Loader size="small" />
        {label}
      </>
    ) : (
      <>
        <Icon width={entityIconSize} height={entityIconSize} />
        {label}
      </>
    );

  return (
    <Fragment>
      <Button
        dropdown
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        data-test="diagram-dropdown"
        className={variant !== 'primary' && 'project-action'}
        variant={variant}
      >
        {label}
      </Button>
      <Dropdown open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {children?.({ closeDropdown: () => setAnchorEl(null) })}
        <Dropdown.ListGroup title="Blank resources">
          <Dropdown.ListItem
            data-test="create-bpmn-diagram"
            onClick={() => {
              dedicatedModesStore.resetViewModeIndexForPlay();
              handleCreateBpmn();
            }}
            disabled={isCreatingFileType === BPMN}
          >
            <Styled.ProjectActionItem>
              <WithLoader type={BPMN} label="BPMN Diagram" Icon={Diagram} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>

          <Dropdown.ListItem
            data-test="create-dmn-diagram"
            onClick={handleCreateDecision}
            disabled={isCreatingFileType === DMN}
          >
            <Styled.ProjectActionItem>
              <WithLoader type={DMN} label="DMN Diagram" Icon={Table} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>

          <Dropdown.ListItem data-test="create-form" onClick={handleCreateForm} disabled={isCreatingFileType === FORM}>
            <Styled.ProjectActionItem>
              <WithLoader type={FORM} label="Form" Icon={Form} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>

          <Dropdown.ListItem
            data-test="create-connector-template"
            onClick={handleCreateConnector}
            disabled={isCreatingFileType === CONNECTOR_TEMPLATE}
          >
            <Styled.ProjectActionItem>
              <WithLoader type={CONNECTOR_TEMPLATE} label="Connector Template" Icon={ConnectorTemplate} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>
        </Dropdown.ListGroup>

        <Dropdown.ListGroup>
          <Dropdown.ListItem
            data-test="create-folder"
            onClick={handleCreateFolder}
            disabled={isCreatingFileType === FOLDER}
          >
            <Styled.ProjectActionItem>
              <WithLoader type={FOLDER} label="Folder" Icon={Folder} />
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>
        </Dropdown.ListGroup>

        <Dropdown.ListGroup>
          <Dropdown.ListItem onClick={() => fileUploadRef.current.selectFile()}>
            <Styled.ProjectActionItem>
              <FileUploadIcon width={entityIconSize} height={entityIconSize} />
              <Styled.FileUploadContainer>
                <FileUpload
                  ref={fileUploadRef}
                  onFiles={handleUpload}
                  label="Upload files"
                  multiple
                  accept=".bpmn, .dmn, .xml, .json, .form"
                />
              </Styled.FileUploadContainer>
            </Styled.ProjectActionItem>
          </Dropdown.ListItem>
        </Dropdown.ListGroup>
      </Dropdown>
    </Fragment>
  );
}
