/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { observer } from 'mobx-react';

import { Dialog } from 'primitives';
import { diffingStore } from 'stores';

import DiffingDetailsModalContent from './DiffingDetailsModalContent';

const DiffingDetailsModal = () => (
  <Dialog open={diffingStore.state.diffingDetailsShown} onClose={diffingStore.hideDiffingDetails}>
    <DiffingDetailsModalContent />
  </Dialog>
);

export default observer(DiffingDetailsModal);
