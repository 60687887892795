/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import Service from './Service';

class UserService extends Service {
  /**
   * Fetches the modeler user object.
   *
   * @returns {Promise}
   */
  fetchMyself() {
    return this.get(`/internal-api/self`);
  }

  /**
   * Inserts or updates a modeler user.
   *
   * @returns {Promise}
   */
  upsertUser(data) {
    return this.post('/internal-api/login', data);
  }
}

export default new UserService();
