/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { isKeycloakAuthTypeEnabled } from 'utils/helpers';

import Service from './Service';

class InvitationService extends Service {
  fetchInvitationSuggestions({ searchQuery }) {
    const endpoint = isKeycloakAuthTypeEnabled() ? '/identity/users' : '/internal-api/users';
    return this.get(searchQuery ? `${endpoint}?search=${searchQuery}` : endpoint);
  }

  inviteExternalUser() {}

  resendInvitation() {}
}

export default new InvitationService();
