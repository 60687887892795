/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import localStorage from './localstorage';

export default function (error) {
  if (Date.now() - localStorage.getItem('lastFailedAsyncImport') < 10000) {
    // if a chunk loading error occured recently (<10s ago), the requested chunk might
    // actually be missing, and we throw the error to avoid infinite reloads
    throw error;
  } else {
    // if the chunk is missing, the user might have an outdated index.html or app bundle
    // reload the window to get the latest version
    localStorage.setItem('lastFailedAsyncImport', Date.now());
    window.location.reload();
  }
}
