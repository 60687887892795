/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const PublicationGroup = styled.div`
  padding: 0 10px 10px;
`;

export const PublicationInformativeText = styled.div`
  font-size: 14px;
  color: var(--cds-text-01);
  margin: var(--spacing-small) 0 var(--spacing-medium) 0;
`;

export const FormLinkSuggestion = styled.div`
  display: flex;
  gap: var(--spacing-tiny);
  margin-bottom: var(--spacing-small);
  align-items: center;

  svg {
    fill: var(--cds-blue-60);
  }
`;
