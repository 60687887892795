/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { PureComponent, createRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import SubDropdown from './SubDropdown';
import ListItem from './ListItem';
import ListGroup from './ListGroup';
import * as Styled from './styled';

export default class Dropdown extends PureComponent {
  state = {};
  ref = createRef();

  componentDidUpdate(prevProps) {
    if (prevProps.open == this.props.open) {
      return;
    }

    this.props.open ? this.init() : this.reset();
  }

  componentWillUnmount() {
    this.reset();
  }

  init() {
    document.body.addEventListener('click', this.handleBodyClick, true);
    window.addEventListener('keydown', this.handleKeyPress);
    window.addEventListener('scroll', this.handleClose);

    // eslint-disable-next-line react/no-set-state
    this.setState(this.position);
  }

  reset() {
    document.body.removeEventListener('click', this.handleBodyClick, true);
    window.removeEventListener('keydown', this.handleKeyPress);
    window.removeEventListener('scroll', this.handleClose);
  }

  handleBodyClick = (evt) => {
    if (evt.target.id !== 'runtime-download' && !evt.target.closest('[data-dropdown]')) {
      this.handleClose(evt);
    }
  };

  handleKeyPress = (evt) => {
    if (evt.key == 'Escape') {
      this.handleClose(evt);
    }
  };

  handleClose = (evt) => {
    evt.preventDefault();

    if (typeof this.props.onClose === 'function') {
      evt.stopPropagation();

      this.props.onClose(evt);
    }
  };

  get position() {
    let position = {};

    if (this.props.anchorEl && this.props.open && this.ref.current) {
      const coords = this.props.anchorEl.getBoundingClientRect();

      if (this.props.align == 'left') {
        if (document.body.offsetWidth - coords.right > this.props.width) {
          position.left = coords.left;
        } else {
          position.right = document.body.offsetWidth - coords.right;
        }
      } else {
        if (coords.right < this.props.width) {
          position.left = coords.left;
        } else {
          position.right = document.body.offsetWidth - coords.right;
        }
      }

      if (document.body.offsetHeight - coords.bottom > this.ref.current.offsetHeight) {
        position.top = coords.bottom;
      } else {
        position.top = coords.top - this.ref.current.offsetHeight;
      }
    }
    return position;
  }

  render() {
    if (!this.props.open) {
      return null;
    }

    return createPortal(
      <Styled.Dropdown
        $noPadding={this.props.noPadding}
        size={this.props.size}
        role="menu"
        data-dropdown
        style={this.state}
        data-test={this.props['data-test']}
        width={this.props.width}
        ref={this.ref}
      >
        {this.props.children}
      </Styled.Dropdown>,
      document.body
    );
  }
}

Dropdown.propTypes = {
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
  align: PropTypes.string,
  open: PropTypes.bool.isRequired,
  size: PropTypes.string,
  width: PropTypes.number
};

Dropdown.defaultProps = {
  open: false,
  size: 'normal',
  align: 'right',
  width: 200
};

Dropdown.ListItem = ListItem;
Dropdown.SubDropdown = SubDropdown;
Dropdown.Title = Styled.Title;
Dropdown.ListItemTitle = Styled.ListItemTitle;
Dropdown.ListItemDivider = Styled.ListItemDivider;
Dropdown.ListGroup = ListGroup;
