/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { organizationStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { isFolder, isBPMN, isDMN, isForm, isProject } from 'utils/helpers';

import { canCreateProjectOrFolder, isItemDisabled, isLinkAction, isMoveButtonDisabled } from './utils';

export const reducer = (state, action) => {
  const filterProjects = (project) => hasAccess(project, actions.CREATE_DIAGRAM);

  const filterFiles = (file) => {
    if (state.action === 'link') {
      return isBPMN(file) && !isItemDisabled(state, file);
    } else if (state.action === 'form-link') {
      return isForm(file) && !isItemDisabled(state, file);
    } else if (state.action === 'business-rule-task-link') {
      return isDMN(file) && file?.decisions?.length > 0;
    }

    return true;
  };

  const mapItems = (item) => ({
    ...item,
    isDisabled: isItemDisabled(state, item),
    isOpenable: isProject(item) || isFolder(item)
  });

  const getFiles = (payload) => {
    return (
      payload.files?.filter(filterFiles).map((file) => {
        if (isDMN(file)) {
          return {
            ...file,
            isOpenable: file.decisions?.length > 1
          };
        }

        return file;
      }) || []
    );
  };

  switch (action.type) {
    case 'OPEN_ORGANIZATION_ROOT':
      return {
        ...state,
        current: {},
        title: organizationStore.currentOrganization.name,
        items: action.payload.filter(filterProjects).map(mapItems),
        selected: null,
        level: 'root',
        isBackButtonVisible: false,
        isMoveButtonDisabled: true,
        isNewButtonVisible: !isLinkAction(state.action),
        isCancelButtonVisible: false
      };

    case 'OPEN_PROJECT': {
      const folders = action.payload.folders?.map(mapItems) || [];
      const files = getFiles(action.payload);

      return {
        ...state,
        current: action.payload,
        title: action.payload.name,
        items: state.showFiles ? [...folders, ...files] : folders,
        selected: null,
        level: 'project',
        isBackButtonVisible: !isLinkAction(state.action),
        isMoveButtonDisabled: state.invalidTargetIds.includes(action.payload.id) || isLinkAction(state.action),
        isNewButtonVisible: canCreateProjectOrFolder(state.action),
        isCancelButtonVisible: false
      };
    }

    case 'OPEN_FOLDER': {
      const folders = action.payload.children.map(mapItems);
      const files = getFiles(action.payload);

      return {
        ...state,
        current: action.payload,
        title: action.payload.name,
        items: state.showFiles ? [...folders, ...files] : folders,
        selected: null,
        level: 'folder',
        isBackButtonVisible: true,
        isMoveButtonDisabled: state.invalidTargetIds.includes(action.payload.id) || isLinkAction(state.action),
        isNewButtonVisible: !isLinkAction(state.action),
        isCancelButtonVisible: false
      };
    }

    case 'OPEN_FILE': {
      return {
        ...state,
        current: action.payload,
        title: action.payload.name,
        items: action.payload.decisions?.map((decision) => ({
          ...decision,
          type: 'DECISION',
          diagramId: action.payload.id,
          projectId: action.payload.projectId
        })),
        selected: null,
        level: 'file',
        isBackButtonVisible: true,
        isMoveButtonDisabled: state.invalidTargetIds.includes(action.payload.id) || isLinkAction(state.action),
        isNewButtonVisible: !isLinkAction(state.action),
        isCancelButtonVisible: false
      };
    }

    case 'OPEN_CONFIRMATION':
      return {
        ...state,
        level: 'confirmation',
        title: 'Move items?',
        current: action.payload,
        items: [],
        selected: null,
        isBackButtonVisible: false,
        isMoveButtonDisabled: false,
        isNewButtonVisible: false,
        isCancelButtonVisible: true
      };

    case 'OPEN_DUPLICATE_CONFIRMATION':
      return {
        ...state,
        level: 'linking-duplicates',
        title: state.action === 'form-link' ? 'Multiple forms linked' : 'Multiple diagrams linked',
        items: action.payload,
        current: state.selected,
        selected: null,
        isBackButtonVisible: false,
        isMoveButtonDisabled: false,
        isNewButtonVisible: false,
        isCancelButtonVisible: true
      };

    case 'TOGGLE_SELECT_ITEM': {
      const selected =
        action.payload.id === state.selected?.id
          ? null
          : {
              ...action.payload,
              projectId: state.current.projectId || state.current.id
            };

      return {
        ...state,
        selected,
        isMoveButtonDisabled: isMoveButtonDisabled(state, selected)
      };
    }

    case 'TOGGLE_NEW_ITEM':
      return {
        ...state,
        selected: null,
        isNewItemVisible: !state.isNewItemVisible,
        isMoveButtonDisabled:
          !state.isNewItemVisible || state.invalidTargetIds.includes(state.current.id) || state.level === 'root'
      };

    case 'ADD_ITEM':
      return {
        ...state,
        isNewItemVisible: false,
        items: [...state.items, { ...action.payload, isOpenable: true }]
      };

    default:
      throw new Error();
  }
};

export const getInitialState = (startingPoint, action, showFiles, invalidTargetIds) => {
  const isDisabled = (item) => startingPoint.id !== item.id && invalidTargetIds.includes(item.id);

  const common = {
    isBackButtonVisible: !isLinkAction(action),
    isMoveButtonDisabled: invalidTargetIds.length > 0 || isLinkAction(action),
    isNewItemVisible: false,
    isNewButtonVisible: canCreateProjectOrFolder(action),
    isCancelButtonVisible: false,
    title: startingPoint.name,
    selected: null,
    current: startingPoint,
    startingPoint,
    invalidTargetIds,
    showFiles,
    action,
    shouldIncludeFiles: showFiles,
    shouldIncludeFolders: true
  };

  const filteredFiles =
    startingPoint.files?.length > 0
      ? startingPoint.files
          .filter((file) => {
            if (isDisabled(file)) {
              return false;
            }

            if (action === 'link') {
              return isBPMN(file);
            } else if (action === 'form-link') {
              return isForm(file);
            } else if (action === 'business-rule-task-link') {
              return isDMN(file) && file?.decisions?.length > 0;
            }

            return true;
          })
          .map((file) => ({ ...file, isOpenable: isDMN(file) && file.decisions?.length > 1 }))
      : [];

  if (isFolder(startingPoint)) {
    const folders = startingPoint.children?.map((item) => ({
      ...item,
      isDisabled: isDisabled(item),
      isOpenable: true
    }));

    return {
      ...common,
      level: 'folder',
      items: showFiles ? [...folders, ...filteredFiles] : folders
    };
  }

  const foldersWithIds =
    startingPoint.folders?.length > 0
      ? startingPoint.folders.map((folder) => ({
          ...folder,
          projectId: startingPoint.id,
          isDisabled: isDisabled(folder),
          isOpenable: true
        }))
      : [];

  return {
    ...common,
    level: 'project',
    items: showFiles ? [...foldersWithIds, ...filteredFiles] : foldersWithIds,
    type: startingPoint.type
  };
};
