/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

export default function isValidConnectorTemplate(json) {
  try {
    const parsed = typeof json === 'object' ? json : JSON.parse(json);

    return parsed.appliesTo && typeof parsed.appliesTo === 'object';
  } catch (err) {
    return false;
  }
}
