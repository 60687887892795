/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { Service } from 'services';

class ZeebeClientService extends Service {
  async checkConnection(payload) {
    const { authentication, endPoint, ...rest } = payload;

    const buildRequest = () => {
      if (authentication === 'none') {
        return {
          clusterUrl: endPoint
        };
      } else {
        const { oAuthURL, audience, clientID, clientSecret, scope } = rest;
        return {
          clusterUrl: endPoint,
          authorization: {
            serverUrl: oAuthURL,
            audience: audience,
            clientId: clientID,
            clientSecret: clientSecret,
            ...(scope && { scope })
          }
        };
      }
    };

    const getEndpointType = () => {
      if (authentication === 'none') {
        return endpointTypes.SELF_HOSTED;
      } else {
        return endpointTypes.OAUTH;
      }
    };

    const request = buildRequest();

    try {
      const response = await this.post('/internal-api/zeebe/check-connection', request);

      if (response.status === 'OK') {
        return {
          success: true,
          clusterVersion: response.clusterVersion
        };
      } else {
        return {
          success: false,
          reason: getErrorReason(response, getEndpointType())
        };
      }
    } catch (e) {
      return {
        success: false,
        reason: 'An unexpected exception occurred.',
        exception: e
      };
    }
  }
}

export default new ZeebeClientService();

const endpointTypes = {
  SELF_HOSTED: 'selfHosted',
  OAUTH: 'oauth'
};

const ERROR_REASONS = {
  UNKNOWN: 'UNKNOWN',
  CONTACT_POINT_UNAVAILABLE: 'CONTACT_POINT_UNAVAILABLE',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  OAUTH_URL: 'OAUTH_URL',
  UNSUPPORTED_ENGINE: 'UNSUPPORTED_ENGINE'
};

function getErrorReason(error, type) {
  const { status, message } = error;

  // (1) handle grpc errors
  if (status === 'UNAVAILABLE') {
    return ERROR_REASONS.CONTACT_POINT_UNAVAILABLE;
  } else if (status === 'UNIMPLEMENTED') {
    return ERROR_REASONS.UNSUPPORTED_ENGINE;
  }

  // (2) handle <unknown>
  if (!message) {
    return ERROR_REASONS.UNKNOWN;
  }

  // (3) handle <not found>
  if (message.includes('ENOTFOUND') || message.includes('Not Found')) {
    if (type === endpointTypes.OAUTH) {
      return ERROR_REASONS.OAUTH_URL;
    }

    return ERROR_REASONS.CONTACT_POINT_UNAVAILABLE;
  }

  // (4) handle other error messages
  if (message.includes('Unauthorized')) {
    return ERROR_REASONS.UNAUTHORIZED;
  }

  if (message.includes('Forbidden')) {
    return ERROR_REASONS.FORBIDDEN;
  }

  if (message.includes('Unsupported protocol') && type === endpointTypes.OAUTH) {
    return ERROR_REASONS.OAUTH_URL;
  }

  return ERROR_REASONS.UNKNOWN;
}
