/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import * as Styled from './styled';

export default function WithLink({ children, to, target }) {
  if (/^http(s)?:\/\//.test(to)) {
    return (
      <Styled.AnchorLink href={to} target={target}>
        {children}
      </Styled.AnchorLink>
    );
  } else if (to) {
    return <Styled.ReactRouterLink to={to}>{children}</Styled.ReactRouterLink>;
  }

  return children;
}
