/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import { useEffect, useState } from 'react';

import hashUserId from './hashUserId';

export default function useInitStatsigParams(userId, currentOrganizationId, currentOrganizationInfo) {
  const [isStatsigParamsInitialized, setIsStatsigParamsInitialized] = useState(false);
  const [hashedUserId, setHashedUserId] = useState(null);

  useEffect(() => {
    async function initialize(userIdVal) {
      setHashedUserId(await hashUserId(userIdVal));
      setIsStatsigParamsInitialized(true);
    }

    if (userId && currentOrganizationId && currentOrganizationInfo?.salesPlan?.type) {
      initialize(userId);
    }
  }, [userId, currentOrganizationId, currentOrganizationInfo?.salesPlan?.type]);

  return { isStatsigParamsInitialized, hashedUserId };
}
