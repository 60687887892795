/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import styled from 'styled-components';

export const ShortcutTable = styled.table`
  color: var(--grey-darken-33);
  line-height: 1.618;
  font-size: 1em;
  font-weight: 400;
  display: table;
  width: 100%;
`;
