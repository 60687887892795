/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import BaseDedicatedModesStore from './DedicatedModesStore.common';

class DedicatedModesStore extends BaseDedicatedModesStore {
  constructor() {
    super();
  }
}

export default new DedicatedModesStore();
