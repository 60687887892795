/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements and licensed to you under a proprietary license.
 * You may not use this file except in compliance with the proprietary license.
 */

import DetectUserTasks from './DetectUserTasks';

export default {
  __init__: ['detectUserTasks'],
  detectUserTasks: ['type', DetectUserTasks]
};
